import React, { useEffect, useState } from "react";
import * as ANTD from "antd";

import excel from "../assets/vector-icons/excel.png";

import search from "../assets/vector-icons/search.png";

import APIClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import {
 
  Image,

  Button,

} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const Storevistors = (props) => {
  const {  Content } = ANTD.Layout;
 
  const [formLayout, setFormLayout] = useState("vertical");
  const [showTable, setShowTable] = useState(false);
  const [UserID, setUserID] = useState(false);
  const [form] = ANTD.Form.useForm();
  const [providersList, setProvidersList] = useState([]);
  const { Option } = ANTD.Select;
  const [AgentField, setAgentField] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [Agentlist, setAgentlist] = useState([]);
  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  useEffect(() => {
    getProviders();
  }, []);
  const getProviders = () => {
    APIClient.get("admin/b2b/user")
      .then((res) => {
        if (res.status == 200) {
          let filterProvider = res.data.filter((item) => item.Status === 1);
          setProvidersList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "sno",
    },
  
    {
      title: "Title",
      dataIndex: "Title",
      key: "tittle",
    },
    {
      title: "First Name",
      dataIndex: "FirstName",

      sorter: (a, b) => a.FirstName.localeCompare(b.FirstName),
    },
    {
      title: "Last Name",
      dataIndex: "LastName",

      sorter: (a, b) => a.LastName.localeCompare(b.LastName),
    },
    {
      title: "Mobile Number",
      dataIndex: "MobileNumber",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Interest / Remarks",
      dataIndex: "Remarks",
    },
    {
      title: "Remainder TYpe",
      dataIndex: "ReminderType",
      key: "rtype",
    },
    {
      title: "Ititenary Upload",
      dataIndex: "Uploads",
      render: (Uploads) => <Image width={200} height={60} src={Uploads} />,
    },
  ];
  function chooseTitle(res) {
    let result = "";
    if (res == 1) {
      result += "Mr";
    }
    if (res == 2) {
      result += "Ms";
    }
    if (res == 3) {
      result += "Mrs";
    }
    return result;
  }
  function chooseReminderType(res) {
    let result = "";
    if (res.indexOf("1") != -1) {
      result += "Mobile,";
    }
    if (res.indexOf("2") != -1) {
      result += " Email,";
    }

    if (res.indexOf("3") != -1) {
      result += " Chat,";
    }
    if (res.indexOf("4") != -1) {
      result += " SocialMedia,";
    }
    return result;
  }

  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;
  const searchView = () => {
    setShowTable(true);
  };

  const reset = () => {
    setShowTable(false);
    setAgentlist([]);
    form.resetFields();
  };
  const disfield = (value) => {
    if (value == 1) {
      setAgentField(true);
    } else {
      setAgentField(false);
    }
  };
  function getAllStoreVisitorsByUserID(UserID) {
    APIClient.get(`StoreVisitors/GetAllVisitors/${UserID}`)
      .then((res) => {
        if (res.statusCode == 200) {
          let filterdata = res.data.filter((item) => item.Status === 1);
          let data = filterdata.map((item, index) => {
            return {
              ...item,
              SNo: index + 1,
              Title: chooseTitle(item.Title),
              FirstName: item.FirstName,
              LastName: item.LastName,
              MobileNumber: item.MobileNumber,
              Remarks: item.Remarks,
              ReminderType: chooseReminderType(item.ReminderType),
            };
          });
          setAgentlist(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = Agentlist.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setAgentlist(searchResults);
    } else {
      UserID ? getAllStoreVisitorsByUserID(UserID) : getAllStoreVisitors();
    }
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const handleExcel = () => {
    const excelData = Agentlist.map((item) => {
      return {
        S_No: item.SNo,
        Title: item.Title,
        FirstName: item.FirstName,
        LastName: item.LastName,
        MobileNumber: item.MobileNumber,
        Remarks: item.Remarks,
        ReminderType: item.ReminderType,
      };
    });

    props.exportExcel(excelData, "Storevisitors");
  };

  const onFinish = (values) => {
    if (values.ReportsOf == 1) {
      getAllStoreVisitors();
      setShowTable(true);
    }
    if (values.ReportsOf == 2) {
      setUserID(values.UserID);
      getAllStoreVisitorsByUserID(values.UserID);
      setShowTable(true);
    }
    var data = {
      ReportsOf: values.ReportsOf,
      UserID: values.UserID,
    };
  };
  const getAllStoreVisitors = () => {
    APIClient.get("StoreVisitors/AllStoreVisitors")
      .then((res) => {
        if (res.statusCode == 200) {
          let data = res.data.map((item, index) => {
            return {
              ...item,
              SNo: index + 1,
              Title: chooseTitle(item.Title),
              FirstName: item.FirstName,
              LastName: item.LastName,
              MobileNumber: item.MobileNumber,
              Remarks: item.Remarks,
              ReminderType: chooseReminderType(item.ReminderType),

              
            };
          });
          setAgentlist(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    <h5>
                      Store Vistors{" "}
                      <HelpInfoHelper screenName={"/admin/storevistors"} />
                    </h5>
                   
                    <div className="add-icon d-none">
                      <i className="fa fa-plus"></i>
                    </div>
                  </div>
                  <div>
                    &nbsp;
                    <ANTD.Form
                      form={form}
                      {...formItemLayout}
                      layout={formLayout}
                      initialValues={{ layout: formLayout }}
                      onFinish={onFinish}
                    >
                      <ANTD.Row>
                        
                        <ANTD.Col md={8} xs={24}>
                          <ANTD.Form.Item
                            label="Reports of"
                            name="ReportsOf"
                            rules={[{ required: true }]}
                          >
                            <ANTD.Select placeholder="All" onChange={disfield}>
                              <option value={1}>All</option>
                              <option value={2}>Specific Partner</option>
                            </ANTD.Select>
                          </ANTD.Form.Item>
                        </ANTD.Col>
                        {AgentField ? (
                          <ANTD.Col md={8} xs={24}>
                            <ANTD.Form.Item label="Partner" name="UserID">
                              <ANTD.Select
                                placeholder="Select Partner"
                                disabled={AgentField}
                              >
                                {providersList.map((data, index) => (
                                  <Option key={index} value={data.UserID}>
                                    {data.userDetails.FirstName}
                                  </Option>
                                ))}
                              </ANTD.Select>
                            </ANTD.Form.Item>
                          </ANTD.Col>
                        ) : (
                          <ANTD.Col md={8} xs={24}>
                            <ANTD.Form.Item
                              label="Partner"
                              name="UserID"
                              rules={[
                                { required: true, message: "Partner required" },
                              ]}
                            >
                              <ANTD.Select
                                placeholder="Select Partner"
                                disabled={AgentField}
                              >
                                {providersList.map((data, index) => (
                                  <Option key={index} value={data.UserID}>
                                    {data.userDetails.FirstName}
                                  </Option>
                                ))}
                              </ANTD.Select>
                            </ANTD.Form.Item>
                          </ANTD.Col>
                        )}
                      </ANTD.Row>
                      <ANTD.Row className="reports-btns">
                        <ANTD.Col>
                          {/* <div > */}
                          <ANTD.Form.Item>
                            <Button type="primary" htmlType="submit">
                              Search
                            </Button>
                          </ANTD.Form.Item>
                        </ANTD.Col>
                        <ANTD.Col>
                          <ANTD.Form.Item>
                            <ANTD.Button type="danger" onClick={reset}>
                              Reset
                            </ANTD.Button>
                            {/* </div> */}
                          </ANTD.Form.Item>
                        </ANTD.Col>
                      </ANTD.Row>
                    </ANTD.Form>
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                   
                    <div className="rows-count">
                      <div>
                        <h5>View Report</h5>
                        <p>{Agentlist.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                     
                    </div>
                    
                  </div>

                 

                  <div>
                   
                    {showTable && (
                      <div>
                        <ANTD.Table
                          className="table-scroll-none"
                          bordered
                          dataSource={Agentlist}
                          columns={columns}
                        />
                      </div>
                    )}

                  
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>
    </div>
  );
};

export default UpdatedComponent(Storevistors);
