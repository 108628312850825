import {
  getConvFee,
  getPromoDiscount,
} from "../../common/AllTickets/promoConvhelper";

export function getHolidayPrice(
  holidayCheckOutData,
  ConvFee,
  promoData,
  redeemAmount
) {
  let totalAmount = 0;
  let grandTotal = 0;
  let convAmount = 0;
  let discount = 0;
  let markup = 0;
  let agentMarkup = 0;
  let commision = 0;
  let redeemTotal = 0;
  let totalPackagePrice = 0;
  let totalExtraCharge = 0;
  let totaladultamount = 0;
  let totalchildamount = 0;
  let totalinfantamount = 0;
  let adultCount = 0;
  let childCount = 0;
  let infantCount = 0;
  totalAmount = holidayCheckOutData?.price?.totalAmount;
  totalPackagePrice = holidayCheckOutData?.price?.totalPackagePrice;
  totalExtraCharge = holidayCheckOutData?.price?.totalExtraCharge;
  totaladultamount = holidayCheckOutData?.price?.totalAdultPrice;
  totalchildamount = holidayCheckOutData?.price?.totalChildPrice;
  totalinfantamount = holidayCheckOutData?.price?.totalInfantPrice;
  adultCount = holidayCheckOutData?.price?.adultCount;
  childCount = holidayCheckOutData?.price?.childCount;
  infantCount = holidayCheckOutData?.price?.infantCount;

  discount = Number(getPromoDiscount(promoData, totalAmount));

  grandTotal = totalAmount - discount;


  return {
    totalAmount: Number(totalAmount).toFixed(2),
    grandTotal: Number(grandTotal).toFixed(2),
    totalPackagePrice: Number(totalPackagePrice).toFixed(2),
    totalExtraCharge: Number(totalExtraCharge).toFixed(2),
    totaladultamount: Number(totaladultamount).toFixed(2),
    totalchildamount: Number(totalchildamount).toFixed(2),
    totalinfantamount: Number(totalinfantamount).toFixed(2),
    convAmount,
    discount,
    markup,
    agentMarkup,
    commision,
    redeemTotal,
    adultCount,
    childCount,
    infantCount
  };
}

export function getHolidayPartPaymentDetails(grandTotal, partPayment) {
  let partPaymentAmount = 0;
  let dueAmount = 0;
  if (partPayment.isPartPayment) {
    let copyTotal = grandTotal;

    partPaymentAmount = Number(
      (parseFloat(copyTotal) * partPayment?.PartPaymentPercentage) / 100
    ).toFixed(2);

    dueAmount = Number(copyTotal - partPaymentAmount).toFixed(2);
  } else {
    partPaymentAmount = grandTotal;
  }

  return {
    partPaymentAmount,
    dueAmount,
  };
}

export function getCmsType(type) {
  switch (type) {
    case 1:
      return "Terms and Conditions";
    case 2:
      return "Cancel Policy ";
    case 3:
      return "Whats Included ";
    case 4:
      return "Whats Not Included ";
    case 5:
      return "Payment Policy ";
    case 6:
      return "How To use ";
    case 7:
      return "PickUp Information ";
    case 8:
      return "Additional info ";
    case 9:
      return "Instructions ";
    case 10:
      return "Liveguide ";
    case 11:
      return "How to Get There ";
    case 12:
      return "Common Questions ";
    default:
      return "";
  }
}
