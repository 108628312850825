import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Row,
  Spin,
  Table,
  Select,
} from "antd";
import UpdatedComponent from "../ExportExcel";

import moment from "moment";
import ApiClient from "../../helpers/ApiClient";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Content } = Layout;
const { Option } = Select;
const oriDateFormat = "DD-MM-YYYY";

const HolidayRescheduleRequest = ({ exportExcel }) => {
  const [showTable, setShowTable] = useState(false);
  const [rescheduleList, setRescheduleList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [currentRecord, setCurrentRecord] = useState({});
  const [viewRecord, setViewRecord] = useState("view");
  const [selectedRescheduleStatus, setSelectedRescheduleStatus] = useState(null);

  const [viewModel, setViewModal] = useState(false);
  const [resReqForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [rescheduleForm] = Form.useForm();
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [toDisableDate, setToDisableDate] = useState(moment());

  const onChangeFromDate = (momentdate, _) => {
    setToDisableDate(momentdate);
  };
  const disabledSelectedDate = (currentDate) => {
    return currentDate < moment(toDisableDate).startOf("day");
  };

  const getStatus = (status) => {
    switch (status) {
      case 1: {
        return (
          <p style={{ color: "#FFA500", marginBottom: 0 }}>
            ALREADY CANCELLED{" "}
          </p>
        );
      }

      case 2: {
        return <p style={{ color: "#FF0000", marginBottom: 0 }}>BLOCKED</p>;
      }

      case 3: {
        return <p style={{ color: "#008000", marginBottom: 0 }}>SUCCESS </p>;
      }
      case 4: {
        return <p style={{ color: "#FFA500", marginBottom: 0 }}>CANCELLED</p>;
      }
      case 5: {
        return (
          <p style={{ color: "#FF0000", marginBottom: 0 }}>PARTIAL CANCELLED</p>
        );
      }
      case 6: {
        return (
          <p style={{ color: "#FF0000", marginBottom: 0 }}>
            CANCELLATION REQUESTED
          </p>
        );
      }
      case 7: {
        return (
          <p style={{ color: "#FF0000", marginBottom: 0 }}>
            CANCELLATION REJECTED
          </p>
        );
      }
      case 8: {
        return (
          <p style={{ color: "#FF0000", marginBottom: 0 }}>
            {" "}
            RESCHEDULE REQUESTED{" "}
          </p>
        );
      }
      case 9: {
        return (
          <p style={{ color: "#FF0000", marginBottom: 0 }}>
            {" "}
            RESCHEDULE REJECTED
          </p>
        );
      }
      case 10: {
        return (
          <p style={{ color: "#FF0000", marginBottom: 0 }}>
            {" "}
            RESCHEDULE APPROVED{" "}
          </p>
        );
      }

      default:
        return;
    }
  };

  const showViewModal = () => {
    setViewModal(true);
  };

  const tableActions = (_, rec) => {
    return (
      <>
        <Button
          onClick={() => {
            setCurrentRecord(rec);
            showViewModal();
            setViewRecord("view");
          }}
          className="mr-1"
        >
          View
        </Button>

        {rec.RescheduleStatus === "RESCHEDULEREQUESTED" && (
          <Button
            onClick={() => {
              setCurrentRecord(rec);
              showViewModal();
              setViewRecord("reschedule");
            }}
          >
            Reschedule
          </Button>
        )}
      </>
    );
  };

  const columns = [
    {
      title: "Action",

      render: (text, rec) => {
        return tableActions(text, rec);
      },
    },
    {
      title: "Ref No.",
      dataIndex: "ReferenceNo",
      sorter: (a, b) => a.ReferenceNo.localeCompare(b.ReferenceNo),
    },

    {
      title: "Res. Status",
      dataIndex: "RescheduleStatus",
    },

    {
      title: "Bk. Amt.",
      dataIndex: "BookingAmount",
    },

    {
      title: "Res. Date",
      dataIndex: "RescheduleDate",
      render: (RescheduleDate) => moment(RescheduleDate).format("DD-MM-YYYY"),
    },

    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
  ];

  const getRescheduleRequest = (values) => {
    setLoading(true);
    setShowTable(true);
    setRescheduleList([]);

    setTableData([]);
    ApiClient.get("HolidayExtranet/Holidaye/reschedulefilter", values)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setRescheduleList(data);
          setTableData(data);
        }
        setLoading(false);
      })
      .catch((e) => {
        setRescheduleList([]);
        setTableData([]);
        setLoading(false);
      });
  };

  const onFormSubmit = (values) => {
    if (values.options == 5) {
      values.fromDate = moment(values.fromDate).format("YYYY-MM-DD");
      values.toDate = moment(values.toDate).format("YYYY-MM-DD");
    } else {
      values.fromDate = "";
      values.toDate = "";
    }
    getRescheduleRequest(values);
  };
  let key = 0;
  const keyGenerate = () => {
    return key++;
  };
  const formSubmit = (values, record) => {
    let data = {
      ReferenceNo: record.ReferenceNo,
      RescheduleId: record.Id,
      AdminRemarks: values.AdminRemarks,
      RescheduleStatus: values.RescheduleStatus,
      RescheduleAmount: Number(values.RescheduleAmount),
      BankName: values.BankName,
      BankAccountNo: values.BankAccountNo,
      IFSC_CODE: values.IFSC_CODE,
    };
    ApiClient.put("HolidayExtranet/Holidayreschedule/update", {}, data)
      .then((res) => {
        if (res.status === 200) {
          message.success("Reschedule Successfully", 3);
          setViewModal(false);
          setCurrentRecord({});
          rescheduleForm.resetFields();
        } else {
          message.error(" Failed", 3);
        }
      })
      .catch((e) => {});
  };

  const RescheduleDetails = () => {
    if (Object.keys(currentRecord).length > 0)
      return (
        <div>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <p>
                <b>Tour Name:</b> {currentRecord.TourName}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Booking Amount:</b> {currentRecord.BookingAmount}
              </p>
            </Col>

            <Col md={12} xs={24}>
              <p>
                <b>Reference Number:</b> {currentRecord.ReferenceNo}
              </p>
            </Col>

            <Col md={12} xs={24}>
              <p>
                <b>Travelling Date:</b> {currentRecord.TravellingDate}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Reschedule Date:</b>{" "}
                {currentRecord.RescheduleDate
                  ? moment(currentRecord.RescheduleDate).format("DD-MM-YYYY")
                  : ""}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Reschedule Charge:</b> {currentRecord.RescheduleCharge}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Name:</b> {currentRecord.Name}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Email:</b> {currentRecord.Email}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Remarks:</b> {currentRecord.Remarks}
              </p>
            </Col>
          </Row>
        </div>
      );
  };

  const handleTimeAndDate = (value) => {
    if (value === 5) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };

  return (
    <div className="manage-markup-section">
      <Content className="container-fluid">
        <Row>
          <Col span={24}>
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <h5 className="mb-1">
                    Reschedule Requests{" "}
                    <HelpInfoHelper
                      screenName={"/admin/toursextranet-reschedulerequest"}
                    />
                  </h5>
                </div>
              </div>
              <div className="card-bt-gap">
                <Form
                  layout="vertical"
                  initialValues={{
                    RescheduleStatus: "",
                    ReferenceNo: "",

                    options: 2,
                  }}
                  form={resReqForm}
                  onFinish={onFormSubmit}
                >
                  <Row gutter={16}>
                    <Col className="gutter-row" md={6} xs={24}>
                      <Form.Item
                        label="Time and Date"
                        name="options"
                        rules={[{ required: true }]}
                      >
                        <Select
                          placeholder="Please select"
                          onChange={handleTimeAndDate}
                        >
                          <Option value={1}>Last One Hour</Option>
                          <Option value={2}>Yesterday</Option>
                          <Option value={3}>Last Week</Option>
                          <Option value={5}>Custom Dates</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {isDateDisplay ? (
                      <>
                        <Col md={6} sm={12} xs={24}>
                          <Form.Item
                            label="From Date"
                            name="fromDate"
                            rules={[{ required: true }]}
                          >
                            <DatePicker
                              format="YYYY-MM-DD"
                              style={{ width: "100%" }}
                              disabledDate={disabledFutureDate}
                              onChange={(date, dateString) =>
                                onChangeFromDate(date, dateString)
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col md={6} sm={12} xs={24}>
                          <Form.Item
                            label="To Date"
                            name="toDate"
                            rules={[{ required: true }]}
                          >
                            <DatePicker
                              format="YYYY-MM-DD"
                              style={{ width: "100%" }}
                              disabledDate={disabledSelectedDate}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    ) : null}
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item label="Reference No" name="ReferenceNo">
                        <Input autocomplete="newpassword" />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label="Reschedule Status"
                        name="RescheduleStatus"
                      >
                        <Select placeholder="Reschedule Status">
                          <Option value={"SUCCESS"}>Success</Option>
                          <Option value={"PENDING"}>Pending</Option>
                          <Option value={"CANCELLED"}>Cancelled</Option>
                          <Option value={"ONHOLD"}>On Hold</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-class" md={18} xs={24}>
                      <Row className="reports-btns">
                        <Button type="primary" htmlType="submit">
                          Search
                        </Button>
                        <Button
                          type="danger"
                          onClick={(e) => setShowTable(false)}
                        >
                          Reset
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Card>
          </Col>
          {showTable && (
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  {tableData.length > 0 ? (
                    <>
                      <div className="card-add-heading">
                        <div className="rows-count">
                          <div>
                            <h5>View Reschedule Request</h5>
                            <p>{tableData.length} rows found !</p>
                          </div>
                        </div>
                       
                      </div>

                      <Table
                        bordered
                        dataSource={tableData}
                        columns={columns}
                        scroll={{ x: true }}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                        rowKey={keyGenerate}
                      />
                    </>
                  ) : loading ? (
                    <Spin
                      size="large"
                      tip="Loading..."
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <b>No Results Found!</b>
                  )}
                </Card>
              </div>
            </Col>
          )}
        </Row>

        <Modal
          width="900px"
          visible={viewModel}
          title="Reschedule Request"
          className=" modal-header-bg12"
          onCancel={(e) => {
            setViewModal(false);
          }}
          footer={[
            <div>
              <Button key="clear" onClick={() => setViewModal(false)}>
                Close
              </Button>
              {viewRecord === "reschedule" && (
                <Button
                  key="clear"
                  onClick={() => {
                   
                    rescheduleForm.submit();
                  }}
                  type="primary"
                >
                  Reschedule
                </Button>
              )}
            </div>,
          ]}
        >
          {Object.keys(currentRecord).length > 0 && (
            <div>
              {RescheduleDetails()}
              {viewRecord === "reschedule" && (
                <Form
                  form={rescheduleForm}
                  layout="vertical"
                  onFinish={(val) => formSubmit(val, currentRecord)}
                  className="mt-2"
                >
                  <Row gutter={16}>
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label="Reschedule Status"
                        name="RescheduleStatus"
                        rules={[{ required: true }]}
                      >
                        <Select placeholder="Reschedule Status"
                        onChange={(value) => setSelectedRescheduleStatus(value)}
                        >
                          <Option value={8}>RESCHEDULE REQUESTED </Option>
                          <Option value={9}>RESCHEDULE REJECTED </Option>
                          <Option value={10}>RESCHEDULE APPROVED </Option>
                          <Option value={11}>RESCHEDULE SUCCESS </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {selectedRescheduleStatus === 8 && (
  <>
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label=" Reschedule Amount"
                        name="RescheduleAmount"
                        rules={[
                          { required: true },
                          {
                            pattern: "^[0-9]*$",
                            message: "Must be a Number",
                          },
                        ]}
                      >
                        <Input placeholder="RescheduleAmount"></Input>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label=" Bank Name"
                        name="BankName"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="BankName"></Input>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label=" Bank Account No"
                        name="BankAccountNo"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Bank Account No"></Input>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label=" IFSC CODE"
                        name="IFSC_CODE"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="IFSC CODE"></Input>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label=" Remarks"
                        name="AdminRemarks"
                        rules={[{ required: true }]}
                      >
                        <Input.TextArea placeholder="Admin Remarks"></Input.TextArea>
                      </Form.Item>
                    </Col>
                    </>
                    )}
                  </Row>
                </Form>
              )}
            </div>
          )}
        </Modal>
      </Content>
    </div>
  );
};

export default UpdatedComponent(HolidayRescheduleRequest);
