import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Row,
  Spin,
  Table,
  Select,
  Radio,
  Upload,
} from "antd";
import UpdatedComponent from "../ExportExcel";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { getPaymentType } from "../../helpers/Deposits/depositsHelpers";
import moment from "moment";
import ApiClient from "../../helpers/ApiClient";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import { PlusOutlined } from "@ant-design/icons";
import HolidayCancellationPolicy from "../../pages/Holidays/HolidayCancellationPolicy";

const { Content } = Layout;
const { Option } = Select;

const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/singleImage";

const ImageUpload = ({
  category,
  fileList,
  setFileList,
  formName,
  deleteBanner,
  limit,
  limitSize,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadImageButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    if (info.file.status === "uploading") {
      setFileList(info.fileList);
      setIsUploading(false);
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
      setFileList(info.fileList);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setFileList(info.fileList);
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item
        name={formName}
        // rules={[
        //   { required: fileList.length > 0 ? false : true, message: "Required" },
        // ]}
        label="Image Upload"
      >
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList.length >= limitSize
              ? null
              : uploadImageButton
            : uploadImageButton}
        </Upload>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const HolidayCancelRequest = ({ exportExcel }) => {
  const { user } = useAuthContext();
  const [showTable, setShowTable] = useState(false);
  const [cancellationList, setCancellationList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [refundRecord, setRefundRecord] = useState({});
  const [refundModal, setRefundModal] = useState(false);
  const [viewModel, setViewModal] = useState(false);
  const [holidayRefundModal, setHolidayRefundModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refundform] = Form.useForm();
  const [holidayRefundform] = Form.useForm();
  const [cancelReqForm] = Form.useForm();
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [toDisableDate, setToDisableDate] = useState(moment());
  const [offlineInputFeilds, setOfflineInputFeilds] = useState(false);
  const [pgList, setPgList] = useState([]);
  const [image, setImage] = useState([]);

  const getPgdetails = () => {
    ApiClient.get("admin/b2bPG")
      .then((res) => {
        if (res.status == 200) {
          setPgList(res.data);
        }
      })
      .catch(() => {
        setPgList([]);
      });
  };

  const onChangeFromDate = (momentdate, _) => {
    setToDisableDate(momentdate);
  };
  const disabledSelectedDate = (currentDate) => {
    return currentDate < moment(toDisableDate).startOf("day");
  };

  const getStatus = (status) => {
    switch (status) {
      case 1: {
        return (
          <p style={{ color: "#FFA500", marginBottom: 0 }}>
            ALREADY CANCELLED{" "}
          </p>
        );
      }

      case 2: {
        return <p style={{ color: "#FF0000", marginBottom: 0 }}>BLOCKED</p>;
      }

      case 3: {
        return <p style={{ color: "#008000", marginBottom: 0 }}>SUCCESS </p>;
      }
      case 4: {
        return <p style={{ color: "#FFA500", marginBottom: 0 }}>CANCELLED</p>;
      }
      case 5: {
        return (
          <p style={{ color: "#FF0000", marginBottom: 0 }}>PARTIAL CANCELLED</p>
        );
      }
      case 6: {
        return (
          <p style={{ color: "#FF0000", marginBottom: 0 }}>
            CANCELLATION REQUESTED
          </p>
        );
      }
      case 7: {
        return (
          <p style={{ color: "#FF0000", marginBottom: 0 }}>
            CANCELLATION REJECTED
          </p>
        );
      }
      case 8: {
        return (
          <p style={{ color: "#FF0000", marginBottom: 0 }}>
            {" "}
            RESCHEDULE REQUESTED{" "}
          </p>
        );
      }
      case 9: {
        return (
          <p style={{ color: "#FF0000", marginBottom: 0 }}>
            {" "}
            RESCHEDULE REJECTED
          </p>
        );
      }
      case 10: {
        return (
          <p style={{ color: "#FF0000", marginBottom: 0 }}>
            {" "}
            RESCHEDULE APPROVED{" "}
          </p>
        );
      }

      default:
        return;
    }
  };

  const getRefundStatus = (status) => {
    // RefundPending  = 1,
    // Refunded = 2,
    // Rejected = 3
    switch (status) {
      case 1: {
        return <p>SUCCESS </p>;
      }

      case 2: {
        return <p>PENDING </p>;
      }

      case 3: {
        return <p>CANCELLED </p>;
      }
      case 4: {
        return <p>ONHOLD </p>;
      }

      default:
        return;
    }
  };

  const showRefundModal = () => {
    setRefundModal(true);
    refundform.resetFields();
  };
  const showViewModal = () => {
    setViewModal(true);
  };

  const showHolidayRefundModal = () => {
    setHolidayRefundModal(true);
    setImage([]);
    holidayRefundform.resetFields();
  };

  const tableActions = (_, rec) => {
    return (
      <>
        <Button
          onClick={() => {
            setRefundRecord(rec);
            showViewModal();
          }}
        >
          View
        </Button>

        {rec.CancellationStatus === 6 && (
          <Button
            onClick={() => {
              setRefundRecord(rec);
              showRefundModal();
            }}
          >
            Cancellation
          </Button>
        )}

        {rec.CancellationStatus === 4 &&
          rec.RefundStatus === "PENDING" &&
          !rec?.RefundType && (
            <Button
              onClick={() => {
                setRefundRecord(rec);
                showHolidayRefundModal();
              }}
            >
              Refund
            </Button>
          )}
      </>
    );
  };

  const columns = [
    {
      title: "Action",

      render: (text, rec) => {
        return tableActions(text, rec);
      },
    },
    {
      title: "Ref No.",
      dataIndex: "ReferenceNo",
      sorter: (a, b) => a.ReferenceNo.localeCompare(b.ReferenceNo),
    },

    {
      title: "Can. RefNo",
      dataIndex: "CancellationRefNo",
    },

    {
      title: "Bk. Amt.",
      dataIndex: "BookingAmount",
    },

    {
      title: "CNC",
      dataIndex: "CancellationCharge",
    },

    {
      title: "Rfd. Amt.",
      dataIndex: "RefundAmount",
    },

    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
    {
      title: "Cancellation Status",
      dataIndex: "CancellationStatus",
      render: (status) => getStatus(status),
    },
    {
      title: "Refunds Status",
      dataIndex: "RefundStatus",
    },
    {
      title: "Refund ID",
      dataIndex: "cf_refund_id",
    },
  ];

  const getCancelRequest = (values) => {
    setLoading(true);
    setShowTable(true);
    setCancellationList([]);

    setTableData([]);
    ApiClient.get("HolidayExtranet/Holidaycancellationrefundfilter", values)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setCancellationList(data);
          setTableData(data);
        }
        setLoading(false);
      })
      .catch((e) => {
        setCancellationList([]);
        setTableData([]);
        setLoading(false);
      });
  };

  const onFormSubmit = (values) => {
    if (values.options == 5) {
      values.fromDate = moment(values.fromDate).format("YYYY-MM-DD");
      values.toDate = moment(values.toDate).format("YYYY-MM-DD");
    } else {
      values.fromDate = "";
      values.toDate = "";
    }
    getCancelRequest(values);
  };
  let key = 0;
  const keyGenerate = () => {
    return key++;
  };
  const RefundCancellationSubmit = (values, record) => {
    let data = {
      CancellationId: record.Id,
      CancellationRefNo: record.CancellationRefNo,
      AdminRemarks: values.AdminRemarks,
    };
    ApiClient.post("HolidayExtranet/Holidaycancellation/cancellation", data)
      .then((res) => {
        if (res.status === 200) {
          message.success("Refund Cancellation Successfully", 3);
          setRefundModal(false);
          setRefundRecord({});
          cancelReqForm.submit();
        } else {
          message.error(" Failed", 3);
        }
      })
      .catch((e) => {});
  };

  const HolidayRefundSubmit = (values, record) => {
    let ImageArray = [];

    if (image.length > 0) {
      for (let i = 0; i < image.length; i++) {
        if (image[i].response.status === 200) {
          ImageArray.push(image[i].response.data.filepaths);
        }
      }
    }
    let data = {
      CancellationId: record.Id,
      CancellationRefNo: record.CancellationRefNo,
      ...values,
      Image: ImageArray.length > 0 ? ImageArray : "",
    };
    ApiClient.post("HolidayExtranet/Holidaycancellation/refund", data)
      .then((res) => {
        if (res.status === 200) {
          message.success(res.message, 10);
          setHolidayRefundModal(false);
          setRefundRecord({});
          cancelReqForm.submit();
        } else {
          message.error(" Failed", 3);
        }
      })
      .catch((e) => {});
  };

  const RefundCancellationDetials = () => {
    if (Object.keys(refundRecord).length > 0)
      return (
        <div>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <p>
                <b>Tour Name:</b> {refundRecord.TourName}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Booking Amount:</b> {refundRecord.BookingAmount}
              </p>
            </Col>

            <Col md={12} xs={24}>
              <p>
                <b>Reference Number:</b> {refundRecord.ReferenceNo}
              </p>
            </Col>

            <Col md={12} xs={24}>
              <p>
                <b>Cancellation Ref No:</b> {refundRecord.CancellationRefNo}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Cancellation Charge:</b> {refundRecord.CancellationCharge}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Refund Amount:</b> {refundRecord.RefundAmount}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Cancelled Date:</b> {refundRecord.CancelledDate}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Refunded Date:</b> {refundRecord.RefundedDate}
              </p>
            </Col>
            <Col md={12} xs={24}>
              <p>
                <b>Payment Method:</b> {refundRecord.PgTypeName}
              </p>
            </Col>
            {refundRecord.CancellationPolicy && (
              <Col md={12} xs={24} className="mb-2">
                <p>
                  <b> Cancellation Policy:</b>
                </p>{" "}
                <HolidayCancellationPolicy
                
                  data={refundRecord.CancellationPolicy}
                />
              </Col>
            )}
          </Row>
        </div>
      );
  };

  const handleTimeAndDate = (value) => {
    if (value === 5) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };
  const handleRefundType = (e) => {
    if (e.target.value === 0) {
      setOfflineInputFeilds(true);
    } else {
      setOfflineInputFeilds(false);
    }
  };

  useEffect(() => {
    getPgdetails();
  }, []);

  return (
    <div className="manage-markup-section">
      <Content className="container-fluid">
        <Row>
          <Col span={24}>
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <h5 className="mb-1">
                    Cancelation Requests{" "}
                    <HelpInfoHelper
                      screenName={"/admin/toursextranet-cancelrequest"}
                    />
                  </h5>
                </div>
              </div>
              <div className="card-bt-gap">
                <Form
                  layout="vertical"
                  initialValues={{
                    CancellationRefNo: "",
                    CancellationStatus: "",
                    ReferenceNo: "",
                    RefundStatus: "",
                    options: 2,
                  }}
                  form={cancelReqForm}
                  onFinish={onFormSubmit}
                >
                  <Row gutter={16}>
                    <Col className="gutter-row" md={6} xs={24}>
                      <Form.Item
                        label="Time and Date"
                        name="options"
                        rules={[{ required: true }]}
                      >
                        <Select
                          placeholder="Please select"
                          onChange={handleTimeAndDate}
                        >
                          <Option value={1}>Last One Hour</Option>
                          <Option value={2}>Yesterday</Option>
                          <Option value={3}>Last Week</Option>
                          <Option value={5}>Custom Dates</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {isDateDisplay ? (
                      <>
                        <Col md={6} sm={12} xs={24}>
                          <Form.Item
                            label="From Date"
                            name="fromDate"
                            rules={[{ required: true }]}
                          >
                            <DatePicker
                              format="YYYY-MM-DD"
                              style={{ width: "100%" }}
                              disabledDate={disabledFutureDate}
                              onChange={(date, dateString) =>
                                onChangeFromDate(date, dateString)
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col md={6} sm={12} xs={24}>
                          <Form.Item
                            label="To Date"
                            name="toDate"
                            rules={[{ required: true }]}
                          >
                            <DatePicker
                              format="YYYY-MM-DD"
                              style={{ width: "100%" }}
                              disabledDate={disabledSelectedDate}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    ) : null}
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label="Cancellation Ref. No."
                        name="CancellationRefNo"
                      >
                        <Input autocomplete="newpassword" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item
                        label="Cancellation Status"
                        name="CancellationStatus"
                      >
                        <Select placeholder="Cancellation Status">
                          <Option value={3}>Success</Option>
                          <Option value={4}>Cancelled</Option>
                          <Option value={6}>Cancellation Requested</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item label="Reference No" name="ReferenceNo">
                        <Input autocomplete="newpassword" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-class" md={6} xs={24}>
                      <Form.Item label="Refund Status" name="RefundStatus">
                        <Select placeholder="Cancellation Status">
                          <Option value={"SUCCESS"}>Success</Option>
                          <Option value={"PENDING"}>Pending</Option>
                          <Option value={"CANCELLED"}>Cancelled</Option>
                          <Option value={"ONHOLD"}>On Hold</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-class" md={18} xs={24}>
                      <Row className="reports-btns">
                        <Button type="primary" htmlType="submit">
                          Search
                        </Button>
                        <Button
                          type="danger"
                          onClick={(e) => setShowTable(false)}
                        >
                          Reset
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Card>
          </Col>
          {showTable && (
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  {tableData.length > 0 ? (
                    <>
                      <div className="card-add-heading">
                        <div className="rows-count">
                          <div>
                            <h5>View Cancellation Request</h5>
                            <p>{tableData.length} rows found !</p>
                          </div>
                        </div>
                       
                      </div>

                      <Table
                        bordered
                        dataSource={tableData}
                        columns={columns}
                        scroll={{ x: true }}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                        rowKey={keyGenerate}
                      />
                    </>
                  ) : loading ? (
                    <Spin
                      size="large"
                      tip="Loading..."
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <b>No Results Found!</b>
                  )}
                </Card>
              </div>
            </Col>
          )}
        </Row>
        <Modal
          width="900px"
          visible={refundModal}
          title="Cancelation Ticket"
          className=" modal-header-bg12"
          onCancel={(e) => {
            setRefundModal(false);
          }}
          footer={[
            <div>
              <Button key="clear" onClick={() => refundform.resetFields()}>
                Clear
              </Button>
              <Button key="add" type="primary" onClick={refundform.submit}>
                Cancel Ticket
              </Button>
            </div>,
          ]}
        >
          {Object.keys(refundRecord).length > 0 && (
            <div>
              {RefundCancellationDetials()}
              <Form
                form={refundform}
                layout="vertical"
                onFinish={(val) => RefundCancellationSubmit(val, refundRecord)}
              >
                <Row gutter={16}>
                  <Col className="gutter-class" md={12} xs={24}>
                    <Form.Item
                      label="Admin Remarks"
                      name="AdminRemarks"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Input.TextArea
                        autocomplete="newpassword"
                        placeholder=" AdminRemarks"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </Modal>
        <Modal
          width="900px"
          visible={holidayRefundModal}
          title="Refund Holiday"
          onCancel={(e) => {
            setHolidayRefundModal(false);
          }}
          footer={[
            <div>
              <Button
                key="clear"
                onClick={() => holidayRefundform.resetFields()}
              >
                Clear
              </Button>
              <Button
                key="add"
                type="primary"
                onClick={holidayRefundform.submit}
              >
                Refund
              </Button>
            </div>,
          ]}
        >
          {Object.keys(refundRecord).length > 0 && (
            <div>
              {RefundCancellationDetials()}
              <Form
                form={holidayRefundform}
                layout="vertical"
                onFinish={(val) => HolidayRefundSubmit(val, refundRecord)}
                initialValues={{ RefundType: 1 }}
              >
                <Row gutter={16}>
                  <Col className="gutter-class" md={8} xs={24}>
                    <Form.Item
                      label="Refund Type"
                      name="RefundType"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Radio.Group onChange={(e) => handleRefundType(e)}>
                        <Radio value={1}>Online</Radio>
                        <Radio
                          value={0}
                          disabled={refundRecord.PgType == 3 ? true : false}
                        >
                          Offline
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-class" md={8} xs={24}>
                    <Form.Item
                      label="Refund Remarks"
                      name="RefundRemarks"
                      rules={[{ required: false}]}
                    >
                      <Input.TextArea autocomplete="newpassword" />
                    </Form.Item>
                  </Col>
                </Row>

                {offlineInputFeilds && (
                  <Row gutter={16}>
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item
                        label="Payment Type"
                        name="DepositeType"
                        rules={[{ required: true }]}
                      >
                        <Select placeholder="PaymentType">
                          {pgList.map((data, index) => (
                            <Option key={index} value={data.PaymentTypeID}>
                              {getPaymentType(data.PaymentType)}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={6} sm={12} xs={24}>
                      <Form.Item
                        label="Transaction No"
                        name="TransactionNo"
                        rules={[
                          { required: true },
                          {
                            min: 12,
                            pattern: "^[a-zA-Z0-9]*$",
                            max: 18,
                            message: "Must be in between 12 to 18 Alphanumeric",
                          },
                        ]}
                      >
                        <Input placeholder="TransactionId" />
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item
                        label="Deposit Date"
                        name="DepositeDate"
                        rules={[{ required: true }]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format={"DD-MM-YYYY"}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                      <ImageUpload
                        category="deposits"
                        fileList={image}
                        setFileList={setImage}
                        formName="UploadImage"
                        limit={true}
                        limitSize={3}
                      />
                    </Col>
                  </Row>
                )}
              </Form>
            </div>
          )}
        </Modal>
        <Modal
          width="900px"
          visible={viewModel}
          title="View Request"
          className=" modal-header-bg12"
          onCancel={(e) => {
            setViewModal(false);
          }}
          footer={[
            <div>
              <Button key="clear" onClick={() => setViewModal(false)}>
                Close
              </Button>
            </div>,
          ]}
        >
          {Object.keys(refundRecord).length > 0 && (
            <div>{RefundCancellationDetials()}</div>
          )}
        </Modal>
      </Content>
    </div>
  );
};

export default UpdatedComponent(HolidayCancelRequest);
