import React, { useEffect, useState } from "react";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Popconfirm,
    Row,
    Table,
    Layout,
    DatePicker,
    Modal,
    Upload,
    Switch,
    Image,
    Select,
    Radio,
    Spin,
    Collapse
} from "antd";
import {
    PlusOutlined,
    EditOutlined,
    CloseOutlined,
    DeleteOutlined,
    EyeOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import moment from "moment";
import '../Adminstyles.scss';

const { TextArea } = Input;
const { Content } = Layout;
const { Option } = Select;
const BASE = process.env.REACT_APP_BASE_URL;





const Price = ({ exportExcel }) => {
    let history = useHistory();
    const { Panel } = Collapse;
    const [modalVisible, setModalVisible] = useState(false);
    const [MediaForm] = Form.useForm();
    const [priceList, setPriceList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [currID, setCurrID] = useState(-1);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [excelList, setExcelList] = useState([]);
    const [packageList, setPackageList] = useState([]);

    const [Addupdate, setAddupdate] = useState(false);
    const [video, setvideo] = useState([]);
    const [validationDate, setvalidationDate] = useState(null)
    const [arrivalDate, setArrivalDate] = useState(null);
    const [tilldate, settilldate] = useState(null);
    const [roomCategoryList, setRoomCategoryList] = useState([])

    useEffect(() => {
        getPriceList();
        getPacakageList();
        getRoomCategoryList()
    }, []);

    const getRoomCategoryList = () => {
        ApiClient.get("HolidayExtranet/getAllHotelRoomCategory")
            .then((res) => {
                if (res.status == 200) {
                    let data = res.data
                    setRoomCategoryList(data);
                } else {
                    setRoomCategoryList([]);
                }
            })
            .catch((e) => {
                setRoomCategoryList([]);
            });
    }

    const getPacakageList = () => {
        ApiClient.get("HolidayExtranet/GetHolidayPackages")
            .then((res) => {
                if (res.status == 200) {
                    setPackageList(res.data);
                    setvalidationDate(res.data)

                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getPriceList = () => {
        ApiClient.get("HolidayExtranet/holidays/price")
            .then((res) => {
                if (res.status == 200) {

                    let data = res.data.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,

                            ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
                        };
                    });
                    setPriceList(data);
                    defineExcelData(data);
                } else {
                    setPriceList([]);
                }
            })
            .catch((e) => {
                setPriceList([]);
            });
    };

    const submitForm = (values) => {

        //const stayOption = values.StayOption.split('-')

        let data = {
            HolidayPackageId: values.HolidayPackageId,
            //  RoomCategoryId: stayOption[0],
            //  RoomCategoryName: stayOption[1],
            RoomCategoryId: values.RoomCategoryId,
            PerAdultCharge: values.PerAdultCharge,
            PerChildCharge: values.PerChildCharge,
            PerInfantCharge: values.PerInfantCharge,
            ExtraCharges: values.ExtraCharges,
        };
        isEdit ? updateMediaRequest(data, currID) : addMediaRequest(data);
    };



    const addMediaRequest = (data) => {
        setAddupdate(true);

        ApiClient.post("HolidayExtranet/save/holiday/price", data)
            .then((response) => {
                if (response.status == 200) {

                    Modal.confirm({
                        title: 'Price created successfully!!',
                        content: `Please click OK to manage Media for this create package`,

                        onOk() {
                            history.push('/admin/toursextranet-media')
                        },

                    })

                  
                    getPriceList();
                    setModalVisible(false);
                    MediaForm.resetFields();
                } else {
                    message.error(response.message, 3);

                }
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const updateMediaRequest = (data, recId) => {
        setAddupdate(true);
        ApiClient.put("HolidayExtranet/update/holiday/price/" + recId, {}, data)
            .then((response) => {
                if (response.status == 200) {
                    Modal.confirm({
                        title: 'Price Updated successfully!!',
                        content: 'Please click OK to manage Media for this update package',
                        onOk() {
                            history.push('/admin/toursextranet-media')
                        },
                    })

                  
                    getPriceList();
                    setModalVisible(false);
                } else {
                    message.error(response.message, 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const deleteMediaRequest = (id) => {
        ApiClient.delete("HolidayExtranet/delete/holiday/price/" + id)
            .then((response) => {
                if (response.status == 200) {
                    Modal.confirm({
                        title: 'Price Delete successfully!!',
                        content: 'Please click OK to manage Media for this delete package',
                        onOk() {
                            history.push('/admin/toursextranet-media')
                        },

                    })
                    
                    getPriceList();
                } else {
                    message.error(response.message, 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const showModal = () => {
        setAddupdate(false);
        setIsEdit(false);

        MediaForm.resetFields();
        setModalVisible({
            visible: true,
        });
    };
    const disabledToDate = (current) => {
       
        return current.valueOf() < (new Date(arrivalDate)) || current.valueOf() > (new Date(tilldate))
    };


    const datevalidation = (val) => {

        let validation = validationDate.filter((e) => e.HolidayPackageId == val);

        let fromdate = moment(validation?.[0]?.ValidFromDatetime).format("YYYY-MM-DD")
        let todate = moment(validation?.[0]?.ValidtillDatetime).format("YYYY-MM-DD")
        setArrivalDate(fromdate);
        settilldate(todate)

    }

    const onUpdate = (currentData) => {
        setAddupdate(false);
        
        setIsEdit(true);
        setModalVisible(true);
        setCurrID(currentData.HolidayPriceId);
        MediaForm.setFieldsValue({
            ...currentData,
           
        });
    };

    const defineExcelData = (data) => {
        const filteredData = data.map((item) => {
            const obj = {
                SrNo: item.SNo,
                PackageTitle: item.PackageTitle,
                RoomCategoryName: item.RoomCategoryName,
                AdultCharge: item.PerAdultCharge,
                PerChildCharge: item.PerPerChildCharge,
                InfantCharge: item.PerInfantCharge,
                ExtraCharges: item.ExtraCharges,
                CreatedDate: item.CreatedDate

            };
            return obj;
        });
        setExcelList(filteredData);
    };

    const tableActions = (currentItem) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div
                    className="edit-icon"
                    style={{ textAlign: "center" }}
                    onClick={() => onUpdate(currentItem)}
                >
                    <EditOutlined
                        style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => deleteMediaRequest(currentItem.HolidayPriceId)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const DeleteImage = (id) => {
     };

    const columns = [
        {
            title: "Package Title",
            dataIndex: "PackageTitle",
        },

        {
            title: "RoomCategory",
            dataIndex: "RoomCategoryName",
            sorter: (a, b) => a.RoomCategoryName.localeCompare(b.RoomCategoryName),
        },

        {
            title: "Adult Charge",
            dataIndex: "PerAdultCharge",
            sorter: (a, b) => a.PerAdultCharge.localeCompare(b.PerAdultCharge),
        },
        {
            title: "Child Charge (2-5)",
            dataIndex: "PerAdultCharge",
            sorter: (a, b) => a.PerAdultCharge.localeCompare(b.PerAdultCharge),
        },

        {
            title: "Infant Charge",
            dataIndex: "PerInfantCharge",
            sorter: (a, b) => a.PerInfantCharge.localeCompare(b.PerInfantCharge),
        },
        {
            title: "Extra Charge",
            dataIndex: "ExtraCharges",
            sorter: (a, b) => a.ExtraCharges.localeCompare(b.ExtraCharges),
        },
        {
            title: "Created Date",
            dataIndex: "CreatedDate",
            render: (date) => moment(date).format("DD-MM-YYYY"),
            sorter: (a, b) => a.CreatedDate.localeCompare(b.CreatedDate),
        },

        {
            title: "Actions",
            render: (currentItem) => tableActions(currentItem),
        },
    ];





    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = priceList.filter(function (item) {
                if (item != undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setPriceList(searchResults);
        } else {
            getPriceList();
            setPriceList(priceList);
        }
    };
    const closeSearch = () => {
        setShowSearchBox(false);
    };


    const closeSearchInput = (
        <div>
            <CloseOutlined onClick={closeSearch} />
        </div>
    );
    const [is, setis] = useState(true);
    const rota = () => {

        setis(!is)
        setvideo(video)
    };


    const searchData = () => {
        setShowSearchBox(true);
    };

    return (
        <div className="promo providerapi">
            <div className="manage-markup-section">
                <Content className="admin-container">
                    <Row>
                        <Col span={24}>
                            <div className="card-bt-gap">
                                <div className="navigationmain-box" >
                                    <a className="navigation-color" style={{ marginLeft: "1.5%" }} href="/admin/toursextranet-packages">Packages </a><i class="arrow right"></i>
                                    <a className="navigation-color" style={{ color: "orange" }} href="/admin/toursextranet-price">Price</a><i class="arrow right"></i>
                                    {/* <a className="navigation-color" href="/admin/toursextranet-overview">overview</a><i class="arrow right"></i>
                                    <a className="navigation-color" href="/admin/toursextranet-cms">cms</a><i class="arrow right"></i> */}
                                    <a className="navigation-color" href="/admin/toursextranet-media">media</a><i class="arrow right"></i>
                                    <a className="navigation-color" href="/admin/toursextranet-cancelpolicy">Cancel Policy</a>
                                </div>
                                <Card bordered={false}>
                                    <div className="card-add-heading">
                                        <div className="rows-count">
                                            <div>
                                                <h5>
                                                    View Price{" "}
                                                    <HelpInfoHelper
                                                        screenName={"/admin/toursextranet-category"}
                                                    />
                                                </h5>
                                                {priceList.length > 0 && (
                                                    <p>{priceList.length} rows found !</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="action-images">
                                            {showSearchBox && (
                                                <Input
                                                    placeholder="Search"
                                                    onChange={(e) => searchTableData(e)}
                                                    suffix={closeSearchInput}
                                                    style={{ padding: "0px 12px" }}
                                                />
                                            )}
                                            &nbsp;&nbsp;
                                            <img
                                                src={search}
                                                alt="search"
                                                onClick={(e) => searchData()}
                                            />
                                            <img
                                                src={excel}
                                                alt="excel"
                                                onClick={(e) =>
                                                    exportExcel(excelList, "TourExtranetCategory")
                                                }
                                            />
                                            <img
                                                src={exchange}
                                                alt="exchange"
                                                onClick={() => getPriceList()}
                                            />
                                            <p className="add-icon" onClick={showModal}>
                                                <PlusOutlined />
                                            </p>
                                        </div>
                                    </div>
                                    {priceList.length > 0 ? (
                                        <Collapse
                                            defaultActiveKey={["1"]}
                                            accordion
                                            expandIconPosition={"right"}
                                        >
                                            {
                                                priceList.map((item, index) => {
                                                    return (<Panel header={`${item.PackageTitle}: ${item.Duration}, ${item.CityName}, ${item.CountryName}`} key={index + "panel"}>
                                                        {item.priceInfo.length > 0 ? (<div>
                                                            <Table
                                                               
                                                                bordered
                                                                dataSource={item.priceInfo}
                                                                columns={columns}
                                                                pagination={{
                                                                    defaultPageSize: 25,
                                                                    showSizeChanger: true,
                                                                    pageSizeOptions: ["25", "50", "100", "125"],
                                                                }}
                                                            />
                                                        </div>) : (<b>No Records Found</b>)}
                                                    </Panel>)
                                                })
                                            }
                                        </Collapse>

                                    ) : (
                                        <b>No record Found</b>
                                    )}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </div>

            <Modal
                title={[
                    <div>
                        <h6 style={{ marginBottom: "0px" }}>
                            <strong>{isEdit ? "Update Price" : "Add Price"}</strong>
                        </h6>
                    </div>,
                ]}
                className="promo-modal-header admin-bc-level modal-header-bg12"
                visible={modalVisible}
                onOk={(e) => setModalVisible(false)}
                onCancel={(e) => setModalVisible(false)}
                footer={[
                    <div>{Addupdate ? (<Spin style={{ marginRight: "5px" }} />) : null}
                        <Button htmlType="submit" type="primary" onClick={MediaForm.submit} disabled={Addupdate}>
                            {isEdit ? "Update" : "Add"}
                        </Button>
                        <Button type="danger" onClick={() => MediaForm.resetFields()}>
                            Clear
                        </Button>
                    </div>,
                ]}
            >
                <Form layout={"vertical"} form={MediaForm} onFinish={submitForm}>
                    <Row gutter={(12, 12)} className="mb-2">
                        <Col className="gutter-row" md={24} xs={24}>
                            <Form.Item
                                label="Package"
                                name="HolidayPackageId"
                                rules={[{ required: true }]}
                            >
                                <Select showSearch placeholder="Select Package" onChange={(e) => datevalidation(e)}>
                                    {packageList.map((item, index) => (
                                        <Option
                                            key={index + "CategoryId"}
                                            value={item.HolidayPackageId}

                                        >
                                            {item.PackageTitle}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    

                        <Col className="gutter-row" md={12} xs={24}>
                            <Form.Item
                                label="Room Category"
                                name="RoomCategoryId"
                                rules={[{ required: false, message: 'Enter Room Category!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select "
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    {roomCategoryList.map((item, index) => (
                                        <Option key={index + "CategoryId"} value={item.RoomCategoryId}>
                                            {item.RoomCategoryName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" md={12} xs={24}>
                            <Form.Item
                                label="Adult Charges"
                                name="PerAdultCharge"
                                rules={[{ required: false, message: 'Enter Adult Charges!' }]}
                            >
                                <InputNumber min={0} placeholder="Adult Charges" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" md={12} xs={24}>
                            <Form.Item
                                label="PerChildCharge"
                                name="PerChildCharge"
                                rules={[{ required: false, message: 'Enter Child Charges!' }]}
                            >
                                <InputNumber min={0} placeholder="Child Charges" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                       
                        <Col className="gutter-row" md={12} xs={24}>
                            <Form.Item
                                label="Infant Charges"
                                name="PerInfantCharge"
                                rules={[{ required: false, message: 'Enter INfant Charges!' }]}
                            >
                                <InputNumber min={0} placeholder="Infant Charges" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={12} xs={24}>
                            <Form.Item
                                label="Extra Charges"
                                name="ExtraCharges"
                                rules={[{ required: false, message: 'Enter Adult Charges!' }]}
                            >
                                <InputNumber min={0} placeholder="Extra Charges" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        
                    </Row>
                </Form>
            </Modal>


        </div>
    );
};

export default UpdatedComponent(Price);
