import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ApiClient from "../../helpers/ApiClient";
import CountryList from "../../common/CountryList";
import Login from "../Login/Login";
import OTP from "../OTP/Otp";
import "./UserRegistration.scss";


const UserRegistration = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [userId, setUserId] = useState(-1);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  const onFinish = (values) => {
    let regObj = {
      Name: values.username,
      Email: values.email,
      DialingCode: values.DialingCode,
      Mobile: values.phone,
      Password: values.password,
      isloginType: values?.providerId === "google.com" ? 2 : 1,
      Role: 2,
      DeviceToken: "string",
      DeviceType: "Web",
      FirBaseToken: "string",
    };



    ApiClient.post("admin/user/register", regObj).then((response) => {
      if (response?.status === 200) {
        setUserId(response.data.userDetails.UserId);
        setShowSuccessMessage(true);
        message.success(response.message);
      } else if (response?.status === 409) {
        message.error(response.message);
      }
    });
  };



  const prefixSelector = (
    <Form.Item name="DialingCode" noStyle>
      <Select style={{ width: 80 }} defaultValue="+91" showSearch>
        {CountryList.map((item) => (
          <Option key={item.dial_code} value={item.dial_code}>
            {item.dial_code}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const handleOk = (e) => {
    setShowSuccessMessage(false);
  };

  const handleCancel = (e) => {
    setShowSuccessMessage(false);
  };

  const openlogin = () => {
    setLoginModal(true);
  };

  const handleOkLogin = (e) => {
    setLoginModal(false);
  };

  const handleCancelLogin = (e) => {
    setLoginModal(false);
  };

  return (
    <>
      <div className="register-section">
        <div className="reg-container">
          <h2>Register account and travel with ease</h2>
          <Row className="register-row">
            <Col md={7} xs={24}></Col>
            <Col md={10} xs={24} className="right-panel">
              <Card className="register-card">
                <div className="register-card-body">
                  <Form
                    name="basic"
                    form={form}
                    initialValues={{ DialingCode: "+91" }}
                    onFinish={onFinish}
                    size="large"
                  >
                    <Form.Item
                      name="username"
                      rules={[
                        { required: true, message: "Required" },
                        {
                          pattern: "^[a-zA-Z_ ]*$",

                          message: "Must be Alphabet",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Your Full Name" />
                    </Form.Item>

                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please enter your E-mail!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Your Email" />
                    </Form.Item>
                    <Form.Item
                      name="phone"

                    >
                      <Input
                        addonBefore={prefixSelector}
                        style={{ width: "100%" }}
                        placeholder="Enter Your Number"
                      />
                    </Form.Item>
                    
                    <Form.Item
  name="Password"
 
  rules={[
    { 
      required: true, 
      message: "Password is required" 
    },
    {
      validator: (_, value) => {
        const minLength = 8;
        const maxLength = 24;  
        
        if (value.length < minLength || value.length > maxLength) {
          return Promise.reject(new Error(`Password must be greater than 8 characters and include special characters & numbers`));
        }
        
        if (!/\d/.test(value)) {
          return Promise.reject(new Error('Password must contain at least one number'));
        }
        
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
          return Promise.reject(new Error('Password must contain at least one special character'));
        }
        
        return Promise.resolve();
      },
    },
  ]}
>
  <Input.Password placeholder="Enter Password" />
</Form.Item>

                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject("Required"),
                        },
                      ]}
                    >
                      <Checkbox>
                        <p className="mb-0">
                          I agree with{" "}
                          <Link to="/termsofconditions" target="_blank">
                            {" "}
                            Terms & conditions
                          </Link>{" "}
                          and{" "}
                          <Link to="/privacypolicy" target="_blank">
                            {" "}
                            Privacy Policy
                          </Link>
                        </p>
                      </Checkbox>
                    </Form.Item>

                    <Form.Item className="register-btn-container">
                      <Button
                        disabled={buttonDisabled}
                        type="primary"
                        onClick={() => form.submit()}
                      >
                        Register
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                <div className="otp-modal-container">
                  <Modal
                    visible={showSuccessMessage}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={false}
                    className="otp-modal"
                  >
                    <OTP
                      userId={userId}
                      setUserId={setUserId}
                      setModalVisible={(val) => setShowSuccessMessage(val)}
                    />
                  </Modal>
                </div>
              </Card>
            </Col>
            <Col md={7} xs={24}></Col>
          </Row>
          <Col span={24} className="acc-section">
            <div className="have-account register-user">
              <div className="acc-text">
                <p>
                  <strong>Already have an account ?</strong>
                </p>
              </div>
              <div className="login-btn">
                <button onClick={openlogin}>Login</button>
              </div>
            </div>
          </Col>
          <Col span={24} className="acc-section">

          </Col>
          <Col span={24} className="footer-section">
            <div className="register-footer">
              <p className="register-para">
                The personal data administrator is hopmker.in. The data will be
                processed in order to enable the purchase of a ticket,
                consideration of complaints and for marketing purposes. The data
                will be made available to the carrier and supplier selected by
                the client. Providing data is optional, but it is a condition
                for using the services.The customer has the right to access the
                data content and the right to correct them.
              </p>
            </div>
          </Col>
        </div>
      </div>
      <Modal
        visible={loginModal}
        onOk={handleOkLogin}
        onCancel={handleCancelLogin}
        footer={false}
        className="login-modal"
        centered
      >
        <Login setModalVisible={(value) => setLoginModal(value)} />
      </Modal>
    </>
  );
};

export default UserRegistration;
