import React, { useState, useEffect } from "react";

import queryString from "query-string";
import ApiClient from "../../helpers/ApiClient";

import Flightticketsuccess from "../../components/TicketSuccessPages/Flightticketsuccess/Flightticketsuccess";
import SuccessSkeleton from "../../common/SuccessSkeleton/SuccessSkeleton";
import HotelTicketSuccess from "../../components/TicketSuccessPages/HotelTicketSuccess/HotelTicketSuccess";
import HolidayTicketSuccess from "../../components/TicketSuccessPages/HolidayTicketSuccess/HolidayTicketSuccess";


const PaymentSucces = () => {
  const [type, setType] = useState("");
  const [flightTicketsDetails, setFlightTicketsDetails] = useState({
    errorMessage: "",
    otherMessage: "",
    BookingRefNo: null,
    flightDetails: [],
    ticketAllData: {},
    loading: true,
  });

  const [hotelTicketsDetails, setHotelTicketsDetails] = useState({
    errorMessage: "",
    otherMessage: "",
    ticketAllData: {},
    loading: true,
  });


 
  
  const [holidayTicketDetails, setHolidayTicketDetails] = useState({
    message: "",
    TourDetails: {},
    loading: true,
  });

  const params = queryString.parse(document.location.search);

  const airBook = (value) => {
    ApiClient.post("flights/airBook/" + value)
      .then((response) => {
        if (response.statusCode === 200) {
          setFlightTicketsDetails({
            errorMessage: "",
            otherMessage: "",
            BookingRefNo: response.data.BookingRefNo,
            flightDetails: response.data.flightDetails,
            ticketAllData: response.charges.ticketAllData,
            loading: false,
          });
          
        } else if (response.statusCode == 400 && response.data.length > 0) {
          setFlightTicketsDetails({
            errorMessage: response.data[0].errorDetail,
            otherMessage: "Booking Failed",
            BookingRefNo: null,
            flightDetails: [],
            ticketAllData: {},
            loading: false,
          });
        } else {
          setFlightTicketsDetails({
            errorMessage: "",
            otherMessage: "Booking Failed",
            BookingRefNo: null,
            flightDetails: [],
            ticketAllData: {},
            loading: false,
          });
        }
      })
      .catch((error) => {
        setFlightTicketsDetails({
          errorMessage: "",
          otherMessage: "Booking Failed",
          BookingRefNo: null,
          flightDetails: [],
          ticketAllData: {},
          loading: false,
        });
      });
  };

 

  const hotelBookApi = (preBookingRefId) => {
    setHotelTicketsDetails({
      errorMessage: "",
      otherMessage: "",
      ticketAllData: {},
      loading: true,
    });


    ApiClient.post("hotels-v2/hotelbook/" + preBookingRefId)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.status === 200) {
          setHotelTicketsDetails({
            errorMessage: "",
            otherMessage: "",
            ticketAllData: resp.data,
            loading: false,
          });
        } else if (resp.status == 400 && resp.data.length > 0) {
          setHotelTicketsDetails({
            errorMessage: resp.data[0].errorDetail,
            otherMessage: "Booking Failed",
            ticketAllData: {},
            loading: false,
          });
        } else {
          setHotelTicketsDetails({
            errorMessage: "",
            otherMessage: "Booking Failed",
            ticketAllData: {},
            loading: false,
          });
        }
      })
      .catch((err) => {
        setHotelTicketsDetails({
          errorMessage: "",
          otherMessage: "Booking Failed",
          ticketAllData: {},
          loading: false,
        });
      });
  };





  useEffect(() => {
    if (params.ref && params.ref.indexOf("TRYP-F") != -1) {
      setType("FLIGHT");
      airBook(params.ref);
    } else if (params.ref && params.ref.indexOf("TRYP-HPK") != -1) {
      setType("HOLIDAY");
      holidayBookApi(params.ref);
    }else if (params.ref && params.ref.indexOf("TRYP-H") != -1) {
      setType("HOTEL");
      hotelBookApi(params.ref);
    } else if (params.ref && params.ref.indexOf("User") != -1) {
     
    } 
   
  }, []);

  
  const holidayBookApi = (bookingRefId) => {
    setHolidayTicketDetails({
      message: "",
      TourDetails: {},
      loading: true,
    });
    
    ApiClient.get("HolidayExtranet/holiday/book/" + bookingRefId)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.status === 200) {
          setHolidayTicketDetails({
            ...resp.data,
            message: "SUCCESS",
            loading: false,
          });
        } else {
          setHolidayTicketDetails({
            ...holidayTicketDetails,
            message: "FAILED",
            loading: false,
          });
        }
      })
      .catch((err) => {
        setHolidayTicketDetails({
          ...holidayTicketDetails,
          message: "FAILED",
          loading: false,
        });
        return err;
      });
  };
  return (
    <div className="success_area">
      {type === "FLIGHT" ? (
        flightTicketsDetails.loading ? (
          <SuccessSkeleton title="Flight" />
        ) : (
          <Flightticketsuccess flightTicketsDetails={flightTicketsDetails} />
        )
      ) : null}
      {type === "HOTEL" ? (
        hotelTicketsDetails.loading ? (
          <SuccessSkeleton title="Hotel" />
        ) : (
          <HotelTicketSuccess hotelTicketsDetails={hotelTicketsDetails} />
        )
      ) : null}
      {type === "HOLIDAY" ? (
        holidayTicketDetails.loading ? (
          <SuccessSkeleton title="Holiday" />
        ) : (
          <HolidayTicketSuccess holidayTicketDetails={holidayTicketDetails} />
        )
      ) : null}
    </div>
  );
};
export default PaymentSucces;
