import React, { useEffect, useState } from "react";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Table,
  Layout,
  Modal,
  Upload,
  Switch,
  Image,
  Select,
  Radio,
  Spin,
  Collapse
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  CloseOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";

import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import moment from "moment";

const { TextArea } = Input;
const { Content } = Layout;
const { Option } = Select;
const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";
const uploadVideoUrl = BASE + "media/upload/singleVideo";

const ImageUpload = ({
  category,
  fileList,
  setFileList,
  formName,
  deleteBanner,
  limit,
  limitSize,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadImageButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );
 
  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    if (info.file.status === "uploading") {
      setFileList(info.fileList);
      setIsUploading(false);
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
      setFileList(info.fileList);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setFileList(info.fileList);
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
     if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
   };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item
        name={formName}
        rules={[
          { required: fileList.length > 0 ? false : true, message: "Required" },
        ]}
      >
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}

          data={{ category: category }}
          onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList.length >= limitSize
              ? null
              : uploadImageButton
            : uploadImageButton}
        </Upload>
      </Form.Item>
      
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const VideoUpload = ({
  category,
  fileList,
  setFileList,
  formName,
  deleteBanner,
  limit,
  limitSize
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadImageButton = !isUploading ? (
    <div style={{ border: '1px solid', height: "100px", width: "100px" }}>
      <PlusOutlined style={{ marginLeft: 40, marginTop: 30 }} />
      <div className="ant-upload-text" style={{ marginLeft: 23, marginTop: 10 }}>Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {

    const checkJpgOrPng =
      file.type === "video/mp4";
   
    if (!checkJpgOrPng) {
      message.error("You can only upload mp4 file!");
    }

    const checkFileSize = file.size < 5 * 1126400  //1126400;
    if (!checkFileSize) {
      message.error(" Video must be smaller than 5Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
     });
  }

  function handleChange(info) {
    if (info.file.status === "uploading") {
      setFileList(info.fileList);
      setIsUploading(false);
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
      setFileList(info.fileList);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setFileList(info.fileList);
      setIsUploading(false);
     }
  }

  const handlePreview = async (file) => {
 
     if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };
  const newst = async (e) => {
     return (<video><source src={URL.createObjectURL(e)} /></video>)

  }

  const props = {
    name: 'video',
    listType: 'picture',
    action: uploadVideoUrl,
    data: { category: category },
    fileList: fileList,
    onPreview(file) {
      handlePreview(file);
    },
    beforeUpload(info) { beforeUpload(info); },
    onChange(file) { handleChange(file) },
  };

  return (
    <>
      <Form.Item
        name={formName}
        rules={[
          { required: fileList.length > 0 ? false : true, message: "Required" },
        ]}
      >

        <Upload
          {...props}
          className="avatar-uploader"
        >


          {limit
            ? fileList.length >= limitSize
              ? null
              : uploadImageButton
            : uploadImageButton}
        </Upload>

      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <video style={{ width: "100%", height: "300px", }} controls><source src={previewImage} type="video/mp4" /></video>
      </Modal>
    </>
  );
};


const Media = ({ exportExcel }) => {
  let history = useHistory();
  const { Panel } = Collapse;
  const [modalVisible, setModalVisible] = useState(false);
  const [MediaForm] = Form.useForm();
  const [mediaList, setMediaList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currID, setCurrID] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [excelList, setExcelList] = useState([]);
  const [image, setImage] = useState([]);
  const [imageRequestModal, setImageRequestModal] = useState(false);
  const [imageRequest, setImageRequest] = useState([]);
  const [packageList, setPackageList] = useState([]);

  const [Addupdate, setAddupdate] = useState(false);
  const [video, setvideo] = useState([]);
  const [videoRequestModal, setvideoRequestModal] = useState(false);
  const [videoRequest, setvideoRequest] = useState([]);
  const [selectMedia, setSelectMedia] = useState(1)

  useEffect(() => {
    getMediaList();
    getPacakageList();
  }, []);

  const getPacakageList = () => {
    ApiClient.get("HolidayExtranet/GetHolidayPackages")
      .then((res) => {
        if (res.status == 200) {
          setPackageList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getMediaList = () => {
    ApiClient.get("HolidayExtranet/getHolidayMedia")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,

              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setMediaList(data);
          defineExcelData(data);
        } else {
          setMediaList([]);
        }
      })
      .catch((e) => {
        setMediaList([]);
      });
  };

  const submitForm = (values) => {
    let ImageArray = [];
    let videoArray = [];

    if (video.length > 0) {
      for (let i = 0; i < video.length; i++) {
        if (video[i].response.status === 200) {
          videoArray.push(video[i].response.data.filepath);
        }
      }
    }

    if (image.length > 0) {
      for (let i = 0; i < image.length; i++) {
        if (image[i].response.status === 200) {
          ImageArray.push(image[i].response.data.filepaths[0]);
        }
      }
    }
    let data = {
      TypeImages: ImageArray,
      HolidayPackageId: values.HolidayPackageId,
      url: values.url,
      Status: values.Status,
      Videos: videoArray,
    };

    isEdit ? updateMediaRequest(data, currID) : addMediaRequest(data);
  };

  const addMediaRequest = (data) => {
    setAddupdate(true);
    ApiClient.post("HolidayExtranet/HolidayMedia", data)
      .then((response) => {
        if (response.status == 200) {
          Modal.confirm({
            title: 'Media added successfully!!',
            content: 'Please click OK to manage cancel policy for this created package',
            onOk() {
              history.push('/admin/toursextranet-cancelpolicy')
            },

          })
          getMediaList();
          setModalVisible(false);
          MediaForm.resetFields();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const updateMediaRequest = (data, recId) => {
    setAddupdate(true);
    ApiClient.put("HolidayExtranet/updateHolidayMedia/" + recId, {}, data)
      .then((response) => {
        if (response.status == 200) {
          Modal.confirm({
            title: 'Price updated successfully!!',
            content: 'Please click OK to manage cancel policy for this created package',
            onOk() {
              history.push('/admin/toursextranet-cancelpolicy')
            },

          })
          getMediaList();
          setModalVisible(false);
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteMediaRequest = (id) => {
    ApiClient.delete("HolidayExtranet/deleteHolidayMedia/" + id)
      .then((response) => {
        if (response.status == 200) {
          Modal.confirm({
            title: 'Price deleted successfully!!',
            content: 'Please click OK to manage cancel policy for this created package',
            onOk() {
              history.push('/admin/toursextranet-cancelpolicy')
            },

          })
          getMediaList();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showModal = () => {
    setAddupdate(false);
    setIsEdit(false);

    MediaForm.resetFields();
    setImage([]);
    setvideo([]);
    setModalVisible({
      visible: true,
    });
  };

  const onUpdate = (currentData) => {
    setAddupdate(false);
    setImage([]);
    setvideo([]);
    MediaForm.resetFields();
    setIsEdit(true);
    setModalVisible(true);
    setCurrID(currentData.HolidaymediaId);
    MediaForm.setFieldsValue({
      url: currentData?.url,
      HolidayPackageId: currentData.HolidayPackageId,
      Status: currentData.Status,
    });
    let array = [];
    let varray = [];
    if (currentData.TypeImages.length > 0) {
      for (let i = 0; i < currentData.TypeImages.length; i++) {
        array.push({
          uid: currentData.HolidaymediaId,
          status: "done",
          url: `${BASE}${currentData.TypeImages[i].substring(1)}`,
          response: {
            status: 200,
            data: {
              filepaths: [currentData.TypeImages[i]],
            },
          },
        });
      }
      setImage(array);
      // setvideo(array);
    }
    if (currentData.Videos.length > 0) {
      for (let i = 0; i < currentData.Videos.length; i++) {
        varray.push({
          uid: currentData.HolidaymediaId,
          status: "done",
          url: `${BASE}${currentData.Videos[i].substring(1)}`,
          response: {
            status: 200,
            data: {
              filepaths: [currentData.Videos[i]],
            },
          },
        });
      }
      
      setvideo(varray);
    }

  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        SrNo: item.SNo,
        CategoryImages: item.CategoryImages,
        CategoryName: item.CategoryName,
        Description: item.Description,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const tableActions = (currentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}
          onClick={() => onUpdate(currentItem)}
        >
          <EditOutlined
            style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteMediaRequest(currentItem.HolidaymediaId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const DeleteImage = (id) => {
   };

  const columns = [
    {
      title: "SNo",
      dataIndex: "SNo",
    },
    {
      title: "Media Images",
      dataIndex: "TypeImages",
      render: (TypeImages) => {
        return TypeImages?.length > 0 ? ImageRequest(TypeImages) : null;
      },
    },
    {
      title: "Media Videos",
      dataIndex: "Videos",
      render: (Videos) => {
        return Videos?.length > 0 ? videoRequests(Videos) : null;
      },

    },
    {
      title: "Tours Packages",
      dataIndex: "PackageTitle",
    },
    {
      title: "Description",
      dataIndex: "url",
      render: (text) => {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            style={{ whiteSpace: "break-spaces", lineHeight: "14px" }}
          ></div>
        );
      },
    },
    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const ImageRequest = (holiday) => {
    return (
      <div className="text-center">
        <span
          style={{ cursor: "pointer", color: "green" }}
          onClick={(e) => {
            setImageRequest(holiday);
            setImageRequestModal(true);
          }}
        >
          <EyeOutlined />
        </span>
      </div>
    );
  };

  const videoRequests = (holiday) => {
    return (
      <div className="text-center">
        <span
          style={{ cursor: "pointer", color: "green" }}
          onClick={(e) => {
            setvideoRequest(holiday);
            setvideoRequestModal(true);
          }}
        >
          <EyeOutlined />
        </span>
      </div>
    );
  };

  const handelStatus = (data) => {
    return (
      <Switch
        checkedChildren="Active"
        unCheckedChildren="Inactive"
        checked={data.Status == 1 ? true : false}
        onChange={() => {
          changeStatus(data, data.Status == 1 ? 0 : 1);
        }}
      />
    );
  };

  const changeStatus = (info, Status) => {
    delete info.SNo;
    delete info.ModifiedDate;
    delete info.PackageTitle;
    let obj = {
      ...info,
      Status: Status,
    };
    let id = info?.HolidaymediaId;

    updateMediaRequest(obj, id);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = mediaList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setMediaList(searchResults);
    } else {
      getMediaList();
      setMediaList(mediaList);
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };


  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const [is, setis] = useState(true);
  const toggleMediaType = () => {
    setis(!is)
    setvideo(video)
  };

  const onChangeMediaType = (e) => {
    setSelectMedia(e.target.value)
  }

  const searchData = () => {
    setShowSearchBox(true);
  };

  return (
    <div className="promo providerapi">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <div className="navigationmain-box" >
                  <a className="navigation-color" style={{ marginLeft: "1.5%" }} href="/admin/toursextranet-packages">Packages </a><i class="arrow right"></i>
                  <a className="navigation-color" href="/admin/toursextranet-price">Price</a><i class="arrow right"></i>
                  
                  <a className="navigation-color" style={{ color: "orange" }} href="/admin/toursextranet-media">media</a><i class="arrow right"></i>
                  <a className="navigation-color" href="/admin/toursextranet-cancelpolicy">Cancel Policy</a>
                </div>
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Media{" "}
                          <HelpInfoHelper
                            screenName={"/admin/toursextranet-category"}
                          />
                        </h5>
                        {mediaList.length > 0 && (
                          <p>{mediaList.length} rows found !</p>
                        )}
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img
                        src={excel}
                        alt="excel"
                        onClick={(e) =>
                          exportExcel(excelList, "TourExtranetCategory")
                        }
                      />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getMediaList()}
                      />
                      <p className="add-icon" onClick={showModal}>
                        <PlusOutlined />
                      </p>
                    </div>
                  </div>

                  {mediaList.length > 0 ? (
                    <Collapse
                      defaultActiveKey={["1"]}
                      accordion
                      expandIconPosition={"right"}
                    >
                      {
                        mediaList.map((item, index) => {
                          return (<Panel header={`${item.PackageTitle}: ${item.Duration}, ${item.CityName}, ${item.CountryName}`} key={index + "panel"}>
                            {item.MediaInfo.length > 0 ? (<div>
                              <Table
                                className="table-scroll-none"
                                bordered
                                dataSource={item.MediaInfo}
                                columns={columns}
                                pagination={{
                                  defaultPageSize: 25,
                                  showSizeChanger: true,
                                  pageSizeOptions: ["25", "50", "100", "125"],
                                }}
                              />
                            </div>) : (<b>No Records Found</b>)}
                          </Panel>)
                        })
                      }
                    </Collapse>

                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>{isEdit ? "Update Media" : "Add Media"}</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header admin-bc-level modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>{Addupdate ? (<Spin style={{ marginRight: "5px" }} />) : null}
            <Button htmlType="submit" type="primary" onClick={MediaForm.submit} disabled={Addupdate}>
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button type="danger" onClick={() => MediaForm.resetFields()}>
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form layout={"vertical"} form={MediaForm} onFinish={submitForm}>
          <Row gutter={(12, 12)} className="mb-2">
            <Col className="gutter-row" md={24} xs={24}>
              <Form.Item
                label="Package"
                name="HolidayPackageId"
                rules={[{ required: true }]}
              >
                <Select showSearch placeholder="Select Package">
                  {packageList.map((item, index) => (
                    <Option
                      key={index}
                      value={item.HolidayPackageId}
                    >
                      {item.PackageTitle}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} xs={24}>
              <Form.Item label="Description" name="url" rules={[{ required: true }]}>
                <TextArea rows={4} placeholder="Media Description" />
              </Form.Item>
            </Col>
            {is ? (<Col xs={24} md={24}>
              <p>Images</p>

              <div className="upload-logo">
                <Radio.Group onChange={onChangeMediaType} value={selectMedia} >
                  <Radio value={1} >Image</Radio>
                  <Radio value={0} onClick={toggleMediaType}>Video</Radio>
                </Radio.Group>

                <ImageUpload
                  category="CategoryImages"
                  fileList={image}
                  setFileList={setImage}
                  formName="CategoryImages"
                  limit={true}
                  limitSize={10}
                  deleteBanner={DeleteImage}
                />

              </div>
            </Col>) : (<Col xs={24} md={24}>
              <p>Video</p>

              <div className="upload-logo">
                <Radio.Group onChange={onChangeMediaType} value={selectMedia} >
                  <Radio value={1} onClick={toggleMediaType}>Image</Radio>
                  <Radio value={0}>Video</Radio>
                </Radio.Group>

                <VideoUpload
                  category="holiday"
                  fileList={video}
                  setFileList={setvideo}
                  formName="CategoryImages"
                  limit={true}
                  limitSize={10}
                  deleteBanner={DeleteImage}
                
                />

              </div>
            </Col>)
            }
            <Col md={24} xs={24}>
              <Form.Item
                label="Status"
                name="Status"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        visible={imageRequestModal}
        title="Photos"
        onCancel={(e) => {
          setImageRequestModal(false);
        }}
        footer={false}
        className="modal-header-bg12"
      >
        <Row gutter={12}>
          {imageRequest.map((image, index) => (
            <Col md={6} sm={8} xs={12} key={index}>
              <Image src={BASE + image.substring(1)} />
            </Col>
          ))}
        </Row>
      </Modal>
      <Modal
        visible={videoRequestModal}
        title="Photos"
        onCancel={(e) => {
          setvideoRequestModal(false);
        }}
        footer={false}
        className="modal-header-bg12"
      >
        <Row gutter={12}>
          {videoRequest.map((image, index) => (
            <Col md={6} sm={8} xs={12} key={index}>
              <video style={{ width: "100%", height: "300px", }} controls><source src={BASE + image.substring(1)} type="video/mp4" /></video>
            </Col>
          ))}
        </Row>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(Media);
