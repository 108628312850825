import React, { useState, Suspense } from 'react';
import { Link, useHistory } from "react-router-dom";
import './Combineser.scss';
import Drawersearch from '../navbar/Drawersearch';
import { useAuthContext } from "../../common/providers/AuthProvider";
const HotelSearch = React.lazy(() => import('../../components/Hotels/Hotels'))
const FlightSearch = React.lazy(() => import('../../components/flight-search/FlightSearch'))
const HolidaySearchForm = React.lazy(() => import('../../pages/Holidays/HolidaysSearch/HolidaySearchForm'))


const CombineServLink = (props) => {

    const [index, setIndex] = useState(props.activetab);

    const {
        isLogin: { agent },
        user,
        isGuest,
    } = useAuthContext();

    let history = useHistory();
    const handelIndex = (index, link) => {
        setIndex(index)

    }
    return (
        <div className='main-bar'>
            <div className="main-searchbar-tabs-box">
                <div className="main-searchbar-tabs-box1">
                    <div className=" ">
                        <div className="tabs-main-box-outer">
                           
                            {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
                                <Link to="/" className={`tabs-main-box-inner  ${index === 0 ? 'active2' : null}`}>  <div onClick={() => handelIndex(0, "/")}>   <span class="fa fa-plane">  <span className='combinesearch-tab-headng' >Flights</span></span> </div></Link> : null}
                            {((user?.Role?.Name === "Agent" && user?.Services.includes("2")) || agent === false) ?
                                <Link to="/hotels" className={`tabs-main-box-inner  ${index === 1 ? 'active2' : null}`}>   <div onClick={() => handelIndex(1, "/hotels")}>   <span class="fa fa-hotel" >  <span className='combinesearch-tab-headng' > Hotels</span></span></div></Link> : null}
                            {((user?.Role?.Name === "Agent" && user?.Services.includes("3")) || agent === false) ?
                                <Link to="/holidays" className={`tabs-main-box-inner  ${index === 2 ? 'active2' : null}`} ><div onClick={() => handelIndex(2, "/holidays")}>  <span class="fa fa-shopping-bag" >  <span className='combinesearch-tab-headng' > Holidays</span></span></div></Link> : null}
                        </div>
                    </div>
                    <div>
                        <div hidden={index !== 0}>
                            <Suspense fallback={<div>Loading...</div>}>
                                <FlightSearch />
                            </Suspense>
                        </div>
                        <div hidden={index !== 1}>

                            <Suspense fallback={<div>Loading...</div>}>
                                <HotelSearch />
                            </Suspense>
                        </div>
                        <div hidden={index !== 2}>

                            <Suspense fallback={<div>Loading...</div>}>
                                <HolidaySearchForm />
                            </Suspense>
                        </div>
                    </div>
                </div>
            </div>
           

            <div className='main-searchbar-tabs-boxfor-mob-view' >
                <Drawersearch
                    index={index} />
            </div>
        </div>
    )
}
export default CombineServLink;
