import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ApiClient from "../../helpers/ApiClient";
import Banner from "../../components/banner/Banner";
import HomeCarousel from "../HomeCarousel/HomeCarousel";
import "./Home.scss";
import CityTopHotels from "../../pages/Hotels/TopHotels/CityTopHotels";
import { useSytContext } from "../../common/providers/SytProvider";
import { Link } from "react-router-dom";
import { Carousel } from "antd";
import AgentHomePage from "../../components/Agenthomepage/Agenthomepage";
import { useAuthContext } from "../../common/providers/AuthProvider";
import Subscribe from "../subscribe/Subscribe";
import Reviews from "../../common/Reviews/Reviews";
import FooterBanner from "./FooterBanner/FooterBanner";
import PopularHolidays from "../../pages/Holidays/HolidaysSearch/PopularHolidays";
import HomeSlider from "./HomeSlider/HomeSlider";
import TopDomesticRoutes from "./TopDomesticRoutes/topDomesticRoutes";
import OffersSlider from "../../common/LandingPageOffers/LandingPageOffers";
import CombineServLink from "../../common/CombineServicesLink/CombineServLink";
const Home = () => {
  const {
    blog,
    flightBanners,
    footerBanner,
    bigHomeSliderBanners,
    smallHomeSliderBanners,
    promoDataSource,
  } = useSytContext();

  const [dataSource, setDataSource] = useState([]);
  const [recentSearchResults, setRecentSearchResults] = useState([]);
  const [deal, setDeal] = useState([])
  const getDeal = () => {
    ApiClient.get("admin/deals")
      .then((res) => {
        if (res.status === 200) {
          setDeal(res.data);
        }
      })
      .catch((e) => {
        setDeal([]);
      });
  };
  const {
    isLogin: { flag, agent },
    user,
    isGuest,
  } = useAuthContext();

  const [dealsList, setDealsList] = useState([]);

  const getDeals = () => {
    ApiClient.get("admin/getAllServicesTopCities")
      .then((res) => {
        if (res.status === 200) {
          setDealsList(res.data);
        }
      })
      .catch((e) => {
        setDealsList([]);
      });
  };
 

  useEffect(() => {
    getDeals();
    let data = sessionStorage.getItem("recentSearchesResults");
    getDeal()
    if (data?.length) {
      setRecentSearchResults(JSON.parse(data));
    }
  }, []);
  useEffect(() => {
    if (promoDataSource.length) {
      let data = promoDataSource.filter((item) => item.ServiceType === 1);

      setDataSource(data);
    }

  }, [promoDataSource]);
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://cdn.trustindex.io/loader.js?f9e2cb227d8102278196d0e2475';
  //   script.async = true;
  //   script.defer = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <div id="home-page" >
      {(agent && user?.Role?.RoleId === 5) || user?.Role?.RoleId === 7 ? (
        <>
          <div className="work-agent-baer">
            <Banner banner={flightBanners} />
          </div>
          {(isGuest || user?.Services.includes("1")) && (
            <AgentHomePage />
          )}
        </>
      ) : (
        <section className="hero-banner">
         

          <Banner banner={flightBanners} />
          <CombineServLink activetab={0}
          />
        </section>
      )}


      <section className="main-box-dis-work-section">
        <h3 style={{ marginLeft: '2%' }}><strog>Signup & save upto 30%</strog></h3>
        <div className="main-box-dis-work" >
          <div className="main-box-dis-work1" >
            <i class='fa fa-tag' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-beer' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-globe' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-phone' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
        </div>
        <div className="liks-about-sigup">
          <Link to="/about-us" className="homepage-aboutus-cot">Learn more about us</Link>
          {flag === true || agent === true ? (null) : (
            <Link to="/user-registration" > <button className="homepage-sigup-btn" >Sign up and save</button></Link>)}
        </div>
      </section>

      <section className="mainn-section-tophotels" style={{ width: '80%', margin: 'auto' }}>
        <CityTopHotels />
      </section>


    
      <section>
        {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
          <HomeCarousel deal={deal} />
          : null}
      </section>
      <div className="card holidays_search_container_1" style={{ border: 'none' }} >
        <PopularHolidays />
      </div>

      <section className="container">
        {footerBanner.length > 0 && (
          <div className="domestic-airsearch faqs-flight-sec">
            <FooterBanner footerBanner={footerBanner} />
          </div>
        )}
      </section>

      
    


     


      

      <section className="top-route-bb">
        <Reviews serviceType={1} />
      </section>


      <Subscribe />
      {/* <section className="top-google_reviews">
      <script defer async src='https://cdn.trustindex.io/loader.js?c618f5427da04298cd361e82e92'></script>
        {/* <script defer async src='https://cdn.trustindex.io/loader.js?f2ad38f27bdf023de06689a794b'></script> *
      </section> */}

    </div >
  );
};

export default Home;
