import React from "react";

const HolidayCancellationPolicy = ({ data, cancellation }) => {
  const policyStringArray = data.split("|");

  return (
    <div className="cancellation-block-body">
      <ul>
        <li>
          <span className="title">Time Before Departure </span>{" "}
          <span className="title last">Cancellation Charges</span>
        </li>
        {policyStringArray.map((data, index) => {
          let val = data.split(":");

          if (index === 0) {
            return (
              <li key={index}>
                <span>
                  {`between ${val[0].replace(
                    "-",
                    ""
                  )} hours and 0 hours before journey time`}{" "}
                </span>
                <span className="last">
                  {val[2] === 0 ? `Rs ${val[1]}` : `${val[1]} %`}
                </span>
              </li>
            );
          } else if (index === policyStringArray.length - 1) {
            return val[2] ? (
              <li key={index}>
                <span>
                  {val[0].replace("-", "") > 24
                    ? `${val[0].replace("-", "") / 24} days before journey time`
                    : `${val[0].replace(
                      "-",
                      ""
                    )} hours before journey time`}{" "}
                </span>
                <span className="last">{`${val[1]}%`}</span>
              </li>
            ) : null;
          } else {
            return (
              <li key={index}>
                <span>
                  {val[1].replace("-", "") >= 24
                    ? val[1].replace("-", "") > 24
                      ? `Between ${val[1].replace("-", "") / 24
                      } days and ${val[0].replace(
                        "-",
                        ""
                      )} before journey time`
                      : `Between  ${val[1].replace(
                        "-",
                        ""
                      )} hours 0 days and ${val[0].replace(
                        "-",
                        ""
                      )} hours before journey time `
                    : `Between 0 days ${val[1].replace(
                      "-",
                      ""
                    )} hours and 0 days ${val[0].replace(
                      "-",
                      ""
                    )} hours before journey time `}
                </span>
                <span className="last">{`${val[1]}%`}</span>
              </li>
            );
          }
        })}
      </ul>

    </div>
  );
};

export default HolidayCancellationPolicy;
