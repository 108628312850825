import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useSytContext } from "../../../common/providers/SytProvider";
import Banner from "../../../components/banner/Banner";

import ApiClient from "../../../helpers/ApiClient";
import queryString from "query-string";
import Subscribe from "../../../components/subscribe/Subscribe";
import moment from "moment";
import Reviews from "../../../common/Reviews/Reviews";
import { AppConstants } from "../../../helpers/constants";
import "../HotelSearch/HotelSearch.scss";
import CityTopHotels from "../TopHotels/CityTopHotels";
import TopHotels from "../TopHotels/TopHotels";
import PopularHolidays from "../../Holidays/HolidaysSearch/PopularHolidays";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import HomeCarousel from "../../../components/HomeCarousel/HomeCarousel";

import CombineServLink from "../../../common/CombineServicesLink/CombineServLink";
const HotelSearch = (props) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { hotelBanners } = useSytContext();
  const [cityHotelData, setCityHotelData] = useState([]);
  const [cityHotel, setCityHotel] = useState([]);
  const [cityTopHotel, setCityTopHotel] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const [recentSearchResults, setRecentSearchResults] = useState([]);
  const {
    isLogin: { flag, agent },
    user,
    isGuest,
  } = useAuthContext();
  const { promoDataSource } = useSytContext();
  let tomorrowDate = moment().add(1, 'days').format("YYYY-MM-DD");
  let dayafter = moment().add(2, "days").format("YYYY-MM-DD");
  const getCityHotel = () => {
    ApiClient.get("admin/cityHotels")
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data?.filter(
            (item) => item?.Status === 0 && item?.Servicetype === 2
          );

          setCityHotel(data);
        }
      })
      .catch((e) => console.log("api error", e));
  };

  const getCityHotelList = () => {
    ApiClient.get("admin/getAllServicesTopCities")
      .then((res) => {

        if (res.status === 200) {
          let data = res.data.filter(
            (item) => item.Status === 1 && item.ServiceType === 2
          );

          setCityHotelData(data);
        }
      })
      .catch((e) => console.log("api error", e));
  };

  const [deal, setDeal] = useState([])
  const getDeal = () => {
    ApiClient.get("admin/deals")
      .then((res) => {
        if (res.status === 200) {
          setDeal(res.data);
        }
      })
      .catch((e) => {
        setDeal([]);
      });
  };


  useEffect(() => {
    getCityHotelList();
    getCityHotel();
   
    getDeal()
    if (promoDataSource.length) {
      let data = promoDataSource.filter((item) => item.ServiceType === 2);

      setDataSource(data);
    }
  }, [promoDataSource]);




  const getHotelSer = (url) => {
    let formData = {
      checkInDate: tomorrowDate,
      checkOutDate: dayafter,
      hotelCityCode: url?.CityName,
      roomGuests: JSON.stringify([{
        noOfAdults: 1,
        noOfChilds: 0,
        childAge: [],
      },]),
      nationality: "IN",
      currency: "INR",
      countryCode: "IN",
      traceId: "string",
    };

    const query = queryString.stringify(formData);

    return "/hotels/listing?" + query;
  };

  return (
    <div className="hotels_search_container">
      <Helmet>
        <title>
          Book Best Luxury and Budget Hotels Online - {AppConstants.DOMAIN_NAME}
        </title>
        <meta
          name="description"
          content="Best hotel deals are available at hopmker. Book budget hotels, luxury hotels or resorts at cost effective rates."
        />
      </Helmet>

      <section className="hero-banner">
       
        <Banner banner={hotelBanners} />
        <CombineServLink activetab={1} />


      </section>



      <section className="main-box-dis-work-section">
        <h3 style={{ marginLeft: '2%' }}><strog>Signup & save upto 30%</strog></h3>
        <div className="main-box-dis-work" >
          <div className="main-box-dis-work1" >
            <i class='fa fa-tag' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-beer' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-globe' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-phone' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
        </div>
        <div className="liks-about-sigup">
          <Link to="/about-us" className="homepage-aboutus-cot">Learn more about us</Link>
          {flag === true || agent === true ? (null) : (
            <Link to="/user-registration" > <button className="homepage-sigup-btn" >Sign up and save</button></Link>)}
        </div>
      </section>
     
      <section className="mainn-section-tophotels" style={{ width: '80%', margin: 'auto' }}>
        <TopHotels />
      </section>
      <section className="mainn-section-tophotels" style={{ width: '80%', margin: 'auto' }}>
        <CityTopHotels />
      </section>
      <section>
        {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
          <HomeCarousel deal={deal} />
          : null}
      </section>
      <div className="card holidays_search_container_1" style={{ border: 'none' }} >
        <PopularHolidays />
      </div>

     
      <section className="top-route-bb">
        <Reviews serviceType={2} />
      </section>

      <section className="discounts_container">
        <Subscribe />
      </section>
    </div>
  );
};

export default HotelSearch;
