import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Tabs, Popconfirm, Skeleton } from "antd";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import APIClient from "../../helpers/ApiClient";
import moment from "moment";
import img1 from "../../assets/images/Offers/img1.jpg";

import "./Offers.scss";
import Banner from "../banner/Banner";
import { useSytContext } from "../../common/providers/SytProvider";
const BASE = process.env.REACT_APP_BASE_URL;

function copyToClipboard(text) {
  const elem = document.createElement("textarea");
  elem.value = text;
  document.body.appendChild(elem);
  elem.select();
  document.execCommand("copy");
  document.body.removeChild(elem);
}

const ImagesSlider = ({ image }) => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    infinite: true,
    arrows: false,
  };
  return (
    <div className="offers-slider">
      <Slider {...settings}>
        <div className="imagewrapper">
          <img
            className="slide-img"
            src={image ? BASE + image : img1}
            alt="img1"
          />
        </div>
      </Slider>
    </div>
  );
};
const OfferSkeleton = () => {
  return (
    <Col className="gutter-row" lg={6} md={8} sm={12} xs={24}>
      <div className="coupon-card">
        <div className="images-slider">
          <div
            style={{
              height: 200,
              borderRadius: "35px",
              backgroundColor: "#f2f2f2",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Skeleton.Image />
          </div>
        </div>
        <div className="coupon-content">
          <div className="coupon-code">
            <div className="codes">
              <Skeleton.Input style={{ width: 50 }} active size="small" />
            </div>

            <div className="copy-code">
              <Skeleton.Input style={{ width: 50 }} active size="small" />
            </div>
          </div>
          <hr />
          <Skeleton active paragraph={{ rows: 1 }} />
          <hr />
          <div className="coupon-expiry-details">
            <div className="countdown">
              <Skeleton.Input style={{ width: 50 }} active size="small" />
            </div>
            <div className="expiry-date">
              <Skeleton.Input style={{ width: 50 }} active size="small" />
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

const OffersBox = ({ promo, gotoResults, getDaysRemaining, showModal }) => {
  return (
    <Col className="gutter-row" lg={6} md={8} sm={12} xs={24}>
      <div className="coupon-card">
        <div className="images-slider">
          <ImagesSlider image={promo.Image ? promo.Image.substring(1) : ""} />
        </div>
        <div className="coupon-content">
          <div className="coupon-code">
            <div className="codes">
              <p>{promo.Name}</p>
              <h6>{promo.Code}</h6>
            </div>
            <Popconfirm
              title="Code Copied, Click on OK"
              onConfirm={() => copyToClipboard(promo.Code)}
              onCancel={false}
              okText="Ok"
              cancelText="No"
            >
              <div className="copy-code">
                <p>COPY CODE</p>
              </div>
            </Popconfirm>
          </div>
          <hr />
          <p className="offers-discriptioncard-cont" >
            {promo.Description}
          </p>
          <hr />
          <div className="coupon-expiry-details">
            <div className="countdown">
              <p>
                {getDaysRemaining(promo.ValidTill)} Day(s) <br />
                Remaining
              </p>
            </div>
            <div className="expiry-date">
              <p>Book by {moment(promo.ValidTill).format("DD MMM YYYY")}</p>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

const Offers = () => {
  let dateFormat = "YYYY-MM-DD";
  let activeTab = 1;
  let flightPromo = [];
  let hotelPromo = [];
  let HolidayPromo = [];
  let AllPromo = [];

  const goto = useHistory();
  const { TabPane } = Tabs;
  const [details, setDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { offerBanners } = useSytContext();
  const [promoCode, setPromoCode] = useState([]);

  useEffect(() => {
    getpromo();
  }, []);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status == 200) {

          setPromoCode(res.data);


        } else {
          setPromoCode([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (promoCode.length > 0) {
    AllPromo = promoCode.filter((promo) => ((moment(promo.ValidTill, "YYYY-MM-DD")).add(-1, "days").isAfter
      (moment().format('YYYY-MM-DD'), "day")));
    flightPromo = promoCode.filter((promo) => promo.ServiceType == 1 && ((moment(promo.ValidTill, "YYYY-MM-DD").add("days", -1)).isAfter
      (moment().format('YYYY-MM-DD'), "day")));
    hotelPromo = promoCode.filter((promo) => promo.ServiceType == 2 && (moment(promo.ValidTill, "YYYY-MM-DD").isAfter
      (moment().format('YYYY-MM-DD'), "day")));
    HolidayPromo = promoCode.filter((promo) => promo.ServiceType == 6 && (moment(promo.ValidTill, "YYYY-MM-DD").isAfter
      (moment().format('YYYY-MM-DD'), "day")));
  }

  const showModal = () => {
    setDetails(true);
  };
  const handleOk = (e) => {
    setDetails(false);
  };

  const handleCancel = (e) => {
    setDetails(false);
  };

  function tabsChange(key) {
    activeTab = key;
  }

  function getDaysRemaining(ValidTill) {
    let today = new moment();
    let ValidTillDate = moment(ValidTill, dateFormat);
    return ValidTillDate.diff(today, "days");
  }

  function gotoResults() {
    switch (activeTab) {
      case "1":
        goto.push("/Flights");
        break;
      case "2":
        goto.push("/Hotels");
        break;
      case "3":
        goto.push("/Holiday");
        break;
      default:
        goto.push("/");
        break;
    }
  }

  return (
    <div className="promocodes">
      <div className="Holiday-block">
        <div className="Holiday-banner">
          <Banner banner={offerBanners} />
        </div>
        <div className="Holiday-search-header">
          <h2 style={{ textAlign: 'center' }} >Latest Offers and Promocodes</h2>
        </div>
      </div>

      <div className="promocode-wrapper">
        <div className="container">
          <div className="promocodes-section">
            <div className="service-type-tabs">
              <Tabs defaultActiveKey="1" onChange={tabsChange}>
                <TabPane tab="All" key="1">
                  <div className="tab1-content">
                    <Row gutter={[32, 32]}>
                      {isLoading ? (
                        <OfferSkeleton />
                      ) : AllPromo.length > 0 ? (
                        AllPromo.map((promo, idx) => (
                          <OffersBox
                            promo={promo}
                            gotoResults={gotoResults}
                            getDaysRemaining={getDaysRemaining}
                            showModal={showModal}
                            key={idx + "all"}
                          />
                        ))
                      ) : (
                        <Col
                          className="gutter-row"
                          lg={6}
                          md={8}
                          sm={12}
                          xs={24}
                        >
                          {" "}
                          <p>No Promo Available</p>
                        </Col>
                      )}
                    </Row>
                    <Modal
                      title="Basic Modal"
                      visible={details}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <p>Some contents...</p>
                      <p>Some contents...</p>
                      <p>Some contents...</p>
                    </Modal>
                  </div>
                </TabPane>
                <TabPane tab="Flights" key="Flights">
                  <div className="tab1-content">
                    <Row gutter={[32, 32]}>
                      {isLoading ? (
                        <OfferSkeleton />
                      ) : flightPromo.length > 0 ? (
                        flightPromo.map((promo, idx) => (
                          <OffersBox
                            promo={promo}
                            gotoResults={gotoResults}
                            getDaysRemaining={getDaysRemaining}
                            showModal={showModal}
                            key={idx + "flight"}
                          />
                        ))
                      ) : (
                        <Col
                          className="gutter-row"
                          lg={6}
                          md={8}
                          sm={12}
                          xs={24}
                        >
                          {" "}
                          <p>No Promo Available</p>
                        </Col>
                      )}
                    </Row>
                    <Modal
                      title="Basic Modal"
                      visible={details}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <p>Some contents...</p>
                      <p>Some contents...</p>
                      <p>Some contents...</p>
                    </Modal>
                  </div>
                </TabPane>
                <TabPane tab="Hotels" key="3">
                  <div className="tab3-content">
                    <Row gutter={[32, 32]}>
                      {isLoading ? (
                        <OfferSkeleton />
                      ) : hotelPromo.length > 0 ? (
                        hotelPromo.map((promo, idx) => (
                          <OffersBox
                            promo={promo}
                            gotoResults={gotoResults}
                            getDaysRemaining={getDaysRemaining}
                            showModal={showModal}
                            key={idx + "hotel"}
                          />
                        ))
                      ) : (
                        <Col
                          className="gutter-row"
                          lg={6}
                          md={8}
                          sm={12}
                          xs={24}
                        >
                          {" "}
                          <p>No Promo Available</p>
                        </Col>
                      )}
                    </Row>
                    <Modal
                      title="Basic Modal"
                      visible={details}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <p>Some contents...</p>
                      <p>Some contents...</p>
                      <p>Some contents...</p>
                    </Modal>
                  </div>
                </TabPane>
                <TabPane tab="Holiday" key="2">
                  <div className="tab3-content">
                    <Row gutter={[32, 32]}>
                      {isLoading ? (
                        <OfferSkeleton />
                      ) : HolidayPromo.length > 0 ? (
                        HolidayPromo.map((promo, idx) => (
                          <OffersBox
                            promo={promo}
                            gotoResults={gotoResults}
                            getDaysRemaining={getDaysRemaining}
                            showModal={showModal}
                            key={idx + "Holiday"}
                          />
                        ))
                      ) : (
                        <Col
                          className="gutter-row"
                          lg={6}
                          md={8}
                          sm={12}
                          xs={24}
                        >
                          {" "}
                          <p>No Promo Available</p>
                        </Col>
                      )}
                    </Row>
                    <Modal
                      title="Basic Modal"
                      visible={details}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <p>Some contents...</p>
                      <p>Some contents...</p>
                      <p>Some contents...</p>
                    </Modal>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Offers;
