import React, { useEffect, useState } from "react";
import hotelNoImg from "../../../assets/images/hotels/no_photo.png";

const HotelCardImage = ({ hotelDetailsRespObj, hotelRoom, onHandleModal }) => {
  const [showImage, setShowImage] = useState(true);

  return hotelDetailsRespObj?.images?.length > 0 ? (
    <div className="hotel_imge_">

      <img
        height={"150px"}
        width={"100%"}
        src={
          showImage
            ? hotelDetailsRespObj?.images[
            Math.floor(Math.random() * hotelDetailsRespObj?.images?.length)
            ]
            : hotelNoImg
        }
        onClick={() =>
          onHandleModal({
            roomName: hotelRoom.roomName,
            images: hotelDetailsRespObj?.images,
          })
        }
        alt={hotelRoom.roomName}
        onError={() => {
          setShowImage(false);
        }}
      />

    </div>
  ) : null;
};

export default HotelCardImage;
