import React, { useState, useEffect } from "react";
import GlobalStatesContext from "./GlobalStatesContext";
import moment from "moment";
import ApiClient from "../../helpers/ApiClient";
import { useLocalStorage } from "../../helpers/useStorage";
import { useSessionStorage } from "../../helpers/useStorage";

import { message } from "antd";

let dateFormat = "YYYY-MM-DD";

const defCombinedSearch = {
  tripType: "oneWay",
  classType: "Economy",
  origin: [],
  destination: [],
  fromDate: moment(new Date()).format("YYYY-MM-DD"),
  toDate: "",
  adultCount: 1,
  childCount: 0,
  infantCount: 0,
};

const GlobalStatesProvider = (props) => {
  const [busPersist, setBusPersist] = useLocalStorage("busPersist", {
    busSearchObj: {
      from: null,
      to: null,
      departureDate: new Date(),
    },
    selectedBusData: [],
  });

  const [busPassengerData, setBusPassengerData] = useState([]);
  const [busBookingResp, setBusBookingResp] = useState([]);
  const [busSearchResultObj, setBusSearchResultObj] = useState({});
  const [serviceTypeList, setServiceTypeList] = useState([]);
  const [otherData, setOtherData] = useState({
    promoData: { status: false, Discount: 0 },
    ConvFee: { status: false, amount: 0 },
    insuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
    selectedInsuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
    redeemAmount: { CouponAmt: 0, status: false },
    mealData:[],
    baggageData:[]
  });

  const validatePromoCode = (req) => {
    ApiClient.post("admin/validate/promo", req)
      .then((res) => {
        if (res.status === 200) {
          setOtherData((prev) => ({
            ...prev,
            promoData: {
              Code: res.data.Code,
              Discount: res.data.Discount,
              DiscountType: res.data.DiscountType,
              FromAmount: res.data.FromAmount,
              ToAmount: res.data.ToAmount,
              PromoID: res.data.PromoID,
              status: true,
            },
          }));
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((error) => { });
  };

  const validateRedeemCoupon = (req) => {
    ApiClient.post("admin/validateCouponAmt", req)
      .then((res) => {
        if (res.status === 200) {
          setOtherData((prev) => ({
            ...prev,
            redeemAmount: { CouponAmt: res.appliedCouponAmt, status: true },
          }));
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((error) => {});
  };
  const getAllServiceType = () => {
    setServiceTypeList([]);
    ApiClient.get(`admin/services`)
      .then((res) => {
        if (res.status === 200) {
          setServiceTypeList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
       });
  };

  const RemovePromoConvFee = () => {
    setOtherData({
      promoData: { status: false, Discount: 0 },
      ConvFee: { status: false, amount: 0 },
      insuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
      selectedInsuranceData: { amount: 0, insuranceCoverage: 0, status: 0 },
      redeemAmount: { CouponAmt: 0, status: false },
    });
  };

  const RemovePromo = () => {
    setOtherData((prev) => ({
      ...prev,
      promoData: { status: false, Discount: 0 },
    }));
  };

  const RemoveRedeemCoupon = () => {
    setOtherData((prev) => ({
      ...prev,
      redeemAmount: { CouponAmt: 0, status: false },
    }));
  };

  const AddConvFee = (type) => {
    ApiClient.get(`admin/conveniencefeebyservicetype/${type}`)
      .then((res) => {
        if (res.status === 200) {
          let convobj = {
            id: res.data.ID,
            status: true,
            amount: res.data.Value,
            type: res.data.ConvenienceFeeType,
          };
          setOtherData((prev) => ({
            ...prev,
            ConvFee: convobj,
          }));
        }
      })
      .catch((error) => { });
  };

  
  const [partPayment, setPartPayment] = useState({
    isPartPayment: false,
    ServiceType: 1,
    PartPaymentPercentage: 0,
  });

  const getPartPayment = (type, value) => {
    if (type == 2) {
      let obj = {
        BusinessType: value.BusinessType,
        ServiceType: value.ServiceType,
      };
      ApiClient.post("admin/partPayment", obj)
        .then((resp) => {
          if (resp.status == 200) {
            let catId = resp?.data?.CategoryID
              ? resp?.data?.CategoryID.split(",")
                .map((item) => {
                  if (item) {
                    return parseInt(item);
                  }
                })
                .filter((item) => item)
              : [];
            let data = {
              ...resp.data,
              isPartPayment: true,
              ServiceType: resp.data.ServiceType,
              PartPaymentPercentage: parseFloat(
                resp.data.PartPaymentPercentage
              ),
              CategoryID: catId,
              categories: resp?.data?.categories ?? [],
            };
            setPartPayment(data);
          } else {
            setPartPayment({
              ...partPayment,
              isPartPayment: false,
              PartPaymentPercentage: 0,
            });
            message.error("Part Payment Not Availale For The Moment", 3);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      setPartPayment({
        ...partPayment,
        isPartPayment: false,
        PartPaymentPercentage: 0,
      });
    }
  };

 
  const combinedPersistJson =
    JSON.parse(localStorage.getItem("combinedPersist")) || {};
  let initCombinedSearch =
    combinedPersistJson.combineSearchData || defCombinedSearch;

  const [airlineMatrixReset, setAirlineMatrixReset] = useState(false);

  const [tourPassangerData, setTourPassangerData] = useState([]);

  const [combineSearchData, setCombineSearchData] =
    useState(initCombinedSearch);

  useEffect(() => {
    
    localStorage.setItem(
      "combinedPersist",
      JSON.stringify({
        combineSearchData: combineSearchData,
      })
    );
  }, [combineSearchData]);
 


  
 
  
  const [holidaySearchResultObj, setHolidaySearchResultObj] = useState({});
  const [holidayBookingResp, setHolidayBookingResp] = useState([]);
  const [holidayBookingData, setHolidayBookingData] = useLocalStorage(
    "holidayBookingData",
    {}
  );
  
 

  const [flightFilters, setFlightFilters] = useState({});

  return (
    <GlobalStatesContext.Provider
      value={{
        state: {
         
          combineSearchData,
          busSearchResultObj,
          otherData,
          tourPassangerData,
          airlineMatrixReset,
          partPayment,
         
         
          holidaySearchResultObj,
          holidayBookingResp,
          holidayBookingData,
         
         
         
          flightFilters,
          serviceTypeList
        },
        ResetAirlineMatrix: (val) => {
          setAirlineMatrixReset(val);
        },

        validatePromoCode: (val) => {
          validatePromoCode(val);
        },

        getPartPayment: (e, val) => {
          getPartPayment(e, val);
        },
        AddConvFee: (v) => {
          AddConvFee(v);
        },
       
        updateOtherData: (val) => {
          setOtherData(val);
        },
        setSelectedInsuranceData: (val) => {
          setOtherData((prev) => ({
            ...prev,
            selectedInsuranceData: val,
          }));
        },
        UpdateMealData: (val) => {
          setOtherData((prev) => ({
            ...prev,
            mealData: val,
          }));
        },
        UpdateBaggageData: (val) => {
          setOtherData((prev) => ({
            ...prev,
            baggageData: val,
          }));
        },
        RemovePromo: () => {
          RemovePromo();
        },
        RemoveRedeemCoupon: () => {
          RemoveRedeemCoupon();
        },
        RemovePromoConvFee: () => {
          RemovePromoConvFee();
        },

        updataCombineSearchObj: (attribute, value) => {
          setCombineSearchData({ ...combineSearchData, [attribute]: value });
        },

        updateTourPassangerData: (obj) => {
          setTourPassangerData(obj);
        },
      
                validateRedeemCoupon: (val) => {
          validateRedeemCoupon(val);
        },
       
        updateHolidaySearchResultObj: (holidaySearchResp) => {
          setHolidaySearchResultObj(holidaySearchResp);
        },
        updateSelectedholidayData: (selectedobj) => {
          setHolidayBookingData(selectedobj);
        },
        updateHolidayBookingData: (respObj) => {
          setHolidayBookingResp(respObj);
        },
        
        updateFlightFilterForSlider: (flightFilteres) => {
          setFlightFilters(flightFilteres);
        },
        getAllServiceType:()=>{
          getAllServiceType();
        }
      }}
    >
      {props.children}
    </GlobalStatesContext.Provider>
  );
};
export default GlobalStatesProvider;
