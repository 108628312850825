//import * as ReactBoostrap from "react-bootstrap";
import excel from "../assets/vector-icons/excel.png";

import search from "../assets/vector-icons/search.png";


import React, { useState, useEffect } from "react";
import UpdatedComponent from "../../../src/components/ExportExcel";
import APIClient from "../../helpers/ApiClient";
import * as ANTD from "antd";
import {
  PlusOutlined,
  EditOutlined,
  IdcardOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  
} from "@ant-design/icons";
import { Form, Input, Modal, Radio, Select, Menu } from "antd";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const B2Bpg = (props) => {
  const arrayy = [];
  const [showSearchBox, setShowSearchBox] = useState(false);
  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = ANTD.Form.useForm();
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [modalVisible, setModalVisible] = React.useState(false);
  const [Visible, setVisible] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [formLayout, setFormLayout] = React.useState("vertical");

  const formItemLayout =
    formLayout === "vertical"
      ? {
        labelCol: { span: 24 },
        wrapperCol: { span: 23 },
      }
      : null;



  
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };



  

  useEffect(() => {
    Pgdetails();
  }, []);
  const Pgdetails = () => {
    APIClient.get("admin/b2bPG")
      .then((res) => {
        if (res.status == 200) {
          let filterProvider = res.data.filter((item) => item.Status === 1);
          //arrayy.push()
          let ardata = [];
          ardata = res.data.map((item) => {
            return {
              PaymentTypeID: item.PaymentTypeID,
              Amount: item.Amount,
              PaymentGatewayID: item.PaymentGatewayID,
              PaymentType: chooseTitle(item.PaymentType),
              PaymentGatewayID: chooseGate(item.PaymentGatewayID),
              Type: chooseType(item.Type),
            };
          });

          setList(ardata);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function chooseType(res) {
    let result = "";
    if (res == 1) {
      result += "Percentage";
    }
    if (res == 2) {
      result += "Fixed";
    }
    return result;
  }
  function choosetype(res) {
    let result = "";
    if (res == "Percentage") {
      result += 1;
    }
    if (res == "Fixed") {
      result += 2;
    }
    return parseInt(result, 10);
  }
  function chooseptype(res) {
    let result = "";
    if (res == "DebitCard") {
      result += 1;
    }
    if (res == "CreditCard") {
      result += 2;
    }
    if (res == "NetBanking") {
      result += 3;
    }
    if (res == "UPI") {
      result += 4;
    }
    if (res == "Amex") {
      result += 5;
    }
    if (res == 1) {
      result += 1;
    }
    if (res == 2) {
      result += 2;
    }
    if (res == 3) {
      result += 3;
    }
    if (res == 4) {
      result += 4;
    }
    if (res == 5) {
      result += 5;
    }
    return result;
  }
  function chooseTitle(res) {
    let result = "";
    if (res == 1) {
      result += "DebitCard";
    }
    if (res == 2) {
      result += "CreditCard";
    }
    if (res == 3) {
      result += "NetBanking";
    }
    if (res == 4) {
      result += "UPI";
    }
    if (res == 5) {
      result += "Amex";
    }
    return result;
  }
  function chooseGate(res) {
    let result = "";
    if (res == 2) {
      result += "CC Avenue";
    }
    else if(res ==1){
      result += "CC Avenue";
    }
    return result;
  }
  function choosegate(res) {
    let result = "";
    if (res == "Razorpay" || res == 2) {
      result += 2;
    }
    return parseInt(result, 10);
  }

  const columnsbankbtob = [
    {
      title: "Name",
      dataIndex: "PaymentType",
      sorter: (a, b) => a.PaymentType.localeCompare(b.PaymentType),
      
    },
    // {
    //   title: "Payment ID",
    //   dataIndex: "PaymentGatewayID",
      
    // },

    {
      title: "Price RS",
      dataIndex: "Amount",
    },

    {
      title: "Type",
      dataIndex: "Type",
     
    },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];
  const can = () => {
    setModalVisible(false);
    form.resetFields();
    setIsEditMode(false);
  };
  const tableActions = (CurrentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}

        // onClick={() => updateFlightComm(currentFlightComm)}
        >
          <EditOutlined
            onClick={() => updateFlightComm(CurrentItem)}
            style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteFlightComm(CurrentItem.PaymentTypeID)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };
  const [id, setId] = useState(-1);
  const updateFlightComm = (values) => {
    setId(values.PaymentTypeID);
    setIsEditMode(true);
    form.setFieldsValue({
      PaymentGatewayID: values.PaymentGatewayID,
      PaymentType: values.PaymentType,
      Type: choosetype(values.Type),
      Price: values.Amount,
    });
    setModalVisible(true);
    
  };
  const submitUpdateMap = (formMapData) => {
    APIClient.put(`admin/b2bPG/${id}`, {}, formMapData)
      .then((response) => {
        if (response.status == 200) {
          ANTD.message.success(" Updated SuccessFully", 3);
          Pgdetails();
          setModalVisible(false);
        } else if (response.status == 404) {
          ANTD.message.error("Not Found", 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteFlightComm = (id) => {
    APIClient.delete("admin/b2bPG/" + id)

      .then((res) => {
        if (res.status == 200) {
          ANTD.message.success("Deleted  successfully", 3);
          Pgdetails();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitForm = (values) => {
    let data = {
      PaymentGatewayID: values.PaymentGatewayID,
      PaymentType: values.PaymentType,
      Type: values.Type,
      Amount: values.Price,
      DeviceOS: 1,
      ApplicationType: 1,
      CreatedBy: 0,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
    };
    let dataa = {
      PaymentGatewayID: choosegate(values.PaymentGatewayID),
      PaymentType: chooseptype(values.PaymentType),
      Type: values.Type,
      Amount: values.Price,
      DeviceOS: 1,
      ApplicationType: 1,
      CreatedBy: 0,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
    };

    isEditMode ? submitUpdateMap(dataa) : submitMapDetails(data);
  };

  const submitMapDetails = (formMapData) => {
    APIClient.post("admin/b2bPG", formMapData)
      .then((response) => {
        if (response.status == 200) {
          ANTD.message.success("Details Added SuccessFully", 3);
          Pgdetails();
          setModalVisible(false);
          // getAgentList();
          form.resetFields();
        } else if (response.status == 201) {
          ANTD.message.error("Inserted Successfully", 3);
          //setAddMarkupModal(false);
        } else if (response.status == 400) {
          ANTD.message.error("Duplicate Entry", 3);
        } else if (response.status == 404) {
          ANTD.message.error("Not Found", 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  
  const searchTableData = (e) => {
    var searchVal = e.target.value;

    if (searchVal.length > 0) {
      const searchResults = list.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });

      setList(searchResults);
    } else {
      Pgdetails();
    }
  };
  const handleExcel = () => {
    const excelData = list.map((item, index) => {
      return {
        S_No: index + 1,
        Name: chooseTitle(item.PaymentType),
        PaymentGateway: chooseGate(item.PaymentGatewayID),
        Type: chooseType(item.Type),
        PriceRs: item.Amount,
      };
    });

    props.exportExcel(excelData, "PartnerWallet");
  };

  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <Content className="admin-container">
            <ANTD.Row>
              
              <ANTD.Col span={24}>
                <div className="card-bt-gap">
                  <ANTD.Card bordered={false}>
                    <div className="card-add-heading">
                      {/* <div className="table-heading-count"> */}
                      <div className="rows-count">
                        <div>
                          <h5>
                            View Payment Type{" "}
                            <HelpInfoHelper screenName={"/admin/b2b"} />
                          </h5>
                          <p>{list.length} rows found !</p>
                        </div>
                      </div>
                      <div className="action-images">
                        {showSearchBox && (
                          <ANTD.Input
                            placeholder="Search"
                            onChange={(e) => searchTableData(e)}
                            suffix={closeSearchInput}
                            style={{ padding: "0px 12px" }}
                          />
                        )}
                        &nbsp;&nbsp;
                        <img
                          src={search}
                          alt="search"
                          onClick={(e) => searchData()}
                        />
                        <img src={excel} alt="excel" onClick={handleExcel} />
                       
                        <p className="add-icon" onClick={showModal}>
                          <PlusOutlined />
                        </p>
                      </div>
                      {/* </div> */}
                    </div>

                    <div>
                      
                      <ANTD.Table
                        className="table-scroll-none"
                        bordered
                        dataSource={list}
                        columns={columnsbankbtob}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />

                     
                    </div>
                  </ANTD.Card>
                </div>
              </ANTD.Col>
            </ANTD.Row>
          </Content>
        </div>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Add Payment Type</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header admin-bc-level modal-header-bg12"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => can()}
          footer={[
            <div>
              <ANTD.Button key="add" type="primary" onClick={form.submit}>
                {isEditMode ? "Update" : "Add"}
              </ANTD.Button>
            </div>,
          ]}
        >
          <ANTD.Form
            {...formItemLayout}
            form={form}
            layout={formLayout}
            onFinish={submitForm}
            initialValues={{ layout: formLayout }}
          >
            <ANTD.Row>
              <ANTD.Col md={24} xs={24}>
                <ANTD.Form.Item
                  label="Payment Gateway"
                  rules={[{ required: true }]}
                  name="PaymentGatewayID"
                >
                  <ANTD.Select
                    size={"large"}
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    placeholder="Please Select"
                  >
                    {/* <ANTD.Select.Option value="1">CashFree</ANTD.Select.Option> */}
                    <ANTD.Select.Option value="2">CC AVENUE</ANTD.Select.Option>
                  </ANTD.Select>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col md={24} xs={24}>
                <ANTD.Form.Item
                  label="Payment Type"
                  rules={[{ required: true }]}
                  name="PaymentType"
                >
                  <ANTD.Select
                    size={"large"}
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    placeholder="Please Select"
                  >
                    <ANTD.Select.Option value="1">DebitCard</ANTD.Select.Option>
                    <ANTD.Select.Option value="2">
                      CreditCard
                    </ANTD.Select.Option>
                    <ANTD.Select.Option value="3">
                      NetBanking
                    </ANTD.Select.Option>
                    <ANTD.Select.Option value="4">UPI</ANTD.Select.Option>
                    <ANTD.Select.Option value="5">Amex</ANTD.Select.Option>
                  </ANTD.Select>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col md={24} xs={24}>
                <ANTD.Form.Item
                  label="Price"
                  name="Price"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter your Price",
                    },
                  ]}
                >
                  <ANTD.Input placeholder="Price" size={"large"} />
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col md={12} xs={24}>
                <Form.Item
                  label="Type"
                  name="Type"
                  rules={[{ required: true, message: "Type is required" }]}
                >
                  <Radio.Group>
                    <Radio value={1}>Percentage</Radio>
                    <Radio value={2}>Fixed</Radio>
                  </Radio.Group>
                </Form.Item>
              </ANTD.Col>
            </ANTD.Row>
          </ANTD.Form>
        </Modal>
      </div>
    </div>
  );
};

export default UpdatedComponent(B2Bpg);
