import React, { useState, useRef } from "react";

import { Radio, Col, Row, Form, Select, DatePicker, Button, Input } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import AirportAutoComplete from "../../common/AutoCompleteSelect/AirportAutoCompleteSelect";
import { OnlyFutureDateValidator } from "../../helpers/CustomValidators";

const MultiCityLIst1 = ({ updateinputfield = () => { }, getnoofroutes = () => { }, ChangeDates = () => { } }) => {
  let dept = useRef([]);
  let to = useRef([]);
  let mdateBox = useRef([]);
  const [refresh, setRefresh] = useState(false);
  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };
  const [fDestinationDate, setfDestinationDate] = useState(moment());
  const [sDestinationDate, setsDestinationDate] = useState(moment());
  const [tDestinationDate, settDestinationDate] = useState(moment());
  const [foDestinationDate, setfoDestinationDate] = useState(moment());


  const OriginDestinationField = ({ count, field, add, index, remove }) => {
    const [showMDate, setShowMDate] = useState(false);

    const handleOnSubmit = (ref, isfieldKey, fieldKey) => {
      if (isfieldKey) {
        ref.current[fieldKey].focus();
        if (ref === mdateBox) {
          setShowMDate(true);
        }
      }
      updateinputfield(fieldKey);
    };
    const onChangeFromDate = (momentdate, _, fieldKey) => {
      if (fieldKey == 0) {
        setfDestinationDate(momentdate)
      } else if (fieldKey === 1) {
        setsDestinationDate(momentdate)
      }
      else if (fieldKey === 2) {
        settDestinationDate(momentdate)

      } else if (fieldKey === 3) {
        setfoDestinationDate(momentdate)
      }
      var selectedDate = fieldKey === 0 ? sDestinationDate : fieldKey === 1 ? tDestinationDate : foDestinationDate;
      if (momentdate > selectedDate) {
        ChangeDates(momentdate, fieldKey)
      }

    };
    const disabledFutureDate = (currentDate, key) => {
      if (key === 0) {
        return currentDate < moment().startOf("day");
      }
      if (key === 1) {
        return currentDate < moment(fDestinationDate).startOf("day");
      }
      if (key === 2) {
        return currentDate < moment(sDestinationDate).startOf("day");
      }
      if (key === 3) {
        return currentDate < moment(tDestinationDate).startOf("day");
      }
    };
    return (
      <Row gutter={16}   >
        <Col md={8} xs={12} style={{ paddingLeft: '0px', paddingRight: '0px', marginLeft:'1%' }} >
          <span className="for-to-labflisearch" style={{ position: 'absolute', zIndex: '1', fontSize: "14px", fontWeight: "500", fontFamily: "sans-serif", color:'#3298ff', marginLeft:'8%' }} >From</span>
          <AirportAutoComplete
            formItemProps={{
              label: "Depart From",
              ...field,
              name: [field.name, "origin"],
              fieldKey: [field.fieldKey, "origin"],

              rules: [{ required: true, message: "Please Select a City" }],
            }}
            selectProps={{ size: "large", placeholder: "Select Source" }}
            refName={dept}
            focusRef={to}
            isfieldKey={true}
            fieldKey={field.name}
            handleOnSubmit={handleOnSubmit}
          />
        </Col>
        <Col md={8} xs={12} style={{ paddingLeft: '0px', paddingRight: '0px' , marginLeft:'1%'}}>
          <span className="for-to-labflisearch" style={{ position: 'absolute', zIndex: '1', fontSize: "14px", fontWeight: "500", fontFamily: "sans-serif", color:'#3298ff', marginLeft:'8%' }} >To</span>

          <AirportAutoComplete
            formItemProps={{
              label: "Flying To",
              ...field,
              name: [field.name, "destination"],
              fieldKey: [field.fieldKey, "destination"],

              rules: [{ required: true, message: "Please Select a City" }],
            }}
            selectProps={{ size: "large", placeholder: "Select destination" }}
            refName={to}
            isfieldKey={true}
            fieldKey={field.name}
            focusRef={mdateBox}
            handleOnSubmit={handleOnSubmit}
          />
        </Col>
        <Col md={7} xs={12} style={{ paddingLeft: '0px', paddingRight: '0px' }} className="date-multi-city-work" >
          <span className="for-to-labflisearch1" style={{ position: 'absolute', zIndex: '1', fontSize: "14px", fontWeight: "500", fontFamily: "sans-serif", color:'#3298ff', marginLeft:'6%' }} >Departing</span>

          <Form.Item
            label="Departure Date"
            {...field}
            name={[field.name, "departureDateTime"]}
            fieldKey={[field.fieldKey, "departureDateTime"]}
            rules={[{ required: true, message: "Select a Date" }]}
          >
            <DatePicker
              className="train-search-btn"
              size="large"
              style={{ width: "100%" }}
              format={"DD-MM-YYYY"}
              ref={(el) => (mdateBox.current[field.name] = el)}
              open={showMDate}
              disabledDate={(e) => disabledFutureDate(e, field?.fieldKey)}
              onChange={(date, dateString) => {
                onChangeFromDate(date, dateString, field?.fieldKey)
                if (field.name === count) {
               
                } else {
                  dept.current[field.key + 1].focus();
                  setShowMDate((prev) => !prev);
                }
              }}
              onOpenChange={() => setShowMDate((prev) => !prev)}
            />
          </Form.Item>
        </Col>
        {field.name === 2 || field.name === 3 ? (
          <MinusCircleOutlined
          style={{zIndex:'1', position:'absolute'}}
            className="minus_multicity"
            onClick={() => remove(index)}
          />
        ) : null}
      </Row>
    );
  };

  const intitalValues = DefaultMulticityLoad();
  const countfields = (fieldsL) => {
    getnoofroutes((fieldsL?.length) + 1)
  }
  return (
    <Col md={16} xs={20} >
       <div id="multiCityForm" className="multicity-main-box">
        <Form.List name="originDestinationFields">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => {
                  return (
                    <OriginDestinationField
                      count={fields.length - 1}
                      field={field}
                      index={index}
                      key={field.key}
                      remove={remove}
                    />
                  );
                })}

                {fields.length < 4 ? (
                  <PlusCircleOutlined className="plus-icon" onClick={() => { add(); countfields(fields) }}>hvuyvuvu</PlusCircleOutlined>
                ) : null}
              </>
            );
          }}
        </Form.List>
      </div>
    </Col>
  );
};

export const DefaultMulticityLoad = () => {
  const value =
    localStorage.getItem("multicity") != undefined
      ? {
        originDestinationFields: JSON.parse(
          localStorage.getItem("multicity")
        ).map((i) => {
          return {
            ...i,
            departureDateTime: i.departureDateTime
              ? moment(i.departureDateTime)
              : "",
          };
        }),
      }
      : {
        originDestinationFields: [{}, {}],
      };
  return value;
};

export default React.memo(MultiCityLIst1);
