import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
import "./cmspages.scss";
import ReactHtmlParser from "react-html-parser";

import APIClient from "../../helpers/ApiClient";



const CmsPages = ({ cmsId, pageTitle }) => {
  const [CMSList, setCMSList] = useState([]);

  const getCMSList = () => {
    APIClient.get(`admin/cms/${cmsId}`)
      .then((response) => {
        if (response?.status == 200) {
          setCMSList(response.data);
        } else {
          setCMSList([]);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getCMSList();
  }, []);

  return (
    <div className="collapseCustomWhite">
      <div className="promo providerapi ourteam-values"></div>

      <div className="ourteam-bg">
        <div className="admin-container cms-pages-width">
          <h2 className="common-headings">{pageTitle}</h2>

         
          {CMSList.map((res) => (
            <div key={res.Id} className="cms-records-wrapper">
              <p className="title">{res.Title}</p>

              <div className="des-wrapper">
                {ReactHtmlParser(res.Description)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};



export default CmsPages;
