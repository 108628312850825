import React from "react";
import { Result } from "antd";
import "./unauth.scss";
import { Link } from "react-router-dom";
import * as ANTD from "antd";
const CustomNoResultFound = ({ title }) => {
  return (
    <div className="error-404">
      <img
        className="no-results-img"
        src={require("../../assets/images/no-res-one.png").default}
        alt="search-img"
       
      />
      <p className="text-results-found">{title}</p>
      <Link to="/">
        <ANTD.Button className="backto-homebtn" type="primary">
          Back Home
        </ANTD.Button>
      </Link>
     </div>
  );
};

export default CustomNoResultFound;
