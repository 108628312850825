import moment from "moment";
const OnlyFutureDateValidator = (rule, value) => {
  const currentDate = Date.now();
  if (currentDate > value) {
    return Promise.reject("Only Future Date Allowed");
  } else {
    return Promise.resolve();
  }
};

export const DepartureAndArrivalDatevalidator = (rule, value) => {
  if (!value) {
    return Promise.reject("");
  } else {
    const currentDate = Date.now();

    if (currentDate >= value[0]) {
      return Promise.reject("Select Future Date");
    } else {
      return Promise.resolve();
    }
  }
};

export const PaxAgeValidator = (paxType, dob) => {
  // if (!dob) {
  //   return Promise.reject(" Date");
  // }

  let age = moment().diff(dob, "years");
  var days = moment().diff(dob, "days");

  if (paxType === "ADT") {
    if (age >= 12) {
      return Promise.resolve();
    } else {
      return Promise.reject("Adult Age at least 12");
    }
  } else if (paxType === "CHD") {
    if (age < 12 && age >= 2) {
      return Promise.resolve();
    } else {
      return Promise.reject("Child Age between 2 and 12");
    }
  } else if (paxType === "INF") {
    if (days < 730) {
      return Promise.resolve();
    } else {
      return Promise.reject("Infant Should  Be less than 2 years old");
    }
  } else {
    return Promise.reject("Please Select Pax Type First");
  }
};

export const OriDesValidate = (origin, destination) => {
  if (origin && destination) {
    if (origin === destination) {
      return Promise.reject("Origin and Destination cannot be same");
    }
  }
  return Promise.resolve();
};

export { OnlyFutureDateValidator };
