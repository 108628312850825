import React from "react";
import * as ANTD from "antd";
import {
  DownloadOutlined,
  DownOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import dateFormat from "dateformat";
const UserInvoice = () => {
  const columns = [
    {
      title: "S.No.",
      dataIndex: "serialno",
      key: "serialno",
    },
    {
      title: "Refrence No.",
      dataIndex: "referenceno",
      key: "referenceno",
    },
    {
      title: "Confirmation No.",
      dataIndex: "confirmationno",
      key: "confirmationno",
    },
    {
      title: "City Name",
      dataIndex: "cityname",
      key: "cityname",
    },
    {
      title: "Hotel Name",
      dataIndex: "hotelname",
      key: "hotelname",
    },
    {
      title: "Check In",
      dataIndex: "checkin",
      key: "checkin",
    },
    {
      title: "Check Out",
      dataIndex: "checkout",
      key: "checkout",
    },
    {
      title: "No. Of Nights",
      dataIndex: "noofnights",
      key: "noofnights",
    },
    {
      title: "PNR No.",
      dataIndex: "pnrno",
      key: "pnrno",
    },
    {
      title: "E-Ticket",
      dataIndex: "eticket",
      key: "eticket",
    },
    {
      title: "Sectors",
      dataIndex: "Sectors",
      key: "Sectors",
    },
    {
      title: "Operator Name",
      dataIndex: "operatorname",
      key: "operatorname",
    },
    {
      title: "Passenger Info",
      dataIndex: "paxname",
      key: "paxname",
    },
    {
      title: "PAX Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
    },
    {
      title: "Seat No.",
      dataIndex: "seatno",
      key: "seatno",
    },
    {
      title: "Fare",
      dataIndex: "fare",
      key: "fare",
    },
  ];

  const data = [
    {
      serialno: "1",
      referenceno: "98745",
      pnrno: "5478521",
      confirmationno: "45687",
      cityname: "Hyderabad",
      hotelname: "Taj",
      checkin: "24-5-2020",
      checkout: "25-5-2020",
      noofnights: "2",
      Sectors: "BLR-DL",
      eticket: "",
      operatorname: "Air-asia",
      paxname: "jhon",
      type: "ADT",
      class: "EC",
      seatno: "25",
      fare: "5074",
    },
    {
      
      class: "Tax",
      
      fare: "674",
    },
    {
      
      fare: "5748",
    },
    {
      
      type: "Discount",
     
      fare: "180",
    },
    {
      
      type: "Less : Commiission",
     
      fare: "31.21",
    },
    {
      
      type: "ADD : TDS",
      
      fare: "1.56",
    },
    {
      
      type: "ADD : TDS",
      class: "Total",
      seatno: "",
      fare: "5718.35",
    },
  ];
  const gstdata = [
    {
      companyname: "Travels",
      gstnumber: "12345",
      contactnumber: 9874563210,
      gstemail: "travels@gmail.com",
      gstaddress: "10 Downing Street",
    },
  ];

  const gstcolumns = [
    {
      title: "GST Company Name",
      dataIndex: "companyname",
      key: "companyname",
    },
    {
      title: "GST Number",
      dataIndex: "gstnumber",
      key: "gstnumber",
    },
    {
      title: "GST Company Contact Number",
      dataIndex: "contactnumber",
      key: "contactnumber",
    },
    {
      title: "GST Company Contact Email",
      dataIndex: "gstemail",
      key: "gstemail",
    },
    {
      title: "GST Company Address",
      dataIndex: "gstaddress",
      key: "gstaddress",
    },
  ];
  return (
    <div>
      <div className="invoices-block">
        <div className="invoice-header-block">
          <div className="logo">
            <h4>Logo</h4>
          </div>
          <div className="download-icons">
            <div className="print-btn">
              <ANTD.Button type="primary">
                <PrinterOutlined />
                <span>Print</span>
              </ANTD.Button>
            </div>
            <div className="print-btn">
              <ANTD.Button type="primary">
                <DownloadOutlined /> <span>Download</span>
              </ANTD.Button>
            </div>
          </div>
        </div>
        <div className="invoice-title">
          <div className="banner-line"></div>
          <div className="invoice-text">
            <h5> Invoice</h5>
          </div>
        </div>
        <div className="invoice-details-block">
          <div className="user-details-list">
            <ul>
              <li>
                Agency Name : <span>A12088446</span>
              </li>
              <li>
                Address : <span>16-07-2020</span>
              </li>
              <li>
                City : <span>E5-088446</span>
              </li>
              <li>
                State : <span>18-07-2020</span>
              </li>
            </ul>
          </div>
          <div className="pnr-details-block">
            <ul>
              <li>
                Invoice Number : <span>A12088446</span>
              </li>
              <li>
                Invoice Date : <span>16-07-2020</span>
              </li>
              <li>
                PNR Number : <span>E5-088446</span>
              </li>
              <li>
                Travel Date : <span>18-07-2020</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="table-block">
          <ANTD.Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={data}
            bordered
          />
          ;
        </div>
        
        <div className="amount-text">
          <p>
            Amount in Words :{" "}
            <span>
              {" "}
              Five Thousand Seven Hundred Eighteen Rupees Thirty Five Paisa Only
            </span>
          </p>
        </div>
        <div className="gst-table table-scroll-none" bordered>
          <ANTD.Table
            scroll={{ x: true }}
            dataSource={gstdata}
            columns={gstcolumns}
          />
        </div>
        <div className="invoice-footer">
          <div className="footer-left-block">
            <div className="terms-conditions">
              <ul>
                <li>Terms & Conditions</li>
                <li>E & O.E.</li>
                <li>Raj Travels</li>
                <li>M.G.Road</li>
                <li>Delhi</li>
              </ul>
            </div>
            <div className="mail-info">
              <ul>
                <li>
                  Mail To : <span>Support@gmail.com</span>
                </li>
                <li>
                  Phone No : <span>9874563210</span>
                </li>
                <li>
                  GST No: <span>5674852</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-right-block">
            <p>Travel Services Pvt. Ltd.</p>
            <p>Authorised Signatory</p>
          </div>
        </div>
        <div className="invoice-note">
          <p>
            NOTE : This is computer generated receipt and does not require
            physical signature.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserInvoice;
