import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const Loading = ({loading}) => {
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 22,
                color: "white",
                marginLeft: "5px"
            }}
            spin
        />
    );
    return (<>
        {loading ? <Spin indicator={antIcon} /> : null}
    </>)
}
export default Loading;