import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Popconfirm,
  Radio,
  Row,
  Select,
  Switch,
  Upload,
  Table,
} from "antd";
import moment from "moment";
import APIClient from "../../helpers/ApiClient";
import excel from "../assets/vector-icons/excel.png";
import exchange from "../assets/vector-icons/exchange.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";
import Loading from "../../common/Loading/Loading";

import { useAuthContext } from "../../common/providers/AuthProvider";

import {
  CloseOutlined,
  StarOutlined,
  UploadOutlined,
  DeleteOutlined,
  EditOutlined,
  LeftSquareOutlined,
  PlusCircleFilled,
  PlusOutlined,
  StopOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import VerifyPasswordModal from "../../common/VerifyPasswordModal/VerifyPasswordModal";

const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const ActivitiesImageUpload = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );
  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "application/pdf" ||
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be Smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(true);

      return;
    }
    if (info.file.status === "removed") {
      setIsUploading(false);

      // setFileList(info.fileList);
    }
    if (info.file.status === "error") {
      setIsUploading(false);

      return;
    }
    if (info.file.status === "done") {
      // setFileList(info.fileList);

      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          // onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList
              ? fileList.length >= limitSize
                ? null
                : uploadLogoButton
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        destroyOnClose={true}
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};
const AgentRegistration = (props) => {
  const handleVerifyPassword = (values) => {
    APIClient.post("admin/verifyTransactionPassword", {
      UserID: user.UserID,
      Password: values,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          message.success(res.message);
          if (formType == 1) {
            form.submit();
          } else if (formType == 2) {
            deleteFlightComm(UserId);
          } else if (formType == 3) {
            changeStatus(userData, 0);
          } else if (formType == 4) {
            changeStatus(userData, 1);
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };
  const [pan, setPan] = useState([]);
  const [adhar, setAdhar] = useState([]);
  const [gst, setGst] = useState([]);

  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [formType, setFormType] = useState(-1);
  const { user } = useAuthContext();

  const [otheruploads, setOtheruploads] = useState([]);
  const { Option } = Select;
  const [id, setId] = useState(-1);
  const [addMarkupModal, setAddMarkupModal] = useState(false);
  const [providersList, setProvidersList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [UserId, setUserId] = useState(-1);
  const [flightCommLists, setFlightCommLists] = useState([]);
  const [userData, setUserData] = useState({});
  const[isLoading,setisLoading]=useState(false);
  const [logo,setLogo] = useState({})

  const disabledOriginDate = (currentDate) => {
    return currentDate > moment().startOf("day");
  };
  function onChangeDate(date, dateString) { }
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [member, setMember] = useState([]);
  const [form] = Form.useForm();
  const validateMessages = {
    required: "",
  };
  useEffect(() => {
    getAgentList();
    getMembership();
  }, []);
  const getMembership = () => {
    APIClient.get("admin/memberships")
      .then((res) => {
        if (res.status == 200) {
          let filterProvider = res.data.filter(
            (item) => item.ServiceType === 1
          );
          setMember(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [imagePath, setImagePath] = useState({
    company: [],
    progressbarBanners: [],
  });
  const [dataFileList, setDataFileList] = useState([]);
  const prop = {
    action: uploadActionUrl,
    listType: "picture",
    name: "image",
    fileList: [...dataFileList],
    className: "upload-list-inline",
    data: { category: "progressbarBanner" },
  };
  const handleImageChange = (fileData) => {
    if (fileData.file.status === "uploading") {
      return;
    }
    setDataFileList(fileData.fileList);
    if (fileData.file.status === "done") {
      let copy = imagePath;
      copy["progressbarBanners"].push(fileData.file.response.data.filepaths[0]);
      setImagePath(copy);
    }
  };
  const getAgentList = () => {
    APIClient.get("admin/user")
      .then((res) => {
        if (res.status == 200) {
          let filterProvider = res.data.filter(
            (item) => item?.role?.Level === 3
          );

          let data = filterProvider.map((item, index) => {
            let FirstName = item.userDetails ? item.userDetails.FirstName : "";
            let LastName = item.userDetails ? item.userDetails.LastName : "";
            let service =
              item.Services != null
                ? Array.from(item.Services.split("$"), Number)
                : "";

            return {
              ...item,
              SNo: index + 1,

              UserID: item.UserID,
              Wallet: item.agentWalletDetails
                ? item.agentWalletDetails.Amount != undefined
                  ? item.agentWalletDetails.Amount
                  : 0
                : 0,
              Amount: item.Amount != undefined ? item.Amount : "",
              Credit: item.agentWalletDetails
                ? item.agentWalletDetails.CreditAmount != undefined
                  ? item.agentWalletDetails.CreditAmount
                  : 0
                : 0,
              CreditDate: item.agentWalletDetails
                ? item.userDetails.DOB != undefined
                  ? moment(item.agentWalletDetails.FromDate).format(
                    "DD-MM-YYYY"
                  )
                  : ""
                : "",
              ExpiryDate: item.agentWalletDetails
                ? item.agentWalletDetails.ExpiryDate != undefined
                  ? moment(item.agentWalletDetails.ExpiryDate).format(
                    "DD-MM-YYYY"
                  )
                  : ""
                : "",
              Title: item.userDetails ? item.userDetails.Title : "",
              FirstName: item.userDetails ? item.userDetails.FirstName : "",
              LastName: item.userDetails ? item.userDetails.LastName : "",
              AgentName: FirstName + " " + LastName,
             
              DOB: item.userDetails
                ? item.userDetails.DOB != undefined
                  ? moment(item.userDetails.DOB).format("MM-DD-YYYY")
                  : ""
                : "",
              Services: item.Services,
              MembershipType: item.Membership,
              Gender: item.userDetails ? item.userDetails.Gender : "",
              Email: item.Email,
              PANUpload: item.userDetails ? item.userDetails.PANUpload : "",
              GSTUpload: item.userDetails ? item.userDetails.GSTUpload : "",
              AdhaarCardUpload: item.userDetails
                ? item.userDetails.AdhaarCardUpload
                : "",
              MobileNumber: item.Mobile,
              AgencyName: item.userDetails ? item.userDetails.AgencyName : "",
              StoreAddress: item.userDetails ? item.userDetails.Address1 : "",
              RegisteredAddress: item.userDetails
                ? item.userDetails.Address2
                : "",
              Comments: item.userDetails ? item.userDetails.Comments : "",
              Status: item.Status,
              AdhaarNumber: item.userDetails ? item.userDetails.AadharCard : "",
              PANNumber: item.userDetails ? item.userDetails.PAN : "",
              GSTINNumber: item?.GSTDetails ? item?.GSTDetails.GSTINNumber : "",
              GSTCompanyName: item?.userGstvat?.GstCompanyName ?? "",
              GstNumber: item?.userGstvat?.GstNumber ?? "",
              GstCompanyContactNumber:
                item?.userGstvat?.GstCompanyContactNumber ?? "",
              GstCompanyEmail: item?.userGstvat?.GstCompanyEmail ?? "",
              GstCompanyAddress: item?.userGstvat?.GstCompanyAddress ?? "",
            };
          });

          setFlightCommLists(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [currentuser, setCurrentUser] = useState({});
  const [UploadGSTprops, setUploadGSTprops] = useState({});
  const [UploadPANprops, setUploadPANprops] = useState({});
  const [UploadADHARprops, setUploadADHARprops] = useState({});
  const updateFlightComm = (currentData) => {
    setUploadGSTprops({
      onChange({ file, fileList }) {
        if (file.status !== "uploading") {
         }
      },
      showUploadList: {
        showDownloadIcon: true,
        downloadIcon: "download ",
        showRemoveIcon: true,
        removeIcon: (
          <StarOutlined
            onClick={(e) => console.log(e, "custom removeIcon event")}
          />
        ),
      },
    });

    setUploadADHARprops({
      onChange({ file, fileList }) {
        if (file.status !== "uploading") {
         }
      },
      showUploadList: {
        showDownloadIcon: true,
        downloadIcon: "download ",
        showRemoveIcon: true,
        removeIcon: (
          <StarOutlined
            onClick={(e) => console.log(e, "custom removeIcon event")}
          />
        ),
      },
    });
    setUploadPANprops({
      onChange({ file, fileList }) {
        if (file.status !== "uploading") {
         }
      },
      showUploadList: {
        showDownloadIcon: true,
        downloadIcon: "download ",
        showRemoveIcon: true,
        removeIcon: (
          <StarOutlined
            onClick={(e) => console.log(e, "custom removeIcon event")}
          />
        ),
      },
    });
    if (currentData.Services.includes("$")) {
      currentData.Services = currentData.Services.split("$").map((e) =>
        parseInt(e)
      );
    } else {
      currentData.Services = currentData.Services;
    }

    setIsEditMode(true);
    setUserId(currentData.UserID);
    setCurrentUser(currentData);
    Logo(currentData.UserID)
    form.setFieldsValue({
      ...currentData,
      GSTUpload: currentData.GSTAdress,
      PANUpload: currentData.PANUpload,
      DOB: currentData.DOB ? moment(currentData.DOB) : "",
      ExpiryDate: currentData.ExpiryDate ? moment(currentData.ExpiryDate) : "",
      Gender: currentData.Gender,
      servicetype: currentData.Services,
      AdhaarCardUpload: currentData.AdhaarCardUpload,
      GSTINNumber: currentData?.userGstVat?.GstNumber,
    });
    if (currentData.GSTUpload) {
      let gstImgArr = [];
      gstImgArr.push({
        uid: currentData.UserID,
        name: currentData.FirstName,
        status: "done",
        url: `${BASE}${currentData.GSTUpload.substring(1)}`,
        response: {
          status: 200,
          data: {
            filepaths: [currentData.GSTUpload],
          },
        },
      });
      setGst(gstImgArr);
    } else {
      setGst([]);
    }
    if (currentData.AdhaarCardUpload) {
      let AdharImgArr = [];
      AdharImgArr.push({
        uid: currentData.UserID,
        name: currentData.FirstName,
        status: "done",
        url: `${BASE}${currentData.AdhaarCardUpload.substring(1)}`,
        response: {
          status: 200,
          data: {
            filepaths: [currentData.AdhaarCardUpload],
          },
        },
      });
      setAdhar(AdharImgArr);
    } else {
      setAdhar([]);
    }

    if (currentData.PANUpload) {
      let PanImgArr = [];
      PanImgArr.push({
        uid: currentData.UserID,
        name: currentData.FirstName,
        status: "done",
        url: `${BASE}${currentData.PANUpload.substring(1)}`,
        response: {
          status: 200,
          data: {
            filepaths: [currentData.PANUpload],
          },
        },
      });

      setPan(PanImgArr);
    } else {
      setPan([]);
    }

    setAddMarkupModal(true);
  };
  const prefixSelector = 91;

  const deleteFlightComm = (UserID) => {
    APIClient.delete("admin/user/" + UserID)
      .then((res) => {
        if (res.status == 200) {
          message.success("Deleted  Successfully", 3);
          getAgentList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const revokeCredit = (Credit, userID) => {
    const data = {
      CreditAmount: Credit,
      AddrRevoke: 3,
      UserID: userID,
      DeviceOS: 0,
      DeviceOSVersion: "string",
      ApplicationType: 0,
      DeviceToken: "string",
      FirBaseToken: "string",
      CreatedBy: 0,
    };
    APIClient.post("admin/AddrRevoke/DepositRequest", data)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.AddrRevoke == 1) {
            message.success("Amount Added SuccessFully", 3);
          } else {
            message.success("Amount Revoked Successfully", 3);
          }
          getAgentList();
        } else if (response.status == 400) {
          message.error("Enter Required Fields", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const tableActions = (currentFlightComm) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => updateFlightComm(currentFlightComm)}
            style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => {
              setFormType(2);
              setVerifyModalVisible(true);
              setUserId(currentFlightComm.UserID);
            }}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
        {currentFlightComm.Credit > 0 ? (
          <div className="edit-icon" style={{ textAlign: "center" }}>
            <Popconfirm
              title="Are you sure to revoke Credit?"
              onConfirm={() =>
                revokeCredit(currentFlightComm.Credit, currentFlightComm.UserID)
              }
            >
              <LeftSquareOutlined
                style={{ color: "black", fontSize: "20px" }}
              />
            </Popconfirm>
          </div>
        ) : (
          <div className="edit-icon" style={{ textAlign: "center" }}>
            <Popover content="No Credit">
              <StopOutlined
                style={{ color: "red", fontSize: "20px", textAlign: "center" }}
              />
            </Popover>
          </div>
        )}
      </div>
    );
  };

  const add = () => {
    setGst([]);
    setPan([]);
    setAdhar([]);
    form.resetFields();
    if (isEditMode) {
      setIsEditMode(false);
      form.resetFields();
    }
    setAddMarkupModal(true);
  };

  const clear = () => {
    setAddMarkupModal(false);
  };

  const tableColumns = [
    {
      title: "S.No",
      dataIndex: "SNo",
    },

    {
      title: "Membership",
      dataIndex: "memberShip",
      render: (memberShip) => (memberShip ? memberShip?.Name : ""),
    },

    {
      title: "Agency Name",
      dataIndex: "AgencyName",
      sorter: (a, b) => a.AgencyName?.localeCompare(b.AgencyName),
    },
    {
      title: "Partner ID",
      dataIndex: "AgentID",
    },
    {
      title: "Partner Name",
      dataIndex: "AgentName",
      sorter: (a, b) => a.AgentName?.localeCompare(b.AgentName),
    },
    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email?.localeCompare(b.Email),
    },
    {
      title: "Mobile",
      dataIndex: "Mobile",
      sorter: (a, b) => a.Mobile?.localeCompare(b.Mobile),
    },
    {
      title: "Wallet",
      dataIndex: "Wallet",
      sorter: (a, b) => a.Wallet - b.Wallet,
    },
    {
      title: "Credit",
      dataIndex: "Credit",

      sorter: (a, b) => a.Credit - b.Credit,
    },
   
    {
      title: "ExpiryDate",
      dataIndex: "ExpiryDate",
      //  render: (ExpiryDate) =>ExpiryDate?moment(ExpiryDate).format("DD-MM-YYYY"):"",
      sorter: (a, b) => a.ExpiryDate?.localeCompare(b.ExpiryDate),
    },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
    {
      title: "Status",

      render: (currentItem) => handelStatus(currentItem),
    },
  ];
  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => {
            setVerifyModalVisible(true);
            setFormType(3);
            setUserData(data);
          }}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => {
            setVerifyModalVisible(true);
            setFormType(4);
            setUserData(data);
          }}
        />
      );
    }
  };
  const changeStatus = (values, Status) => {
    const id = values.UserID;
    //delete info.SNo
    //delete info.categoryId
    values.Services = values?.Services?.split("$");
    setUserId(values.UserID);
    let obj = {
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Email,
      DialingCode: 91,
      Mobile: values.MobileNumber,
      DOB: values.DOB,
      Gender: values.Gender,
      StoreAddress: values.StoreAddress,
      Status: Status,
      RegisterdAddress: values.RegisteredAddress,
      AgencyLogo: currentuser.AgencyLogo,
      AgencyName: values.AgencyName,
      Membership: values.MembershipType,
      Services: values.Services,
      PaymentPreference: [1],
      Role: 5,
      PAN: values.PANNumber,
      PANUpload: values.PANUpload ? values.PANUpload : "",
      AdhaarCard: values.AdhaarNumber,
       AdhaarCardUpload: values.AdhaarCardUpload ? values.AdhaarCardUpload : "",
      GSTUpload: values.GSTUpload ? values.GSTUpload : "",
      LicenseNumber: "string",
      VoterId: "string",
      Passport: "string",
      PassportUpload: "string",
      OtherDocumentsUpload: values.OtherDocumentsUpload
        ? values.OtherDocumentsUpload
        : "",
      Comments: values.Comments,
      Title: values.Title,
      GSTDetails: {
        GSTCompanyName: values?.userGstVat?.GstCompanyName ?? "",
        GSTINNumber: values?.userGstVat?.GstNumber ?? "",
        GSTAdress: values?.userGstVat?.GstCompanyAddress ?? "",
        GSTPhone: values?.userGstVat?.GstCompanyContactNumber ?? "",
        GSTEmail: values?.userGstVat?.GstCompanyEmail ?? "",
      },
      ModifiedBy: 0,
      ModifiedDate: new Date(),
    };
    submitUpdateMap(obj, id);
  };

  const Logo = (UserId ) => {
    APIClient.get(`admin/b2b/user/${UserId}`)
    .then((response) => {
     
      if (response.status === 200) {
      
        setLogo(response.data.userDetails)
      }
      else{
        console.log("error")
      }
    })
  }

  const submitForm = (values) => {

    let adharPath = "";

    if (adhar.length > 0) {
      adharPath = adhar[0].response.data.filepaths[0];
    }

    let panUpload = "";

    if (pan.length > 0) {
      panUpload = pan[0].response.data.filepaths[0];
    }
    let GSTUpload = "";

    if (gst.length > 0) {
      GSTUpload = gst[0].response.data.filepaths[0];
    }
    let otherUpload = "";

  
    if (otheruploads.length > 0) {
      otherUpload = otheruploads[0].response.data.filepaths[0];
    }

    if (values.Gender == "Male") {
      values.Gender = 1;
    } else if (values.Gender == "Female") {
      values.Gender = 2;
    } else if (values.Gender == "Other") {
      values.Gender = 3;
    } else {
      values.Gender = values.Gender;
    }

    let data = {
      Title: values.Title,
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Email,
      DialingCode: 91,
      Mobile: values.MobileNumber,
      AgencyName: values.AgencyName,
      Password: values.Password,
      DOB: values?.DOB ? moment(values.DOB).format("YYYY-MM-DD") : "",
      Gender: values.Gender,
      StoreAddress: values.StoreAddress,
      RegisterdAddress: values.RegisteredAddress,
      Comments: values.Comments,
      PAN: values.PANNumber,
      PANUpload: panUpload ? panUpload : "",
      AdhaarCard: values.AdhaarNumber,
      AdhaarCardUpload: adharPath ? adharPath : "",
      GSTUpload: GSTUpload ? GSTUpload : "",
      LicenseNumber: values.LicenseNumber ? values.LicenseNumber : " ",
      VoterId: values.VoterId ? values.VoterId : "",
      Passport: values.Passport ? values.Passport : "",
      PassportUpload: values.PassportUpload ? values.PassportUpload : "",
      OtherDocumentsUpload: otherUpload ? otherUpload : "",
      Role: 7,
      Status: 1,
      Membership: values.MembershipType,
      Services: values.servicetype,
      DeviceOS: 1,
      ApplicationType: 1,
      GSTDetails: {
        GSTCompanyName: "",
        GSTINNumber: "",
        GSTAdress: "",
        GSTPhone: "",
        GSTEmail: "",
      },
      CreadtedBy: 0,
      //CreatedDate: 0,
      DeviceToken: "string",
      DeviceType: "string",
      FirBaseToken: "string",
      DeviceOSVersion: "string",
    };

    let regObj = {
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Email,
      DialingCode: 91,
      Mobile: values.MobileNumber,
      DOB: values.DOB ? moment(values.DOB).format("YYYY-MM-DD") : "",
      Gender: values.Gender,
      Status: 1,
      StoreAddress: values.StoreAddress,
      AgencyLogo:logo.AgencyLogo,
      RegisterdAddress: values.RegisteredAddress,
      //AgencyLogo: currentuser?.AgencyLogo ?? "",
      AgencyName: values.AgencyName,
      Membership: values.MembershipType,
      Services: values.servicetype,
      PaymentPreference: [1],
      Role: 5,
      PAN: values.PANNumber,
      PANUpload: panUpload ?? "",
      AdhaarCard: values.AdhaarNumber,
      //AdhaarCardUpload: "kkkkkk",
      AdhaarCardUpload: adharPath ?? "",
      GSTUpload: GSTUpload ?? "",
      LicenseNumber: "",
      VoterId: "",
      Passport: "",
      PassportUpload: "",
      OtherDocumentsUpload: "",
      Comments: values.Comments ? values.Comments : "",
      Title: values.Title,
      GSTDetails: {
        GSTCompanyName: currentuser?.userGstVat?.GstCompanyName ?? "",
        GSTINNumber: values.GSTINNumber ?? "",
        GSTAdress: currentuser?.userGstVat?.GstCompanyAddress ?? "",
        GSTPhone: currentuser?.userGstVat?.GstCompanyContactNumber ?? "",
        GSTEmail: currentuser?.userGstVat?.GstCompanyEmail ?? "",
      },
      ModifiedBy: 0,
      ModifiedDate: new Date(),
    };

    isEditMode ? submitUpdateMap(regObj, UserId) : submitMapDetails(data);
  };

  const submitMapDetails = (formMapData) => {
    setisLoading(true)
    APIClient.post("admin/b2b/signUp", formMapData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Partner created SuccessFully", 3);

          setAddMarkupModal(false);
          getAgentList();
          form.resetFields();
        } else if (response.status == 201) {
          message.error("Inserted Successfully", 3);
          setAddMarkupModal(false);
        } else if (response.status == 500) {
          message.error("Duplicate Entry For Email or Mobile", 3);
          setAddMarkupModal(false);
        } else if (response.status == 409) {
          message.error("Entered Mobile Number or Mail Already existed", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
        setisLoading(false)
      })
      .catch((error) => {
        setisLoading(false)
        console.error(error);
      });
  };

  const submitUpdateMap = (formMapData, UserId) => {
    setisLoading(true)
    APIClient.put(`admin/b2b/user/${UserId}`, {}, formMapData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Details Updated SuccessFully", 3);
          setAddMarkupModal(false);
          getAgentList();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else if (response.status == 500) {
          message.error("Duplicate Entry For Email or Mobile", 3);
          setAddMarkupModal(false);
        } else {
          message.error("Something Went Wrong", 3);
        }
        setisLoading(false)
      })
      .catch((error) => {
        setisLoading(false)
        console.error(error);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = flightCommLists.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });

      setFlightCommLists(searchResults);
    } else {
      getAgentList();
    }
  };

  const handleExcel = () => {
    const excelData = flightCommLists.map((item) => {
      return {
        S_No: item.SNo,
        Email: item.Email,
        AgencyName: item.AgencyName,
        AgentName: item.FirstName + "" + item.LastName,
        Mobile: item.Mobile,
        Wallet: item.Wallet,
        Credit: item.Credit,
        CreditDate: item.CreditDate,
        ExpiryDate: item.ExpiryDate,
        DOB: item.DOB,
        Status: item.Status,
      };
    });

    props.exportExcel(excelData, "Registration");
  };
  const ValidateField = () => {
    form
      .validateFields()
      .then(() => {
        setVerifyModalVisible(true);
      })
      .catch((e) => {
        if (!e.errorFields) {
          return;
        }
        form.scrollToField(e.errorFields[0].name);
      });
  }
  return (
    <div>
      <div className="manage-markup-section">
        <div className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Partner Details{" "}
                          <HelpInfoHelper
                            screenName={"/admin/agentregistration"}
                          />
                        </h5>
                        <p>{flightCommLists.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getAgentList()}
                      />
                      <div onClick={add} className="add-deposit-icon">
                        <PlusCircleFilled />
                      </div>
                    </div>
                  </div>
                  <div>
                    <Table
                      scroll={{ x: true }}
                      bordered
                      dataSource={flightCommLists}
                      columns={tableColumns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["5", "50", "100", "125"],
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        width={"1000px"}
        className="admin-bc-level modal-header-bg12"
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Partner Registration</strong>
            </h6>
          </div>,
        ]}
        visible={addMarkupModal}
        onCancel={(e) => setAddMarkupModal(false)}
        footer={[
          <div>
            <Button key="submit" type="danger" onClick={clear}>
              Cancel
            </Button>
            <Button
              key="add"
              type="primary"
              onClick={() => {
                //form.submit();
                ValidateField();
                setFormType(1);
              }}
            >
              <Loading loading={isLoading}/>
              {isEditMode ? "Update" : "Add"}
            </Button>
          </div>,
        ]}
      >
        <Form
          layout={"vertical"}
          form={form}
          onFinish={submitForm}
          validateMessages={validateMessages}
        >
          <div>
            <Row gutter={16}>
              <Col className="gutter-row" md={6} xs={24}>
                <Form.Item
                  label="Title"
                  name="Title"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Title">
                    <Option value="Mr">Mr</Option>
                    <Option value="Ms">Ms</Option>
                    <Option value="Mrs">Mrs</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={6} xs={24}>
                <Form.Item
                  label="FirstName"
                  name="FirstName"
                  rules={[
                    { required: true },
                    {
                      pattern: "^[a-zA-Z]*$",

                      message: "Must be Alphabet",
                    },
                  ]}
                >
                  <Input placeholder="Enter FirstName" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={6} xs={24}>
                <Form.Item
                  label="LastName"
                  name="LastName"
                  rules={[
                    { required: true },
                    {
                      pattern: "^[a-zA-Z]*$",

                      message: "Must be Alphabet",
                    },
                  ]}
                >
                  <Input placeholder="Enter LastName" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" md={6} xs={24}>
                
                <Form.Item label="DOB" name="DOB" rules={[{ required: true }]}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    style={{ width: "230px" }}
                    disabledDate={disabledOriginDate}
                   
                    placeholder="DOB"
                    maxDate={new Date()}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" md={6} xs={24}>
                <Form.Item
                  label="MembershipType"
                  name="MembershipType"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select members">
                    {member.map((data, index) => (
                      <Option key={index} value={data.MembershipID}>
                        {data.Name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row" md={6} xs={24}>
                <Form.Item
                  label="Gender"
                  name="Gender"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Gender">
                    <Option value={1}>Male</Option>
                    <Option value={2}>FeMale</Option>
                    <Option value={3}>Other</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={6} xs={24}>
                <Form.Item
                  label="Email"
                  name="Email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Enter Email" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={6} xs={24}>
                <Form.Item
                  label="Mobile Number"
                  name="MobileNumber"
                  rules={[
                    { required: true },
                    {
                      minLength: 10,
                      maxLength: 10,
                      pattern: "^[0-9]{10}$",
                      message: "Must be 10 digits",
                    },
                  ]}
                >
                  <Input
                    addonBefore={prefixSelector}
                    placeholder="Enter Mobile Number"
                  />
                </Form.Item>
              </Col>
              {isEditMode == false ? (
                <Col className="gutter-row" md={6} xs={24}>
                  <Form.Item
                    label="Password"
                    name="Password"
                    rules={[{ required: true }]}
                  >
                    <Input.Password
                      placeholder="Enter Password"
                      size="small"
                      style={{ height: "35px" }}
                    />
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}
              <Col className="gutter-row" md={6} xs={24}>
                <Form.Item
                  label="AgencyName"
                  name="AgencyName"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Agency Name" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={6} xs={24}>
                <Form.Item
                  label="StoreAddress"
                  name="StoreAddress"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Enter Store Address" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={6} xs={24}>
                <Form.Item
                  label="Registered Address"
                  name="RegisteredAddress"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Registered Address" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={4} xs={24}>
                <Form.Item
                  label="Comments"
                  name="Comments"
                  // rules={[{ required: true }]}
                >
                  <Input placeholder="Enter comments" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={5} xs={24}>
                <Form.Item
                  label="Status"
                  name="Status"
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value={1}>Active</Radio>
                    <Radio value={0}>Inactive</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={15} xs={24}>
                <Form.Item
                  name="servicetype"
                  label="ServiceType"
                  rules={[{ required: true, message: "service is required" }]}
                >
                  <Checkbox.Group defaultValue={["Flights"]}>
                    <Row>
                      <Checkbox value={1}>Flights</Checkbox>
                      <Checkbox value={2}>Hotels</Checkbox>
                      <Checkbox value={3}>Holiday</Checkbox>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <div>
                <h5>ID Information</h5>
              </div>
            </Row>
            <Row>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="GST"
                  name="GSTINNumber"
                // rules={[{ required: true }]}
                >
                  <Input placeholder="Enter GST" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Aadhaar Number"
                  name="AdhaarNumber"
                  rules={[
                    { required: false },
                    {
                      pattern: "^[0-9]{12}$",
                      message: "Must be 12 digits",
                    },
                  ]}
                >
                  <Input placeholder="Aadhar Number" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="PAN Number"
                  name="PANNumber"
                  rules={[
                    { required: false },
                    {
                      pattern: "^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
                      message: "Must be 10 digits AlphaNumeric Number",
                    },
                  ]}
                >
                  <Input placeholder="ABCDF6789G" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <div className="upload-logo">
                  <h6>GST</h6>
                  <div className="upload-logo">
                    <ActivitiesImageUpload
                      category="gst"
                      fileList={gst}
                      setFileList={setGst}
                      formName="GSTUpload"
                      limit={true}
                      limitSize={1}
                    // deleteBanner={DeleteBannerImage}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <Form.Item
                  label="Aadhaar upload"
                  name="AdhaarCardUpload"
                // onChange={setAdhar}
                >
                  <ActivitiesImageUpload
                    category="adhar"
                    fileList={adhar}
                    setFileList={setAdhar}
                    formName="AdhaarCardUpload"
                    limit={true}
                    limitSize={1}
                  
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={12}>
                <div className="upload-logo">
                  <h6>PAN Upload</h6>
                  <ActivitiesImageUpload
                    category="pan"
                    fileList={pan}
                    setFileList={setPan}
                    formName="PANUpload"
                    limit={true}
                    limitSize={1}
                  
                  />
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="upload-logo">
                  <h6>Other Uploads</h6>
                  <div className="upload-logo">
                    {/* <h6>Progressbar Banners</h6> */}
                    <div>
                      <ActivitiesImageUpload
                        category="otheruploads"
                        fileList={otheruploads}
                        setFileList={setOtheruploads}
                        formName="OtherUploads"
                        limit={true}
                        limitSize={6}
                      // deleteBanner={DeleteBannerImage}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <Upload {...UploadGSTprops} fileList={gst}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Col>
              <Col md={6} xs={12}>
                <Upload {...UploadADHARprops} fileList={adhar}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Col>
              <Col md={6} xs={12}>
                <Upload {...UploadPANprops} fileList={pan}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Col>
            </Row>
            <Row className="upload-multi-img">
             
            </Row>
          </div>
         
        </Form>
      </Modal>

      {verifyModalVisible && (
        <VerifyPasswordModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyPassword={handleVerifyPassword}
        />
      )}
    </div>
  );
};

export default UpdatedComponent(AgentRegistration);
