import React, { useState, useEffect } from "react";
import { Radio } from "antd";

import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
const Holidaysort = ({ holidayResultObj, setHolidayResultsList }) => {
  const [sorting, setSorting] = useState("price");
  const [sortDirection, setSortDirection] = useState("asc");
  const handleChange = (e) => {
    const selSorting = e.target.value;
    setSorting(selSorting);
    setSortDirection("asc");
    sortTours(selSorting, "asc");
  };

  const getSortIcon = (val) => {
    return val === "asc" ? <CaretUpOutlined /> : <CaretDownOutlined />;
  };

  let Tours = holidayResultObj.tours.tours;

  const sortTours = (selSorting, dir) => {
    let sortedList = [];

    sortedList = Tours.sort((TourA, TourB) => {
      if (selSorting == "price") {
        return dir === "asc"
          ? compareTours(
            Number(TourA.PerAdultCharge),
            Number(TourB.PerAdultCharge),
            selSorting
          )
          : compareTours(
            Number(TourB.PerAdultCharge),
            Number(TourA.PerAdultCharge),
            selSorting
          );
      }
      if (selSorting == "CityName") {
        return dir === "asc"
          ? compareTours(TourA.CityName, TourB.CityName, selSorting)
          : compareTours(TourB.CityName, TourA.CityName, selSorting);
      }
      if (selSorting == "StarRating") {
        return dir === "asc"
          ? compareTours(TourA.StarRating, TourB.StarRating, selSorting)
          : compareTours(TourB.StarRating, TourA.StarRating, selSorting);
      }
    });

    if (
      selSorting == "price" ||
      selSorting == "CityName" ||
      selSorting == "StarRating"
    ) {
      if (sortedList.length) {
        setHolidayResultsList({
          ...holidayResultObj.tours,
          tours: sortedList,
        });
      }
    }
  };

  const compareTours = (a, b, selSorting) => {
    if (selSorting === "CityName" || selSorting === "price") {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return a - b;
    }
  };

  const handleClick = (e) => {
    const selSorting = e.target.value;
    if (sorting === selSorting) {
      let dir = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      sortTours(selSorting, dir);
    }
  };

  useEffect(() => {
    sortTours("price", "asc");
  }, []);

  return (
    <div className="holiday-sort-card">
      <div className="sort-block sort-bus-block-22 holidays-m15">
        <div className="results-sort-block">
          <p className="sort-header mobile-sort-22 mb-0 fs14">
            <strong>Sort by:</strong>
          </p>

          <Radio.Group
            onChange={handleChange}
            value={sorting}
            className="sortbyh"
          >
            <Radio value={"price"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">
                    Fare {sorting === "price" ? getSortIcon(sortDirection) : ""}
                  </p>
                </div>
              </span>
            </Radio>


            <Radio value={"StarRating"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">
                    Rating{" "}
                    {sorting === "StarRating" ? getSortIcon(sortDirection) : ""}
                  </p>
                </div>
              </span>
            </Radio>
          </Radio.Group>

        </div>
      </div>
    </div>
  );
};

export default Holidaysort;
