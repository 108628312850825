import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Card, Col, Modal, Row, Tabs } from "antd";
import dateFormat from "dateformat";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { calculateDuration } from "../../../common/AllTickets/Flight/flightHelper";

import "./Flightticketsuccess.scss";

const { TabPane } = Tabs;
const Flightticketsuccess = ({ flightTicketsDetails }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [detailsModal, setDetailsModal] = useState({});

  const handleModalClose = () => {
    setIsVisible(false);
    setDetailsModal({});
  };

  const formatFlightTime = (flightTime) => {
    let [date, time] = flightTime.split("T");
    let [hour, minute, seconds] = time.split(":");
    return `${hour}:${minute}`;
  };

  return (
    <div className="promo providerapi">
      <div className="ourteam-bg">
        <div className="admin-container cms-pages-width retrieve-linebar">
          <Card className="retrieve-booking busmtb">
            <h4 className="ticket-lti">Flight ltinerary</h4>
            <Row>
              {flightTicketsDetails.flightDetails.length > 0 ? (
                <>
                  <Col md={12} xs={24}>
                    {flightTicketsDetails.flightDetails.map(
                      (flightDetails, i) => (
                        <>
                          <h6 className="type">
                            {i === 0 ? "Onward:" : "Return:"}
                          </h6>
                          <div className="fic-tickets-data">
                            <Row gutter={8}>
                              <Col md={12} xs={24}>
                                <div className="tic-indi-details">
                                  <p>
                                    {flightDetails.flightSegments.length > 0
                                      ? flightDetails.flightSegments[0].origin
                                      : ""}
                                  </p>

                                  <span className="airline-tic-files"></span>
                                  {flightDetails.flightSegments.map(
                                    (segment, index) =>
                                      index !== 0 ? (
                                        <>
                                          <p>{segment.origin}</p>
                                          <span className="airline-tic-files"></span>
                                        </>
                                      ) : null
                                  )}

                                  <p>
                                    {flightDetails.flightSegments.length > 0
                                      ?
                                      <p><span className="code pointer_cursor" style={{ fontSize: "16px", fontWeight: 600 }}>
                                        {flightDetails.destinationCity}
                                      </span><span className="code pointer_cursor" style={{ fontSize: "12px", fontWeight: 600 }}>

                                          {flightDetails.flightSegments[
                                            flightDetails.flightSegments.length -
                                            1
                                          ].destination}
                                        </span></p>
                                      : ""}
                                  </p>
                                </div>
                              </Col>
                              <Col md={6} xs={24}>
                                <div className="plane-retrive-data">
                                  <ul>
                                    <li>
                                      <p>
                                        {flightDetails.flightSegments.length > 0
                                          ? formatFlightTime(
                                            flightDetails.flightSegments[0]
                                              .departureDateTime
                                          )
                                          : ""}
                                      </p>
                                    </li>

                                    <li>
                                      <ArrowRightOutlined />
                                    </li>

                                    <li>
                                      {flightDetails.flightSegments.length >
                                        0 ? (
                                        <p>
                                          {formatFlightTime(
                                            flightDetails.flightSegments[
                                              flightDetails.flightSegments
                                                .length - 1
                                            ].arrivalDateTime
                                          )}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  </ul>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          {/* sub--secondrow--start */}

                          <div className="trip-advise-de">
                            <div className="flight-retrive-value">
                              <div className="plane-retrive-data">
                                <span>
                                  {flightDetails.flightSegments.length > 0
                                    ? dateFormat(
                                      flightDetails.flightSegments[0]
                                        .departureDateTime,
                                      "dd mmm (ddd)"
                                    )
                                    : ""}
                                </span>

                              </div>


                              <div className="infomail-time-data-info">
                                <p>
                                  {flightDetails.flightSegments.length === 1 ? (
                                    "Direct"
                                  ) : (
                                    <>
                                      {flightDetails.flightSegments.length - 1}{" "}
                                      stops
                                    </>
                                  )}
                                </p>
                              </div>
                              <div className="infomail-time-data-info">
                                <p>PNR: {flightDetails.pnr}</p>
                              </div>

                            </div>
                          </div>
                        </>
                      )
                    )}
                  </Col>

                  <Col md={12} xs={24} className="flight-email-dd">
                    <div className="paymet-booking-tic">
                      <h4>Ticket Booking Payment Successful</h4>
                      <p>
                        A confirmation email has been sent your provided email
                        address
                      </p>
                      <p>Ref-No : {flightTicketsDetails.BookingRefNo}</p>
                      <p>
                        Status :{" "}
                        <span className="succes-pay-value">Success</span>
                      </p>
                    </div>

                    <Row>
                      <Col md={12} xs={24}>
                        <Button className="tic-retrive-book" htmlType="submit">
                          <Link
                            to={`flight/ticket?ref=${flightTicketsDetails.BookingRefNo}`}
                          >
                            Retrieve Booking
                          </Link>
                        </Button>
                      </Col>
                      <Col md={12} xs={24}></Col>
                    </Row>
                  </Col>
                </>
              ) : (
                <Col lg={24} md={24} sm={24} xs={24}>
                  <p className="mb-0">
                    <strong>{flightTicketsDetails.otherMessage}</strong>
                  </p>
                  {flightTicketsDetails.errorMessage && (
                    <p className="mb-0">
                      <strong>
                        Message : {flightTicketsDetails.errorMessage}
                      </strong>
                    </p>
                  )}
                </Col>
              )}
            </Row>
          </Card>

          <Modal
            className="modal-fl-heading flight-details-modal"
            title="Flight Details"
            visible={isVisible}
            footer={[null]}
            onOk={handleModalClose}
            onCancel={handleModalClose}
          >
            <div className="all-times-local">
              <Tabs
                defaultActiveKey="1"
             
              >
                {Object.keys(detailsModal).length > 0 ? (
                  <TabPane tab="Segments" key="1">
                    <div className="from-to-modal-details">
                      <Row gutter={16} className="more-details-row">
                        <Col span={14}>
                          <Row gutter={16}>
                            <Col span={8} className="more-details-list">
                              <p>{detailsModal.flightSegments[0].origin}</p>
                              <p>
                                <strong>
                                  {dateFormat(
                                    detailsModal.flightSegments[0]
                                      .departureDateTime,
                                    "HH:MM"
                                  )}
                                  ,
                                </strong>
                                <span>
                                  {dateFormat(
                                    detailsModal.flightSegments[0]
                                      .departureDateTime,
                                    "dd mmm (ddd)"
                                  )}
                                </span>
                              </p>
                            </Col>
                            <Col span={8} className="more-details-line"></Col>
                            <Col span={8} className="more-details-list">
                              <p>
                                {
                                  detailsModal.flightSegments[
                                    detailsModal.flightSegments.length - 1
                                  ].destination
                                }
                              </p>
                              <p>
                                <strong>
                                  {dateFormat(
                                    detailsModal.flightSegments[
                                      detailsModal.flightSegments.length - 1
                                    ].arrivalDateTime,
                                    "HH:MM"
                                  )}
                                  ,
                                </strong>
                                <span>
                                  {dateFormat(
                                    detailsModal.flightSegments[
                                      detailsModal.flightSegments.length - 1
                                    ].arrivalDateTime,
                                    "dd mmm (ddd)"
                                  )}
                                </span>
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={6} className="trip-duration-time">
                          <p>Total trip duration:</p>
                          <p>
                            {detailsModal.flightSegments.length > 0
                              ? calculateDuration(
                                detailsModal.flightSegments[0]
                                  .departureDateTime,
                                detailsModal.flightSegments[
                                  detailsModal.flightSegments.length - 1
                                ].arrivalDateTime
                              )
                              : calculateDuration(
                                detailsModal.flightSegments[0]
                                  .departureDateTime,
                                detailsModal.flightSegments[0].arrivalDateTime
                              )}{" "}
                            |
                            {detailsModal.flightSegments.length === 0 ? (
                              <>Direct</>
                            ) : (
                              <>
                                {detailsModal.flightSegments.length - 1} stops{" "}
                              </>
                            )}
                          </p>
                        </Col>
                        <Col span={4} className="trip-duration-time">
                          <p>class:</p>
                          <p>Economy</p>
                        </Col>
                      </Row>
                    </div>

                    {detailsModal.flightSegments.map((flightSegment) => (
                      <div className="total-flight-details">
                        <div className="to-fro-flight-details">
                          <div className="from-city-flight-details">
                            <span>{flightSegment.origin}</span>
                          </div>
                          <div>
                            <span>
                              departure:{" "}
                              <strong>
                                {dateFormat(
                                  flightSegment.departureDateTime,
                                  "HH:MM"
                                )}
                                ,
                              </strong>
                            </span>
                            <span>
                              {dateFormat(
                                flightSegment.departureDateTime,
                                "dd mmm (ddd)"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="collapsed-card"></div>
                        <div className="to-fro-flight-details">
                          <div className="from-city-flight-details">
                            <span>{flightSegment.destination}</span>
                          </div>
                          <div>
                            <span>
                              arrival:
                              <strong>
                                {formatFlightTime(
                                  flightSegment.arrivalDateTime
                                )}
                              </strong>
                            </span>
                            <span>
                              {dateFormat(
                                flightSegment.arrivalDateTime,
                                "dd mmm (ddd)"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </TabPane>
                ) : null}
              </Tabs>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Flightticketsuccess;
