
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ImagesLightbox.scss";

const ImagesDetbox = ({ hotelImages }) => {
  const [images, setimages] = useState(hotelImages);
  const onImageError = (ui) => {
    setimages(images?.filter(e => e != ui))
  }
  return (
    <>
      <div className="carousel-wrapper">
        {images?.length === 1 ? (
          <Carousel
            showStatus={false}
            showIndicators={false}
            dynamicHeight={false}
          >
            {images?.map((hotelImage, i) => (
              <div key={i + "hotelimg"}>
                <img src={hotelImage} alt="image" className="carousel-images" />
              </div>
            ))}
          </Carousel>
        ) : (
          <Carousel
            showStatus={false}
            showIndicators={false}
            dynamicHeight={false}
            centerMode={true}
            centerSlidePercentage={80}
            selectedItem={1}
          >
            {images
              .filter(hotelImage => (hotelImage.includes("max500") || hotelImage.includes("_P") || hotelImage.includes("_z")))
              .map((filteredImage, i) => (
                <div key={"hotel" + i}>
                  <img
                    src={filteredImage}
                    alt="hotel"
                    className="carousel-images"
                    onError={() => onImageError(filteredImage)}
                  />
                </div>
              ))}
          </Carousel>
        )}
      </div>
    </>
  );
};
export default ImagesDetbox;
