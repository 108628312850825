import React, { useState } from "react";

import nohotelimage from "../assets/images/hotels/reception.png";

const CustomImg = ({ src, alt }) => {
  const [showImg, setShowImg] = useState(true);
  return (
    <img
    className="top-hotel-imag-lik"
      src={showImg ? src : nohotelimage}
      alt={alt}
      onError={() => {
        setShowImg(false);
      }}
    />
  );
};

export default CustomImg;
