import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Card, Col, Row, Layout } from "antd";
import ApiClient from "../../../helpers/ApiClient";
import queryString from "query-string";
const ActiveTabs = () => {
    const [dataSource, setDataSource] = useState({
        sliderData: [],
        cityData: [],
    });

    let tomorrowDate = moment().add(5, 'days').format("YYYY-MM-DD");
    let dayafter = moment().add(7, "days").format("YYYY-MM-DD");
    const getHotelSer = (url) => {
       
        let formData = {
            checkInDate: tomorrowDate,
            checkOutDate: dayafter,
            hotelCityCode: url,
            roomGuests: JSON.stringify([{
                noOfAdults: 1,
                noOfChilds: 0,
                childAge: [],
            },]),
            nationality: "IN",
            currency: "INR",
            countryCode: "IN",
            traceId: "string",
        };

        const query = queryString.stringify(formData);

        return "/hotels/listing?" + query;
    };
    const getallHolidayRecords = () => {
        ApiClient.get("HolidayExtranet/getHolidayPackages")
            .then((res) => {
                if (res.status === 200) {
                    let data = res.data.filter((item) => (item.ShowHomepage == 1 && item.IsRecommended == 1));
                    let result = data.reduce(function (obj, key) {
                        obj[key.CountryName] = obj[key.CountryName] || [];
                        obj[key.CountryName].push(key);
                        return obj;
                    }, {});
                    setDataSource({ sliderData: data, cityData: result });
                } else {
                    setDataSource({ sliderData: [], cityData: [] });
                }
            })
            .catch((e) => {
                setDataSource({ sliderData: [], cityData: [] });
            });
    };
    useEffect(() => {
        getallHolidayRecords();
    }, []);

    return (

        <div className="active-ht-tag">
            {Object.keys(dataSource.sliderData).length > 0 ?
                <>
                    <p className="act-hdr-tag">Trending Holidays</p>
                    <ul className="active-tg">
                        
                        {dataSource.sliderData.map((itemData, ind) => {
                            return (
                                <li className="active-t"><Link className='anc-tab'
                                    to={`/holidays/results?catId=${itemData.CategoryId}&cityId=${itemData.CityId}&cityName=${itemData.CityName}%2C${itemData.CountryName}&countryId=${itemData.CountryCode}&countryName=${itemData.CountryName}&travelDate=${moment(itemData.journeyDate, 'DD-MM-YYYY').format('YYYY-MM-DD')}`}>{" # "}{itemData.CityName}</Link></li>

                            );
                        })}

                    </ul>
                </> : null}
        </div>

    );
};
export default ActiveTabs;