import React, { useState, useEffect, Suspense, useCallback } from "react";

import * as ReactBootstrap from "react-bootstrap";
import {
  Card,
  Col,
  Row,
  Grid,
  Skeleton,
  Collapse,
  Button,
  Modal,
  Form,
  Statistic,
} from "antd";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import Hotels from "../../../components/Hotels/Hotels";
import { useHotelContext } from "../../../common/providers/Hotels/HotelProvider";
import ApiClient from "../../../helpers/ApiClient";
import SkeletonLayout from "../../../components/Skeleton/Skeleton";
import FilterSkeletonLayout from "../../../components/FilterSkeleton/FilterSkeleton";
import queryString from "query-string";
import timeoutimg from "../../../assets/images/timeout.jpg"
import "../HotelResults/HotelResults.scss";
import { useHistory } from "react-router-dom";

const Filter = React.lazy(() => import('../../../common/Filters/Filter'))
const HotelContainer = React.lazy(() => import('./HotelContainer'))
const HotelSort = React.lazy(() => import('../HotelSort/HotelSort'))
const CustomNoResultFound = React.lazy(() => import('../../../components/ErrorPages/CustomNoResultFound'))
const NoResultFound = React.lazy(() => import('../../../components/ErrorPages/NoResultFound'))


const { useBreakpoint } = Grid;
const { Panel } = Collapse;
const { Countdown } = Statistic;


const HotelResults = ({ hotelParam = false, isFromPackage = false }) => {
  const { md } = useBreakpoint();
  const { user } = useAuthContext();
  const [mainHotelsListResp, setMainHotelsListResp] = useState([]);
  const [listOfHotels, setListOfHotels] = useState([]);
  const [key, setKey] = useState([]);
  const [HotelSearchobj, setHotelSearchobj] = useState([]);
  const [traceId, setTraceId] = useState(null);
  const [isHotelSearchLoad, setIsHotelSearchLoad] = useState(false);
  const [showNetFare, setShowNetFare] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisiblenew, setModalVisiblenew] = useState(false);
  const [popUpVisibility, setPopUpVisibility] = useState(false);
  const [istopset, setistopset] = useState(-410);
  const [y, setY] = useState(window.scrollY);
  const [hotelalldata, sethotelalldata] = useState({});
  let history = useHistory();
  const { state: { sessiontimeout, status }, getsessiontimeout } = useHotelContext();
  useEffect(() => {
    fetchStaticData();
  }, [window.location.search]);

  function onInactive(ms, cb) {
    var wait = setTimeout(cb, ms);

    document.onmousemove =
      document.mousedown =
      document.mouseup =
      document.onkeydown =
      document.onkeyup =
      document.focus =
      document.scroll =
      function () {
        clearTimeout(wait);

        if (
          !localStorage.getItem("popupShown") &&
          JSON.parse(localStorage.getItem("popupShown")) !== true
        ) {
          wait = setTimeout(cb, ms);
        }
      };
  }

  useEffect(() => {
    if (
      !localStorage.getItem("popupShown") &&
      JSON.parse(localStorage.getItem("popupShown")) !== true
    ) {
      onInactive(15000, function () {
        setPopUpVisibility(true);
      });
    }
  });

  const showModalflight = () => {
    setModalVisible({
      visible: true,
    });
  };
  const showModalsort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };

  const fetchStaticData = () => {
    setMainHotelsListResp([]);
    sethotelalldata({});
    setListOfHotels([]);
    setIsLoading(true);
    setIsHotelSearchLoad(true);

    if (!hotelParam) {
      const hotelSearchParams = queryString.parse(window.location.search);
      if (hotelSearchParams) {
        let hotelCityCode = queryString.parse(hotelSearchParams.hotelCityCode);
        let staticObj = {
          traceId: "",
          cityId: Number(hotelCityCode.cityId),
          userId: user?.UserID ?? 1,
          roleType: user?.Role?.RoleId ?? 4,
          membership: user?.Membership ?? 1,
        };
        getStaticData(staticObj);
      }
    } else if (Object.keys(hotelParam).length > 0) {
      let staticObj = {
        traceId: "",
        cityId: Number(hotelParam.hotelCityCode),
      };
      getStaticData(staticObj);
    }
  };
  const getStaticData = (staticObj) => {

    ApiClient.post("hotels-v2/hotelstaticdetails", staticObj)
      .then((result) => {
        return result;
      })
      .then((res) => {

        if (res?.status === 200) {

          if (!res.data.errors) {



            const hotelDetail = res.data.hotelDetails.map((hotel) => {
              return {
                ...hotel,
                hotelCode: hotel.hotelId,
                hotelName: hotel.propertyName,
                isVisible: true,
                isPriceAvailable: false,
              };
            });
            setMainHotelsListResp(hotelDetail);
            setListOfHotels(hotelDetail);
            fetchHotelSearch(hotelDetail);

          } else {

            setMainHotelsListResp([]);
            setListOfHotels([]);
          }
        } else {
          setMainHotelsListResp([]);
          setListOfHotels([]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const timeout = () => {
    return (

      <div>

        <div className="timeout-popup-main">
          <div className="timeout-popup-main1">
            <img className="timeout-img" src={timeoutimg} alt="time" />
          </div>
          <div className="timeout-popup-main2">
            <h4 style={{ color: "red", }}><strong>SESSION TIMEOUT</strong></h4>
            <p className="popup-session-timeout-p-tag">Your Session is Expired</p>
            <p className="popup-session-timeout-p-tag">Click on "OK" to continue with New Search</p>
          </div>
        </div>

      </div>
    );
  }
  const handelCountdown = () => {

    
    let query = queryString.stringify(JSON.parse(localStorage.getItem('HotelSearchBar')));
    Modal.warning({
      icon: <></>,
      //title: "",
      content: timeout(),
      onOk() {
        getsessiontimeout();
        fetchStaticData();
        history.push("/hotels/listing?" + query);

      },
    });
  };

  const fetchHotelSearch = (hotelDetail) => {

    if (!hotelParam) {
      const hotelSearchParams = queryString.parse(window.location.search);

      if (hotelSearchParams) {
        let hotelCityCode = queryString.parse(hotelSearchParams.hotelCityCode);
        let searchReqObj = {
          checkInDate: hotelSearchParams.checkInDate,
          checkOutDate: hotelSearchParams.checkOutDate,

          hotelCityCode: hotelCityCode.cityId,
          hotelId: hotelSearchParams?.hotelId ?? "",
          roomGuests: JSON.parse(hotelSearchParams.roomGuests),
          nationality: hotelSearchParams?.nationality ?? "IN",

          countryCode: hotelDetail[0]?.countryCode,
          isHotelDescriptionRequried: false,
          currency: hotelSearchParams.currency,
          traceId: " ",
          userId: user?.UserID ?? 1,
          roleType: user?.Role?.RoleId ?? 4,
          membership: user?.Membership ?? 1,
        };
        localStorage.setItem("HotelSearch", JSON.stringify(searchReqObj))
        getHotelDetails(searchReqObj, hotelDetail);
        setHotelSearchobj(searchReqObj);

      }
    } else if (Object.keys(hotelParam).length > 0) {
      let searchReqObj = {
        ...hotelParam,
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
      };
      getHotelDetails(searchReqObj, hotelDetail);
    }
  };

  const getHotelDetails = (searchReqObj, hotelDetail) => {

    ApiClient.post("hotels-v2/hotelsearch", searchReqObj)
      .then((result) => {
        return result;
      })
      .then((res) => {

        if (res?.status === 200) {

          setTraceId(res.data.traceId);
          const hotelsdata = res.data.hotels;

          let combinedHotelDetails = [];


          if (hotelsdata.length > 0) {
            for (let i = 0; i < hotelsdata.length; i++) {
              let matchHotelSearchData = hotelDetail.filter((data) => data?.hotelId == hotelsdata[i].hotelCode);
           
              combinedHotelDetails?.push({
                ...matchHotelSearchData[0],
                ...hotelsdata[i],

                isPriceAvailable: true,
                isVisible: true
              });
            }
          }
          sethotelalldata(res.data.filters);
          setMainHotelsListResp(combinedHotelDetails);
        
          setIsHotelSearchLoad(false);
        } else {
          sethotelalldata({})
          setMainHotelsListResp([]);
          setListOfHotels([]);
        }

        setIsHotelSearchLoad(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsHotelSearchLoad(false);
        setIsLoading(false);
      });
  };
  const handleNavigation = useCallback(
    e => {
      const window = e.currentTarget;
      if (y > window.scrollY) {

        setistopset(0)

      } else if (y < window.scrollY) {
        setistopset(-410)
      }
      setY(window.scrollY);
    }, [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);
  return (
    <div className="hotels_results_page">
      <div className="hotels-page-wrapper">
        {isFromPackage ? null : (
          <section className="hotels_modify">
            <div className="hotel-modify-cont">
              <Collapse
                activeKey={md ? ["1"] : key}
                showArrow={false}
                onChange={(val) => {
                  setKey(val);
                }}
              >
                <Panel
                  className="modify-search-pan"
                  showArrow={false}

                  key="1"
                >
                  <Hotels
                    hotelSearchAPI={fetchStaticData}
                    modifySearch={true}
                  />
                </Panel>
              </Collapse>
            </div>
          </section>
        )}
        {isLoading ? (
          <section>
            <div className="hotel-skeleton-layout">
              <div className="hotel-skeleton-layout-container">
                <Row gutter={16}>
                  <Col md={6} xs={0} className="filter-skeleton">
                    <FilterSkeletonLayout />
                  </Col>
                  <Col md={18} xs={24} className="result-body-skeleton">
                    <Card className="card-skeleton">
                      <Skeleton active={true} paragraph={{ rows: 0 }} />
                    </Card>

                    {[...Array(6)].map((i) => (
                      <SkeletonLayout key={i} />
                    ))}
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        ) : mainHotelsListResp.length > 0 ? (
          <section className="hotels_list">
            <div className="hotels-list-wrapper">
              <div className="list-container">
                <div className="filters-box filter-fflight-hop">
                  <div
                   
                    style={{
                      position: "sticky",
                      zIndex: "0",
                      
                    }}
                  >
                    <Suspense fallback={<div>Loading...</div>}>
                      <Filter
                        count={mainHotelsListResp.length}
                        data={mainHotelsListResp}
                        setListOfHotels={setListOfHotels}
                        isHotelSearchLoad={isHotelSearchLoad}
                        filtersObj={hotelalldata}
                      />
                    </Suspense>
                  </div>
                </div>
                <div className="hotels-box result-flight-hop">
                  {isHotelSearchLoad ? (
                    <Card className="card-skeleton">
                      <Skeleton active={true} paragraph={{ rows: 0 }} />
                    </Card>
                  ) : (
                    <>

                      <Suspense fallback={<div>Loading...</div>}>
                        <div className="add-title">
                        
                          <span className="add-hdr-det">{(mainHotelsListResp?.[1]?.addresses && mainHotelsListResp?.[1]?.addresses?.[0]?.cityName) ?
                            <h4>{mainHotelsListResp[1]?.addresses?.[0]?.cityName}</h4> : <h4>{"Hotels"}</h4>}
                            <p> :{" "}{mainHotelsListResp?.length}{" "} Properties found</p>
                          </span>
                        </div>

                        <HotelSort
                          // listOfHotels={mainHotelsListResp}
                          listOfHotels={listOfHotels}
                          setListOfHotels={setListOfHotels}
                          showNetFare={showNetFare}
                          setShowNetFare={setShowNetFare}
                        />
                      </Suspense>
                    </>
                  )}
                  {listOfHotels.length > 0 ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <HotelContainer
                        isFromPackage={isFromPackage}
                        listOfHotels={listOfHotels}
                        traceId={traceId}
                        isHotelSearchLoad={isHotelSearchLoad}
                        showNetFare={showNetFare}
                        searchObj={HotelSearchobj}
                      />
                    </Suspense>
                  ) : (<Suspense fallback={<div>Loading...</div>}>
                    <CustomNoResultFound title={"No Hotels Available"} />
                  </Suspense>
                  )}
                </div>
              </div>
            </div>
          </section>
        ) : (
          <Suspense fallback={<div>Loading...</div>}>
            <NoResultFound />
          </Suspense>
        )}
      </div>

      {status != 0 &&
        (!isLoading && !isHotelSearchLoad) ? (<>
          <div className="countdown31">
            <div className="countdown-main-box">
              <div className="countdown1">
                <span class="fa fa-clock-o" style={{ fontSize: "18px" }}></span><span> Your session will expire in</span>
              </div>
              <div className="countdown2">
                <Countdown
                  className="busCountdown"
                  onFinish={handelCountdown}
                  value={sessiontimeout}
                  format={"mm[ min]  ss[ sec]"}
                />
              </div>
            </div>
          </div>

          <div className="phone-view-for-result-page">
            <Countdown
              className="busCountdown"
              //onFinish={handelCountdown}
              value={sessiontimeout}
              format="mm:ss"
            />
          </div>      </>) : null}

      <Row className="fiters-value-hotel">
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalflight} style={{ color: 'white' }}>
            {" "}
            <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;Filters
          </h5>
        </Col>
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalsort} style={{ color: 'white' }}>
            <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
            &nbsp;Sort by
          </h5>
        </Col>
      </Row>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Filters</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => setModalVisible(false)}>
              Close
            </Button>
          </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            <Filter
              count={mainHotelsListResp.length}
              data={mainHotelsListResp}
              setListOfHotels={setListOfHotels}
              isHotelSearchLoad={isHotelSearchLoad}
              filtersObj={hotelalldata}
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Sort By</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header modal-hotel-show12"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => setModalVisiblenew(false)}>
              Close
            </Button>
          </div>,
        ]}
      >
        <Form>
          <Row>
            <Col md={24} xs={24}>
              <HotelSort
                listOfHotels={mainHotelsListResp}
               
                setListOfHotels={setListOfHotels}
                showNetFare={showNetFare}
                setShowNetFare={setShowNetFare}
              />
            </Col>
          </Row>
        </Form>
      </Modal>

    </div>
  );
};

export default HotelResults;
