import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";

import ApiClient from "../../helpers/ApiClient";
import "./Webcheckin.scss";

const Webcheckin = () => {
  const [allAirlines, setAllAirlines] = useState([]);

  const getAllWebcheckin = () => {
    ApiClient.get("airlinewebcheckin/getallairlinewebcheckin")
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.status === 200) {
          setAllAirlines(resp.data);
        } else {
          setAllAirlines([]);
        }
      })
      .catch(() => { });
  };

  useEffect(() => {
    getAllWebcheckin();
  }, []);

  return (
    <div className="collapseCustomWhite">
      <div className=" providerapi web-check-in-page"></div>

      <div className="ourteam-bg">
        <div className="admin-container cms-pages-width">
          <Row>
            <Col md={24} xs={24}>
              <div className="">
                <div className="">
                  <h2 className="web-heading-check">Web Check In</h2>
                  <span className="span-web-check">
                    Choose any of the Airline for web Check In
                  </span>
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={[12, 12]} className="my-2">
            {allAirlines.map((item) => (
              <Col key={item.Id} md={4} xs={24}>
                <a
                  href={item.AirlineURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="list-web-checkin">
                    {item.AirlineLogo && (
                      <img
                        className="list-cc-img-web"
                        src={item.AirlineLogo}
                        alt="Indigo image"
                      />
                    )}

                    <span>{item.AirlineName}</span>
                  </div>
                </a>
              </Col>
            ))}

          </Row>
        </div>
      </div>
    </div>
  );
};

export default Webcheckin;
