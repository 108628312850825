import React, { useState, useEffect } from "react";
import { Col, Row, Layout } from "antd";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Button, Modal, Rate, Skeleton, Badge, Carousel } from "antd"
import HolidaySearchForm from "./HolidaySearchForm";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import "./HolidaysSearch.scss";
import ApiClient from "../../../helpers/ApiClient";
import { useSytContext } from "../../../common/providers/SytProvider";
import Banner from "../../../components/banner/Banner";

import PopularHolidays from "./PopularHolidays";
import OffersSlider from "../../../common/LandingPageOffers/LandingPageOffers";
import CombineServLink from "../../../common/CombineServicesLink/CombineServLink";
import { StarOutlined } from "@ant-design/icons";
import TopHotels from "../../Hotels/TopHotels/TopHotels";
import CityTopHotels from "../../Hotels/TopHotels/CityTopHotels"
import HomeCarousel from "../../../components/HomeCarousel/HomeCarousel";
import NoImage from "../../../assets/images/image-not-found-scaled-1150x647.png"
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
const { Content } = Layout;
const BASE = process.env.REACT_APP_BASE_URL;

const MobileSlidersettings = {
  arrows: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const HolidaySearch = () => {
  const { holidayBanners, flightBanners, promoDataSource } = useSytContext();
  const [cityHolidayData, setCityHolidayData] = useState({
    sliderData: [],
    cityData: [],
  });
  const {
    isLogin: { flag, agent },
    user,
    isGuest,
  } = useAuthContext();
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [toursData,setToursData] = useState()

  const [dataSource, setDataSource] = useState([]);
  

  const [deal, setDeal] = useState([])
  const getDeal = () => {
    ApiClient.get("admin/deals")
      .then((res) => {
        if (res.status === 200) {
          setDeal(res.data);
        }
      })
      .catch((e) => {
        setDeal([]);
      });
  };

  useEffect(() => {
    getDeal()
    if (promoDataSource.length) {
      let data = promoDataSource.filter((item) => item.ServiceType === 6);
      setDataSource(data);
    }
  }, [promoDataSource]);

   const [recentSearchResults, setRecentSearchResults] = useState([]);

  useEffect(() => {
    let data = sessionStorage.getItem("holidaysRecentSearchesResults");

    if (data.length) {
      setRecentSearchResults(JSON.parse(data));
    }
  }, []);

  const getCityHolidayList = () => {
    ApiClient.get("ToursExtranet/getTourDeals")
      .then((res) => {
       
        if (res.status === 200) {
          let data = res.data.filter(
            (item) => item.Status === 0 && item.Servicetype === 1
          );
          let result = data.reduce(function (obj, key) {
            obj[key.CountryName] = obj[key.CountryName] || [];
            obj[key.CountryName].push(key);
            return obj;
          }, {});
          setCityHolidayData({ sliderData: data, cityData: result });
        } else {
          setCityHolidayData({ sliderData: [], cityData: [] });
        }
      })
      .catch((e) => {
        setCityHolidayData({ sliderData: [], cityData: [] });
      });
  };

    const  data = () =>{
      ApiClient.get("HolidayExtranet/getHolidayPackages")
      .then((res)=>{
        if (res.status==200){
          setToursData(res)
        }
      })
      .catch((e) => {
        console.log(e)
      })
    }
 
  return (
    <>
      <div className="bus-block">
        <div className="bus-banner">
         
          <Banner banner={holidayBanners} />
        </div>

        <div className="hotel_search">
          <CombineServLink activetab={2} />

        </div>
      </div>



      <section className="main-box-dis-work-section">
        <h3 style={{ marginLeft: '2%' }}><strog>Signup & save upto 30%</strog></h3>
        <div className="main-box-dis-work" >
          <div className="main-box-dis-work1" >
            <i class='fa fa-tag' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-beer' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-globe' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-phone' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
        </div>
        <div className="liks-about-sigup">
        <Link to="/about-us" className="homepage-aboutus-cot">Learn more about us</Link>
        {flag === true || agent === true ? (null):(
        <Link to="/user-registration" > <button className="homepage-sigup-btn" >Sign up and save</button></Link>)}
        </div>
      </section>


<section className="mainn-section-tophotels" style={{ width: '80%', margin: 'auto' }}>
        <CityTopHotels/>
      </section>

      <section>
        {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
         <HomeCarousel deal={deal} />
          : null}
      </section>


     
       <div className="card holidays_search_container_1" style={{ border: 'none' }} >
        <PopularHolidays />
      </div> 





      

<section className="mainn-section-tophotels" style={{width:'80%', margin:'auto'}}>
        <TopHotels />
      </section>
      

        
      <section className="queries_about_hotels">
        <div className="my_holiday">
          <div className="query-hotels-heading">
            <h2 style={{color:" #363a45"}}>Why you should <i>book</i>  with us </h2>
           
          </div>
          <div className="query-hotels-body">
            <Row gutter={{ xs: 8, sm: 16, md:16}}>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                <i class="fa fa-star-o" aria-hidden="true" style={{fontSize:22,color:" #363a45"}}></i>
                </div>
                <div className="box-body">
                  <h2 style={{fontWeight:"bolder",fontSize:18}}>Exclusive tours, curated by experts</h2>
                  <p style={{fontSize:14}}>
                  With years of experience in the travel industry, we provide expert guidance and curated experiences tailored to your preferences.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                <i class="fa fa-usd" aria-hidden="true" style={{fontSize:22,color:" #363a45"}}></i>
                </div>
                <div className="box-body">
                <h2 style={{fontWeight:"bolder",fontSize:18}}>Great value small-group tours</h2>
                  <p style={{fontSize:14}}>
                  Our website ensures you get the best deals with our price match guarantee, offering value for every penny spent.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                <i class="fa fa-gift" aria-hidden="true" style={{fontSize:22,color:" #363a45"}}></i>
                </div>
                <div className="box-body">
                <h2 style={{fontWeight:"bolder",fontSize:18}}>Exclusive Packages</h2>
                  <p style={{fontSize:14}}>
                  Discover exclusive travel packages and unique experiences available only through our platform, designed to create unforgettable memories
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                <i class="fa fa-comments-o" aria-hidden="true" style={{fontSize:22,color:" #363a45"}}></i>
                </div>
                <div className="box-body">
                <h2 style={{fontWeight:"bolder",fontSize:18}}>Secure Booking</h2>
                  <p style={{fontSize:14}}>
                  Rest assured with our secure and seamless booking process, ensuring your personal information and payments are always protected.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                <i class="fa fa-comments-o" aria-hidden="true" style={{fontSize:22,color:" #363a45"}}></i>
                </div>
                <div className="box-body">
                <h2 style={{fontWeight:"bolder",fontSize:18}}>24/7 dedicated tours concierge</h2>
                  <p style={{fontSize:14}}>
                  Prioritizing customer satisfaction, we offer 24/7 support and strive to exceed your expectations at every step of your journey.
                  </p>
                </div>
              </div>
            </Col>
            
            
           
            </Row>
          </div>
        </div>
      </section> 
     

    </>
  );
};

export default HolidaySearch;
