import React from "react";
import "../banner/Banner.scss";
import { Carousel } from "antd";

const Banner = (props) => {
  const BASE = process.env.REACT_APP_BASE_URL;

  return (
    <div id="banner" className="fh-main-cc2">

      {props?.banner?.length > 0 ? (
        <Carousel autoplay dots={false}>
          {props?.banner.map((item, index) => {
            return (
              item && (
                <img
                  key={"banners" + index}
                  className="d-block w-100"
                  src={BASE + item.path.substring(1)}
                  alt="First slide"
                />
              )
            );
          })}
        </Carousel>
      ) : (
        <div
          className="d-block w-100"
          style={{
            height: "100%",
            backgroundColor: "#2f81ed",
            backgroundImage: "linear-gradient( 90deg,#2f80ed,#56ccf2)",
            paddingBottom: "20px",
          }}
        ></div>
      )}
    </div>
  );
};

export default Banner;
