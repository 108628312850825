import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSytContext } from "../../../common/providers/SytProvider";
import ApiClient from "../../../helpers/ApiClient";
import queryString from "query-string";
import moment from "moment";
import './tophotels.scss';
import Slider from "react-slick";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";

const MobileSlidersettings = {
    arrows: true,
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                arrows: false,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};
const TopHotels = (props) => {

    const BASE = process.env.REACT_APP_BASE_URL;
    const [cityHotelData, setCityHotelData] = useState([]);
    const [cityHotel, setCityHotel] = useState([]);
    const [dataSource, setDataSource] = useState([]);

    const [recentSearchResults, setRecentSearchResults] = useState([]);
    const { activeCurrency, currencyValue } = useCurrencyContext();
    const { promoDataSource } = useSytContext();
    let tomorrowDate = moment().add(1, 'days').format("YYYY-MM-DD");
    let dayafter = moment().add(2, "days").format("YYYY-MM-DD");
    const getCityHotel = () => {
        ApiClient.get("admin/cityHotels")
            .then((res) => {
                if (res.status === 200) {
                    let data = res?.data?.filter(
                        (item) => item?.Status === 0 && item?.Servicetype === 2
                    );

                    setCityHotel(data);
                }
            })
            .catch((e) => console.log("api error", e));
    };
    const getCityHotelList = () => {
        ApiClient.get("admin/getAllServicesTopCities")
            .then((res) => {

                if (res.status === 200) {
                    let data = res.data.filter(
                        (item) => item.Status === 1 && item.ServiceType === 2
                    );

                    setCityHotelData(data);
                }
            })
            .catch((e) => console.log("api error", e));
    };



    useEffect(() => {
        getCityHotelList();
        getCityHotel();
        if (promoDataSource.length) {
            let data = promoDataSource.filter((item) => item.ServiceType === 2);

            setDataSource(data);
        }
    }, [promoDataSource]);

    useEffect(() => {
        getRecentSearches();
    }, []);
    const getRecentSearches = () => {
        let data = sessionStorage?.getItem("hotelRecentSearchesResults");

        if (data?.length) {
            setRecentSearchResults(JSON.parse(data));
        }
    };


    const getHotelSer = (url) => {
        let formData = {
            checkInDate: tomorrowDate,
            checkOutDate: dayafter,
            hotelCityCode: url?.CityName,
            roomGuests: JSON.stringify([{
                noOfAdults: 1,
                noOfChilds: 0,
                childAge: [],
            },]),
            nationality: "IN",
            currency: "INR",
            countryCode: "IN",
            traceId: "string",
        };

        const query = queryString.stringify(formData);

        return "/hotels/listing?" + query;
    };

    return (
        <>
            {cityHotel?.length > 0 ?
                <div >
                    <h5 className="topcities_heading">Top Hotels</h5>

                    <Slider {...MobileSlidersettings}>
                        {cityHotel ? cityHotel.map((item, index) => (
                            <div key={index} className="slick-slide-item">
                                <div className="main-head-img">
                                    <Link
                                        to={getHotelSer(item)}
                                    // className="main-head-img"
                                    >
                                        <img
                                            className="top-hotel-imag-lik"
                                            src={
                                                item.HotelImage1
                                                    ? `${BASE}${item.HotelImage1.substring(1)}`
                                                    : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                                                        .default
                                            }
                                            alt="Hotel Image"
                                      
                                        />
                                        <div className="top-hotels-name-main-div">
                                            <p className="ht-name">{item?.CityName?.split("=")[1]?.split(',')[0]},{item?.CityName?.split(",")[1]?.split('&&')[0]}</p>
                                            <div className="fromAmnt">
                                                Starting : <span className="amnt-frm"> {currencyValue(item?.FromAmount)}{" /-"}</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )) : ""}
                    </Slider>
                </div > : null}

        </>
    );
};

export default TopHotels;
