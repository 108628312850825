import React from "react";
import "../Hotelsalescount/Hotelsalescount.scss";
import { Card, Col, Row, Table } from "antd";
import { Link } from "react-router-dom";


const Hotelsalescount = ({ hotelData }) => {
 
console.log("data",hotelData)
  const columnshotelcount = [
    {
      title: "ReferenceID",
      dataIndex: "RefNumber",
      render: (text, record) => renderReffrence(text, record),
    },
    {
      title: "Hotel Name",
      dataIndex: "HotelName",
    },

    {
      title: "Amount ",
      dataIndex: "Fare",
      render: (p) => Math.round(p),
    },
    {
      title: "Status",
      dataIndex: "Status",
      render: (type) => getStatus(type),
    },
  ];

  const renderReffrence = (ref, rec) => {
    let url = "";

    if (ref.indexOf("TRYP-H") != -1) {
      url = "/admin/hotel/ticket";
    }
    return <Link to={url + "?ref=" + ref}>{ref}</Link>;
  };

  const getStatus = (type) => {
    switch (type) {
      case 1:
        return "Failed ";
      case 2:
        return "Confirmed ";
      case 3:
        return "Cancelled ";
      case 4:
        return "Pending ";
      case 5:
        return "Rejected  ";
      case 6:
        return "Hold ";
      case 7:
        return "CancellationRequest ";
      case 8:
        return "CancellationPending ";
      case 9:
        return "CancellationInProgress ";
      default:
        return "";
    }
  };

  return (
    <div>
      
      <div className="order-count-cards hotel-sales-new">
        <Row>
        

          <Col xs={24} md={4}>
            <div className="ad-hom-box-2">
              <span className="ad-span-col-2">
                <i className="fa fa-bookmark" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Booking Count</p>
                <h5>{Number(hotelData?.booking_count).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

          <Col xs={24} md={4}>
            <div className="ad-hom-box-3">
              <span className="ad-span-col-3">
                <i className="fa fa-ban" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Cancelled Count </p>
                <h5>{Number(hotelData?.cancelled_count).toFixed(2)}</h5>
              </div>
            </div>
          </Col>

        
          <Col xs={24} md={4}>
            <div className="ad-hom-box-7">
              <span className="ad-span-col-7">
                <i className="fa fa-bar-chart"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Over all Day Sales </p>
                <h5>
                  {hotelData?.day_sales
                    ? Number(hotelData?.day_sales).toFixed(2)
                    : 0}
                </h5>
              </div>
            </div>
          </Col>

          <Col xs={24} md={4}>
            <div className="ad-hom-box-8">
              <span className="ad-span-col-8">
                <i className="fa fa-bookmark" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Yesterday's Sales </p>
                <h5>
                  {hotelData?.yesterday_sales
                    ? Number(hotelData?.yesterday_sales).toFixed(2)
                    : 0}
                </h5>
              </div>
            </div>
          </Col>

         

          <Col xs={24} md={4}>
            <div className="ad-hom-box-10">
              <span className="ad-span-col-10">
                <i className="fa fa-calendar-o" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Month Wise </p>
                <h5>
                  {hotelData?.month_sales
                    ? Number(hotelData?.month_sales).toFixed(2)
                    : 0}
                </h5>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="order-count-cards ">
        <Row
          gutter={[
            { xs: 0, md: 16 },
            { xs: 8, md: 16 },
          ]}
          className="charts-bars card-count-balance"
        >
          <Col xs={24} md={12}>
            <Card className="side-bar-graph-1">
              <p className="month-wise-cart">Upcoming Bookings</p>
             
              <Table
                className="table-scroll-none flight-new-pagenation"
                bordered
                dataSource={hotelData?.upcoming_bookings.filter(x=> x.Status ===2)}
                columns={columnshotelcount}
              />
            </Card>
          </Col>

          <Col xs={24} md={12}>
            <div className="site-card-border-less-wrapper">
              <Card
                className="side-bar-graph-new-flight" /* title="Hotel recent 10 bookings" bordered={false} */
              >
                <p className="month-wise-cart">Recent Bookings</p>
                <Row className="boder-count">
                  <Col xs={24} md={24} className="total-cal-fl">
                    <Table
                      className="table-scroll-none flight-new-pagenation"
                      bordered
                      dataSource={hotelData?.recent_bookings}
                      columns={columnshotelcount}
                    />
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
      </div>

      
    </div>
  );
};
export default Hotelsalescount;
