import React, { createContext, useContext, useState, useEffect } from "react";

import ApiClient from "../../../helpers/ApiClient";

const defFlightSearchObj = null;
export const FlightContext = createContext();

export function useFlightContext() {
  return useContext(FlightContext);
}

const FlightProvider = (props) => {
  
  const flightPersistJson =
    JSON.parse(localStorage.getItem("flightPersist")) || {};

  let initFlightSearchObj =
    flightPersistJson.flightSearchObj || defFlightSearchObj;
  let initSelectedFlight = flightPersistJson.selectedFlight || [];

  const [flightSearchObj, setFlightSearchObj] = useState(initFlightSearchObj);

  const [originDestinationsArr, setOriginDestinationsArr] = useState([
    {
      departureDateTime: "2020-09-25T00:00:00",
      origin: "LON",
      destination: "BOM",
    },
  ]);

  const [flightSearch, setFlightSearch] = useState({
    originDestinations: originDestinationsArr,
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
    cabinClass: "Economy",
    includeCarrier: null,
    excludeCarrier: null,
    stopOver: "None",
    airTravelType: "oneWay",
    flightDateFlex: 0,
    itineraryViewType: "1",
  });

  const [mOriginDest, setMOriginDest] = useState([
    {
      origin: "",
      destination: "",
      departureDateTime: "",
    },
    {
      origin: "",
      destination: "",
      departureDateTime: "",
    },
  ]);

  const [flightFareRulesResp, setFlightFareRulesResp] = useState("");

  const [selectedFlight, setSelectedFlight] = useState(initSelectedFlight);

  const [airBookStateObj, setAirBookStateObj] = useState({});

  const [flightBookingResp, setFlightBookingResp] = useState({});

  const [flightAirSearchResp, setFlightAirSearchResp] = useState({});

  const [flightAirPriceResp, setFlightAirPriceResp] = useState({});

  const [sessiontimeout, setsessiontimeout] = useState([]);
  const [status, setStatus] = useState('');
  const getsessiontimeout = () => {
    ApiClient.get("admin/get_active_session_timeout/1")
      .then((res) => {
        if (res.status == 200) {
          setsessiontimeout(new Date().getTime() + Number((res.data.sessionTime) * 60 * 1000));
          setStatus(res.data.status);
        }
        else {
          setsessiontimeout(new Date().getTime() + Number(0));
          setStatus(res.data.status);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  
  const updateFlightSearchObj = (attribute, value) => {
    setFlightSearchObj({ ...flightSearchObj, [attribute]: value });
  };
   const updateFlightSearch = (attribute, value) => {
    setFlightSearch({ ...flightSearch, [attribute]: value });
  };


  const updateOriginDest = (value) => {
    originDestinationsArr.map(() => setOriginDestinationsArr(value));
    setFlightSearch({ ...flightSearch, originDestinations: value });
  };

  const updateMOriginDest = (value) => {
    setMOriginDest(value);
  };

 
  const updateFlightFares = (FareRulesResp) => {
    setFlightFareRulesResp(FareRulesResp);
  };

  
  const updateSelectedFlight = (flightObj) => {
    setSelectedFlight(flightObj);
  };

  
  const updateAirBookState = (airBookReq) => {
    setAirBookStateObj(airBookReq);
  };

 
  const updateAirBookRespState = (airBookResp) => {
    setFlightBookingResp(airBookResp);
  };

 
  const updateFlightAirSearchRespObj = (flightAirResponse) => {
    setFlightAirSearchResp(flightAirResponse);
  };

  
  const updateFlightAirPrice = (airPriceRespObj) => {
    setFlightAirPriceResp(airPriceRespObj);
  };

  const resetFlightSearchObj = (flightSearchObj) => {
    setFlightSearchObj(flightSearchObj || defFlightSearchObj);
  };

  useEffect(() => {
    localStorage.setItem(
      "flightPersist",
      JSON.stringify({
        flightSearchObj: flightSearchObj,
        selectedFlight: selectedFlight,
      })
    );
  }, [flightSearchObj, selectedFlight]);

  return (
    <FlightContext.Provider
      value={{
        state: {
          flightSearch,
          originDestinationsArr,
          mOriginDest,
          flightSearchObj,
          flightFareRulesResp,
          selectedFlight,
          airBookStateObj,
          flightBookingResp,
          flightAirPriceResp,
          flightAirSearchResp,
          sessiontimeout,
          status
        },
        setFlightSearchObj: setFlightSearchObj,
        updateFlightSearch: updateFlightSearch,
        updateOriginDest: updateOriginDest,
        updateMOriginDest: updateMOriginDest,
        updateFlightSearchObj: updateFlightSearchObj,
        updateFlightFares: updateFlightFares,
        updateSelectedFlight: updateSelectedFlight,
        updateAirBookState: updateAirBookState,
        updateAirBookRespState: updateAirBookRespState,
        updateFlightAirSearchRespObj: updateFlightAirSearchRespObj,
        updateFlightAirPrice: updateFlightAirPrice,
        resetFlightSearchObj: resetFlightSearchObj,
        getsessiontimeout: () => {
          getsessiontimeout()
        },
        resetFlightSelectedData: () => {
          setSelectedFlight([]);
          setFlightAirSearchResp({});
        },
      }}
    >
      {props.children}
    </FlightContext.Provider>
  );
};

export default FlightProvider;
