import React from "react";
import { Col, Row } from "antd";
import { useAuthContext } from "../../providers/AuthProvider";
import ReactHtmlParser from "react-html-parser";
import { getHolidayTicketStatus } from "../../../common/AllTickets/AllTicketStatus";
import "../ticketActivities.scss";
import noimage from "../../../assets/images/image-not-found-scaled-1150x647.png"
import LogoImg from "../../../assets/images/trypmaker.png";
const TicketHoliday = ({ ticketData, cmsFareRules, }) => {
  const {
    isLogin: { agent },
  } = useAuthContext();
  const BASE = process.env.REACT_APP_BASE_URL;
  return (
    <div className="activities-ticket">
      
              <img style={{ width: 175, height: 72 }} src={LogoImg} />
          
      <div className="activities-details">
        <div className="tour-details-panel">
          <h4 className="pax-title">
            Tour Detail's
          </h4>
          <div className="passenger-details">
            <Row gutter={[8, 8]}>
              <Col md={24} sm={24} xs={24}>
                <h4
                  className="tour-timeline-title"
                  style={{ color: "#00427a", fontFamily: "BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif" }}
                >
                  {ticketData?.TourName}
                </h4>
              </Col>

              <Col md={24} sm={12} xs={24}>
                <div className="img-hol-pic" style={{ height: "320px" }}>
                  {ticketData?.TourMedia?.length >= 0 ? (

                    <img
                      className="hotel-tag-1"
                      src={ticketData?.TourMedia[0]?.Images[0]}
                      alt="Loading..!"
                      style={{
                        height: "100%",
                        objectPosition: "center",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />

                  ) : (
                    <img
                      style={{
                        height: "100%",
                        objectPosition: "center",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      src={noimage} alt="Loading trypmaker" />
                  )}
                </div>
              </Col>
              <Col md={12} sm={12} xs={24}>
                <p>
                  Country Name:
                  <span> {ticketData?.Country}</span>
                </p>

                <p className="d-flex">
                  Booking Status:{" "}
                  <span>
                    {"  "}
                    {getHolidayTicketStatus(ticketData?.BookingStatus)}
                  </span>
                </p>
                {ticketData?.RefundStatus && (
                  <p className="d-flex">
                    Refund Status:{" "}
                    <span>
                      {"  "}
                      {ticketData?.RefundStatus}
                    </span>
                  </p>
                )}
              </Col>
              <Col md={12} sm={12} xs={24}>
                <p>
                  Reference Number:
                  <span> {ticketData?.ReferenceNo}</span>
                </p>
                <p>
                  Booked On:
                  <span> {(ticketData?.CreatedDate).split(" ")[0]}</span>
                </p>

              </Col>

            </Row>
          </div>
        </div>


        <li className="tour-timeline-inverted tour-timeline-item">
          <div className="cnt-inf-hdr">
            <h4>
              Your Detail
            </h4>
          </div>
          <div
            className="tour-timeline-panel"
            style={{ backgroundColor: "rgb(242 249 255)" }}
          >
            <div className="card-details-info-cont">


              <Row>
                <Col md={12} sm={12} xs={24}>
                  <p className="d-flex">
                    Name :{" "}
                    <span>{ticketData?.Name}</span>
                  </p>

                  <p className="d-flex">
                    Duration :{" "} <span> {ticketData?.Day} day's/{ticketData?.Night} night's
                    </span>
                  </p>
                  <p className="d-flex">
                    Guest's :{" "} <span>{ticketData?.TotalPax}{" "}{ticketData?.TotalPax > 1 ? "Traveller's" : "Traveller"}</span>
                  </p>

                </Col>
                <Col md={12} sm={12} xs={24}>
                  <p>
                    Email : {" "}<span>{ticketData?.Email}</span>
                  </p>

                  <p className="d-flex">

                    Contact :{" "} <span>{ticketData?.PhoneNo}
                    </span>
                  </p>
                  <p className="d-flex">

                    Travelling On : <span>{ticketData?.TravellingDate}
                    </span>
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </li>
        {/* ))}  */}
        <li className="tour-timeline-inverted tour-timeline-item">
          <div className="fare-rules-holiday">
            <div className="pack-ovr">
              <h4>Price Summary</h4>
            </div>
            <div className="price-h-card">
              <p>Base fare x {ticketData?.TotalPax}:
                <span>
                  INR {ticketData?.Price}
                </span>
              </p>
              {ticketData?.Conveniencefee > 0 ?
                <p>Convenience :
                  <span>
                    {ticketData?.Conveniencefee}
                  </span>
                </p>
                : null
              }
              {ticketData?.AdminCommission > 0 ? (
                <><p>
                  Additional :
                  <span>
                    INR {ticketData?.AdminCommission}
                  </span>
                </p>
                </>
              ) : null}
              {agent ? (
                <>
                  {ticketData?.Commission > 0 ? (
                    <p> Commision :
                      <span>
                        INR {ticketData?.Commission}
                      </span>

                    </p>
                  ) : null}
                </>
              ) : null}
              {ticketData?.promoData?.status ?
                (
                  <>
                    <p>
                      Discount :
                      <span>{ticketData?.Discount}</span>
                    </p>
                  </>
                ) : null}
              <p>Grand Total :
                <span>
                  INR {ticketData?.TotalPrice}
                </span>
              </p>

              {ticketData?.convienenceData?.amount > 0 ? (
                <p>
                  Convienence Fee:
                  <span>
                    {" "}
                    INR{" "}
                    {Number(
                      ticketData?.convienenceData?.amount ?? 0
                    ).toFixed(2)}
                  </span>
                </p>
              ) : null}
              {ticketData.BookingStatus === 4 && (
                <p>
                  Refund Amount:
                  <span> INR {ticketData.RefundAmount ?? 0}</span>
                </p>
              )}
            </div>
          </div>
        </li>
        <li className="tour-timeline-inverted tour-timeline-item">
          {cmsFareRules?.cancelPolicyDescription ? (
            <div className="tour-timeline-panel">
              <div className="terms-panel">
                <h5 className="terms-title">Terms and Condition</h5>
                <div className="terms-description">
                  <p className="terms-title">Cancel Policy</p>

                  {ReactHtmlParser(cmsFareRules?.cancelPolicyDescription)}
                  {cmsFareRules?.otherRulesDescription ? (
                    <p className="title">Other Policy</p>
                  ) : null}
                  {ReactHtmlParser(cmsFareRules?.otherRulesDescription)}
                </div>
              </div>
            </div>
          ) : null}
        </li>
        <li className="tour-timeline-inverted tour-timeline-item">
          <div className="pack-ovr">
            <h4>
              Package Overview
            </h4>
          </div>
          <div className="pack-overview">
            {ReactHtmlParser(ticketData?.PackageOverview)}
          </div>
        </li>
        
      </div>
    </div>
  );
};

export default TicketHoliday;
