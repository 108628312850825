import { PlusOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Upload,
  message,
  Modal,
  Layout,
  Row,
 
} from "antd";
import React, { useEffect, useState } from "react";
import APIClient from "../../helpers/ApiClient";
import "./Banners.scss";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Content } = Layout;
const { Panel } = Collapse;
const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const Logos = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
  deleteBanner,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    

    return checkJpgOrPng;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(false);
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList.length >= limitSize
              ? null
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const Banners = () => {
  const [companyForm] = Form.useForm();
  const [flightForm] = Form.useForm();
  const [footerBannerForm] = Form.useForm();
  const [bigBannerForm] = Form.useForm();
  const [smallBannerForm] = Form.useForm();
  const [flightSearchForm] = Form.useForm();
  const[HolidayForm]= Form.useForm();
 
  const[HolidaySearchForm]= Form.useForm();
  const [hotelForm] = Form.useForm();
  const [hotelSearchForm] = Form.useForm();
  const [blogbannersForm] = Form.useForm();
  const [offerBannersForm] = Form.useForm();
  const [companyLogo, setCompanyLogo] = useState([]);
  const [flightBanners, setFlightBanners] = useState([]);
  const[HolidayBanners, setHolidayBanners] = useState([]);
  
  const [flightSearchBanners, setFlightSearchBanners] = useState([]);
  
  const [hotelBanners, setHotelBanners] = useState([]);
  const [hotelSearchBanners, setHotelSearchBanners] = useState([]);
  const [packBanners, setPackBanners] = useState([]);
  const [footerBanners, setFooterBanners] = useState([]);
  const [packSearchBanners, setPackSearchBanners] = useState([]);
  const [bigSliderBanners, setBigSliderBanners] = useState([]);
  const [smallSliderBanners, setSmallSliderBanners] = useState([]);
  const initialState = {
    ServiceType: 0,
    ApplicationType: 0,
    BannerType: 0,
    ModifiedBy: 0,
    CreatedBy: 0,
    imagesPath: ["string"],
  };
  
  const [blogBanners, setBlogBanners] = useState([]);
  const [offerBanners, setOfferBanners] = useState([]);
  const [bannerData, setbannerData] = useState(initialState);
  const submitCompanyData = (val) => {
    if (val.companyLogo) {
      if (val.companyLogo.fileList.length > 0) {
        if (val.companyLogo.fileList[0].response.status === 200) {
          let data = {
            ...bannerData,
            imagesPath: [
              val.companyLogo.fileList[0].response.data.filepaths[0],
            ],
            BannerType: 1,
          };
          callPostApi(data);
        }
      }
    }
  };

  const submitFlightData = (val) => {
    if (val.flightBanners) {
      if (val.flightBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.flightBanners.fileList.length; i++) {
          if (val.flightBanners.fileList[i].response.status === 200) {
            array.push(
              val.flightBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 7,
          };
          callPostApi(data);
        }
      }
    }
  };

  const submitFlightSearchBannerData = (val) => {
    if (val.flightSearchBanners) {
      if (val.flightSearchBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.flightSearchBanners.fileList.length; i++) {
          if (val.flightSearchBanners.fileList[i].response.status === 200) {
            array.push(
              val.flightSearchBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 13,
          };
          callPostApi(data);
        }
      }
    }
  };

  const submitFooterBannerData = (val) => {
    if (val.footerBanners) {
      if (val.footerBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.footerBanners.fileList.length; i++) {
          if (val.footerBanners.fileList[i].response.status === 200) {
            array.push(
              val.footerBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 20,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitBusData = (val) => {
    if (val.busBanners) {
      if (val.busBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.busBanners.fileList.length; i++) {
          if (val.busBanners.fileList[i].response.status === 200) {
            array.push(val.busBanners.fileList[i].response.data.filepaths[0]);
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 8,
          };
          callPostApi(data);
        }
      }
    }
  };
  
  const submitHotelData = (val) => {
    if (val.hotelBanners) {
      if (val.hotelBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.hotelBanners.fileList.length; i++) {
          if (val.hotelBanners.fileList[i].response.status === 200) {
            array.push(val.hotelBanners.fileList[i].response.data.filepaths[0]);
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 9,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitHotelSearchBannerData = (val) => {
    if (val.hotelSearchBanners) {
      if (val.hotelSearchBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.hotelSearchBanners.fileList.length; i++) {
          if (val.hotelSearchBanners.fileList[i].response.status === 200) {
            array.push(
              val.hotelSearchBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 15,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitHolidayData = (val) => {
    if (val.HolidayBanners) {
      if (val.HolidayBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.HolidayBanners.fileList.length; i++) {
          if (val.HolidayBanners.fileList[i].response.status === 200) {
            array.push(
              val.HolidayBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 47,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitBlogBanners = (val) => {
    if (val.blogbanners) {
      if (val.blogbanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.blogbanners.fileList.length; i++) {
          if (val.blogbanners.fileList[i].response.status === 200) {
            array.push(val.blogbanners.fileList[i].response.data.filepaths[0]);
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 48,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitOfferBanners = (val) => {
    if (val.offerbanners) {
      if (val.offerbanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.offerbanners.fileList.length; i++) {
          if (val.offerbanners.fileList[i].response.status === 200) {
            array.push(val.offerbanners.fileList[i].response.data.filepaths[0]);
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 49,
          };
          callPostApi(data);
        }
      }
    }
  };

  
  const submitHomeBigBannerData = (val) => {
    if (val.bigSliderBanners) {
      if (val.bigSliderBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.bigSliderBanners.fileList.length; i++) {
          if (val.bigSliderBanners.fileList[i].response.status === 200) {
            array.push(
              val.bigSliderBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 21,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitHomeSmallBannerData = (val) => {
    if (val.smallSliderBanners) {
      if (val.smallSliderBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.smallSliderBanners.fileList.length; i++) {
          if (val.smallSliderBanners.fileList[i].response.status === 200) {
            array.push(
              val.smallSliderBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 22,
          };
          callPostApi(data);
        }
      }
    }
  };

  const callPostApi = (data) => {
    APIClient.post("admin/banners", data)
      .then((response) => {
        if (response.status == 200) {
          getBannersList();
          message.success("Banner Uploaded SuccessFully", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else if (
          response.status == 409 ||
          response?.statusCode == 401 ||
          response.status == 500
        ) {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getBannersList();
  }, []);

  const getBannersList = () => {
    APIClient.get("admin/banners")
      .then((res) => {
        if (res.status === 200) {
          let logoList = res.data.filter((item) => item.BannerType === 1);
          if (logoList.length > 0) {
            if (logoList[0].images.length > 0) {
              let data = {
                uid: logoList[0].images[0].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + logoList[0].images[0].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [logoList[0].images[0].path],
                  },
                },
              };
              setCompanyLogo([data]);
            }
          }

          let flightList = res.data.filter((item) => item.BannerType === 7);
          if (flightList.length > 0) {
            let array = [];
            for (let i = 0; i < flightList[0].images.length; i++) {
              array.push({
                uid: flightList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + flightList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [flightList[0].images[i].path],
                  },
                },
              });
            }
            setFlightBanners(array);
          }

          let flightSearchList = res.data.filter(
            (item) => item.BannerType === 13
          );
          if (flightSearchList.length > 0) {
            let array = [];
            for (let i = 0; i < flightSearchList[0].images.length; i++) {
              array.push({
                uid: flightSearchList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + flightSearchList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [flightSearchList[0].images[i].path],
                  },
                },
              });
            }
            setFlightSearchBanners(array);
          }

          
          let hotelList = res.data.filter((item) => item.BannerType === 9);
          if (hotelList.length > 0) {
            let array = [];
            for (let i = 0; i < hotelList[0].images.length; i++) {
              array.push({
                uid: hotelList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + hotelList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [hotelList[0].images[i].path],
                  },
                },
              });
            }
            setHotelBanners(array);
          }
          let hotelSearchList = res.data.filter(
            (item) => item.BannerType === 15
          );
          if (hotelSearchList.length > 0) {
            let array = [];
            for (let i = 0; i < hotelSearchList[0].images.length; i++) {
              array.push({
                uid: hotelSearchList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + hotelSearchList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [hotelSearchList[0].images[i].path],
                  },
                },
              });
            }
            setHotelSearchBanners(array);
          }
          let HolidayList = res.data.filter(
            (item) => item.BannerType === 47
          );

          if (HolidayList.length > 0) {
            let array = [];
            for (let i = 0; i < HolidayList[0].images.length; i++) {
              array.push({
                uid: HolidayList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + HolidayList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [HolidayList[0].images[i].path],
                  },
                },
              });
            }
            setHolidayBanners(array);
          }


          let blogBannerList = res.data.filter(
            (item) => item.BannerType === 48
          );
          if (blogBannerList.length > 0) {
            let array = [];
            for (let i = 0; i < blogBannerList[0].images.length; i++) {
              array.push({
                uid: blogBannerList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + blogBannerList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [blogBannerList[0].images[i].path],
                  },
                },
              });
            }
            setBlogBanners(array);
          }
          let offerBannerList = res.data.filter(
            (item) => item.BannerType === 49
          );
          if (offerBannerList.length > 0) {
            let array = [];
            for (let i = 0; i < offerBannerList[0].images.length; i++) {
              array.push({
                uid: offerBannerList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + offerBannerList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [offerBannerList[0].images[i].path],
                  },
                },
              });
            }
            setOfferBanners(array);
          }

          let footerBannersList = res.data.filter(
            (item) => item.BannerType === 20
          );
          if (footerBannersList.length > 0) {
            let array = [];
            for (let i = 0; i < footerBannersList[0].images.length; i++) {
              array.push({
                uid: footerBannersList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + footerBannersList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [footerBannersList[0].images[i].path],
                  },
                },
              });
            }
            setFooterBanners(array);
          }

          let bigHomeSliderBannerList = res.data.filter(
            (item) => item.BannerType === 21
          );
          if (bigHomeSliderBannerList.length > 0) {
            let array = [];
            for (let i = 0; i < bigHomeSliderBannerList[0].images.length; i++) {
              array.push({
                uid: bigHomeSliderBannerList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url:
                  BASE + bigHomeSliderBannerList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [bigHomeSliderBannerList[0].images[i].path],
                  },
                },
              });
            }
            setBigSliderBanners(array);
          }
          let smallHomeSliderBannerList = res.data.filter(
            (item) => item.BannerType === 22
          );
          if (smallHomeSliderBannerList.length > 0) {
            let array = [];
            for (
              let i = 0;
              i < smallHomeSliderBannerList[0].images.length;
              i++
            ) {
              array.push({
                uid: smallHomeSliderBannerList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url:
                  BASE +
                  smallHomeSliderBannerList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [smallHomeSliderBannerList[0].images[i].path],
                  },
                },
              });
            }
            setSmallSliderBanners(array);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DeleteBannerImage = (id) => {
    APIClient.delete("admin/banners/" + id)
      .then((response) => {
        if (response.status == 200) {
          getBannersList();
          message.success("Deleted Successsfully");
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="manage-markup-section">
      <Content className="container-fluid">
        <Row>
          <Col span={24}>
            <Card bordered={false}>
              <h5>
                Upload Images <HelpInfoHelper screenName={"/admin/banners"} />
              </h5>
              <Collapse accordion defaultActiveKey={["1"]}>
                <Panel header="Company Logo's" key="1">
                  <Form
                    form={companyForm}
                    layout="vertical"
                    onFinish={submitCompanyData}
                  >
                    <div className="company-logos">
                      <div className="logo-section">
                        <Row>
                          <Col md={8} xs={24}>
                            <div className="upload-logo">
                              <p>Company Logo</p>

                              <Logos
                                category="companylogo"
                                fileList={companyLogo}
                                setFileList={setCompanyLogo}
                                formName="companyLogo"
                                limit={true}
                                limitSize={1}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div
                        className="upload-btns"
                        style={{ textAlign: "right" }}
                      >
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Panel>
                <Panel header="Home Pages Banners" key="2">
                  <div className="company-logos">
                    <div className="logo-section">
                      <Row>
                        <Col md={8} xs={24}>
                          <Form
                            form={flightForm}
                            layout="vertical"
                            onFinish={submitFlightData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Flight Banner</p>
                              <Logos
                                category="flightbanners"
                                fileList={flightBanners}
                                setFileList={setFlightBanners}
                                formName="flightBanners"
                                limit={true}
                                limitSize={5}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form
                            form={hotelForm}
                            layout="vertical"
                            onFinish={submitHotelData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Hotel Banner</p>
                              <Logos
                                category="hotelbanners"
                                fileList={hotelBanners}
                                setFileList={setHotelBanners}
                                formName="hotelBanners"
                                limit={true}
                                limitSize={5}
                                deleteBanner={DeleteBannerImage}
                              />

                            </div>
                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form
                            form={HolidayForm}
                            layout="vertical"
                            onFinish={submitHolidayData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Holiday Banner</p>
                              <Logos
                                category="Holidaybanners"
                                fileList={HolidayBanners}
                                setFileList={setHolidayBanners}
                                formName="HolidayBanners"
                                limit={true}
                                limitSize={5}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                           
                            

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form
                            form={blogbannersForm}
                            layout="vertical"
                            onFinish={submitBlogBanners}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Blog Banners</p>
                              <Logos
                                category="blogbanners"
                                fileList={blogBanners}
                                setFileList={setBlogBanners}
                                formName="blogbanners"
                                limit={true}
                                limitSize={3}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form
                            form={offerBannersForm}
                            layout="vertical"
                            onFinish={submitOfferBanners}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Offer Banners</p>
                              <Logos
                                category="offerbanners"
                                fileList={offerBanners}
                                setFileList={setOfferBanners}
                                formName="offerbanners"
                                limit={true}
                                limitSize={3}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                      
                      </Row>
                    </div>
                  </div>
                </Panel>
                {/**search Results banners */}
                <Panel header="Search  Result Banners" key="3">
                  <div className="company-logos">
                    <div className="logo-section">
                      <Row>
                        <Col md={8} xs={24}>
                          <Form
                            form={flightSearchForm}
                            layout="vertical"
                            onFinish={submitFlightSearchBannerData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Flight Search Banners</p>
                              <Logos
                                category="flightsearchbanners"
                                fileList={flightSearchBanners}
                                setFileList={setFlightSearchBanners}
                                formName="flightSearchBanners"
                                limit={true}
                                limitSize={5}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                           

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form
                            form={hotelSearchForm}
                            layout="vertical"
                            onFinish={submitHotelSearchBannerData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Hotel Search Banners</p>
                              <Logos
                                category="hotelsearchbanners"
                                fileList={hotelSearchBanners}
                                setFileList={setHotelSearchBanners}
                                formName="hotelSearchBanners"
                                limit={true}
                                limitSize={5}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                       
                      </Row>
                    </div>
                  </div>
                </Panel>
                {/**End search Results banners */}

                <Panel header="Footer Banners" key="4">
                  <div className="company-logos">
                    <div className="logo-section">
                      <Row>
                        <Col md={8} xs={24}>
                          <Form
                            form={footerBannerForm}
                            layout="vertical"
                            onFinish={submitFooterBannerData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Footer Banners</p>
                              <Logos
                                category="footerbanners"
                                fileList={footerBanners}
                                setFileList={setFooterBanners}
                                formName="footerBanners"
                                limit={true}
                                limitSize={3}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Panel>
                <Panel header="Home Slider Banners" key="5">
                  <div className="company-logos">
                    <div className="logo-section">
                      <Row>
                        <Col md={12} xs={24}>
                          <Form
                            form={bigBannerForm}
                            layout="vertical"
                            onFinish={submitHomeBigBannerData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Home Big Slider Banners</p>
                              <Logos
                                category="bigSliderBanners"
                                fileList={bigSliderBanners}
                                setFileList={setBigSliderBanners}
                                formName="bigSliderBanners"
                                limit={true}
                                limitSize={3}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button
                                type="primary"
                                htmlType="submit"
                                
                              >
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        <Col md={12} xs={24}>
                          <Form
                            form={smallBannerForm}
                            layout="vertical"
                            onFinish={submitHomeSmallBannerData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Home Small Slider Banners</p>
                              <Logos
                                category="smallSliderBanners"
                                fileList={smallSliderBanners}
                                setFileList={setSmallSliderBanners}
                                formName="smallSliderBanners"
                                limit={true}
                                limitSize={3}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </Card>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default Banners;
