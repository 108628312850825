import React from "react";
import "../HotelDetailsBox/HotelDetailsBox.scss";
import { Card, Col, Rate, Row } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";

const HotelDetailsCard = ({ Ids, hotelDetailsObj, hotelSearchData }) => {


  return (
    <>


      <Card>

        <div className="hedr">
          <h6>Your Booking Details</h6>
        </div>
        <Row gutter={12} className="cal-top-date-pic">
          <Col md={12}>
            <div className="cal-block-div" style={{ borderRight: `1px solid #e7e7e7` }}>
              <div className="cb-top-div-1">
                <span className="checkin-text-div"><b>Check-In</b></span>
                <span className="bold bkg-date-div">
                  {moment(hotelSearchData.checkInDate).format("ddd, MMM DD, YYYY")}
                </span>
                {hotelDetailsObj?.combineRoom[0]?.rateComments?.checkin_begin_time ?
                  <span>
                    <p>From {hotelDetailsObj?.combineRoom[0]?.rateComments?.checkin_begin_time}</p>
                  </span> :
                  <span>
                    <p>From {hotelDetailsObj?.policy?.checkInTime}</p>
                  </span>}


              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="cal-block-div">
              <div className="cb-top-div">
                <span className="checkin-text-div"><b>Check-Out</b></span>
                <span className="bold bkg-date-div">
                  {moment(hotelSearchData.checkOutDate).format(
                    "ddd, MMM DD, YYYY"
                  )}
                </span>
                {hotelDetailsObj?.combineRoom[0]?.rateComments?.checkout_time ?
                  <span>
                    <p>Until {hotelDetailsObj?.combineRoom[0]?.rateComments?.checkout_time}</p>
                  </span> :
                  <span>
                    <p>Until {hotelDetailsObj?.policy?.checkOutTime}</p>
                  </span>}
              </div>
            </div>
          </Col>
        </Row>
        <div className="stay-info-card">
          <h6>Total Length of Stay:</h6>
          <p style={{ marginBottom: "0" }}>
            <b>{moment(hotelSearchData.checkOutDate).diff(moment(hotelSearchData.checkInDate), 'days')} {"night's"}</b>
          </p>
        </div>
        <div className="br-line"></div>
        <div className="sel-room-details">
          <h6>You Selected:</h6>
          <p>{hotelDetailsObj?.combineRoom[0]?.combineRooms[0]?.roomName}</p>
        </div>
        <div className="second-col-details-div">

          {Object.keys(hotelSearchData).length > 0 &&
            hotelSearchData.roomGuests.length > 0 && (
              <div className="room-info-grid">
                {hotelSearchData.roomGuests.map((item, index) => (
                  <div key={`room-${index}`} className="room-info">
                    <div className="spin-days-rooms">
                      <div className="room-spin-cp">Room {index + 1}:</div>
                      <div className="room-pkg-spin-cp">
                        {item.noOfChilds > 0
                          ? `${item.noOfAdults} Adults & ${item.noOfChilds} Childs`
                          : `${item.noOfAdults} Adults`}
                      </div>
                    </div>
                  </div>
                ))}


              </div>
            )}


        </div>

      </Card>



    </>
  );
};

export default HotelDetailsCard;
