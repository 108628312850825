import React from "react";
import "../HotelDetailsBox/HotelDetailsBox.scss";
import hotelNoImg from "../../assets/images/hotels/no_img.png";
import { Card, Col, Rate, Row } from "antd";

import { useHistory } from "react-router-dom";

const HotelDetailsBox = ({ Ids, hotelDetailsObj, hotelSearchData }) => {
  let history = useHistory();




  return (
    <>
      <Row>
        <Col md={8} sm={24} xs={24}>
          <div className="image-details one-img-hotel">

            {(hotelDetailsObj?.images != "" || hotelDetailsObj?.images != null) ? (
              <>
                {(hotelDetailsObj?.images?.length > 0) ? (
                  <img
                    src={hotelDetailsObj?.images?.[0]}
                    alt={hotelDetailsObj.hotelName}
                  />
                ) : (
                  <img src={hotelNoImg} alt="no-photo" />
                )}
              </>

            ) : (
              <img
                src={hotelDetailsObj?.images}
                alt={hotelDetailsObj.hotelName}
              />
            )}



            <div className="tag-cp-img">
              <p>You Save Rs.2,640 </p>
            </div>
          </div>
        </Col>

        <Col md={16} sm={24} xs={24}>
          <div className="disp-table-cell-div">
            <div className="hotel-plan-div">
              <p>Hotel </p>
              {hotelDetailsObj.starRating && (
                <div style={{ width: "40%" }} className="hotel-star">
                  <Rate
                    className="starRating"
                    disabled
                    value={Number(hotelDetailsObj.starRating)}
                    allowHalf={true}
                  />
                </div>
              )}

            </div>
            <div className="hotel-name">
              <h4>{hotelDetailsObj.hotelName}</h4>
            </div>
            <p className="post-div-para">
              {hotelDetailsObj?.addresses[0]?.address}
            </p>
          </div>
          <Col md={24} sm={24} xs={24}>


            <Col className="inclusion-cp">
              <span>Inclusions :</span>
              {hotelDetailsObj?.combineRoom[0]?.inclusions.length > 0 ? (hotelDetailsObj?.combineRoom[0]?.inclusions.map((i, idx) =>
                idx >= 0 ? (
                  <>

                    <ul>

                      <li>
                        {i.toUpperCase().includes("WIFI") || i.toUpperCase().includes("WI-FI") ?
                          <i class="fa fa-wifi" aria-hidden="true"></i> : null}
                        {i.toUpperCase().includes("PARKING") || i.toUpperCase().includes("free-parking") ?
                          <i class="fa fa-product-hunt" aria-hidden="true"></i> : null}
                        {i.toUpperCase().includes("BREAKFAST") || i.toUpperCase().includes("Restaurant") ?
                          <i class="fa fa-cutlery" aria-hidden="true"></i> : null}
                        {i.toUpperCase().includes("BAR") || i.toUpperCase().includes("drinks") ?
                          <i class="fa fa-glass" aria-hidden="true"></i> : null}
                        {i.toUpperCase().includes("WHEELCHAIR") || i.toUpperCase().includes("handicaped") ?
                          <i class="fa fa-wheelchair" aria-hidden="true"></i> : null}
                        &nbsp;
                        {i}
                      </li>
                    </ul>
                  </>
                ) : (
                  null
                )
              )) : ""}
            </Col>


          </Col>
        </Col>

      </Row>
      <Row>

      </Row>


    </>
  );
};

export default HotelDetailsBox;
