import React, { useEffect } from "react";
import queryString from "query-string";
import "./HolidayOfflineSuccess.scss";
import { Row, Col, Layout, Card } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

const HolidayOfflineSuccess = () => {
  const params = queryString.parse(document.location.search);
  

  return (
    <div className="collapseCustomWhite">
      <div className=" providerapi">
        <Layout className="ourteam-bg">
          <div className="thankyou-panel">
            <div className="page-width-1">
              <Card>
                <Row justify="center">
                  <Col md="24" xs={24}>


                    <h5>
                      Your Request of Reference No. {params?.ref} is Submited
                      Successfully.
                    </h5>

                    <div className="sucessModal-v3">
                      <CheckCircleOutlined className="modalIcon-v3" />

                      <p>One of our representative will contact you.</p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default HolidayOfflineSuccess;
