import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import * as ANTD from "antd";
import { Form, Input, message, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import profileImg from "../../admin/assets/profileUser.png";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import "./MyProfile.scss";

const MyProfile = () => {
  const [mobileNo, setMobileNo] = useState("");
  const [upLoadImage, setUpLoadImage] = useState(false);
  const BASE = process.env.REACT_APP_BASE_URL;
  const uploadActionUrl = BASE + "media/upload/singleImage";

  const initialState = {
    Email: "",
    Mobile: "",
    Name: "",
    Gender: 0,
    DOB: "",
    ProfilePic: null,
  };
  const titleType = (res) => {
    let result = "";
    if (res == "Mr") {
      result += "1";
    }
    if (res == "Ms") {
      result += "2";
    }
    if (res == "Mrs") {
      result += "3";
    }
    if (res == "1") {
      result += "Mr";
    }
    if (res == "2") {
      result += "Ms";
    }
    if (res == "3") {
      result += "Mrs";
    }
    if (res == "string") {
      result += "Mr";
    }
    return result;
  };
  const [userData, setUserData] = useState(initialState);
  const [AllData, setAllData] = useState(initialState);

  const { user } = useAuthContext();
  const userID = user?.UserID;
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [reportModal, setReportModalView] = useState(false);
  const [formLayout, setFormLayout] = useState("vertical");
  const [showTable, setShowTable] = useState(false);
  const [ProfileForm] = ANTD.Form.useForm();
  const { Option } = ANTD.Select;
  const [imgUrl, setImgUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [upLoadImageshow, setUpLoadImageshow] = React.useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const uploadLogoButton = (
    <div>
      {upLoadImage ? <LoadingOutlined /> : <img src={profileImg} alt="" />}
      <div className="ant-upload-text">Upload Image</div>
    </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const handlePreview = async (file) => {
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };
  function handleChange(info) {
    if (info.file.status === "uploading") {
      setUpLoadImage(true);
      setImageUrl(null);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response.status == 200) {
        setUserData({
          ...userData,
          AgencyLogo: info.file.response.data.filepath,
        });
        getBase64(info.file.originFileObj, (imageUrl) => {
          setImageUrl(imageUrl);
          setUpLoadImage(false);
        });
      }
      setUpLoadImage(false);
    }
  }

  const handleCancel = () => {
    setPreviewVisible(false);
  };
  useEffect(() => {
    getUserProfile();
  }, []);
  const getUserProfile = async () => {
    try {
      const response = await ApiClient.get(`admin/b2b/user/${userID}`);
      if (response.status === 200) {
        setAllData(response.data);
        setUserData({
          ...response.data.userDetails,
        });
        if (response.data.userDetails.AgencyLogo) {
          setImageUrl(BASE + response.data.userDetails.AgencyLogo.substring(1));
         
          setUserData({
            ...userData,
            AgencyLogo: response.data.userDetails.AgencyLogo
              ? response.data.userDetails.AgencyLogo.slice(28)
              : "",
          });
        }
        ProfileForm.setFieldsValue({
          ...response.data.userDetails,
          ...response.data,
          ...response.data.GstDetails,
          DOB: moment(response.data.DOB),


        });
        setMobileNo(response.data.MobileNo);
      }
    } catch (error) {
      ANTD.message.error(error.message);
    }
  };

  const clear = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const closeModal = () => {
    setModalVisible({
      visible: false,
    });
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const handleImageDelete = () => {
    setImageUrl(null);
    setUserData({
      ...userData,
      AgencyLogo: null,
    });
    message.success("Image deleted successfully");
  };

  const tableActions = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}
          onClick={showModal}
        >
          <EditOutlined
            style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm title="Are you sure to delete?">
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };

  const formItemLayout =
    formLayout === "vertical"
      ? {
        labelCol: { span: 24 },
        wrapperCol: { span: 23 },
      }
      : null;


  const reset = () => {
    setShowTable(false);
  };
  
  const EditUserDetail = async (values) => {

    delete values.UploadImage;
    let Reminder_Type = values.PaymentPreference ?? "";
    let userInfo = {
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Email,
      DailingCode: user.DailingCode,
     
      Mobile: values.MobileNo,
      DOB: user.DOB,
      Gender: user.Gender,
      Status: 1,
      StoreAddress: values.StoreAddress,
      RegisterdAddress: values.RegisterdAddress,
      AgencyLogo: userData.AgencyLogo,
     
      AgencyName: user.AgencyName,
      Membership: user.Membership,
      PaymentPreference: [1, 2],
      PAN: user.PAN ?? "",
      Services: user.Services,
      PANUpload: user.PANUpload,
      AdhaarCard: user.AdhaarCard,
      AdhaarCardUpload: user.AdhaarCardUpload,
      GSTUpload: user.GSTUpload,
      LicenseNumber: user.LicenseNumber,
      VoterId: user.VoterId,
      Passport: user.Passport,
      Role: 5,
      PassportUpload: user.PassportUpload,
      OtherDocumentsUpload: user.OtherDocumentsUpload,
      Comments: user.Comments,
      Title: values.Title,
      GSTDetails: {
        GSTCompanyName: values.GSTCompanyName,
        GSTINNumber: values.GSTINNumber,
        GSTAdress: values.GSTAdress,
        GSTPhone: values.GSTPhone,
        GSTEmail: values.GSTEmail,
      },
      ModifiedBy: 0,
    };

    try {
      const response = await ApiClient.put(
        `admin/b2b/user/${userID}`,
        {},
        userInfo
      );

      if (response.status === 200) {
        ANTD.message.success("Profile Updated");
        getUserProfile();
      } else {
        ANTD.message.error("Something went wrong. Try Again");
      }
    } catch (error) {
      ANTD.message.error(error.message);
    }
  };

  return (
    <div className="manage-markup-section ourteam-bg">
      <div className="container-fluid">
        <ANTD.Row>
          <ANTD.Col span={24}>
            <div className="card-bt-gap">
              <ANTD.Card
                bordered={false}
                className="retrieve-booking my-pro-v3"
              >
                <div className="card-add-heading">
                  <h5>My Profile</h5>
                  <div className="add-icon d-none">
                    <i className="fa fa-plus"></i>
                  </div>
                </div>
                <div>
                  &nbsp;
                  <ANTD.Form
                    form={ProfileForm}
                    {...formItemLayout}
                    layout={formLayout}
                    onFinish={EditUserDetail}
                    initialValues={{ layout: formLayout }}
                  >
                    <ANTD.Row>
                      <ANTD.Col md={6} xs={24}>
                        <ANTD.Form.Item
                          label="Title"
                          name="Title"
                          rules={[{ required: true }]}
                        >
                          <ANTD.Select placeholder="Mr">
                            <option value="Mr">Mr</option>
                            <option value="Ms">Ms</option>
                            <option value="Mrs">Mrs</option>
                          </ANTD.Select>
                        </ANTD.Form.Item>
                      </ANTD.Col>
                      <ANTD.Col md={6} xs={24}>
                        <ANTD.Form.Item
                          label="First Name"
                          name="FirstName"
                          rules={[
                            { required: true },
                            {
                              pattern: "^[a-zA-Z]*$",

                              message: "Must be Alphabet",
                            },
                          ]}
                        >
                          <Input placeholder="Name" />
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col md={6} xs={24}>
                        <ANTD.Form.Item
                          label="Last Name"
                          name="LastName"
                          rules={[
                            { required: true },
                            {
                              pattern: "^[a-zA-Z]*$",

                              message: "Must be Alphabet",
                            },
                          ]}
                        >
                          <Input placeholder="Last Name" />
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col md={6} xs={24}>
                        <ANTD.Form.Item
                          label="Email"
                          name="Email"
                          className="profile-value-de-retrive"
                          readOnly
                          rules={[
                            {
                              message: "email is required",
                            },
                          ]}
                        >
                          <Input
                            disabled={true}
                            placeholder="Enter Your Email"
                          />
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col md={6} xs={24}>
                        <ANTD.Form.Item
                          className="profile-value-de-retrive"
                          label="Mobile Number"
                          readOnly
                          name="MobileNo"
                          // name="GSTPhone"
                          value={mobileNo}
                        >
                          <Input
                            disabled={true}
                            placeholder="Enter Your Mobile Number"
                          />
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col md={6} xs={24}>
                        <ANTD.Form.Item
                          label="StoreAddress(optional)"
                          name="StoreAddress"
                          rules={[
                            {
                              message: "StoreAddress is required",
                            },
                          ]}
                        >
                          <TextArea
                            placeholder="Enter StoreAddress"
                            style={{ width: "100%", height: "39px" }}
                          />
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col md={6} xs={24}>
                        <ANTD.Form.Item
                          label="RegisterdAddress(optional)"
                          name="RegisterdAddress"
                          rules={[
                            {
                              message: "RegisterdAddress is required",
                            },
                          ]}
                        >
                          <TextArea
                            placeholder="RegisterAddress"
                            style={{ width: "100%", height: "39px" }}
                          />
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col md={6} xs={24}>
                        <ANTD.Form.Item
                          name="GSTPhone"
                          label="GSTPhone"
                          rules={[
                            {
                              minLength: 10,
                              maxLength: 10,
                              pattern: "^[0-9]{10}$",
                              message: "Must be 10 digits",
                            },
                          ]}
                        >
                          <Input placeholder="GSTPhone" />
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col md={18} xs={24}>
                        <ANTD.Row>
                          <ANTD.Col md={24} xs={24}>
                            <h5 className="remtype">GSTDetails(optional) </h5>
                          </ANTD.Col>
                          <ANTD.Col md={6} xs={24}>
                            <ANTD.Form.Item
                              label="GSTCompanyName"
                              name="GSTCompanyName"
                              rules={[
                                {
                                  message: "GSTCompanyName is required",
                                },
                              ]}
                            >
                              <Input placeholder="GST CompanyName" />
                            </ANTD.Form.Item>
                          </ANTD.Col>
                          <ANTD.Col md={6} xs={24}>
                            <ANTD.Form.Item
                              label="GSTINNumber"
                              name="GSTINNumber"
                              rules={[
                                {
                                  pattern: "^[A-Z0-9]{15}$",

                                  message: "Must be 15 Digits Alphanumeric",
                                },
                              ]}
                            >
                              <Input placeholder="GSTIN Number" />
                            </ANTD.Form.Item>
                          </ANTD.Col>
                          <ANTD.Col md={6} xs={24}>
                            <ANTD.Form.Item
                              label="GSTAdress"
                              name="GSTAdress"
                              rules={[
                                {
                                  message: "GSTAdress is required",
                                },
                              ]}
                            >
                              <TextArea
                                placeholder="GSTAddress"
                                style={{ width: "100%", height: "39px" }}
                              />
                            </ANTD.Form.Item>
                          </ANTD.Col>

                          <ANTD.Col md={6} xs={24}>
                            <ANTD.Form.Item
                              label="GSTEmail"
                              name="GSTEmail"
                              rules={[
                                {
                                  type: "email",
                                  message: "GSTAdress is required",
                                },
                              ]}
                            >
                              <Input placeholder="GSTEmail" />
                            </ANTD.Form.Item>
                          </ANTD.Col>
                        </ANTD.Row>
                      </ANTD.Col>

                      <ANTD.Col xs={24} md={4}>
                        <Form.Item
                          label="Upload Image"
                          name="UploadImage"
                          hidden={upLoadImageshow}

                        >
                          <Upload
                            name="image"
                            listType="picture-card card-deposit-preview"
                            className="avatar-uploader"
                            showUploadList={false}
                            onPreview={handlePreview}
                            action={uploadActionUrl}
                            data={{ category: "promo" }}
                            onChange={handleChange}
                            beforeUpload={beforeUpload}
                          >
                            {imageUrl ? (
                              <div style={{ position: "relative" }}>
                                <img
                                  src={imageUrl}
                                  alt="avatar"
                                  style={{ width: "100%" }}
                                />
                                <DeleteOutlined
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                    color: "red",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleImageDelete}
                                />
                              </div>
                            ) : (
                              uploadButton
                            )}
                          </Upload>
                        </Form.Item>
                      </ANTD.Col>
                    </ANTD.Row>
                    <ANTD.Row className="reports-btns">
                      <ANTD.Button
                        type="primary"

                        htmlType="submit"
                      >
                        Update
                      </ANTD.Button>

                    </ANTD.Row>
                  </ANTD.Form>
                </div>

              </ANTD.Card>
            </div>
          </ANTD.Col>
        </ANTD.Row>
      </div>
    </div>
  );
};

export default MyProfile;
