import React, { useEffect, useState } from "react";
import * as ANTD from "antd";
import { Form, Select, Input, Button } from "antd";
import PaymentGateway from "./helpers/PaymentGateway";
import ApiClient from "./helpers/ApiClient";

const PgrequestTest = (props) => {

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };
  useEffect(() => {
    ApiClient.get("admin/user").then((res) => {

    });
  }, []);

  const [form] = Form.useForm();
  const [pgDisplay, setPgDisplay] = useState(false);
  const [pgData, setPgData] = useState({});

  const submitform = (data) => {
    setPgData(data);
    setPgDisplay(true);
  };

  return (
    <ANTD.Layout style={{ paddingTop: 32 }}>
      <h2>Payment Gateway Testing</h2>
      <Form
        {...formLayout}
        onFinish={submitform}
        initialValues={{
          PgType: "1",
          OrderCurrency: "INR",
          CustomerEmail: "test@test.com",
          CustomerName: "Test User",
          CustomerPhone: "1234657980",
          OrderId: "TSG-F10612171",
          OrderNote: "Temp Note",
          OrderAmount: 4000,
        }}
        form={form}
      >
        <Form.Item name="OrderCurrency" label="Select Currency">
          <Select placeholder="Select Currency">
            <option value="INR">INR</option>
          </Select>
        </Form.Item>
        <Form.Item name="OrderAmount" label="Amount" defaultValue="100">
          <Input placeholder="Enter Amount" />
        </Form.Item>
        <Form.Item name="OrderId" label="orderId" defaultValue="101">
          <Input placeholder="Enter orderId" />
        </Form.Item>
        <Form.Item
          name="CustomerName"
          label="Customer Name"
          defaultValue="test user"
        >
          <Input placeholder="Enter Name" />
        </Form.Item>
        <Form.Item
          name="CustomerEmail"
          label="email"
          defaultValue="test@email.com"
        >
          <Input placeholder="Enter email" />
        </Form.Item>
        <Form.Item name="CustomerPhone" label="phone" defaultValue="1234567890">
          <Input placeholder="Enter phone" />
        </Form.Item>

        <Form.Item
          name="OrderNote"
          label="Order Note "
          defaultValue="1234567890"
        >
          <Input placeholder="Order Note " />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8 }}>
          {pgDisplay == false ? (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          ) : (
            <PaymentGateway data={pgData} />
          )}
        </Form.Item>
      </Form>
    </ANTD.Layout>
  );
};

export default PgrequestTest;

