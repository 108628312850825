import React, { useContext, useState, useEffect } from "react";
import { Button, Card, Col, Collapse, Input, Radio, Row, Form, Popover, List, message, Modal } from "antd";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import Login from "../../../components/Login/Login";
import moment from "moment";
import ApiClient from "../../../helpers/ApiClient";

import { QuestionCircleTwoTone, CloseOutlined } from "@ant-design/icons";

import {
  getHolidayPrice,
  getHolidayPartPaymentDetails,
} from "../holidayHelper";

const { Panel } = Collapse;
const oriDateFormat = "YYYY-MM-DD";
const dateFormat = "DD-MM-YYYY";

const HolidayTotalCard = ({
  holidayCheckOutData,
  isPartPayment,
  isPromoVisible,
  location,
}) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();

  const { activeCurrency, currencyValue } = useCurrencyContext();
  const {
    state: {
      otherData: { ConvFee, promoData },
      partPayment,
    },
    AddConvFee,
    validatePromoCode,
    RemovePromo,
  } = useContext(GlobalStatesContext);

  const [form] = Form.useForm();
  const [Loc, setLoc] = useState({});
  useEffect(() => {
    if (location) {
      setLoc(location);
    }
  }, [location]);
  const [promoCode, setPromoCode] = useState([]);
  const [promoCodeId, setpromoCodeId] = useState(null);

  const {
    totalAmount,
    grandTotal,
    convAmount,
    discount,
    totalPackagePrice,
    totalExtraCharge,
    totaladultamount,
    totalchildamount,
    totalinfantamount,
    adultCount,
    childCount,
    infantCount

  } = getHolidayPrice(holidayCheckOutData, ConvFee, promoData);

  const { partPaymentAmount, dueAmount } = getHolidayPartPaymentDetails(
    grandTotal,
    partPayment
  );

  useEffect(() => {
    if (!agent) {
      AddConvFee(4);
      getpromo();
    }
  }, [agent]);

  const getpromo = () => {
    ApiClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, oriDateFormat).isSameOrAfter(moment())
          );
          if (data.length > 0) {
            let holidayPromo = data.filter(
              (promo) =>
                promo.ServiceType === 6 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(holidayPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);
      });
  };

  const handleApply = (code) => {
    if (user != null) {
      setpromoCodeId(code)
      form.setFieldsValue({
        promo: code,
      });

      form.submit();
    } else {
      message.error("pls Sign-In to Avail Offers")
    }
  };


  const content = () => {
    return (<div> <Row gutter={[2]}>
      <Col md={12} xs={12}>
        <p className=" ">Adult <CloseOutlined style={{ fontSize: "75%" }} /> {adultCount}</p>
      </Col>
      <Col md={12} xs={12}>
        <p className="left">
          {activeCurrency} {currencyValue(totaladultamount)}
        </p>
      </Col>
      <Col md={12} xs={12}>
        <p className=" ">Child <CloseOutlined style={{ fontSize: "75%" }} /> {childCount}</p>
      </Col>
      <Col md={12} xs={12}>
        <p className="left">
          {activeCurrency} {currencyValue(totalchildamount)}
        </p>
      </Col>
      <Col md={12} xs={12}>
        <p className=" ">Infant <CloseOutlined style={{ fontSize: "75%" }} /> {infantCount}</p>
      </Col>
      <Col md={12} xs={12}>
        <p className="left">
          {activeCurrency} {currencyValue(totalinfantamount)}
        </p>
      </Col></Row></div>)
  }
  const contentcharges = () => {
    return (<div><Row gutter={[2]}> <Col md={12} xs={12}>
      <p className=" ">Convenience Fee:</p>
    </Col>
      <Col md={12} xs={12}>
        <p className="left">
          {activeCurrency} {currencyValue(totalExtraCharge)}
        </p>
      </Col></Row></div>)
  }
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: "USER",
  });
  const showModal1 = (type) => {
    /*============= check user logged or not =========== */
    setModalVisible({ visible: true, type: type });

  };
  return (
    <>
      <div className="fare-summary-card">
        <h5>
          <strong>Fare Details</strong>
        </h5>
        <div className="total-card">
          <div className="package-fare">
            <p className="para">Package Fare: <p className="left">{activeCurrency} {currencyValue(totalPackagePrice)}
              <Popover content={content} title="Package Fare">
                <QuestionCircleTwoTone type="primary" style={{ cursor: "pointer" }} />
              </Popover></p></p>
          </div>
          <div className="other-charge">
            <p className="para">Other Charge: <p className="left">
              {activeCurrency} {currencyValue(totalExtraCharge)} <Popover content={contentcharges} title="Additional Fee">
                <QuestionCircleTwoTone type="primary" style={{ cursor: "pointer" }} />
              </Popover>
            </p> </p>
          </div>


          {promoData.status && (
            <>
              <div className="discount-charge">
                <p className=" ">Discount :<p className="left" style={{ marginLeft: "60px" }}>
                  - {activeCurrency} {currencyValue(discount)}
                </p></p>
              </div>
            </>
          )}

          {partPayment.isPartPayment && isPartPayment === 2 ? (
            <>
              <div className="due-amnt">
                <p className=" ">Due Amount :<p className="left">
                  {activeCurrency} {currencyValue(dueAmount)}
                </p></p>
              </div>

              <div className="total-pmnt">
                <p className=" ">Total Payment: <p className="left">
                  {activeCurrency} {currencyValue(partPaymentAmount)}
                </p></p>
              </div>
            </>
          ) : (
            <>

              <div className="Grand-ttl">
                <p className="grnd">Grand Total </p>
                <p className="left">
                  {activeCurrency} {currencyValue(grandTotal)}
                </p>
              </div>
            </>
          )}

        </div>

        {!agent && user?.Role?.RoleLevel !== 3 && isPromoVisible ? (
          <div className="promo_wrap">
            {promoData.status == false ? (
              <div className="apply-promo">
                <h6 className="promo-hdr"><strong>Promo Code</strong></h6>
                {user != null ?
                  <p className="promo-sub">Have a Promo Code?

                    <Form
                      layout="vertical"
                      form={form}
                      onFinish={(d) => {
                        validatePromoCode({
                          ServiceType: 6,
                          Amount: Number(grandTotal),
                          PromoCode: d.promo,
                          UserId: user?.UserID ?? 1,
                          userMail: user?.Email,
                        });
                      }}
                    >
                      <div className="promo-codes">
                        <div className="promo-1">
                          <Form.Item
                            name="promo"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Input
                              className="inputbg"
                              placeholder="Enter Your Promo code"
                              autoComplete="off"
                            />
                          </Form.Item>
                        </div>
                        <div className="promo-2">
                          <Form.Item>
                            <Button
                              type="primary"
                              onClick={() => {
                                form.submit();
                              }}
                            >
                              Apply
                            </Button>
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                  </p> : <p className="promo-sub">Have a Promo Code?
                    <span style={{ color: "blue", cursor: "pointer" }} onClick={() => showModal1("USER")}> Sign-In </span> to Avail.
                  </p>}
                <div className="promo-offers">
                  {promoCode.length ? (
                    <Radio.Group value={promoCodeId}>{
                      promoCode.map((item, key) => {
                        return (
                          <>
                            <div className="promo-cp-coupons mt-0" key={key}>
                              <div className="inline-cp-promo">
                                <Form>
                                  <Form.Item>
                                    <Radio value={item.Code}
                                      onClick={() => handleApply(item.Code)}
                                    ></Radio>
                                  </Form.Item>
                                </Form>

                                <p className="promo-key-cp">{item.Code}</p>
                                {item.DiscountType === 1 ? (
                                  <p className="save-cp-offer">
                                    Save {item.Discount + " %"}
                                  </p>
                                ) : (
                                  <p className="save-cp-offer">
                                    Save {activeCurrency}&nbsp;
                                    {currencyValue(item.Discount)}
                                  </p>
                                )}
                                <div class="circle1"></div>
                                <div class="circle2"></div>
                              </div>

                            </div>
                          </>
                        );
                      })}
                    </Radio.Group>
                  ) : (
                    <>
                      <div className="promo-cp-coupons">
                        <div className="inline-cp-promo">
                          <p className="promo-key-cp">No Promo Code Available</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (

              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      setpromoCodeId(null)
                      RemovePromo();
                      form.resetFields();
                    }}
                  />{" "}
                </p>

                <div className="promo-input">
                  <p>
                    <span className="applied"> {promoData.Code} </span> Promo Code
                    Applied
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <Modal
        centered
        visible={modalVisible.visible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        className="login-modal"
        footer={false}
      >
        <Login
          location={Loc}
          setModalVisible={(value) => setModalVisible(value)}
          type={modalVisible.type}
        />
      </Modal>

    </>
  );
};

export default HolidayTotalCard;
