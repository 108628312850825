import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import "./HomeCarousel.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide_1_1 from "../../assets/images/HomeCarousel/slide_1_1.jpg";
import topFlightNames from "./../flight-search/FlightsTopCities.json";
import moment from "moment";

const CarouselNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-table", background: "#164993" }}
      onClick={onClick}
    />
  );
};
const cardStyle = {
  width: "600px",
  height: "400px",
}
const CarouselPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-table", background: "#164993" }}
      onClick={onClick}
    />
  );
};

const HomeCarousel = ({ deal }) => {
  
  const BASE = process.env.REACT_APP_BASE_URL;
  const { activeCurrency, currencyValue } = useCurrencyContext();
  let tomorrowDate = moment().add(5, "days").format("YYYY-MM-DD");

  const [deals, setDeals] = useState([]);
  const [mobileDeals, setmobileDeals] = useState([]);
  const settings = {
    infinite: true,
    className: "center",
    centerMode: true,
    slidesToShow: 2,
    //autoplay: true,
    autoplaySpeed: 2000,
    rows: 1,
    slidesPerRow: 1,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
  };

  const MobileSlidersettings = {
    infinite: true,
    className: "center",
    lazyLoad: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    rows: 1,
    slidesPerRow: 1,
    pauseOnHover: true,
    arrows: false,
  };

  useEffect(() => {
    if (deal.length > 0) {

      mapDeals(deal);

    }
  }, [deal]);

  const slider_slides = [];


  const mapDeals = (deal) => {
  
    const map_data = deal.map((item) => {
      item.FromAirportName = getFlighNameFromCode(item.FromAirportCode);
      item.ToAirportName = getFlighNameFromCode(item.ToAirportCode);

      return item;
    });
    setmobileDeals(map_data);
   
    setDeals(map_data);
  };

  const getFlighNameFromCode = (code) => {
    let result = code;
    topFlightNames.forEach((x) => {
      if (x.codeIataAirport == code) {
        result = x.nameAirport;
        return;
      }
    });

    return result;
  };

  const Slide = ({ i, deal, span }) => {
    return (
      <Col key={i + "desk" + deal.DealID} span={span}>
        <div className="slide-wrapper">
          <div className="slide-over-layer"></div>
          <div className="slide-hoverlayer"></div>
          <img
            className="carousel-img"
            src={deal.Image ? BASE + deal.Image.substring(1) : slide_1_1}
            alt="slide_1_1"
          />
          <div className="content-on-slide">
            <div className="top-text">
              <p>{deal?.FromCountryID?.Country}</p>
            </div>
            <div className="bottom-text">
              <div className="hr-top">
                <p>From: {deal.FromAirportName}</p>
                <div className="text-flight">
                  <i className="fa fa-plane"></i>
                  <h5>{deal.ToAirportName}</h5>
                </div>
              </div>
            
              <div className="price-bottom-hr">
                <h6>FROM </h6>
               
                <span>{currencyValue(deal.FromAmount)}</span>
               
                <p>{activeCurrency}</p>
              </div>
              <div className="check-details-btn">
                {deal.serviceType === 2 ?
                  <Link to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&stopOver=none&priceForTrip=REGULAR&includeCarrier=&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${(deal.FromAirportCode)}&destination=${(deal.ToAirportCode)}`}>
                    <Button className="btn-styles">Check details</Button>
                  </Link> :
                  <Link to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&stopOver=none&priceForTrip=REGULAR&includeCarrier=&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${(deal.source)}&destination=${(deal.destination)}`}>
                    <Button className="btn-styles">Check details</Button>
                  </Link>}
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  };

  const MobSlide = ({ key, deal, span }) => {
    return (
      <Col key={key} span={span}>
        <Link to={"/deals?country=" + deal?.FromCountryID?.Country}>
          <div className="slide-wrapper">
            <div className="slide-over-layer"></div>
            <div className="slide-hoverlayer"></div>
            <img
              className="carousel-img"
              src={deal.Image ? BASE + deal.Image.substring(1) : slide_1_1}
              alt="slide_1_1"
            />
            <div className="content-on-slide">
              <div className="top-text">
                <p>{deal?.FromCountryID?.Country}</p>
              </div>
              <div className="bottom-text">
                <div className="hr-top">
                  <p>From: {deal?.FromAirportName}</p>
                  <div className="text-flight">
                    <i className="fa fa-plane"></i>
                    <h5>{deal?.ToAirportName}</h5>
                  </div>
                </div>
                <div className="price-bottom-hr">
                  <h6>FROM </h6>
                  <span>{currencyValue(deal.FromAmount)}</span>
                  <p>{activeCurrency}</p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </Col>
    );
  };

  return (
    <div key={"homecarousel-1"} className="home_carousel" style={{ marginTop: '5%' }}>
      <h5 style={{ color: "black", fontFamily: 'Times New Roman, Times, serif', fontSize: "30px",fontWeight:1000 ,textAlign: 'center' }}>Trending destinations</h5>

      <section className="carousel-section desktop_crousal">
        <div className="right-layer d-none"></div>
        <div className="left-layer d-none"></div>
        <div className="container1">
          <div className="slides-section">
            <Slider {...settings}>{deals.map((item, i) => {
              return (
                <Slide
                  key={i + "desk" + item.DealID}
                  deal={item}
                  span={23}
                />
              );
            })}</Slider>
          </div>
        </div>
      </section>
      <section className="carousel-section mobile_crousal">
        <div className="right-layer d-none"></div>
        <div className="left-layer d-none"></div>
        <div className="container">
          <div className="slides-section">
            <Slider {...MobileSlidersettings}>
              {mobileDeals.map((item, i) => {
                return (
                  <MobSlide
                    key={"mob" + item.DealID + i}
                    deal={item}
                    span={24}
                  />
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
};

export const CharterFlightHomeCarousel = ({ deal }) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { activeCurrency, currencyValue } = useCurrencyContext();

  const [deals, setDeals] = useState([]);
  const [mobileDeals, setmobileDeals] = useState([]);
  const settings = {
    infinite: true,
    className: "center",
    centerMode: true,
    slidesToShow: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
    rows: 1,
    slidesPerRow: 1,
    pauseOnHover: true,
    // arrows: true,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
  };

  const MobileSlidersettings = {
    infinite: true,
    className: "center",
    lazyLoad: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    rows: 1,
    slidesPerRow: 1,
    pauseOnHover: true,
    arrows: false,
  };

  useEffect(() => {
    if (deal.length > 0) {
      mapDeals(deal);
    }
  }, [deal]);

  const slider_slides = [];


  const createSlides = (data) => {
    for (let i = 0; i < data.length; i += 5) {
      let slide = [];
      let row_1 = [];
      // let row_2 = [];
      let a = i;
      if (data[a] != null) {
        row_1.push(<Slide i={i} deal={data[a]} span={8} key={i} />);
      }

      let b = i + 1;
      if (data[b] != null) {
        row_1.push(<Slide i={i} deal={data[b]} span={8} key={i} />);
      }

      let c = i + 2;
      if (data[c] != null) {
        row_1.push(<Slide i={i} deal={data[c]} span={8} key={i} />);
      }
      let d = i + 3;
      if (data[d] != null) {
        row_1.push(<Slide i={i} deal={data[d]} span={8} key={i} />);
      }

      let e = i + 4;
      if (data[e] != null) {
        row_1.push(<Slide i={i} deal={data[e]} span={8} key={i} />);
      }
      slide.push(<Row>{row_1}</Row>);
      // slide.push(<Row>{row_2}</Row>);
      slider_slides.push(<div>{slide}</div>);
    }

    return slider_slides;
  };
  const mapDeals = (deal) => {
    setmobileDeals(deal);
    const maped_slides = createSlides(deal);
    setDeals(maped_slides);
  };

  const Slide = ({ i, deal, span }) => {
    return (
      <Col key={i + "desk" + deal.CharterFlightId} span={span}>
        <div className="slide-wrapper">
          <div className="slide-over-layer"></div>
          <div className="slide-hoverlayer"></div>
          <img
            className="carousel-img"
            src={deal?.Fleet?.Images ? deal?.Fleet?.Images[0] : slide_1_1}
            alt="slide_1_1"
          />
          <div className="content-on-slide">
            <div className="top-text">
              <p>{deal.SourceCountry}</p>
            </div>
            <div className="bottom-text">
              <div className="hr-top">
                <p>From: {`${deal.SourceCity}`}</p>
                <div className="text-flight">
                  <i className="fa fa-plane"></i>
                  <h5>{`${deal.DestinationCity}`}</h5>
                </div>
              </div>

              <div className="price-bottom-hr">
                <h6>FROM </h6>
               
                <span>{currencyValue(deal?.Price.TotalFare)}</span>
                
                <p>{activeCurrency}</p>
              </div>
              <div className="check-details-btn">
                <Link
                  to={`/charterFlights/results?airTravelType=oneWay&paxCount=1&departureDateTime=${moment().format(
                    "YYYY-MM-DD HH:mm"
                  )}&origin=${deal.SourceAirportCode}&destination=${deal.DestinationAirportCode
                    }`}
                >
                  <Button className="btn-styles">Check details</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  };

  const MobSlide = ({ key, deal, span }) => {
    return (
      <Col key={key} span={span}>
        <Link
          to={`/charterFlights/results?airTravelType=oneWay&paxCount=1&departureDateTime=${moment().format(
            "YYYY-MM-DD HH:mm"
          )}&origin=${deal.SourceAirportCode}&destination=${deal.DestinationAirportCode
            }`}
        >
          <div className="slide-wrapper">
            <div className="slide-over-layer"></div>
            <div className="slide-hoverlayer"></div>
            <img
              className="carousel-img"
              src={deal?.Fleet?.Images ? deal?.Fleet?.Images[0] : slide_1_1}
              alt="slide_1_1"
            />
            <div className="content-on-slide">
              <div className="top-text">
                <p>{deal.SourceCountry}</p>
              </div>
              <div className="bottom-text">
                <div className="hr-top">
                  <p>From: {`${deal.SourceCity}`}</p>
                  <div className="text-flight">
                    <i className="fa fa-plane"></i>
                    <h5>{`${deal.DestinationCity}`}</h5>
                  </div>
                </div>
                <div className="price-bottom-hr">
                  <h6>FROM </h6>
                  <span>{currencyValue(deal?.Price.TotalFare)}</span>
                  <p>{activeCurrency}</p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </Col>
    );
  };

  return (
    <div className="home_carousel">
      <h3>Travel For Less With Our Great Charter Deals</h3>
      <section className="carousel-section desktop_crousal">
        <div className="right-layer d-none"></div>
        <div className="left-layer d-none"></div>
        <div className="container">
          <div className="slides-section">
            <Slider {...settings}>{deals.map((item, i) => (
              <div style={cardStyle} key={i}>
                {item}
              </div>
            ))}</Slider>
          </div>
        </div>
      </section>
      <section className="carousel-section mobile_crousal">
        <div className="right-layer d-none"></div>
        <div className="left-layer d-none"></div>
        <div className="container">
          <div className="slides-section">
            <Slider {...MobileSlidersettings}>
              {mobileDeals.map((item, i) => (
                <MobSlide key={"mob" + item.DealID + i} deal={item} span={24} />
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
};


export default HomeCarousel;
