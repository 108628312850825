import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Layout,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import "rc-datepicker/lib/style.css";
import React, { useEffect, useRef, useState, useContext } from "react";
import ApiClient from "../../helpers/ApiClient";

import search from "../assets/vector-icons/search.png";
import "./TopCitiesAndDestination.scss";
import HotelAutoSelect from "../../common/AutoCompleteSelect/HotelAutoSelect";


import { GlobalStatesContext } from "../../common/providers";
import AirportAutoCompleteSelectForCharterFlights from "../../common/AutoCompleteSelect/AirportAutoCompleteSelectBytopcities";
const { Option } = Select;
const { Panel } = Collapse;

const TopCitiesAndDestination = () => {
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [id, setId] = useState(-1);
  const [form] = Form.useForm();
  const [serviceType, setServiceType] = useState(0);
  const [dataFlip, setDataFlip] = useState(false);

  const [countryLists, setCountryList] = useState([]);
  const [dataSource, setDataSource] = useState([]);


  const [searchCity, setSearchCity] = useState("");
  const {
    state: {
      serviceTypeList
    },
    getAllServiceType
  } = useContext(GlobalStatesContext);
  const origin = useRef();
  const destination = useRef();
  const city = useRef(null);
  const dateBox = useRef(null);
  const tocityBox = useRef(null);

  const handleOnSubmit = (ref) => { };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const handleAddClick = () => {
    form.resetFields();
    showModal();
    setIsEditMode(false);
  };
  const handleEditClick = (rec) => {
    form.setFieldsValue({
      ...rec,
    });
    setServiceType(rec.ServiceType);

    setIsEditMode(true);

    showModal();
  };

  const getCountriesLists = () => {
    ApiClient.get("admin/country")
      .then((res) => {
        if (res.status == 200) {

          setCountryList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAllServicesTopCities = () => {
    setDataSource([]);
    ApiClient.get("admin/getAllServicesTopCities").then((res) => {
      if (res.status == 200) {

        setDataSource(res.data);
      }
    });
  };
  useEffect(() => {
    getAllServicesTopCities();
    getCountriesLists();
    getAllServiceType();
  }, []);

  const SubmitTopCity = (formvalues) => {
    ApiClient.post("admin/createTopCity", formvalues).then((response) => {
      if (response.status === 200) {
        setModalVisible(false);
        message.success(response.message);

        getAllServicesTopCities();
        form.resetFields();
      } else {
        message.error(" Error Creating Top City ");
      }
    });
  };

  const handleSubmit = (formvalues) => {
    formvalues.ServiceName = getServiceType(formvalues.ServiceType);
    if (isEditMode) {
      UpdateTopCity(formvalues);
    } else {
      SubmitTopCity(formvalues);
    }
  };

  const UpdateTopCity = (formvalues) => {
    const updatedFormData = {
      ...formvalues,
    };
    ApiClient.put("admin/updateTopCity/" + id, {}, updatedFormData).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          message.success(response.message);

          getAllServicesTopCities();
          form.resetFields();
        } else {
          message.error(" Error Updating Top Cities ");
        }
      }
    );
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const deleteCarType = (id) => {
    ApiClient.delete("admin/deleteTopCity/" + id).then((res) => {
      if (res.status == 200) {
        message.success(res.message, 3);
        getAllServicesTopCities();
      }
    });
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              setId(rec.Id);
              handleEditClick(rec);
            }}
            style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteCarType(rec.Id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const { Option } = Select;

  const getServiceType = (num) => {
    let type = "";
    serviceTypeList.map((item) => {
      if (item.ServiceType == num) {
        type = item.ServiceName;
      }
    });
    return type;
  };

  const fightColumns = [
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      key: "ServiceType",
      render: (text, record, index) => {
        return getServiceType(text);
      },
    },

    {
      title: "ServiceType Enum",
      dataIndex: "ServiceType",
    },
    {
      title: "Origin",
      dataIndex: "origin",
    },
    {
      title: "Destination",
      dataIndex: "destination",
    },

    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];

  const hotelColumns = [
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      key: "ServiceType",
      render: (text, record, index) => {
        return getServiceType(text);
      },
    },

    {
      title: "ServiceType Enum",
      dataIndex: "ServiceType",
    },
    {
      title: "Hotel City",
      dataIndex: "hotelCityCode",
    },

    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];

  const HolidayColumns = [
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      key: "ServiceType",
      render: (text, record, index) => {
        return getServiceType(text);
      },
    },

    {
      title: "ServiceType Enum",
      dataIndex: "ServiceType",
    },
    {
      title: "Tour City",
      dataIndex: "destination",
    },
    

    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];


  const RenderColumns = (serviceType) => {
    if (serviceType == 1 || serviceType === 10) {
      return fightColumns;
    } else if (serviceType === 2) {
      return hotelColumns;
    } else if (serviceType === 6) {
      return HolidayColumns;
    }
  };


  const handelStatus = (data) => {

    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const changeStatus = (info, status) => {
    let obj = {
      Id: info.Id,
      Status: status,
    };
    ApiClient.put("admin/updateTopCity/" + obj.Id, {}, obj)
      .then((response) => {

        if (response.status == 200) {
          message.success(" Status updated successfully !");

          getAllServicesTopCities();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>Top Cities </h5>
                        <p></p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />

                      <p className="add-deposit-icon" onClick={handleAddClick}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  <div>
                    {dataSource.length > 0 ? (
                      <Collapse
                        defaultActiveKey={["1"]}
                        accordion
                        expandIconPosition={"right"}
                      >

                        <Panel
                          header={"Flights"}
                          key={0 + "panel"}
                        >
                          <Table
                            className="table-scroll-none"
                            bordered
                            dataSource={
                              dataSource.filter(
                                (i) => i.ServiceType === 1
                              )
                                ? dataSource.filter(
                                  (i) =>
                                    i.ServiceType === 1
                                )
                                : dataSource
                            }
                            columns={RenderColumns(1)}
                            pagination={{
                              defaultPageSize: 25,
                              showSizeChanger: true,
                              pageSizeOptions: ["25", "50", "100", "125"],
                            }}
                          />
                        </Panel>
                        <Panel
                          header={"Hotels"}
                          key={1 + "panel"}
                        >
                          <Table
                            className="table-scroll-none"
                            bordered
                            dataSource={
                              dataSource.filter(
                                (i) => i.ServiceType === 2
                              )
                                ? dataSource.filter(
                                  (i) =>
                                    i.ServiceType === 2
                                )
                                : dataSource
                            }
                            columns={RenderColumns(2)}
                            pagination={{
                              defaultPageSize: 25,
                              showSizeChanger: true,
                              pageSizeOptions: ["25", "50", "100", "125"],
                            }}
                          />
                        </Panel>
                        <Panel
                          header={"Holiday"}
                          key={2 + "panel"}
                        >
                          <Table
                            className="table-scroll-none"
                            bordered
                            dataSource={
                              dataSource.filter(
                                (i) => i.ServiceType === 6
                              )
                                ? dataSource.filter(
                                  (i) =>
                                    i.ServiceType === 6
                                )
                                : dataSource
                            }
                            columns={RenderColumns(6)}
                            pagination={{
                              defaultPageSize: 25,
                              showSizeChanger: true,
                              pageSizeOptions: ["25", "50", "100", "125"],
                            }}
                          />
                        </Panel>
                      </Collapse>
                    ) : null}
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Service Type"
        className="promo-modal-header cartypes-value modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label="Service Type"
                rules={[{ required: true }]}
                name="ServiceType"
              >
                <Select
                  onSelect={(e) => {
                    setServiceType(e);
                  }}
                >
                  {serviceTypeList.length
                    ? serviceTypeList.map((item, index) => {
                      return (
                        <Option
                          key={"service" + index}
                          value={item.ServiceType}
                        >
                          {item.ServiceName}
                        </Option>
                      );
                    })
                    : ""}
                </Select>
              </Form.Item>
            </Col>

            {(serviceType === 1 || serviceType === 10) && (
              <>
                <Col md={12}>
                  <AirportAutoCompleteSelectForCharterFlights
                    formItemProps={{
                      name: "origin",

                      rules: [
                        {
                          required: true,
                          message: "Please Specify The City",
                        },
                      ],
                    }}
                    selectProps={{
                      placeholder: "Origin",
                    }}
                    refName={origin}
                    focusRef={destination}
                    handleOnSubmit={handleOnSubmit}
                    dataFlip={dataFlip}
                    label="Origin"
                  />
                </Col>
                <Col md={12}>
                  {" "}
                  <AirportAutoCompleteSelectForCharterFlights
                    formItemProps={{
                      name: "destination",

                      rules: [
                        {
                          required: true,
                          message: "Please Specify The Destination City",
                        },
                      ],
                    }}
                    selectProps={{
                      placeholder: "Destination",
                    }}
                    refName={destination}
                    handleOnSubmit={handleOnSubmit}
                    dataFlip={dataFlip}
                    label="Destination"
                  />
                </Col>
              </>
            )}

            {serviceType === 2 && (
              <Col md={24}>
                <HotelAutoSelect
                  formItemProps={{
                    name: "hotelCityCode",
                    rules: [
                      { required: true, message: "Please Specify The City" },
                    ],
                  }}
                  selectProps={{
                    size: "large",
                    placeholder: "Enter City Name",
                  }}
                  api={"hotels-v2/searchhotelcity/"}
                  refName={city}
                  focusRef={dateBox}
                  handleOnSubmit={handleOnSubmit}
                  modifySearch={false}
                />
              </Col>
            )}

            {serviceType === 6 && (
              <>
                <Col md={24}>
                  
                  <HotelAutoSelect
                    formItemProps={{
                      name: "destination",
                      rules: [
                        { required: true, message: "Please Specify The City" },
                      
                      ],
                    }}
                    selectProps={{
                      size: "large",
                      placeholder: "Origin",
                    }}
                    
                    api={"hotels-v2/searchhotelcity/"}
                    refName={city}
                   
                    handleOnSubmit={handleOnSubmit}
                    modifySearch={false}
                    dataFlip={dataFlip}
                  />
                </Col>

              </>
            )}

            <Col md={24} xs={24}>
              <Form.Item label="Status" name="Status">
                <Radio.Group
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                form.resetFields();
              }}
              danger
              className="cancel-btn"
            >
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TopCitiesAndDestination;
