import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth,GoogleAuthProvider,FacebookAuthProvider} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAkR8lFCdxwxOjytyhfwhpsHJjlsqTix_M",
  authDomain: "trypmaker-e0b69.firebaseapp.com",
  projectId: "trypmaker-e0b69",
  storageBucket: "trypmaker-e0b69.appspot.com",
  messagingSenderId: "614172867648",
  appId: "1:614172867648:web:5c004a58965cc32c7173a5",
  measurementId: "G-9QEGR4MQTF"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
const fbAuthProvider = new FacebookAuthProvider();
export {auth,provider,fbAuthProvider};