import React, { useEffect, useState } from "react";
import { Button, Form, message, Radio, Space, Spin } from "antd";
import { useAuthContext } from "../common/providers/AuthProvider";
import { LoadingOutlined } from "@ant-design/icons";
import "./payGateway.scss";

const PayGateway = ({
  directPayment = false,
  blockApiReq = () => { },
  loadingSpin,
}) => {
  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const [PaymentOptionsVisible, setPaymentOptionsVisible] = useState(false);
  const showPaymentOptions = () => setPaymentOptionsVisible(true);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#a53939" }} spin />
  );

  useEffect(() => {
    if (user && !directPayment) {
      if (user.Role.RoleId === 5 || user.Role.RoleId === 2) {
        showPaymentOptions();
      }
    } else {

      blockApiReq(4);
    }
  }, []);

  const submitform = (values) => {

    if (values?.PgType) blockApiReq(values?.PgType);
    return;
  };

  return (
    <div>
      {PaymentOptionsVisible && (
        <Form layout="vertical" form={form} onFinish={submitform}>
          <Form.Item
            name="PgType"
            label="Choose Payment Type"
            rules={[{ required: true, message: "Select Payment Gateway" }]}
          >
            <Radio.Group size="large">
              <Space direction="vertical">
                {user.Role.RoleId === 2 && <Radio value={3}>Wallet </Radio>}
                {user.Role.RoleId === 5 && <Radio value={3}>Deposit </Radio>}

                <Radio value={4}>Payment Gateway</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {loadingSpin ? (
            <Button className="payment-btn" disabled>
              <Spin indicator={antIcon} style={{
                primaryColor: "#bd0c21", secondaryColor: "#35459c"
              }} />
            </Button>
          ) : (
            <Button className="payment-btn" onClick={() => form.submit()} >
              Continue Payment
            </Button>
          )}
           {loadingSpin && !PaymentOptionsVisible ? <Button className="payment-btn" disabled>
        <Spin indicator={antIcon} style={{
          primaryColor: "#bd0c21", secondaryColor: "#35459c"
        }} />
      </Button> : null}
        </Form>
      )}
    </div>
  );
};

export default PayGateway;
