import React from "react";
import Slider from "react-slick";
import "./Offers.scss";
import { Card, Row, Col, Popconfirm, Tooltip } from "antd";
import noImage from "../../assets/images/image-not-found-scaled-1150x647.png";
const moment = require('moment');

function OffersSlider(props) {
  let currentDate = new Date()
  let Slides = props?.dataSource;
  const currentTodayDate = moment(currentDate).format('YYYY-MM-DD');
  const MobileSlidersettings = {
    arrows: false,
    dots: false,

    slidesToShow: Slides?.filter((p) => currentTodayDate <= p?.ValidTill)?.length > 2 ? 2 : Slides?.filter((p) => currentTodayDate <= p?.ValidTill)?.length,
    speed: 500,
    slidesToScroll: 1,
   
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    rows: props?.dataSource?.filter((p) => currentTodayDate <= p.ValidTill)?.length < 3 ? 1 : 2,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          rows: 2,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          rows: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          rows: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          rows: 1,
        },
      },
    ],
  };



  function copyToClipboard(text) {
    const elem = document.createElement("textarea");
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }

  const filterPromoData = Slides.filter((p) => currentTodayDate <= p.ValidTill)
  const includedPromos = {};


  return (
    <div className="  offerSlider">
      <div className="col-12">
      {props?.dataSource?.length == 0 ? (null) : (
          <h3 className="mb-3 offers-discounts-v">
            {props?.serviceType} Discounts For You
          </h3>)}
      </div>
      <div className="col-6 text-right">
      </div>
      <div className="col-12">
        <div className="offer-wrapper mb-4 ">
          <Slider {...MobileSlidersettings} >
            {filterPromoData.map((item, i) => {
              if (!includedPromos[item.Code]) {
                includedPromos[item.Code] = true;
                return (
                  <div key={"offerslider" + i} className="card-wrapper" md={3}>
                    <div className="card card-offer" key={i} style={{ justifyContent: 'space-evenly', display: 'flex', flexWrap: 'wrap' }}  >
                      <div style={{ justifyContent: 'space-evenly', display: 'flex', flexWrap: 'wrap', padding: '2%', height: '100%' }} >
                        <div className="card-offer-image-box" >
                          <img
                            src={
                              item.Image
                                ? `${process.env.REACT_APP_BASE_URL +
                                item.Image.substring(1)
                                }`
                                : noImage
                            }
                            alt="book-img"
                          />
                        </div>
                        <div className="card-body card-offer-body" style={{ width: "60%", paddingLeft: '4%' }}>

                          <h5 style={{ color: 'black', fontWeight: '900' }}> {item.Name}</h5>
                          <p style={{ color: 'grey', fontSize: '12px' }}> {item.Description}</p><br />
                          <Tooltip title="Copy">
                            <Popconfirm
                              title="Code Copied, Click on OK"
                              onConfirm={() => copyToClipboard(item.Code)}
                              onCancel={false}
                              okText="Ok"
                              cancelText="No"
                            ><h4 className="card-offer-title card-title">
                                CODE : <span style={{ color: '#330064' }} >{item.Code} <i class="fa fa-copy"></i></span>
                              </h4>
                            </Popconfirm>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default OffersSlider;
