export const getFareType = (num) => {
  

  switch (num) {
    case 1:
      return "GENERAL";
    case 2:
      return "PUBLISH";
    case 3:
      return "INSTANTPUR";
    case 4:
      return "SME";
    case 5:
      return "SAVER";
    case 6:
      return "CORPORATE";
    case 7:
      return "COUPON";
    case 8:
      return "FLEXI";
    case 9:
      return "NDC";
    case 10:
      return "TACTICAL";
    case 11:
      return "SUPER6E";
    case 12:
      return "SME.CRPCON";
    case 13:
      return "SPECIAL";
    case 14:
      return "SUPERFARE";
    case 15:
      return "Cluster/TBF";
    case 16:
      return "Private";
    case 17:
      return "Inst_SeriesPurPF3";
      case 18:
        return "Cluster";
    case 19:
      return "Promo";
    default:
      return "";
  }
};

export const getCabinType = (num) => {
  switch (num) {
    case 1:
      return "Economy Premium";
    case 2:
      return "Business";
    case 3:
      return "First Class";
    case 4:
      return "Economy";
    default:
      return "";
  }
};

export const getRoleType = (num) => {
  if (num === 2) {
    return "Users";
  } else if (num === 4) {
    return "Guest";
  } else if (num === 5) {
    return "Agent";
  }
};

export const getTypeSign = (num) => {
  if (num === 1) {
    return "%";
  }
  return "";
};


