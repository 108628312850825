import React from "react";
import "../NewTicket.scss";
import { Col, Row, Layout, Card, Rate } from "antd";
import moment from "moment";
import { getHotelPrice, getStatus } from "./hotelhelper";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import ReactHtmlParser from "react-html-parser";
import LogoImg from "../../../assets/images/trypmaker.png";

let dateFormat = "MMM, DD-YYYY";

const TicketHotel = ({ ticketData, cmsFareRules,Logo }) => {
  let {
    baseAmount,
    taxAmount,
    convienenceFee,
    discount,
    grandTotal,
    insuranceTotal,
    noOfNights,
  } = getHotelPrice(ticketData);
  const BASE = process.env.REACT_APP_BASE_URL;
  return (
    <>
      <span className="tic-gredient-f"></span>
      <Layout className="cms-pages-width-1 ourteam-bg">
        <Row>
          <Col md={24} xs={24}>
          
              <img style={{ width: 175, height: 72 }} src={LogoImg} />
          
            <Row>
              <Col md={24} xs={24}>

                <p className="tic-city-name-d2"></p>
                <Card className="card-tic-f3">
                  <Row gutter={[16, 8]}>
                    <Col md={14} sm={24} xs={24}>
                      <div className="city-f3">

                        <p>{ticketData.HotelName}
                          <span style={{ marginLeft: "8px" }}>
                            <Rate
                              className="strRating"
                              disabled
                              value={Number(ticketData.starRating)}
                              allowHalf={true}
                            />
                          </span>
                        </p>
                        <span>{ticketData.CityName}</span>
                      </div>
                    </Col>
                    <Col md={10} sm={24} xs={24}>
                      <div className="city-f4">
                        <span>Confirmation No/Booking No</span>
                        <p className="word-break">
                          {ticketData.ConfirmationNumber}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <div className="border-hr"></div>
                  <Row className="row-top-f4">
                    <Col md={7} xs={24} className="city-f4">
                      <span>Ticket Ref Number</span>
                      <p>{ticketData.RefNumber}</p>
                    </Col>
                    <Col md={7} xs={24} className="city-f4">
                      <span>Status</span>
                      <p className="cnfrom-f3">
                        {getStatus(ticketData.BookingStatus)}
                      </p>
                    </Col>
                    <Col md={6} xs={24} className="city-f4">
                      <span>Room(s)</span>
                      <p>{ticketData.NoOfRooms}</p>
                    </Col>
                    <Col md={4} xs={24} className="city-f4">
                      <span>Payment Status</span>
                      <p>Paid</p>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-tic-f1">
                  <Row className="info-flight-t22">
                    <Col md={24} xs={24} className="city-tt-f4">
                      <p><i class="fa fa-hotel" aria-hidden="true"></i>{" "}Hotel Details</p>
                      <span>
                        Here are the details of your Hotel booked from{" "}
                        {moment(ticketData.CheckInDate).format(dateFormat)} to{" "}
                        {moment(ticketData.CheckOutDate).format(dateFormat)}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={24} xs={24} className="city-tt-f5">
                      <div className="logo-h3-text">
                        <div className="city-f55">
                          <p>
                            {moment(ticketData.CheckInDate).format(dateFormat)}
                          </p>
                          <span>Check In</span>
                        </div>
                        <div className="sg-logo-flight">
                          <p>
                            <strong>{ticketData.HotelName}</strong>
                          </p>


                          <p>{ticketData.CityName}</p>
                        </div>

                        <div className="city-f55">
                          <p>
                            {moment(ticketData.CheckOutDate).format(dateFormat)}
                          </p>
                          <span>Check Out</span>
                        </div>
                      </div>
                    </Col>

                  </Row>
                  <Row gutter={[2, 8]}>
                    <Col md={10} xs={24} className="city-tt-f5">
                      <div className="sg-logo-flight">
                        <span>Hotel Address</span>
                        <p>
                          <strong> {ticketData.HotelAddress.address}</strong>
                        </p>
                      </div>
                    </Col>
                    <Col md={8} xs={24} className="city-tt-f5">
                      <div className="sg-logo-flight">
                        <span>Star Rating</span>
                        <p>
                          <strong> {ticketData.StarRating}</strong>
                        </p>
                      </div>
                    </Col>
                    <Col md={6} xs={24} className="city-tt-f5">
                      <div className="sg-logo-flight">
                        <span>No. of Nights</span>
                        <p>
                          <strong> {noOfNights} <i class="fa fa-moon-o" aria-hidden="true"></i></strong>
                        </p>
                      </div>
                    </Col>
                    {ticketData.Rooms.map((item, i) => (
                      <Col
                        key={i + "room"}
                        md={16}
                        xs={24}
                        style={{ marginTop: "4px" }}
                        className="city-tt-f5"
                      >
                        <div className="sg-logo-flight">
                          <span style={{ display: "flex" }}>Room Name
                            <p style={{ marginLeft: "70px" }}>
                              <strong> {item.roomName}</strong>
                            </p></span>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Card>

                <Card className="card-tic-f2">
                  <Row>
                    <Col md={20} xs={24} className="city-tt-f4">
                      <p>Contact Details</p>
                    </Col>
                  </Row>

                  <Row className="details-airlines-contact">
                    <Col md={8} sm={12} xs={24} className="contact-person-d4">
                      <span>Name</span>
                      <p>
                        {" "}
                        {ticketData.guests.length > 0
                          ? `${ticketData?.guests[0]?.FirstName} 
                        ${ticketData?.guests[0]?.LastName}`
                          : null}
                      </p>
                    </Col>
                    <Col md={8} sm={12} xs={24} className="contact-person-d4">
                      <span>Mobile Number</span>
                      <p>{ticketData.phoneNo}</p>
                    </Col>

                    <Col md={8} sm={12} xs={24} className="contact-person-d4">
                      <span>Email</span>

                      <p>{ticketData.email}</p>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-tic-f2">
                  <Row>
                    <Col md={20} xs={24} className="city-tt-f4">
                      <p>Passengers Details</p>
                    </Col>
                  </Row>
                  {ticketData.guests.length > 0
                    ? ticketData.guests.map((item) => (
                      <Row className="details-airlines-contact">
                        <Col md={5} xs={24} className="contact-person-d4">
                          <span>Name</span>
                          <p>
                            {item.FirstName} {item.LastName}
                          </p>
                        </Col>
                        <Col md={3} xs={24} className="contact-person-d4">
                          <span>Age</span>
                          <p>{item.Age}</p>
                        </Col>
                        <Col md={6} xs={24} className="contact-person-d4">
                          <span>Pan No.</span>
                          <p>{item.Pan}</p>
                        </Col>
                        <Col md={4} xs={24} className="contact-person-d4">
                          <span>Guest Type</span>
                          <p>{item.GuestType}</p>
                        </Col>

                      </Row>
                    ))
                    : null}
                </Card>

                {ticketData?.insuranceRequired === 1 &&
                  ticketData.insuranceData &&
                  ticketData?.insuranceData?.serviceType === 2 ? (
                  <Card className="insurance-section card-tic-f2">
                    <h5 style={{ fontSize: "16px" }}>
                      THE SMART INSURANCE COVER
                    </h5>
                    <p>EASY CLAIM PROCESS | NO QUESTIONS ASKED</p>

                    <Row gutter={[16, 16]} align="stretch" className="mt-4">
                      <Col md={8} sm={24} xs={24}>
                        <div className="insurance-box">
                          {ticketData?.insuranceData.description_box_1}
                        </div>
                      </Col>
                      <Col md={8} sm={24} xs={24}>
                        <div className="insurance-box">
                          {ticketData?.insuranceData.description_box_2}
                        </div>
                      </Col>
                      <Col md={8} sm={24} xs={24}>
                        <div className="insurance-box">
                          {ticketData?.insuranceData.description_box_3}
                        </div>
                      </Col>
                    </Row>

                    <div className="insurance-coverage">
                      <SafetyCertificateOutlined />
                      <span>
                        Insurance Coverage Amount :{" "}
                        {ticketData?.insuranceData.insuranceCoverage}
                      </span>
                    </div>
                  </Card>
                ) : null}

                <Card className="card-tic-f2">
                  <Row>
                    <Col md={20} xs={24} className="city-tt-f4">
                      <p>Fare Details</p>
                    </Col>
                  </Row>

                  <Row className="details-airlines-contact">
                    <Col md={18} xs={8} sm={12} className="contact-person-d4">
                      <p>Base Fare</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <p>INR {baseAmount}</p>
                    </Col>
                  </Row>
                  <Row className="details-airlines-contact">
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Taxes</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <p>INR {taxAmount}</p>
                    </Col>
                  </Row>

                  {ticketData?.Conveniencefee > 0 ? (
                    <Row className="details-airlines-contact">
                      <Col md={18} xs={12} className="contact-person-d4">
                        <p>Convenience Fee</p>
                      </Col>
                      <Col md={6} xs={12} className="contact-person-d4">
                        <p>INR {convienenceFee}</p>
                      </Col>
                    </Row>) : null}
                  {ticketData?.insuranceRequired === 1 &&
                    ticketData.insuranceData ? (
                    <Row className="details-airlines-contact">
                      <Col md={18} xs={12} className="contact-person-d4">
                        <p>Insurance Amount</p>
                      </Col>
                      <Col md={6} xs={12} className="contact-person-d4">
                        <p>INR {insuranceTotal}</p>
                      </Col>
                    </Row>
                  ) : null}
                  {ticketData?.PromoData?.status ? (
                    <Row className="details-airlines-contact">
                      <Col md={18} xs={12} className="contact-person-d4">
                        <p>Discount</p>
                      </Col>
                      <Col md={6} xs={12} className="contact-person-d4">
                        <p>INR {discount}</p>
                      </Col>
                    </Row>) : null}
                  <Row className="details-airlines-contact">
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Total</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <p>INR {grandTotal}</p>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-tic-f2">
                  <Row>
                    <Col md={20} xs={24} className="city-tt-f4">
                      <p>Note </p>
                    </Col>
                  </Row>

                  <Row className="details-airlines-contact booking-flight-details-info pt-0">
                    <Col md={24} xs={24} className="contact-person-d4">
                      {cmsFareRules?.cancelPolicyDescription ? (
                        <div className="termCon_wrapper">
                          <div className="description">
                            <p className="title">Cancel Policy</p>
                            {ReactHtmlParser(
                              cmsFareRules?.cancelPolicyDescription
                            )}
                            {cmsFareRules?.covidRulesDescription ? (
                              <>
                                <p className="title">Covid Policy</p>

                                {ReactHtmlParser(
                                  cmsFareRules?.covidRulesDescription
                                )}
                              </>
                            ) : null}

                            {cmsFareRules?.otherRulesDescription ? (
                              <>
                                <p className="title">Other Policy</p>

                                {ReactHtmlParser(
                                  cmsFareRules?.otherRulesDescription
                                )}
                              </>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <div className="termCon_wrapper">
                          <p>
                            * Early check in and late check out is subject to
                            availability.
                          </p>

                          <p>
                            * The passenger has to furnish a photo identity and
                            address proof at the time of verification failing to
                            which all passenger on that voucher shall not be
                            entertained with any refunds or restitution.
                          </p>
                          <p>
                            * Standard Check in time is 1400 hrs / check out
                            time 1200 hrs unless specified.
                          </p>

                          <p>
                            * The refund for early check out or unutilized night
                            or service is subject to discretion of the hotel &
                            the supplier, we strongly recommend to get a letter
                            from the hotel favoring no charges for early check
                            out.
                          </p>

                          <p>
                            * All additional charges other than the room charges
                            and inclusions as mentioned in the booking voucher
                            are to be borne and paid separately during
                            check-out. Please make sure that you are aware of
                            all such charges that may comes as extras. Some of
                            them can be WiFi costs, Mini Bar, Laundry Expenses,
                            Telephone calls, Room Service, Snacks etc.
                          </p>

                          <p>
                            * Voucher covers only those taxes which are included
                            in the booking price (if included), all various
                            other applicable taxes needs to be settled directly
                            by guest .
                          </p>
                          <p>
                            * We are not liable for any loss or damage caused
                            during your stay in the hotel or while availing any
                            service.
                          </p>

                          <p>
                            * We have no relevance with the room service or food
                            menu or any other service as these are all as per
                            the hotel standards.
                          </p>

                          <p>
                            * The hotel reserves the right of admission.
                            Accommodation can be denied to guests posing as a
                            'couple' if suitable proof of identification is not
                            presented at the time of check in. we will not be
                            responsible for any check in denied by the hotel due
                            to aforesaid reason.
                          </p>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col md={6} xs={24}></Col>
        </Row>
      </Layout>
    </>
  );
};

export default TicketHotel;
