import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Select,
  DatePicker,
  message,
  Modal,
  InputNumber,
  Upload,
} from "antd";
import { Helmet } from "react-helmet";
import CountryList from "../../common/CountryList";
import APIClient from "../../helpers/ApiClient";
import {
  CheckCircleOutlined,
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  MailOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { AppConstants } from "../../helpers/constants";
import { useHistory } from "react-router-dom";

const { Option } = Select;

export const Logos = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }


    return checkJpgOrPng;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(false);
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };
  const BASE = process.env.REACT_APP_BASE_URL;
  const uploadActionUrl = BASE + "media/upload/multipleImages";

  return (
    <>
      <Form.Item name={formName} label={"Other Portal Image"}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList.length >= limitSize
              ? null
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

export const HotelOfflineRequest = () => {
  const { Option } = Select;
  const { TextArea } = Input;
  const history = useHistory();
  const [tripType, setTriptype] = useState("local");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [OtherPortalImageList, setOtherPortalImageList] = useState([]);
  const [form] = Form.useForm();
  const validateMessages = {
    required: "",
  };

  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  const submitForm = (values) => {
    if (values.Image.fileList.length > 0) {
      values.Image.fileList.forEach((file, index) => {
        values[`Screenshotfromotherportal`] = file.response.data.filepaths[0];
      });
    }
    delete values.Image;

    APIClient.post(`admin/addHotelFITBookingRequest`, values).then(
      (response) => {
        if (response.status === 200) {
          setVisible(true);
          form.resetFields();
        }
      }
    );
  };

  const [cityList, setCityList] = useState([]);

  const getCitiesByCountry = (countryName) => {
    setCityList([]);
    APIClient.post("admin/citiesbycountry", { country: countryName })
      .then((response) => {
        if (response.status == 200) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
       });
  };

  return (
    <div className="contact_wrapper from-contact-24">
      <Row justify="center">
        <Col lg={14} md={20} xs={24}>
          <div className="inner_wrapper">
            <div className="contact_header">
              <h2>Hotel FIT Booking Request</h2>
            </div>
            <Form
              layout="vertical"
              name="ContactUsForm"
              form={form}
              initialValues={{ ISDCode: "+91", TripType: "local" }}
              validateMessages={validateMessages}
              onFinish={submitForm}
            >
              <Card bordered={false}>
                <Row gutter={16}>
                  <Col md={8} sm={8} xs={24}>
                    <Form.Item
                      label="Name "
                      name="Name"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Input placeholder="Enter Name" />
                    </Form.Item>
                  </Col>

                  <Col md={8} sm={8} xs={24}>
                    <Form.Item
                      label="Contact Number"
                      name="RegisteredMobileNumber"
                      className="phno"
                      rules={[
                        {
                          required: true,
                          message: "Phone Number  Required",
                        },
                        {
                          minLength: 10,
                          maxLength: 10,
                          pattern: "^[0-9]{10}$",
                          message: "Must be 10 digits",
                        },
                      ]}
                    >
                      <Input
                        addonBefore={
                          <Form.Item
                            style={{ width: "35%" }}
                            name="ISDCode"
                            className="phno"
                            rules={[
                              {
                                required: true,
                                message: "Phone Number Code Required",
                              },
                            ]}
                            noStyle
                          >
                            <Select
                              showSearch
                              style={{ width: "80px" }}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {CountryList.map((item) => (
                                <Option
                                  key={item.dial_code}
                                  value={item.dial_code}
                                >
                                  {item.dial_code}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        }
                        className="inputbg"
                        placeholder="Enter Mobile No."
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label="Email"
                      name="Email"
                      rules={[
                        { required: true, message: "Required" },
                        {
                          type: "email",
                          message: "Email is not a valid email",
                        },
                      ]}
                    >
                      <Input type="email" placeholder="Enter Email" />
                    </Form.Item>
                  </Col>
                  <Col md={8}>
                    <Form.Item
                      name="Whatcanwehelpyouwith"
                      label="What can we help you with?"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={8} xs={24}>
                    <Form.Item
                      label="Meal Plan "
                      name="MealPlan"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Select>
                        <Option value={1}>Room Only</Option>
                        <Option value={2}>BreakFast</Option>
                        <Option value={3}>Half Board</Option>
                        <Option value={4}>Full Board</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <div className="card-gap">
                <Card bordered={false}>
                  <div className="card-gap">
                    <Row gutter={16}>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Hotel Name"
                          name="HotelName"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input placeholder="HotelName" />
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Country"
                          name="Country"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Select
                            onSelect={(e) => {
                              getCitiesByCountry(e);
                            }}
                            showSearch
                          >
                            {CountryList.length &&
                              CountryList.map((item) => (
                                <Option
                                  key={item.name + "country"}
                                  value={item.name}
                                >
                                  {item.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="City"
                          name="City"
                          rules={[{ required: true, message: "Required" }]}
                        >

                          <Input />
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Checkin Date"
                          name="Checkin"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <DatePicker
                            className="dateStyle"
                            format={"YYYY-MM-DD"}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Checkout  Date"
                          name="Checkout"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <DatePicker
                            className="dateStyle"
                            format={"YYYY-MM-DD"}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Room Type "
                          name="RoomType"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input placeholder="Room Type" />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="No Of Rooms "
                          name="NoOfRooms"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Select>
                            <Option value={1}>1</Option>
                            <Option value={2}>2</Option>
                            <Option value={3}>3</Option>
                            <Option value={4}>4</Option>
                            <Option value={5}>5</Option>
                            <Option value={6}>6</Option>
                            <Option value={7}>7</Option>
                            <Option value={8}>8</Option>
                            <Option value={9}>9</Option>
                            <Option value={10}>10</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label=" No Of Persons"
                          name="NumberofPersons"
                          rules={[
                            { required: true, message: "Required" },
                            {
                              pattern: "^[0-9]{1,2}$",
                              message: "Must be 1 or 2 digits",
                            },
                          ]}
                        >
                          <Input placeholder="No Of Persons" />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label=" Other Portal Name"
                          name="OtherPortalName"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input placeholder="Other Portal Name" />
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label=" Other Portal Rate"
                          name="Otherportalrate"
                          rules={[
                            { required: true, message: "Required" },
                            { pattern: "^[0-9]*$", message: "Must be  digits" },
                          ]}
                        >
                          <Input placeholder="Other Portal Rate" />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Logos
                          category="screenshotfromotherportal"
                          fileList={OtherPortalImageList}
                          setFileList={setOtherPortalImageList}
                          formName="Image"
                          limit={true}
                          limitSize={1}
                        />
                      </Col>
                    </Row>

                    <Form.Item className="center">
                      <Button
                        type="primary"
                        onClick={() => form.submit()}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                </Card>
              </div>
            </Form>
          </div>
        </Col>
      </Row>

      <Modal
        visible={visible}
        title="Thank you for getting in touch!"
        onOk={() => {
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
        footer={[null]}
        className="successModalBox"
      >
        <Row justify="center">
          <Col>
            <div className="sucessModal">
              <CheckCircleOutlined className="modalIcon" />
              <h6 className="text-white">
                Your message has been successfully sent
              </h6>
              <p>
                We appreciate you submitting a contact form with us. Once we
                receive your
              </p>
              <p>
                information we'll respond as soon as we can. In the meantime,
                sit back, relax
              </p>
              <p>and enjoy the view</p>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
export const HotelOfflineGourpBookingRequest = () => {
  const { Option } = Select;
  const { TextArea } = Input;

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();
  const validateMessages = {
    required: "",
  };

  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  const submitForm = (values) => {
    APIClient.post(`admin/addHotelGroupBookingRequest`, values).then(
      (response) => {
        if (response.status === 200) {
          setVisible(true);
          form.resetFields();
        }
      }
    );
  };

  const [cityList, setCityList] = useState([]);

  const getCitiesByCountry = (countryName) => {
    setCityList([]);
    APIClient.post("admin/citiesbycountry", { country: countryName })
      .then((response) => {
        if (response.status == 200) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
       });
  };

  return (
    <div className="contact_wrapper from-contact-24">
      <Row justify="center">
        <Col lg={14} md={20} xs={24}>
          <div className="inner_wrapper">
            <div className="contact_header">
              <h2>Hotel Offline Group Booking Request</h2>
            </div>
            <Form
              layout="vertical"
              name="ContactUsForm"
              form={form}
              initialValues={{ ISDCode: "+91", TripType: "local" }}
              validateMessages={validateMessages}
              onFinish={submitForm}
            >
              <Card bordered={false}>
                <Row gutter={16}>
                  <Col md={8} sm={8} xs={24}>
                    <Form.Item
                      label="Name "
                      name="Name"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Input placeholder="Enter Name" />
                    </Form.Item>
                  </Col>

                  <Col md={8} sm={8} xs={24}>
                    <Form.Item
                      label="Contact Number"
                      name="RegisteredMobileNumber"
                      className="phno"
                      rules={[
                        {
                          required: true,
                          message: "Phone Number  Required",
                        },
                        {
                          minLength: 10,
                          maxLength: 10,
                          pattern: "^[0-9]{10}$",
                          message: "Must be 10 digits",
                        },
                      ]}
                    >
                      <Input
                        addonBefore={
                          <Form.Item
                            style={{ width: "35%" }}
                            name="ISDCode"
                            className="phno"
                            rules={[
                              {
                                required: true,
                                message: "Phone Number Code Required",
                              },
                            ]}
                            noStyle
                          >
                            <Select
                              showSearch
                              style={{ width: "80px" }}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {CountryList.map((item) => (
                                <Option
                                  key={item.dial_code}
                                  value={item.dial_code}
                                >
                                  {item.dial_code}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        }
                        className="inputbg"
                        placeholder="Enter Mobile No."
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label="Email"
                      name="Email"
                      rules={[
                        { required: true, message: "Required" },
                        {
                          type: "email",
                          message: "Email is not a valid email",
                        },
                      ]}
                    >
                      <Input type="email" placeholder="Enter Email" />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <div className="card-gap">
                <Card bordered={false}>
                  <div className="card-gap">
                    <Row gutter={16}>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Organisation Name"
                          name="OrganisationName"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input placeholder="Organisation Name" />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Hotel Name"
                          name="HotelName"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input placeholder="Hotel Name" />
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Country"
                          name="Country"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Select
                            onSelect={(e) => {
                              getCitiesByCountry(e);
                            }}
                            showSearch
                          >
                            {CountryList.length &&
                              CountryList.map((item) => (
                                <Option
                                  key={item.name + "country"}
                                  value={item.name}
                                >
                                  {item.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="City"
                          name="City"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Select showSearch>
                            {cityList.length
                              ? cityList.map((item, index) => {
                                return (
                                  <Option key={index + "city"} value={item}>
                                    {item}
                                  </Option>
                                );
                              })
                              : ""}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Checkin Date"
                          name="CheckinDate"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <DatePicker
                            className="dateStyle"
                            format={"YYYY-MM-DD"}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Checkout  Date"
                          name="CheckoutDate"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <DatePicker
                            className="dateStyle"
                            format={"YYYY-MM-DD"}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={8} xs={24}>
                        <Form.Item
                          label="Meal Plan "
                          name="MealRequirement"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Select>
                            <Option value={1}>Breakfast</Option>
                            <Option value={2}>Lunch</Option>
                            <Option value={3}>Hi Tea</Option>
                            <Option value={4}>Dinner</Option>
                            <Option value={5}>Cocktail Dinner</Option>
                            <Option value={6}>No Meals</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={8} xs={24}>
                        <Form.Item
                          label="Event Type"
                          name="EventType"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Select>
                            <Option value={1}>Conference</Option>
                            <Option value={2}>Meeting</Option>
                            <Option value={3}>Seminar</Option>
                            <Option value={4}>Exhibition</Option>
                            <Option value={5}>Road Show</Option>
                            <Option value={6}>Wedding</Option>
                            <Option value={6}>Others</Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="No Of Rooms "
                          name="NoOfRooms"
                          rules={[
                            { required: true, message: "Required" },
                            {
                              pattern: "^[0-9]*$",
                              message: "Must be  digits",
                            },
                          ]}
                        >
                          <Input placeholder="No of Rooms" />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label=" No Of Persons"
                          name="NumberofPax"
                          rules={[
                            { required: true, message: "Required" },
                            {
                              pattern: "^[0-9]{1,2}$",
                              message: "Must be 1 or 2 digits",
                            },
                          ]}
                        >
                          <Input placeholder="No Of Persons" />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label=" Hotel Category"
                          name="HotelCategory"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input placeholder="Other Portal Name" />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label=" Hotel Preference"
                          name="HotelPreference"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input placeholder="Other Portal Name" />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Any Other Requirement"
                          name="Anyotherrequirement"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input placeholder="Other Portal Name" />
                        </Form.Item>
                      </Col>

                      <Col md={8}>
                        <Form.Item
                          name="BanquetHallRequired"
                          label="Banquet Hall Required?"
                        >
                          <Radio.Group>
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col md={8}>
                        <Form.Item
                          name="AirportTransferRequired"
                          label="Airport Transfer Required?"
                        >
                          <Radio.Group>
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col md={8}>
                        <Form.Item
                          name="Arethedatesflexible"
                          label="Are the Dates Flexible?"
                        >
                          <Radio.Group>
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>


                    <Form.Item className="center">
                      <Button
                        type="primary"
                        onClick={() => form.submit()}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                </Card>
              </div>
            </Form>
          </div>
        </Col>
      </Row>

      <Modal
        visible={visible}
        title="Thank you for getting in touch!"
        onOk={() => {
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
        footer={[null]}
        className="successModalBox"
      >
        <Row justify="center">
          <Col>
            <div className="sucessModal">
              <CheckCircleOutlined className="modalIcon" />
              <h6 className="text-white">
                Your message has been successfully sent
              </h6>
              <p>
                We appreciate you submitting a contact form with us. Once we
                receive your
              </p>
              <p>
                information we'll respond as soon as we can. In the meantime,
                sit back, relax
              </p>
              <p>and enjoy the view</p>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
