import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import moment from "moment";

import excel from "../../assets/vector-icons/excel.png";
import search from "../../assets/vector-icons/search.png";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import { CloseOutlined } from "@ant-design/icons";
const { Option } = Select;

const Statement = (props) => {
  const { user } = useAuthContext();

  const [form] = Form.useForm();
  let dateFormat = "YYYY-MM-DD";
 

  const [showTable, setShowTable] = useState(false);
  const [isDateDisplay, setIsDateDisplay] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [statementList, setStatementList] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [weekendDate, setWeekendDate] = useState(null);

  const handleExcel = () => {
    let copyData = dataSource;
    const excelData = copyData.map((item) => {
      return {
        Name: item.Name,
        Date: item.CreatedOn,
        Remarks: item.Remarks,
        Amount: item.Amount,
        Credit: item.Credit,
        Debit: item.Debit,
        NetBalance: item.NetBalance,
      };
    });
    props.exportExcel(excelData, "statements");
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = statementList.filter(function (item) {
        if (item !== undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      setDataSource(statementList);
    }
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  const columns = [
    {
      title: "Sr No",
      dataIndex: "SNo",
    },
    {
      title: "Name",
      dataIndex: "Name",
    },

    {
      title: "Date",
      dataIndex: "CreatedOn",
    },
    {
      title: "Remarks",
      dataIndex: "Remarks",
    },
    {
      title: "Amount  (Rs.)",
      dataIndex: "Amount",
      sorter: (a, b) => Number(a.Amount) - Number(b.Amount),
    },
    {
      title: "Credit (Rs.)	",
      dataIndex: "Credit",
      sorter: (a, b) => Number(a.Credit) - Number(b.Credit),
    },
    {
      title: "Debit (Rs.)",
      dataIndex: "Debit",
      sorter: (a, b) => Number(a.Debit) - Number(b.Debit),
    },
    {
      title: "NetBalance (Rs.)",
      dataIndex: "NetBalance",
    },
  ];

  const getStatmentsData = (values) => {
    setLoading(true);
    setDataSource([]);
    setStatementList([]);
    ApiClient.get("filter/usertransaction/records", values)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              CreatedOn: moment(item.CreatedOn?.split("T")[0]).format(dateFormat),
            };
          });

          setStatementList(data);
          setDataSource(data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTimeAndDate = (value) => {
    if (value === 5) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const onFormSubmit = (values) => {

    let data = {
      fromDate: Number(values.dateType) === 5 ? moment(values.fromDate).format("YYYY-MM-DD") + " 00:00:00" : null,
      toDate: Number(values.dateType) === 5 ? moment(values.toDate).format("YYYY-MM-DD") + " 23:59:59" : null,
      UserID: user?.UserID,
      options: values?.dateType,
    };
    getStatmentsData(data);
  };
  const onChangeFromDate = (current, dateString) => {
    
    setArrivalDate(dateString);
  };
  const onChangeToDate = (current, dateString) => {
   
    setWeekendDate(dateString);
  };
  const disabledFromDate = (current) => {
    if (weekendDate != null) {
      return current.valueOf() > (new Date(weekendDate));
    }
   

    return current.valueOf() > Date.now();
  }
  const disabledToDate = (current) => {
   
    return current.valueOf() > Date.now() || current.valueOf() < (new Date(arrivalDate));
  };


  return (
    <div className="manage-markup-section ourteam-bg">
      <div className="container-fluid">
        <div className="card-bt-gap">
          <Card bordered={false} className="retrieve-booking">
            <h5 className="view-pkg-v1">Account Statement</h5>
            <Form
              form={form}
              layout={"vertical"}
              initialValues={{
                dateType: 5,
                reportsOf: 0,
              }}
              onFinish={onFormSubmit}
            >
              <Row gutter={16}>
                <Col className="gutter-row" md={8} xs={24}>
                  <Form.Item
                    label="Time and Date"
                    name="dateType"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                      placeholder="Please Select"
                      onChange={handleTimeAndDate}
                    >
                      <Option value={1}>Last One Hour</Option>
                      <Option value={2}>Yesterday</Option>
                      <Option value={3}>Last Week</Option>
                      <Option value={4}>Last Month</Option>
                      <Option value={5}>Custom Dates</Option>
                    </Select>
                  </Form.Item>
                </Col>

                {isDateDisplay ? (
                  <>
                    <Col className="gutter-row" md={8} xs={24}>
                      <Form.Item
                        label="From Date"
                        name="fromDate"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          placeholder="Select From date"
                          format={dateFormat}
                          onChange={(date, dateString) =>
                            onChangeFromDate(date, dateString)
                          }
                          disabledDate={disabledFromDate}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" md={8} xs={24}>
                      <Form.Item
                        label="To Date"
                        name="toDate"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          placeholder="Select To date"
                          format={dateFormat}
                          disabledDate={disabledToDate}
                          onChange={(date, dateString) =>
                            onChangeToDate(date, dateString)

                          }
                        />
                      </Form.Item>
                    </Col>
                  </>
                ) : null}
              </Row>
              <Row className="reports-btns">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => setShowTable(true)}
                >
                  Search
                </Button>
                <Button type="danger" onClick={() => form.resetFields()}>
                  Reset
                </Button>
              </Row>
            </Form>
          </Card>
        </div>
        <div className="card-bt-gap">
          {showTable && (
            <Card bordered={false}>
              {loading ? (
                <Spin size="large" tip="Loading..." style={{ width: "100%" }} />
              ) : dataSource?.length > 0 ? (
                <>
                  <Row
                    justify="space-between
                  "
                  >
                    <Col md={12} xs={24}>
                      <h5>View Results</h5>
                    </Col>
                    <Col md={12} xs={24}>
                      <div className="action-images">
                        {showSearchBox && (
                          <Input
                            placeholder="Search"
                            onChange={(e) => searchTableData(e)}
                            suffix={closeSearchInput}
                            style={{ padding: "0px 12px", maxWidth: "250px" }}
                          />
                        )}
                        &nbsp;&nbsp;
                        <img
                          src={search}
                          alt="search"
                          onClick={(e) => searchData()}
                        />
                        <img src={excel} alt="excel" onClick={handleExcel} />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={24} xs={24}>
                      <>
                        <Table
                          scroll={{ x: true }}
                          bordered
                          dataSource={dataSource}
                          columns={columns}
                          summary={(pageData) => {
                            let totalCredit = 0;
                            let totalDebit = 0;

                            pageData.forEach(({ Credit, Debit }) => {
                              totalCredit += Number(Credit);
                              totalDebit += Number(Debit);
                            });

                            return (
                              <>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell></Table.Summary.Cell>
                                  <Table.Summary.Cell></Table.Summary.Cell>
                                  <Table.Summary.Cell></Table.Summary.Cell>
                                  <Table.Summary.Cell> </Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <b> Total : </b>
                                  </Table.Summary.Cell>

                                  <Table.Summary.Cell>
                                    <b>Rs. {Number(totalCredit).toFixed(2)}</b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <b>Rs. {Number(totalDebit).toFixed(2)}</b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell> </Table.Summary.Cell>
                                </Table.Summary.Row>
                              </>
                            );
                          }}
                        />
                      </>
                    </Col>
                  </Row>
                </>
              ) : (
                <b>No Results Found!</b>
              )}
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdatedComponent(Statement);
