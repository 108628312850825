import React, { useState, useRef, } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Space,
  TimePicker,
  Modal,
} from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";
import APIClient from "../../helpers/ApiClient";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import AirlineAutoCompleteSelect from "../../common/AutoCompleteSelect/AirlineAutoCompleteSelect";
import CountryList from "../../common/CountryList";
import Thankyou from "../Thankyou/Thankyou";
import {
  CheckCircleOutlined,
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  MailOutlined,
} from "@ant-design/icons";
import Slider from "react-slick";
import "./Flightenquiry.scss";

const FlightEnquiry = () => {
  const { Option } = Select;
  const [isReturn, setIsReturn] = useState(null);
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm";
  const [toDisableDate, setToDisableDate] = useState(moment());
  const [visible, setVisible] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [weekendDate, setWeekendDate] = useState(null);
  const [multicity, setmulticity] = useState(null);
  const [citiesAndAirlines, setCitiesAndAirlines] = useState([
    {
      sourceCity: "",
      destinationCity: "",
      preferredClass: "",
      departureDate: null,
      departureTime: null,
      flightNumber: "",
      airline: "",
    },
  ]);
  const handleAddCityPair = () => {
    if (citiesAndAirlines.length < 4) {
      const newCityPair = {
        sourceCity: "",
        destinationCity: "",
        preferredClass: "",
        departureDate: null,
        departureTime: null,
        airline: "",
      };
      setCitiesAndAirlines([...citiesAndAirlines, newCityPair]);
    } else {
      message.warning("You can only add up to 4 city pairs.");
    }
  };

  const handleDeleteCityPair = (index) => {
    const updatedCityPairs = [...citiesAndAirlines];
    updatedCityPairs.splice(index, 1);
    setCitiesAndAirlines(updatedCityPairs);
  };

  const validateMessages = {
    required: "",
  };
  const disabledSelectedDate = (currentDate) => {
    return currentDate < moment(toDisableDate).startOf("day");
  };
  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }
  const onChangeToDate = (current, dateString) => {
    setWeekendDate(dateString);
  };
  const submitForm = (value) => {
    if (isReturn == null) {
      message("Select one Way or Return option", 3);
      return;
    }

    let req = {
      ServiceType: 7,
      CarType: 0,
      CarCapacity: 0,
      Occasion: 0,
      BusType: 0,
      VisitPurpose: 0,
      BookingCurrencyFor: 0,
      Name: value.Name,
      Email: value.Email,
      ISDCode: value.ISDCode,
      ContactNumber: value.ContactNumber,
      AlternateNumber: "",
      TripType: value.TripType,
      SourceCity: value.SourceCity,
      DestinationCity: value.DestinationCity,
      PreferredClass: value.PreferredClass,
      DepartureDate: value.DepartureDate,
      DepartureTime: value.DepartureTime,
      PickUpLocation: "",
      DropLocation: "",
      EndDate: "",
      EndTime: "",
      ReturnDate: value.ReturnDate ? value.ReturnDate : "",
      ReturnTime: value.ReturnTime ? value.ReturnTime : "",
      OnwardFlightNumber: value.OnwardFlightNumber,
      OnwardFilghtName: value.OnwardFilghtName,
      ReturnFlightNumber: value.ReturnFlightNumber
        ? value.ReturnFlightNumber
        : "",
      ReturnFilghtName: value.ReturnFilghtName ? value.ReturnFilghtName : "",
      NumberOfPassengers: value.NumberOfPassengers,
      Remarks: value.Remarks,
      CreatedBy: 0,
      ModifiedBy: 0,
      NumberOfBuses: 0,
      Currency: "",
      Quantity: "",
      Amount: 0,
    };

    APIClient.post("admin/offlineEnquiry", req)
      .then((response) => {
        if (response.status == 200) {
          setVisible(true);
          form.resetFields();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => { });
  };

  const handleIsReturn = ({ value }) => {
    if (value == "roundTrip") {
      setIsReturn(true);
    } else if (value == "Multi-City") {
      setmulticity(true);
    } else {
      setIsReturn(false)
      setmulticity(false)
    }
  };
  const mobile = useRef();
  const mobileRef = useRef(null);
  const areaCodeRef = useRef(null);
  const handleAreaCodeChange = () => {
    if (mobileRef.current) {
      mobileRef.current.focus();
    }
  };
  const handleOk = () => {
    setVisible(false);
  };
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    infinite: true,
    arrows: true,
  };

  return (
    <>
      {visible ? (
        <Thankyou />
      ) : (
        <div className="manage-markup-section-target flight-enquiry-24">
          <div className="container">
            <Slider {...settings} className="my-4">
              <div className="slider-cards">
                <div
                  style={{
                    background: "linear-gradient(to right,#bd0c21, #2c77fff5)", height: 215,
                  }}
                >
                  <p>
                    A customized fare quote to give you savings over the
                    available fares
                  </p>
                </div>
              </div>
              <div className="slider-cards">
                <div
                  style={{
                    background: " #2c77fff5", height: 215

                  }}
                >
                  <p>
                    Flexibility to add names up to 7 days before the journey
                  </p>
                </div>
              </div>
              <div className="slider-cards">
                <div
                  style={{
                    background: "linear-gradient(to left,#bd0c21, #2c77fff5)", height: 215
                  }}
                >
                  <p>
                    A convenient interface to book, make payment and add name of
                    travellers
                  </p>
                </div>
              </div>

            </Slider>

            <Form
              layout="vertical"
              form={form}
              onFinish={submitForm}
              initialValues={{ ISDCode: "+91" }}
              validateMessages={validateMessages}
            >
              <Card className="cardRounded">
                <Row gutter={24}>
                  <Col md={8} xs={24}>
                    <Form.Item
                      label="Name"
                      name="Name"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={8} xs={24}>
                    <Form.Item
                      label="Email"
                      name="Email"
                      rules={[{ required: true }, { type: "email" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col md={8} xs={24}>
                    <Form.Item
                      label="Contact Number"
                      name="ContactNumber"
                      className="phno"
                      rules={[
                        {
                          required: true,
                          message: "Phone Number  Required",
                        },

                      ]}
                    >
                      <Input
                        ref={mobileRef}
                        addonBefore={
                          <Form.Item
                            style={{ width: "35%" }}
                            name="ISDCode"
                            className="phno"
                            rules={[
                              {
                                required: true,
                                message: "Phone Number Code Required",
                              },
                            ]}
                            noStyle
                          >
                            <Select
                              showSearch
                              style={{ width: "80px" }}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={handleAreaCodeChange}
                              ref={areaCodeRef}
                              focusRef={mobile}
                            >
                              {CountryList.map((item) => (
                                <Option
                                  key={item.dial_code}
                                  value={item.dial_code}
                                >
                                  {item.dial_code}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        }
                        className="inputbg"
                        placeholder="Enter Mobile No."
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <div className="card-gap">
                <Card bordered={false}>
                  <h5 style={{ color: "#bd0c21" }}>Itinerary Details</h5>
                  <Row gutter={[16, 8]}>
                    <Col md={24}>
                      <Form.Item
                        label="Trip Type"
                        name="TripType"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Radio.Group
                          onChange={({ target }) => handleIsReturn(target)}
                        >
                          <Radio value="oneWay">One Way</Radio>
                          <Radio value="roundTrip">Round Trip</Radio>
                          <Radio value="Multi-City">Multi-City</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  {!multicity && (
                    <Row gutter={24}>
                      <Col md={6} xs={24}>
                        <AutoCompleteSelect
                          formItemProps={{
                            label: "Source City",
                            name: "SourceCity",
                            rules: [{ required: true }],
                          }}
                          selectProps={{ placeholder: "Select Source" }}
                          api={"flights/updatedAirPort/search/"}
                          keytext="nameAirport"
                          keyvalue="codeIataAirport"
                        />
                      </Col>
                      <Col md={6} xs={24}>
                        <AutoCompleteSelect
                          formItemProps={{
                            label: "Destination City",
                            name: "DestinationCity",
                            rules: [{ required: true }],
                          }}
                          selectProps={{ placeholder: "Select Destination" }}
                          api={"flights/updatedAirPort/search/"}
                          keytext="nameAirport"
                          keyvalue="codeIataAirport"
                        />
                      </Col>
                      <Col md={6} xs={24}>
                        <Form.Item
                          label="Preferred Class"
                          name="PreferredClass"
                          rules={[{ required: true }]}
                        >
                          <Select placeholder="Select Class">
                            <Option value="Economy">Economy</Option>
                            <Option value="premiumEconomy">
                              Economy Premium
                            </Option>
                            <Option value="Business">Business</Option>
                            <Option value="first">First Class</Option>
                            <Option value="premiumFirst">
                              Premium First Class
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={6} xs={24}>
                        <Form.Item
                          label="Departure Date"
                          name="DepartureDate"
                          rules={[{ required: true }]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format="DD-MM-YYYY"
                            disabledDate={disabledSelectedDate}
                            onChange={(date, dateString) =>
                              onChangeToDate(date, dateString)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col md={6} xs={24}>
                        <Form.Item
                          label="Departure Time"
                          name="DepartureTime"
                          rules={[{ required: true }]}
                        >
                          <TimePicker
                            style={{ width: "100%" }}
                            format={timeFormat}
                            placeholder="--:--"
                          />
                        </Form.Item>
                      </Col>

                      {isReturn ? (
                        <>
                          <Col md={6} xs={24}>
                            <Form.Item
                              label="Return Date"
                              name="ReturnDate"
                              rules={[{ required: true }]}
                            >
                              <DatePicker
                                style={{ width: "100%" }}
                                format={dateFormat}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={6} xs={24}>
                            <Form.Item
                              label="Return Time"
                              name="ReturnTime"
                              rules={[{ required: true }]}
                            >
                              <TimePicker
                                style={{ width: "100%" }}
                                format={timeFormat}
                                placeholder="--:--"
                              />
                            </Form.Item>
                          </Col>
                        </>
                      ) : null}
                      <Col md={6} xs={24}>
                        <Form.Item
                          label="Flight Number"
                          name="OnwardFlightNumber"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      {isReturn ? (
                        <Col md={6} xs={24}>
                          <Form.Item
                            label="Return Flight Number"
                            name="ReturnFlightNumber"
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      ) : null}

                      <Col md={6} xs={24}>
                        <AirlineAutoCompleteSelect
                          formItemProps={{
                            label: "Airline",
                            name: "OnwardFilghtName",
                            rules: [{ required: true }],
                          }}
                          selectProps={{ placeholder: "Search Airline Name" }}
                          api={"flights/airlineNames/search/"}
                          keytext="nameAirline"
                          keyvalue="codeIataAirline"
                        />
                      </Col>
                      {isReturn ? (
                        <Col md={6} xs={24}>
                          <AirlineAutoCompleteSelect
                            formItemProps={{
                              label: "Return Airline",
                              name: "ReturnFilghtName",
                              rules: [{ required: true }],
                            }}
                            selectProps={{ placeholder: "Search Airline Name" }}
                            api={"flights/airlineNames/search/"}
                            keytext="nameAirline"
                            keyvalue="codeIataAirline"
                          />
                        </Col>
                      ) : null}
                      <Col md={6} xs={24}>
                        <Form.Item
                          label="Number of Passengers"
                          name="NumberOfPassengers"
                          rules={[{ required: true }]}
                        >
                          <InputNumber
                            className="contacts-value-de"
                            min={1}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {multicity && (
                    <>
                      {citiesAndAirlines.map((cityAndAirline, index) => (
                        <div key={index}>
                          <Row gutter={24}>
                            <Col md={6} xs={24}>
                              <AutoCompleteSelect
                                formItemProps={{
                                  label: "Source City",
                                  name: `SourceCity[${index}]`,
                                  rules: [{ required: true }],
                                }}
                                selectProps={{ placeholder: "Select Source" }}
                                api={"flights/updatedAirPort/search/"}
                                keytext="nameAirport"
                                keyvalue="codeIataAirport"
                              />
                            </Col>
                            <Col md={6} xs={24}>
                              <AutoCompleteSelect
                                formItemProps={{
                                  label: "Destination City",
                                  name: `DestinationCity[${index}]`,
                                  rules: [{ required: true }],
                                }}
                                selectProps={{
                                  placeholder: "Select Destination",
                                }}
                                api={"flights/updatedAirPort/search/"}
                                keytext="nameAirport"
                                keyvalue="codeIataAirport"
                              />
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Preferred Class"
                                name={`PreferredClass[${index}]`}
                                rules={[{ required: true }]}
                              >
                                <Select placeholder="Select Class">
                                  <Option value="Economy">Economy</Option>
                                  <Option value="premiumEconomy">
                                    Economy Premium
                                  </Option>
                                  <Option value="Business">Business</Option>
                                  <Option value="first">First Class</Option>
                                  <Option value="premiumFirst">
                                    Premium First Class
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Departure Date"
                                name={`DepartureDate[${index}]`}
                                rules={[{ required: true }]}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  format="DD-MM-YYYY"
                                  disabledDate={disabledSelectedDate}
                                  onChange={(date, dateString) =>
                                    onChangeToDate(date, dateString)
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Departure Time"
                                name={`DepartureTime[${index}]`}
                                rules={[{ required: true }]}
                              >
                                <TimePicker
                                  style={{ width: "100%" }}
                                  format={timeFormat}
                                  placeholder="--:--"
                                />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <Form.Item
                                label="Flight Number"
                                name="OnwardFlightNumber"
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col md={6} xs={24}>
                              <AirlineAutoCompleteSelect
                                formItemProps={{
                                  label: "Airline",
                                  name: `Airline[${index}]`,
                                  rules: [{ required: true }],
                                }}
                                selectProps={{
                                  placeholder: "Search Airline Name",
                                }}
                                api={"flights/airlineNames/search/"}
                                keytext="nameAirline"
                                keyvalue="codeIataAirline"
                              />
                            </Col>
                          </Row>

                          {index > 0 && (
                            <Row justify="end">
                              <Button
                                type="danger"
                                onClick={() => handleDeleteCityPair(index)}
                              >
                                Delete
                              </Button>
                            </Row>
                          )}
                        </div>
                      ))}
                      <Row justify="end">
                        <Button type="primary" onClick={handleAddCityPair}>
                          Add
                        </Button>
                      </Row>
                    </>
                  )}
                  {!multicity && (<>
                    <Row gutter={[0, 16]}>
                      <Col md={10} xs={24}>
                        <Form.Item
                          label="Remarks"
                          name="Remarks"
                          rules={[{ required: true }]}
                        >
                          <Input.TextArea rows={3} placeholder="....." />
                        </Form.Item>
                      </Col>

                    </Row>
                  </>)}
                  {multicity && (<>
                    <Row gutter={[24]}>
                      <Col md={6} xs={24}>
                        <Form.Item
                          label="Number of Passengers"
                          name="NumberOfPassengers"
                          rules={[{ required: true }]}
                        >
                          <InputNumber
                            className="contacts-value-de"
                            min={1}
                            style={{ width: "100%", paddingRight: 20 }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={6} xs={24}>
                        <Form.Item
                          label="Remarks"
                          name="Remarks"
                          rules={[{ required: true }]}
                        >
                          <Input.TextArea rows={3} placeholder="....." />
                        </Form.Item>
                      </Col>


                    </Row>
                  </>)}

                  <Row justify="center" className="my-2">
                    <Space size="middle">
                      <Button
                        type="primary"
                        onClick={() => form.submit()}
                      >
                        Submit
                      </Button>
                      <Button type="primary" danger>
                        Cancel
                      </Button>
                    </Space>
                  </Row>
                </Card>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default FlightEnquiry;
