import React, { useState, useCallback, useEffect } from "react";
import { Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import defaultimage from "../../assets/images/Icons/defaultflagicon.png";
import { Form } from "antd";
import { useLocalStorage } from "../../helpers/useStorage";


const API_URL = "flights/updatedAirPort/search/";
const AirportAutoCompleteSelectByCategory = (props) => {
  const [topCities, settopCities] = useState([]);
  useEffect(() => {
    let value = "";
    if (props.isfieldKey) {
      value = props.refName.current[props.fieldKey].props.value;
    } else {
      value = props.refName.current.props.value;
    }

    if (!value || value == "" || value == " ") {
      return;
    }

    fetchData(value);
  }, [
    props.isfieldKey
      ? props.refName.current[props.fieldKey]
      : props.refName.current,
    props.dataFlip,
  ]);

  const check = () => {
    if (props.focusRef) {
      if (props.isfieldKey)
        props.handleOnSubmit(props.focusRef, props.isfieldKey, props.fieldKey);
      else props.handleOnSubmit(props.focusRef);
    }
  };
  const { Option, OptGroup } = Select;
  const [options, setOptions] = useState({
    data: [],
    value: [],
    fetching: false,
  });
  const debounceOnChange = useCallback(debounce(fetchData, 400), []);

  const createOptions = (results) => {
    let arr = [];

    results.forEach((result) => {
      arr.push({
        value: result._source.code,
        display: result._source.suggestionWithTags,
        _class: "parent",
        label: result.suggestion,
      });

      if (result._type == "multiport") {
        result._source.airports_data.forEach((result_2) => {
          arr.push({
            value: result_2._source.code,
            display: result_2._source.suggestionWithTags,
            _class: "child",
            label: result_2.suggestion,
          });
        });
      }
    });

    return arr;
  };
  function fetchData(value) {
    setOptions({ data: [], fetching: true });
    if (value == "" || value == " ") {
      return;
    }

    APIClient.get(`${API_URL}${value}`)
      .then((res) => {

        if (res.statusCode == 200 && res) {
          setOptions({ data: res.data, fetching: false });

          let copy = [];

          settopCities(copy)
          return;
        }
      })
      .catch((error) => {
        setOptions({ data: [], fetching: false });
      });
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }
  const [FlightRecentSearches, setFlightRecentSearches] = useLocalStorage(
    props.recentKey,
    []
  );

  const recentSearches = (e) => {
    if (e && options?.data?.length) {
      const optionObj = options.data.find((item) => item.AirportCode == e);
      if (optionObj) {
        if (FlightRecentSearches?.length > 0) {
          let array = [];
          array = [...FlightRecentSearches];
          if (array?.length > 4) {
            array.pop();
          }

          if (optionObj) {
            setFlightRecentSearches([
              optionObj,
              ...array.filter((item) => item.AirportCode !== e),
            ]);
          }

          return;
        }

        setFlightRecentSearches([optionObj]);
      }
    } else {
      setFlightRecentSearches([]);
    }
  };
  const getflag = (code) => {
    if (code === "yu" || code === "tp") {
      let codeflag = defaultimage;
      return codeflag;
    } else {
      let codeflag = require(`../../assets/country-flag-svg/${code}.svg`).default;
      return codeflag;
    }
  }
  return (
    <>
      <Form.Item
        {...props.formItemProps}
        className="airport_auto_complete notranslate"
      >
        <Select
          style={{ width: "100%" }}
          showSearch
          ref={(el) => {
            props.isfieldKey
              ? (props.refName.current[props.fieldKey] = el)
              : (props.refName.current = el);
          }}
          notFoundContent={options.fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={debounceOnChange}
          onChange={props.onChange}
          {...props.selectProps}
          onSelect={(e) => {
            check();
            recentSearches(e);
          }}
          dropdownClassName={"airpot_names_dropdown"}
          dropdownStyle={{ width: "100%" }}
          className="notranslate"
        >

          {topCities?.length && (
            <OptGroup
              label={`Top ${props?.formItemProps?.name === "destination"
                ? "Destination"
                : "Origin"
                } Cities`}
            >
              {topCities.map((item, index) => {
                if (props?.formItemProps?.name === "destination") {
                  return (
                    <Option
                      key={"topcitydest" + index}
                      value={item?.destination?.split("-")[2]}
                    >
                      <div className="d-flex align-items-center justify-content-between  ">
                        <i class="fa fa-plane forplace-wor-dropdownflight"></i>
                        <div>
                          {" "}
                          <p className="m-0">
                            {item.destination.split("-")[0]},{" "}
                            {item.destination.split("-")[1]}
                          </p>
                          <p
                            className="m-0 city-column"
                            style={{ fontSize: "11px", color: "#9B9B9B" }}
                          >
                            {item.destination.split("-")[3]}
                          </p>
                        </div>
                        <div className="city-column">
                          <p className="m-0" style={{ color: "#9B9B9B" }}>
                            {item.destination.split("-")[2]}
                          </p>
                        </div>
                      </div>
                    </Option>
                  );
                } else {
                  return (
                    <Option
                      key={"topcityori" + index}
                      value={item?.origin?.split("-")[2]}
                    >
                      <div className="d-flex align-items-center justify-content-between  ">
                        <i class="fa fa-plane forplace-wor-dropdownflight"></i>
                        <div>
                          {" "}
                          <p className="m-0">
                            {item.origin.split("-")[0]},{" "}
                            {item.origin.split("-")[1]}
                          </p>
                          <p
                            className="m-0 city-column"
                            style={{ fontSize: "11px", color: "#9B9B9B" }}
                          >
                            {item.origin.split("-")[3]}
                          </p>
                        </div>
                        <div className="city-column">
                          <p className="m-0" style={{ color: "#9B9B9B" }}>
                            {item.origin.split("-")[2]}
                          </p>
                        </div>
                      </div>
                    </Option>
                  );
                }
              })}
            </OptGroup>
          )}
          {options?.data?.length && (
            <OptGroup label="Search Results">
              {options.data.map((d, index) => (
                <Option
                  className="notranslate"
                  value={d.AirportCode}
                  key={"key" + index}
                >

                  <div className="d-flex align-items-center justify-content-between  ">
                    <i class="fa fa-plane forplace-wor-dropdownflight"></i>
                    <div className="for-elepsis-work-dropdownflight">
                      {" "}
                      <p className="m-0" style={{ fontWeight: '500', fontSize: '14px' }} >
                        {d.City}, {d.Country}
                      </p>
                      <p
                        className="m-0 city-column"
                        style={{ fontSize: "10px", color: "#9B9B9B" }}
                      >
                        {d.AirportDesc}
                      </p>
                    </div>
                    <div className="city-column">
                      <p className="" style={{ color: "#9B9B9B", marginTop: '11px' }}>
                        {d.AirportCode}
                        &nbsp;
                        <img

                          src={d?.CountryCode ? getflag(d?.CountryCode?.toLowerCase()) : ""}

                          width="20"
                          alt={d?.Country}></img>

                      </p>
                    </div>
                  </div>


                </Option>
              ))}
            </OptGroup>
          )}
        </Select>
      </Form.Item >
    </>
  );
};

export default AirportAutoCompleteSelectByCategory;
