import {
  Row,
  Rate,
  Col,
  Card,
  Progress,
  Avatar,
  Button,
  Modal,
  Image,
} from "antd";
import React, { useState, useEffect } from "react";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";
import ReviewImage from "../../assets/images/reviewImage.png";

const Review = ({ serviceType }) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const [pagination, SetPagination] = useState(5);
  const [userReviewList, setUserReviewlist] = useState([]);
  const [imageModal, setImageModal] = useState(false);
  const [reviewImages, setReviewImages] = useState([]);
  const showMore = () => {
    SetPagination((prev) => prev + 5);
  };

  const getUserRatingReviews = (type) => {
    setUserReviewlist([]);
    ApiClient.get(`UserReviews/userReviews/report?services=${type}&status=1`)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setUserReviewlist(data);
        } else {
          setUserReviewlist([]);
        }
      })
      .catch(() => {
        setUserReviewlist([]);
      });
  };

  useEffect(() => {
    getUserRatingReviews(serviceType);
  }, []);

  return userReviewList.length > 0 ? (
    <div className="container">
      <Row>
        <Col md={24} xs={24}>
          <h2 className="top-fl-route">Reviews</h2>
        </Col>
      </Row>
      <div className="sec-bg-top">
        <Row>
          <Col md={24} xs={24}>
            <Card>
             
              {userReviewList.map((review, index) => {
                return index < pagination ? (
                  <Row gutter={6}>
                    <Col md={4} xs={24}>
                      <img
                        width={140}
                        src={
                          review?.ReviewImages.length > 0
                            ? BASE +
                              review?.ReviewImages[0]?.ImagePath.substring(1)
                            : ReviewImage.substring(1)
                        }
                        style={{ cursor: "pointer", borderRadius: "50%" }}
                        onClick={(e) => {
                          setReviewImages(review?.ReviewImages);
                          setImageModal(true);
                        }}
                      />
                     
                      <p className="mb-0">
                        <span>
                          {moment(review.CreatedDate).format("MMM DD-YYYY")}
                        </span>
                      </p>
                    </Col>
                    <Col md={16} xs={24}>
                      <h5>{review.EmailId}</h5>
                      <p>{review.Comments}</p>
                    </Col>
                    <Col md={4} xs={24}>
                      <p>{review.StartRating}/5</p>
                      {review.StartRating && (
                        <div className="hotel-star">
                          <Rate
                            className="starRating"
                            disabled
                            value={Number(review.StartRating)}
                            allowHalf={true}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                ) : index === pagination ? (
                  <div className="showmore-wrapper">
                    <Button onClick={showMore}>Show More</Button>
                  </div>
                ) : null;
              })}
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        visible={imageModal}
        title="Photos"
        onCancel={(e) => {
          setImageModal(false);
        }}
        footer={false}
      >
        <Row gutter={12}>
          {reviewImages.map((image, index) => (
            <Col md={8} sm={8} xs={12}>
              <Image src={BASE + image.ImagePath.substring(1)} />
            </Col>
          ))}
        </Row>
      </Modal>
    </div>
  ) : null;
};

export default Review;
