import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Switch,
  Table,
 
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import ApiClient from "../../helpers/ApiClient";
import "antd/dist/antd.css";
import UpdatedComponent from "../ExportExcel";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

import { useAuthContext } from "../../common/providers/AuthProvider";
import VerifyPasswordModal from "../../common/VerifyPasswordModal/VerifyPasswordModal";
const { Content } = Layout;
const { Option } = Select;

const ProviderApi = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [apiProviders, setApiProviders] = useState([]);
  const [providers, SetProviders] = useState([]);
  const [serviceType, SetServiceType] = useState(0);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showUpdateBtnFlag, setShowUpdateBtnFlag] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [isCancelType, setIsCancelType] = useState(false);
  const [apiProviderForm] = Form.useForm();

  const { user } = useAuthContext();
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [formType, setFormType] = useState(-1);

  const [curreRec, setCurreRec] = useState({});
  const [curreID, setCurreID] = useState(-1);

  const handleIsCancel = (e) => {
    if (e.target.value === 1) setIsCancelType(true);
    else setIsCancelType(false);
  };

  useEffect(() => {
    getAPIProvidersList();
    getProvidersList();
  }, []);

  const getAPIProvidersList = () => {
    ApiClient.get("admin/apiProviders")
      .then((res) => {
        if (res.statusCode == 200) {
          setApiProviders(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getProvidersList = () => {
    ApiClient.get("admin/providers")
      .then((resp) => {
        if (resp.statusCode == 200) {
          SetProviders(resp.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /* ========== ADD APIProvider Start ========= */
  const onFinish = (values) => {
    showUpdateBtnFlag ? putAPiProvider(values) : postAPiProvider(values);
  };

  /* ========= Edit / update apiProvider ========== */

  const postAPiProvider = (values) => {
    let Trip_Type = values.ServiceType === 1 ? values.TripType.toString() : "";
    let data = {
      ProviderID: values.ProviderID,
      ServiceType: values.ServiceType,
      APIURL: values.apiUrl,
      APIKey: values.apiConsumerKey,
      APISecretKey: values.apiConsumerSecretKey,
      TripType: [Trip_Type],
      Status: values.Status,
      AutoTicketing: values.AutoTicketing,
      DeviceOS: 3,
      ApplicationType: 1,
      CreatedBy: 0,
      DeviceOSVersion: "",
      DeviceToken: "",
      FirBaseToken: "",
      BookingLimit: values.BookingLimit,
      CancellationPossible:
        values.ServiceType === 1 ? values?.CancellationPossible : null,
      CancellationTypes:
        values.ServiceType === 1 ? values.CancellationTypes : null,
    };

    ApiClient.post("admin/apiProviders", data)
      .then((res) => {
        if (res.statusCode == 200) {
          getAPIProvidersList();
          setModalVisible(false);
          apiProviderForm.resetFields();
          message.success("Successfully added api Provider", 3);
        }
        if (res.status == 400) {
          message.error("Record Already Exists!", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const putAPiProvider = (values) => {
    let apiPID = currentRecord.APIProviderID;
    let Trip_Type = values.ServiceType === 1 ? values.TripType.toString() : "";
    let editObj = {
      ServiceType: values.ServiceType,
      APIURL: values.apiUrl,
      APIKey: values.apiConsumerKey,
      APISecretKey: values.apiConsumerSecretKey,
      TripType: [Trip_Type],
      Status: values.Status,
      AutoTicketing: values.AutoTicketing,
      ProviderID: values.ProviderID,
      DeviceOS: 3,
      ApplicationType: 1,
      CreatedBy: 0,
      DeviceOSVersion: "",
      DeviceToken: "",
      FirBaseToken: "",
      BookingLimit: values.BookingLimit,
      CancellationPossible:
        values.ServiceType === 1 ? values.CancellationPossible : null,
      CancellationTypes:
        values.ServiceType === 1 ? values.CancellationTypes : null,
    };

    ApiClient.put(`admin/apiProviders/${apiPID}`, {}, editObj)
      .then((response) => {
        if (response.statusCode == 200) {
          getAPIProvidersList();
          setModalVisible(false);
          message.success("API Provider Updated successfully", 3);
        } else if (response.statusCode == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    // }
  };

  function handelEdit(cur_Record) {
    setCurrentRecord(cur_Record);
    setShowUpdateBtnFlag(true);
    SetServiceType(cur_Record.ServiceType);
    setIsCancelType(Number(cur_Record?.CancellationPossible) === 1);
    let canType = cur_Record?.CancellationTypes
      ? cur_Record?.CancellationTypes.split("~")
      : [];
    canType = canType.map((item) => parseInt(item, 10));

    apiProviderForm.setFieldsValue({
      ServiceType: cur_Record.ServiceType,
      ProviderID: cur_Record.ProviderID?.ProviderID,
      apiUrl: cur_Record.APIURL,
      apiConsumerKey: cur_Record.APIKey,
      apiConsumerSecretKey: cur_Record.APISecretKey,
      TripType: JSON.parse("[" + cur_Record.TripType + "]"),
      AutoTicketing: cur_Record.AutoTicketing,
      Status: cur_Record.Status,
      CancellationPossible: cur_Record?.CancellationPossible
        ? Number(cur_Record?.CancellationPossible)
        : "",
      CancellationTypes: canType,
      BookingLimit: cur_Record?.BookingLimit,
    });
    setModalVisible(true);
  }

  const tableActions = (c_Record) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => handelEdit(c_Record)}
            style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => {
              setFormType(2);
              setVerifyModalVisible(true);
              setCurreRec(c_Record);
            }}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const deleteAPIProvider = (record) => {
    var Id = record.APIProviderID;
    ApiClient.delete("admin/apiProviders/" + Id)
      .then((res) => {
        if (res.statusCode == 200) {
          getAPIProvidersList();
          message.success("API Provider Deleted successfully", 3);
        }
      })
      .catch((e) => {
        console.log("delete api error", e);
       });
  };

  function chooseServiceType(type) {
    switch (type) {
      case 1:
        return "Flight";
      case 2:
        return "Hotel";
      
      default:
        return null;
    }
  }

  function chooseTripType(res) {
    let result = "";
    if (res.indexOf("1") != -1) {
      result += "OneWay,";
    }
    if (res.indexOf("2") != -1) {
      result += " Round-Trip,";
    }

    if (res.indexOf("3") != -1) {
      result += " Multicity";
    }
    return result;
  }

  function chooseProviderName(pName) {
    return pName;
  }

  const columns = [
    {
      title: "Service",
      render: (record) => chooseServiceType(record.ServiceType),
      sorter: (a, b) =>
        a.ServiceType.toString().localeCompare(b.ServiceType.toString()),
    },
    {
      title: "API Provider Name",
      render: (record) => chooseProviderName(record.ProviderID?.Provider),
      sorter: (a, b) =>
        a.ProviderID?.Provider.toString().localeCompare(
          b.ProviderID?.Provider.toString()
        ),
    },
    {
      title: "Trip Type",
      render: (record) => chooseTripType(record.TripType),
    },
    {
      title: "Auto Ticketing",
      dataIndex: "AutoTicketing",
      key: "AutoTicketing",
      render: (val) => (val ? "No" : "Yes"),
    },
    {
      title: "Status",
      render: (status) => handelStatus(status),
    },
    {
      title: "Actions",
      render: (record) => tableActions(record),
    },
  ];

  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => {
            setCurreID(data.APIProviderID);
            setVerifyModalVisible(true);
            setFormType(3);
          }}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => {
            setCurreID(data.APIProviderID);
            setVerifyModalVisible(true);
            setFormType(4);
          }}
        />
      );
    }
  };

  const changeStatus = (data, status) => {
    let editObj = {
      Id: data,
      status: status,
    };

    ApiClient.put(`admin/updateStatus`, {}, editObj)
      .then((response) => {
        if (response.statusCode == 200) {
          message.success("Status Update Successfully.", 3);
          getAPIProvidersList();
        } else if (response.statusCode == 400) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /***** Radio-btn*****/

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;

    if (searchVal.length > 0) {
      const searchRes = apiProviders.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setApiProviders(searchRes);
    } else {
      getAPIProvidersList();
    }
  };

  const handleShowAddModal = () => {
    apiProviderForm.resetFields();
    SetServiceType(1);
    setIsCancelType(false);
    setShowUpdateBtnFlag(false);
    setModalVisible(true);
  };

  /* ============ Implement search =========== */

  /* =========== excel start ========== */

  const handleExcel = (e) => {
    const excelCustomData = apiProviders.map((item) => {
      let serName = chooseServiceType(item.ServiceType);
      let APIPName = chooseProviderName(item.ProviderID.Provider);
      let tripName = chooseTripType(item.TripType);
      const obj = {
        Service: serName,
        API_Provider_Name: APIPName,
        Trip_Type: tripName,
        API_URL: item.APIURL,
        APIKey: item.APIKey,
        APISecretKey: item.APISecretKey,
      };
      return obj;
    });
    props.exportExcel(excelCustomData, "APIProviders");
  };

  /* =========== excel END ========== */

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };

  const handleVerifyPassword = (values) => {
    ApiClient.post("admin/verifyTransactionPassword", {
      UserID: user.UserID,
      Password: values,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          message.success(res.message);

          if (formType == 1) {
            apiProviderForm.submit();
          } else if (formType == 2) {
            deleteAPIProvider(curreRec);
          } else if (formType == 3) {
            changeStatus(curreID, 0);
          } else if (formType == 4) {
            changeStatus(curreID, 1);
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };
  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <Content className="admin-container">
            <Row>
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    <div className="card-add-heading">
                      <div className="rows-count">
                        <div>
                          <h5>
                            View Providers Configuration{" "}
                            <HelpInfoHelper screenName={"/admin/provider confihuration"} />
                          </h5>
                          <p>{apiProviders.length} rows found !</p>
                        </div>
                      </div>
                      <div className="action-images">
                        {showSearchBox && (
                          <Input
                            placeholder="Search"
                            onChange={(e) => searchTableData(e)}
                            suffix={closeSearchInput}
                            style={{ padding: "0px 12px" }}
                          />
                        )}
                        &nbsp;&nbsp;
                        <img
                          src={search}
                          alt="search"
                          onClick={(e) => searchData(apiProviders)}
                        />
                        <img
                          src={excel}
                          alt="excel"
                          onClick={(e) => handleExcel(e)}
                        />
                        <p className="add-deposit-icon">
                          <PlusCircleFilled onClick={handleShowAddModal} />
                        </p>
                      </div>
                    </div>

                    <div>
                      <Table
                        className="table-scroll-none"
                        size="small"
                        bordered
                        rowKey={uniqueKey}
                        dataSource={apiProviders}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
          </Content>
        </div>

        <Modal
          title={showUpdateBtnFlag ? "Update Provider" : "Provider configuration"}
          className="promo-modal-header admin-bc-level modal-header-bg12"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <Button key="back" onClick={() => setModalVisible(false)}>
              Cancel
            </Button>,
            showUpdateBtnFlag ? (
              <Button
                type="primary"
                onClick={() => {
                  setVerifyModalVisible(true);
                  setFormType(1);
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  setVerifyModalVisible(true);
                  setFormType(1);
                }}
              >
                Add
              </Button>
            ),
          ]}
          width={"1140px"}
        >
          <Form
            form={apiProviderForm}
            layout="vertical"
            name="basic"
            onFinish={onFinish}
            initialValues={{
              ServiceType: 1,
            }}
          >
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Form.Item
                  name="ServiceType"
                  label="Service"
                  rules={[
                    { required: true, message: "Please choose  service" },
                  ]}
                >
                  <Select
                    onSelect={(val) => {
                      SetServiceType(val);
                    }}
                  >
                    <Select.Option value={1}>Flights</Select.Option>
                    <Select.Option value={2}>Hotels</Select.Option>
                    <Select.Option value={3}>Holiday</Select.Option>
                    
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  name="ProviderID"
                  label="API Provider"
                  rules={[
                    { required: true, message: "Please Select provider!" },
                  ]}
                >
                  <Select>
                    {providers.length &&
                      providers
                        .filter((item) => item.ServiceType == serviceType)
                        .map((item) => {
                          return (
                            <Option
                              key={item.ProviderID}
                              value={item.ProviderID}
                            >
                              {item.Provider}
                            </Option>
                          );
                        })}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} xs={24}>
                <Form.Item
                  label="API URL"
                  name="apiUrl"
                  rules={[{ required: true, message: "Please Enter URL!" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  label="API Consumer Key"
                  name="apiConsumerKey"
                  rules={[
                    { required: true, message: "Please Enter consumer key!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col md={8} xs={24}>
                <Form.Item
                  label="API Consumer Secret Key"
                  name="apiConsumerSecretKey"
                  rules={[
                    { required: true, message: "Please Enter secret key!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  label="Booking Limit"
                  name="BookingLimit"
                  rules={[{ required: true, message: "Required!" }]}
                >
                  <Input type="tel" />
                </Form.Item>
              </Col>
              {serviceType === 1 ? (
                <Col md={8} xs={24}>
                  <Form.Item
                    name="TripType"
                    label="Trip Type"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Checkbox.Group>
                      <Row>
                        <Checkbox value={1}>One way</Checkbox>
                        <Checkbox value={2}>Round-trip</Checkbox>
                        <Checkbox value={3}>Multicity</Checkbox>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              ) : null}

              <Col md={8} xs={24}>
                <Form.Item
                  name="AutoTicketing"
                  label="Auto Ticketing"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Radio.Group>
                    <Radio value={0}>Yes</Radio>
                    <Radio value={1}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {serviceType === 1 ? (
                <>
                  <Col md={8} xs={24}>
                    <Form.Item
                      name="CancellationPossible"
                      label="Cancellation Possible"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Radio.Group>
                        <Radio onChange={handleIsCancel} value={1}>
                          Yes
                        </Radio>
                        <Radio onChange={handleIsCancel} value={2}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  {isCancelType ? (
                    <Col md={8} xs={24}>
                      <Form.Item
                        name="CancellationTypes"
                        label="Cancellation Types"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Checkbox.Group>
                          <Row>
                            <Checkbox value={1}>Full cancellation </Checkbox>

                            <Checkbox value={2}>Partial cancellation</Checkbox>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  ) : null}
                </>
              ) : null}

              <Col md={8} xs={24}>
                <Form.Item
                  label="Status"
                  name="Status"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Radio.Group>
                    <Radio value={1}>Active</Radio>
                    <Radio value={0}>Inactive</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        {verifyModalVisible && (
          <VerifyPasswordModal
            visible={verifyModalVisible}
            setVerifyModalVisible={setVerifyModalVisible}
            handleVerifyPassword={handleVerifyPassword}
          />
        )}
      </div>
    </div>
  );
};

export default UpdatedComponent(ProviderApi);
