import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import LogoImg from "../../../assets/images/trypmaker.png";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  section: {
    padding: "0 8px",
    color: "#555",
    background: "#FFF",
  },
  header: {
    padding: "7px 0",
    marginBottom: "10px",
  },
  company: { width: "100%", marginBottom: "20px" },
  company_h: {
    textAlign: "center",
    fontSize: "10px",
    fontWeight: "normal",
    margin: 0,
    color: "#E32025",
  },
  company_p: {
    textAlign: "right",
    fontSize: "8px",
    fontWeight: "normal",
    margin: 0,
    color: "#E32025",
  },
  invoice_h: {
    textAlign: "center",
    fontSize: "11px",
    fontWeight: "bold",
    margin: 0,
  },
  invoice: {
    width: "100%",
  },
  invoice_origin_h: {
    fontSize: "9px",
    fontWeight: "normal",
    width: "100%",
    marginBottom: 10,
    color: "#E32025",
  },
  origin: {
    width: "40%",
  },
  origin1: {
    width: "60%",
  },
  origin_title: { width: "30%", padding: "3px 6px" },
  origin_h: {
    fontSize: "8px",
    fontWeight: "bold",
  },
  origin_p: {
    fontSize: "8px",
    fontWeight: "normal",
  },
  origin_title1: { width: "40%", padding: "3px 6px" },
  origin_deatil1: { width: "50%", padding: "3px 6px" },
  table_p: {
    fontSize: "8px",
    fontWeight: "normal",
    marginBottom: 0,
  },
  origin_deatil: { width: "50%", padding: "3px 6px" },
  origin_deatil_right: { width: "50%" },
  origin_title_right: { width: "50%" },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 6,
    flexWrap: "wrap",
  },

  table_head: {
    width: "100%",
    color: "#000",
    border: "1px solid #000",
  },
  table_des: {
    width: "30%",
   
    padding: "0 3px",
  },
  table_pnr: {
    width: "10%",
    borderRight: "1px solid black",

    
  },
  table_pnr1: {
    width: "20%",
    borderRight: "1px solid black",

   
  },

  borderBottom: { borderBottom: "1px solid black" },
  table_p: {
    padding: "2px",
    fontSize: "7px",
    fontWeight: "bolder",
    color: "#000",
  },
  table_p1: {
    padding: "2px",
    fontSize: "7px",
    color: "#525151",
  },
  table_sub_index: {
    width: "10%",

    padding: "0 3px",
  },

  text_right: {
    textAlign: "right",
  },
  table_sub_wrapper: {},

  sub_table1: { marginTop: 10 },
  sub_table: {
    border: "1px solid black",
    width: "60%",
    marginLeft: "auto",
  },

  m_0: {
    margin: 0,
  },
  p_0: {
    padding: 0,
  },
  sub_table_ph: {
    width: "60%",
    fontSize: "8px",
    fontWeight: "normal",
    padding: "3px 6px",
    borderRight: "1px solid black",
  },
  sub_table_pt: {
    width: "40%",
    fontSize: "8px",
    fontWeight: "normal",
    padding: "3px 10px",
  },
 
});
const getTotalPrice = (data) => {
  var Total = data.subTotal;
  if (data?.convienenceData?.amount) {
    if (data.convienenceData.type === 1) {
      Total += data.convienenceData.amount * data.passengers.length;
    } else if (data.convienenceData.type === 0) {
      Total += (Total / 100) * data.convienenceData.amount;
    }
  }
  return Total;
};
const getTax = (data) => {
  var Total = data.totalPrice;
  var subTotal = data.subTotal;
  var tax = Total - subTotal;
  return tax;
};
const HolidayInvoice = ({ invoiceData, logo, type }) => {
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.header}>
            <View style={styles.company}>
              <View style={styles.imgWrapper}>
                {logo ? (
                  <Image
                    style={{ width: "70px" }}
                    src={process.env.REACT_APP_BASE_URL + logo.substring(1)}
                  />
                ) : (
                  <Image style={{ width: "70px" }} src={LogoImg} />
                )}
               
              </View>
            </View>

            <View style={styles.invoice_origin}>
              <Text style={styles.invoice_origin_h}>
                {type === "credit_note"
                  ? "Credit Note Date :"
                  : "Invoice Date :"}
                {moment(invoiceData?.CreatedDate).format("DD/MM/YYYY")}
              </Text>
            </View>
            <View style={{ ...styles.origin_wrapper, ...styles.row }}>
              <View style={{ ...styles.origin, ...styles.row }}>
                <View style={styles.origin_title}>
                  <Text style={styles.origin_h}>To M/s </Text>
                </View>
                <View style={styles.origin_deatil}>
                  <Text style={styles.origin_p}>
                    : {invoiceData?.Name}
                  </Text>
                </View>
                {invoiceData?.cinNo ? (
                  <>
                    <View style={styles.origin_title}>
                      <Text style={styles.origin_h}>CIN No. </Text>
                    </View>
                    <View style={styles.origin_deatil}>
                      <Text style={styles.origin_p}>
                        : {invoiceData?.cinNo ?? ""}
                      </Text>
                    </View>
                  </>
                ) : null}

               
              
              </View>
              <View style={{ ...styles.origin, ...styles.row }}>
                <View style={styles.origin_title_right}>
                  <Text style={styles.origin_h}>Reference No.</Text>
                </View>
                <View style={styles.origin_deatil_right}>
                  <Text style={styles.origin_p}>
                    : {invoiceData?.ReferenceNo}
                  </Text>
                </View>
                <View style={styles.origin_title_right}>
                  <Text style={styles.origin_h}>
                    {type === "credit_note"
                      ? "Credit Note No. "
                      : "Invoice No."}
                  </Text>
                </View>
                <View style={styles.origin_deatil_right}>
                  <Text style={styles.origin_p}>
                    :{" "}
                    {type === "credit_note"
                      ? invoiceData?.creditNoteNo
                      : invoiceData?.invoiceNo}
                  </Text>
                </View>
                <View style={styles.origin_title_right}>
                  <Text style={styles.origin_h}>Email </Text>
                </View>
                <View style={styles.origin_deatil_right}>
                  <Text style={styles.origin_p}>: {invoiceData?.Email}</Text>
                </View>

              </View>
            </View>
           

            <View style={styles.table_head}>
              <View
                style={{ ...styles.row, ...styles.m_0, ...styles.borderBottom }}
              >
                <View style={styles.table_pnr}>
                  <Text style={styles.table_p}>Pax Name</Text>
                </View>
                <View style={styles.table_pnr}>
                  <Text style={styles.table_p}>Tour Name</Text>
                </View>
                <View style={styles.table_pnr}>
                  <Text style={styles.table_p}>Booking Date</Text>
                </View>
                <View style={styles.table_pnr}>
                  <Text style={styles.table_p}>stay Peroid</Text>
                </View>
                <View style={styles.table_pnr}>
                  <Text style={styles.table_p}>Total Paxs</Text>
                </View>
               
                <View style={styles.table_pnr1}>
                  <Text style={styles.table_p}>Booking ID</Text>
                </View>

                
              </View>
             
                    <View
                      style={{
                        ...styles.row,
                        ...styles.m_0,
                        ...styles.borderBottom,
                      }}
                    >
                      <View style={styles.table_pnr}>
                        <Text style={styles.table_p1}>{invoiceData?.Name}</Text>
                      </View>
                      <View style={styles.table_pnr}>
                        <Text style={styles.table_p1}>
                          {invoiceData?.TourName}
                        </Text>
                      </View>

                      <View style={styles.table_pnr}>
                        <Text style={styles.table_p1}>
                          {invoiceData?.TravellingDate}
                        </Text>
                      </View>
                      <View style={styles.table_pnr}>
                        <Text style={styles.table_p1}>
                          {invoiceData?.Day} days
                        </Text>
                      </View>
                      <View style={styles.table_pnr}>
                        <Text style={styles.table_p1}>
                          {invoiceData?.TotalPax}
                        </Text>
                      </View>
                     

                      <View style={styles.table_pnr1}>
                        <Text style={styles.table_p1}>
                          {invoiceData?.ReferenceNo}
                        </Text>
                      </View>
                    
                    </View>
                  
            </View>

            <View style={styles.sub_table1}>
              <View style={styles.sub_table}>
                <View
                  style={{
                    ...styles.sub_table_details1,
                    ...styles.row,
                    marginBottom: 0,
                  }}
                >
                  <Text
                    style={{
                      ...styles.sub_table_ph,
                    }}
                  >
                    Base Fare
                  </Text>
                  <Text style={{ ...styles.sub_table_pt }}>
                    INR {Number(invoiceData?.Price).toFixed(2)}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.sub_table_details1,
                    ...styles.row,
                    marginBottom: 0,
                  }}
                >
                  <View
                    style={{
                      ...styles.sub_table_ph,
                    }}
                  >
                    <Text>Tax</Text>
                  </View>
                  <Text style={styles.sub_table_pt}>
                  
                    INR 0
                  </Text>
                </View>
                {invoiceData?.Discount > 0 ? 
                <View
                  style={{
                    ...styles.sub_table_details1,
                    ...styles.row,
                    marginBottom: 0,
                  }}
                >
                  <View
                    style={{
                      ...styles.sub_table_ph,
                    }}
                  >
                    <Text>Discount</Text>
                  </View>
                  <Text style={styles.sub_table_pt}>
                   INR {invoiceData?.Discount}
                    
                  </Text>
                </View>
                     : null }
                <View
                  style={{
                    ...styles.sub_table_details1,
                    ...styles.row,
                    marginBottom: 0,
                  }}
                >
                  <View
                    style={{
                      ...styles.sub_table_ph,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "800",
                        color: "#000",
                      }}
                    >
                      Grand Total
                    </Text>
                  </View>
                  <Text
                    style={{
                      ...styles.sub_table_pt,
                      fontWeight: "800",
                      color: "#000",
                    }}
                  >
                   
                    INR {Number(invoiceData?.TotalPrice).toFixed(2)}
                  </Text>
                </View>
                {type === "credit_note" ? (
                  <View
                    style={{
                      ...styles.sub_table_details1,
                      ...styles.row,
                      marginBottom: 0,
                    }}
                  >
                    <View
                      style={{
                        ...styles.sub_table_ph,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "800",
                          color: "#000",
                        }}
                      >
                        Refunded Amount
                      </Text>
                    </View>
                    <Text
                      style={{
                        ...styles.sub_table_pt,
                        fontWeight: "800",
                        color: "#000",
                      }}
                    >
                     
                      INR {invoiceData?.RefundAmount}
                    </Text>
                  </View>
                ) : null}
              </View>
            </View>
            <View style={{ marginTop: "20px", width: "100%" }}>
              <Text style={{ fontSize: "8px", textAlign: "center" }}>
                Note : This is electronic generated Invoice
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default HolidayInvoice;
