import React, { useState } from "react";


const CustomFliImg = ({ src,alt }) => {
  const [showImage, setShowImage] = useState(true);

  const [img, setimg] = useState(alt === 'I51' || alt === 'YY' ? 'I5' : alt === 'IX1'? "IX" : alt);
  
   
  return (
      <img
          // src={`http://www.gstatic.com/flights/airline_logos/70px/${img}.png`}
         src={src}
          alt="image"
          className="airline-img"
          
      />
  );
};
export default CustomFliImg;