import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Row,
  Form,
  Select,
  Table,
  Layout,
  message,
  Modal,
  Popconfirm,
} from "antd";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import APIClient from "../../helpers/ApiClient";
import Loading from "../../common/Loading/Loading";
import {
  getFareType,
  getCabinType,
  getRoleType,
  getTypeSign,
} from "./commissionhelper";
import UpdatedComponent from "../ExportExcel";
import moment from "moment";
import {
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  PlusCircleFilled,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { values } from "@antv/util";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Option } = Select;
const { Content } = Layout;

const FlightsCommissions = (props) => {
  const [addMarkupModal, setAddMarkupModal] = useState(false);
  const [roleType, setRoleType] = useState(1);
  const [isEditMode, setIsEditMode] = useState(false);
  const [id, setId] = useState(-1);
  const [flightCommLists, setFlightCommLists] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [operatorList, setOperatorList] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [membershipList, setMembershipList] = useState([]);
  const[loading,setLoading]=useState(false);

  const [form] = Form.useForm();
  const validateMessages = {
    required: "",
  };
  useEffect(() => {
    getFlightCommissionsList();
    getProviders();
    getOperators();
    getMembership();
  }, []);

  const handleRoleType = (val) => setRoleType(val);

  const getMembership = () => {
    APIClient.get("admin/memberships")
      .then((res) => {
        if (res.status == 200) {
          setMembershipList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getFlightCommissionsList = () => {
    APIClient.get("admin/flightCommissions")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              ...item,
              SNo: index + 1,
              RoleTypeName: getRoleType(item.RoleType),
              FareTypeName: getFareType(item.FareType),
              MembershipID: item.MembershipID.MembershipID,
              MembershipName: item.MembershipID.Name,
              OperatorID: item.OperatorID.OperatorServiceID,
              OperatorName: item.OperatorID.OperatorName,
              ProviderID: item.ProviderID.ProviderID,
              ProviderName: item.ProviderID.Provider,
              FromBooking: getDate(item.FromBooking),
              ToBooking: getDate(item.ToBooking),
              FromTravel: getDate(item.FromTravel),
              ToTravel: getDate(item.ToTravel),
              // FareTypeName: getFareType(item.FareType),
              CabinTypeName: getCabinType(item.CabinType),
            };
          });

          setFlightCommLists(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getProviders = () => {
    APIClient.get("admin/providers")
      .then((res) => {
        if (res.statusCode == 200) {
          let filterProvider = res.data.filter(
            (item) => item.ServiceType === 1
          );
          setProvidersList(filterProvider);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getOperators = () => {
    APIClient.get("admin/operators")
      .then((res) => {
        if (res.status == 200) {
          let filterOperators = res.data.filter(
            (item) => item.ServiceType === 1
          );
          setOperatorList(filterOperators);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateFlightComm = (currentData) => {
    let FromTravel = {};
    let ToTravel = {};
    handleRoleType(currentData.RoleType);
    //setIsEditMode(false);
    setId(currentData.FlightCommissionID);
    if (currentData.FromTravel)
      FromTravel = {
        FromTravel: moment(currentData.FromTravel),
      };

    if (currentData.ToTravel)
      ToTravel = {
        ToTravel: moment(currentData.ToTravel),
      };

    form.setFieldsValue({
      ...currentData,
      FromBooking: moment(currentData.FromBooking),
      ToBooking: moment(currentData.ToBooking),
      ...FromTravel,
      ...ToTravel,
    });
    setAddMarkupModal(true);
    setIsEditMode(true);
  };

  const deleteFlightComm = (id) => {
    APIClient.delete("admin/flightCommissions/" + id)
      .then((res) => {
        if (res.status == 200) {
          message.success("Deleted  Successfully", 3);
          getFlightCommissionsList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const tableActions = (currentFlightComm) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => updateFlightComm(currentFlightComm)}
            style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() =>
              deleteFlightComm(currentFlightComm.FlightCommissionID)
            }
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const add = () => {
    if (isEditMode) {
      setIsEditMode(false);
      form.resetFields();
    }
    handleRoleType(1);
    setAddMarkupModal(true);
  };

  const clear = () => {
    setAddMarkupModal(false);
  };

  const tableColumns = [
    {
      title: "S.No",
      dataIndex: "SNo",
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
    {
      title: "Role",
      dataIndex: "RoleTypeName",
      sorter: (a, b) => a.RoleTypeName.localeCompare(b.RoleTypeName),
    },
    {
      title: "Membership",
      dataIndex: "MembershipName",
      sorter: (a, b) => a.MembershipName.localeCompare(b.MembershipName),
    },
    {
      title: "Markup",
      dataIndex: "Markup",
      render: (value, currentData, index) => (
        <>{value + " " + getTypeSign(currentData.MarkupType)}</>
      ),
    },
    {
      title: "Base Fare Commission",
      dataIndex: "BaseFareCommission",
      render: (value, currentData, index) => (
        <>{value + " " + getTypeSign(currentData.BaseFareCommissionType)}</>
      ),
    },
    {
      title: "Cabin Type",
      dataIndex: "CabinTypeName",
      sorter: (a, b) => a.CabinTypeName.localeCompare(b.CabinTypeName),
    },
    {
      title: "Class Type",
      dataIndex: "ClassType",
    },
    {
      title: "Net/Publish Fare",
      dataIndex: "FareTypeName",
    },

    {
      title: "PLB Amount",
      dataIndex: "PLBAmount",
    },

    {
      title: "Provider",
      dataIndex: "ProviderName",
      sorter: (a, b) => a.ProviderName.localeCompare(b.ProviderName),
    },
    {
      title: "Fare Type",
      dataIndex: "FareTypeName",
    },
    {
      title: "Operator",
      dataIndex: "OperatorName",
      sorter: (a, b) => a.OperatorName.localeCompare(b.OperatorName),
    },
    {
      title: "Origin",
      dataIndex: "Origin",
      sorter: (a, b) => a.Origin.localeCompare(b.Origin),
    },
    {
      title: "Destination",
      dataIndex: "Destination",
      sorter: (a, b) => a.Destination.localeCompare(b.Destination),
    },
    {
      title: "Origin Exception",
      dataIndex: "OriginException",
    },
    {
      title: "Destination Exception",
      dataIndex: "DestinationException",
    },

    {
      title: "From Travel Date",
      dataIndex: "FromBooking",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : null),
    },
    {
      title: "To Travel Date",
      dataIndex: "ToBooking",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : null),
    },

    {
      title: "Cancellation Service Charge",
      dataIndex: "CancellationServiceCharge",
      render: (value, currentData, index) => (
        <>
          {value
            ? value + " " + getTypeSign(currentData.CancellationType)
            : " "}
        </>
      ),
    },

    {
      title: "Re-Schedule Service Charge",
      dataIndex: "ReScheduleServiceCharge",
      render: (value, currentData, index) => (
        <>
          {value ? value + " " + getTypeSign(currentData.ReScheduleType) : " "}
        </>
      ),
    },
  ];

  const submitForm = (value) => {
    let data = {
      CreatedBy: "1",
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
      ...value,
      ClassType: value?.ClassType ?? "",
      DestinationException: value?.DestinationException ?? "",
      Destination: value?.Destination ?? "",
      Origin: value?.Origin ?? "",
      OriginException: value?.OriginException ?? "",
      FromTravel: value?.FromBooking ?? "",
      ToTravel: value?.ToBooking ?? "",
      MembershipID: value.RoleType === 5 ? value.MembershipID : 1,
      BaseFareCommission: value.RoleType === 5 ? value.BaseFareCommission : 0,
      BaseFareCommissionType:
        value.RoleType === 5 ? value.BaseFareCommissionType : 0,
    };

    isEditMode ? submitUpdateMap(data) : submitMapDetails(data);
  };

  const submitMapDetails = (formMapData) => {
    setLoading(true)
    APIClient.post("admin/flightCommissions", formMapData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Details Added SuccessFully", 3);

          setAddMarkupModal(false);
          getFlightCommissionsList();
          form.resetFields();
        } else if (response.status == 400) {
          message.error("Duplicate Entry Membership Type OR Provider", 3);
        } else if (response.status == 404) {
          message.error(response.message, 3);
        } else {
          message.error(response.message, 3);
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });
  };

  const submitUpdateMap = (formMapData) => {
    setLoading(true)
    APIClient.put(`admin/flightCommissions/${id}`, {}, formMapData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Details Updated SuccessFully", 3);
          setAddMarkupModal(false);
          getFlightCommissionsList();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.error(error);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = flightCommLists.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setFlightCommLists(searchResults);
    } else {
      getFlightCommissionsList();
    }
  };

  const selectCustomFilter = (input, option) => {
    let istext =
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    if (istext) {
      return true;
    }
  };
  function getDate(date) {
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    }
    return;
  }

  const handleExcel = () => {
    const excelData = flightCommLists.map((item) => {
      return {
        S_No: item.Sno,
        Role: item.RoleTypeName,
        Membership: item.MembershipName,
        Markup: item.Markup,
        BaseFareCommission: item.BaseFareCommission,
        CabinTypeName: item.CabinTypeName,
        ClassType: item.ClassType,
        FareTypeName: item.FareTypeName,
        PLBAmount: item.PLBAmount,
        ProviderName: item.ProviderName,
        //FareTypeName: item.FareTypeName,
        OperatorName: item.OperatorName,
        Origin: item.Origin,
        Destination: item.Destination,
        OriginException: item.OriginException,
        FromTravel: item.FromTravel,
        ToTravel: item.ToTravel,
        CancellationServiceCharge: item.CancellationServiceCharge,
        ReScheduleServiceCharge: item.ReScheduleServiceCharge,
      };
    });

    props.exportExcel(excelData, "Flights CommissionDetails");
  };

  return (
    <>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <div>
                    <h5>
                      View Flights Commission/Markup{" "}
                      <HelpInfoHelper screenName={"/admin/flightcommissions"} />
                    </h5>
                    <p>{flightCommLists.length} rows found !</p>
                  </div>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img
                    src={search}
                    alt="search"
                    onClick={(e) => searchData()}
                  />
                  <img src={excel} alt="excel" onClick={handleExcel} />
                  <img
                    src={exchange}
                    alt="exchange"
                    onClick={() => getFlightCommissionsList()}
                  />
                  <div onClick={add} className="add-deposit-icon">
                    <PlusCircleFilled />
                  </div>
                </div>
              </div>
              <div>
                <Table
                  scroll={{ x: true }}
                  bordered
                  dataSource={flightCommLists}
                  columns={tableColumns}
                  pagination={{
                    defaultPageSize: 25,
                    showSizeChanger: true,
                    pageSizeOptions: ["25", "50", "100", "125"],
                  }}
                />
              </div>
            </Card>
          </div>
        </Content>
      </div>

      <Modal
        width={"1000px"}
        className="markup-modal admin-bc-level flv-bc-21 modal-header-bg12"
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {isEditMode ? "Update" : "Add"} Flights Commission
              </strong>
            </h6>
          </div>,
        ]}
        visible={addMarkupModal}
        onCancel={(e) => setAddMarkupModal(false)}
        footer={[
          <div>
            <Button key="submit" type="danger" onClick={clear}>
              Clear
            </Button>
            <Button key="add" type="primary" onClick={form.submit}>
              <Loading loading={loading}/>
              {isEditMode ? "Update" : "Add"}
            </Button>
          </div>,
        ]}
      >
        <Form
          layout={"vertical"}
          form={form}
          initialValues={{
            YRCommissionType: 1,
            YRCommission: 1,
            YQCommissionType: 1,
            YQCommission: 1,
          }}
          onFinish={submitForm}
          validateMessages={validateMessages}
        >
          <div>
            <Row gutter={16}>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Role Type"
                  name="RoleType"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Please Select"
                    onSelect={(val) => handleRoleType(val)}
                  >
                    <Option value={2}>Users</Option>
                    <Option value={4}>Guest</Option>
                    <Option value={5}>Partner</Option>
                  </Select>
                </Form.Item>
              </Col>
              {roleType === 5 && (
                <Col className="gutter-row" md={8} xs={24}>
                  <Form.Item
                    label="Membership"
                    name="MembershipID"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Select Membership"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {membershipList.map((data, index) => (
                        <Option key={index} value={data.MembershipID}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Provider"
                  name="ProviderID"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select Provider">
                    {providersList.map((data, index) => (
                      <Option key={index} value={data.ProviderID}>
                        {data.Provider}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Fare Type"
                  name="FareType"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select Fare Type">
                   

                  <Option value={1}>GENERAL</Option>
                  <Option value={2}>PUBLISH</Option>
                  <Option value={3}>INSTANTPUR</Option>
                  <Option value={4}>SME</Option>
                  <Option value={5}>SAVER</Option>
                  <Option value={6}>CORPORATE</Option>
                  <Option value={7}>COUPON</Option>
                  <Option value={8}>FLEXI</Option>
                  <Option value={9}>NDC</Option>
                  <Option value={10}>TACTICAL</Option>
                  <Option value={11}>SUPER6E</Option>
                  <Option value={12}>SME.CRPCON</Option>
                  <Option value={13}>SPECIAL</Option>
                  <Option value={14}>SUPERFARE</Option>
                  <Option value={15}>Cluster/TBF</Option>
                  <Option value={16}>Private</Option>
                  <Option value={17}>Inst_SeriesPurPF3</Option>
                  <Option value={18}>Cluster</Option>
                  <Option value={19}>Promo</Option>
                    
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Operators"
                  name="OperatorID"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    placeholder="Select Operator"
                    filterOption={(val, op) => selectCustomFilter(val, op)}
                  >
                    {/* <Option value={1}>Original</Option> */}
                    {operatorList.map((item, index) => (
                      <option key={index} value={item.OperatorServiceID}>
                        {item.OperatorName}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Select Cabin Type"
                  name="CabinType"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select Cabin Type">
                    <Option value={1}>Economy Premium</Option>
                    <Option value={2}>Business</Option>
                    <Option value={3}>First Class</Option>
                    <Option value={4}>Economy</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Class Type"
                  name="ClassType"
                  // rules={[{ required: true }]}
                >
                  <Input placeholder="Enter class" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          {/* <hr /> */}
          <div className="seperation-line">
            <Row gutter={16}>
              <Col className="gutter-row" md={8} xs={24}>
                <AutoCompleteSelect
                  formItemProps={{
                    label: "Origin",
                    name: "Origin",
                    // rules: [{ required: true }],
                  }}
                  selectProps={{
                    placeholder: "Select Source",
                    allowClear: true,
                  }}
                  api={"flights/updatedAirPort/search/"}
                />
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <AutoCompleteSelect
                  formItemProps={{
                    label: "Destination",
                    name: "Destination",
                    // rules: [{ required: true }],
                  }}
                  selectProps={{
                    placeholder: "Select Source",
                    allowClear: true,
                  }}
                  api={"flights/updatedAirPort/search/"}
                />
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <AutoCompleteSelect
                  formItemProps={{
                    label: "Origin Exception",
                    name: "OriginException",
                  }}
                  selectProps={{
                    placeholder: "Select Source",
                    allowClear: true,
                  }}
                  api={"flights/updatedAirPort/search/"}
                />
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <AutoCompleteSelect
                  formItemProps={{
                    label: "Destination Exception",
                    name: "DestinationException",
                  }}
                  selectProps={{
                    placeholder: "Select Source",
                    allowClear: true,
                  }}
                  api={"flights/updatedAirPort/search/"}
                />
              </Col>

              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item label="From Travel Date" name="FromTravel">
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item label="To Travel Date" name="ToTravel">
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          {/* <hr /> */}
          <div className="seperation-line">
            <Row gutter={16}>
              

              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Markup Type"
                  name="MarkupType"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Please select">
                    <Option value={0}>Fixed</Option>
                    <Option value={1}>Percentage</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Markup"
                  name="Markup"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    type="number"
                  />
                </Form.Item>
              </Col>
              {roleType === 5 && (
                <>
                  <Col className="gutter-row" md={8} xs={24}>
                    <Form.Item
                      label="Commission Type"
                      name="BaseFareCommissionType"
                      rules={[{ required: true }]}
                    >
                      <Select placeholder="Please select">
                        <Option value={0}>Fixed</Option>
                        <Option value={1}>Percentage</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" md={8} xs={24}>
                    <Form.Item
                      label="Commission"
                      name="BaseFareCommission"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item label="PLB" name="PLBAmount">
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="seperation-line">
            <Row gutter={16}>
              <Col className="gutter-row" md={6} xs={24}>
                <Form.Item
                  label="Cancellation Service Charge Type"
                  name="CancellationType"
                >
                  <Select placeholder="Please select">
                    <Option value={0}>Fixed</Option>
                    <Option value={1}>Percentage</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={6} xs={24}>
                <Form.Item
                  label="Cancellation Service Charge"
                  name="CancellationServiceCharge"
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    type="number"
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" md={6} xs={24}>
                <Form.Item
                  label="Re-Schedule Service Charge Type"
                  name="ReScheduleType"
                >
                  <Select placeholder="Please select">
                    <Option value={0}>Fixed</Option>
                    <Option value={1}>Percentage</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={6} xs={24}>
                <Form.Item
                  label="Re-Schedule Service Charge"
                  name="ReScheduleServiceCharge"
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default UpdatedComponent(FlightsCommissions);
