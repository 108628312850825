import { CloseOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Input,
  Layout,
  Row,
  Table,
  Form,
  DatePicker,
  Button,
  Select,
} from "antd";
import moment from "moment";
import React, {  useState } from "react";

import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import ApiClient from "../../helpers/ApiClient";

import UpdatedComponent from "../ExportExcel";

const { Content } = Layout;
const { Option } = Select;
const oriDateFormat = "DD-MM-YYYY";

const NewsLetter = ({ exportExcel }) => {
  const [ordersList, setOrdersList] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [excelList, setExcelList] = useState([]);
  const [form] = Form.useForm();
  const [showTable, setShowTable] = useState(false);
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [toDisableDate, setToDisableDate] = useState(moment());

  const onFormSubmit = (values) => {
    if (values.options == 4) {
      values.fromDate = moment(values.fromDate).format(oriDateFormat);
      values.toDate = moment(values.toDate).format(oriDateFormat);
    } else {
      values.fromDate = "";
      values.toDate = "";
    }

    getOrdersRequest(values);
  };
 

  const getOrdersRequest = (values) => {
    ApiClient.get("admin/getNewsletterSubscriberByFilter", values)
      .then((res) => {
        setShowTable(true);
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              DepartureDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setOrdersList(data);
          defineExcelData(data);
        } else {
          setOrdersList([]);
        }
      })
      .catch((e) => {
        setOrdersList([]);
      });
  };
  const handleTimeAndDate = (value) => {
    if (value === 4) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        ...item,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const columns = [
    {
      title: "SNo",
      dataIndex: "SNo",
    },
    {
      title: "Mobile No.",
      dataIndex: "mobile",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role Type",
      dataIndex: "RoleType",
    },
    {
      title: "Service Type",
      dataIndex: "ServiceType",
    },
  ];

  
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };
  const onChangeFromDate = (momentdate, _) => {
    setToDisableDate(momentdate);
  };
  const disabledSelectedDate = (currentDate) => {
    return currentDate < moment(toDisableDate).startOf("day");
  };
  return (
    <div className="promo providerapi">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <h5 className="mb-1">
                        News Letter Subscribers{" "}
                        <HelpInfoHelper
                          screenName={"/admin/newsletter-subscribers"}
                        />
                      </h5>
                    </div>
                  </div>
                  <div>
                    <Form
                      form={form}
                      layout={"vertical"}
                      initialValues={{
                        options: 3,
                        ReferenceNo: "",
                      }}
                      onFinish={onFormSubmit}
                    >
                      <Row gutter={16}>
                        <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item
                            label="Time and Date"
                            name="options"
                            rules={[{ required: true }]}
                          >
                            <Select
                              placeholder="Please select"
                              onChange={handleTimeAndDate}
                            >
                              <Option value={1}>Last One Hour</Option>
                              <Option value={2}>Yesterday</Option>
                              <Option value={3}>Last Week</Option>
                              <Option value={4}>Custom Dates</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        {isDateDisplay ? (
                          <>
                            <Col md={6} sm={12} xs={24}>
                              <Form.Item
                                label="From Date"
                                name="fromDate"
                                rules={[{ required: true }]}
                              >
                                <DatePicker
                                  format="DD-MM-YYYY"
                                  style={{ width: "100%" }}
                                  disabledDate={disabledFutureDate}
                                  onChange={(date, dateString) =>
                                    onChangeFromDate(date, dateString)
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col md={6} sm={12} xs={24}>
                              <Form.Item
                                label="To Date"
                                name="toDate"
                                rules={[{ required: true }]}
                              >
                                <DatePicker
                                  format="DD-MM-YYYY"
                                  style={{ width: "100%" }}
                                  disabledDate={disabledSelectedDate}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        ) : null}

                        <Col md={6} xs={24}>
                          <Form.Item label="Service Type" name="serviceType">
                            <Input placeholder="Enter Service Type" />
                          </Form.Item>
                        </Col>
                        <Col md={6} xs={24}>
                          <div className="mt-4">
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="mx-2"
                            >
                              Search
                            </Button>
                            <Button
                              type="danger"
                              onClick={() => {
                                form.resetFields();
                                setIsDateDisplay(false);
                                setShowTable(false);
                              }}
                              className="mx-2"
                            >
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Card>
                {showTable && (
                  <Col span={24}>
                    <div className="card-bt-gap">
                      <Card bordered={false}>
                        {ordersList.length > 0 ? (
                          <div>
                            <Table
                              className="table-scroll-none"
                              bordered
                              dataSource={ordersList}
                              columns={columns}
                              pagination={{
                                defaultPageSize: 25,
                                showSizeChanger: true,
                                pageSizeOptions: ["25", "50", "100", "125"],
                              }}
                            />
                          </div>
                        ) : (
                          <b>No record Found</b>
                        )}
                      </Card>
                    </div>
                  </Col>
                )}
              </div>
            </Col>
          </Row>
        </Content>
      </div>
    </div>
  );
};

export default UpdatedComponent(NewsLetter);
