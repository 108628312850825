import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import * as ANTD from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import APIClient from "../../../src/helpers/ApiClient";
import "../../admin/Adminstyles.scss";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";

const BASE = process.env.REACT_APP_BASE_URL;

const HotelFITReports = (props) => {
  const [requestList, setHotelFitBookingList] = useState([]);

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [excelList, setExcelList] = useState([]);
  const [previewCarImage, setPreviewCarImage] = useState("");
  const [previewCarVisible, setPeviewCarVisible] = useState(false);
  useEffect(() => {
    getHotelFITRequest();
  }, []);

  const handleCancel = () => {
    setPeviewCarVisible(false);
  };

  const getHotelFITRequest = () => {
    APIClient.get("admin/HotelFITBookingRequest")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });

          setHotelFitBookingList(data);
          defineExcelData(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        Name: item.Name,
        CreatedDate: item.CreatedDate,
        ModifiedDate: item.ModifiedDate,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
    },
    {
      title: "Name",
      dataIndex: "Name",
    },
    {
      title: "Mobile No.",
      dataIndex: "RegisteredMobileNumber",
    },
    {
      title: "Hotel Name",
      dataIndex: "HotelName",
    },
    {
      title: "City",
      dataIndex: "City",
    },
    {
      title: "Country",
      dataIndex: "Country",
    },
    {
      title: "Checkin",
      dataIndex: "Checkin",
    },
    {
      title: "Checkout",
      dataIndex: "Checkout",
    },
    {
      title: "Room Type",
      dataIndex: "RoomType",
    },
    {
      title: "Other Portal Name",
      dataIndex: "OtherPortalName",
    },
    {
      title: "Image",

      render: (rec) => CarImageView(rec),
    },
  ];

  const CarImageView = (data) => {
    return (
      <div className="edit-icon" style={{ textAlign: "center" }}>
        <EyeOutlined
          onClick={() => showCarImage(data.Screenshotfromotherportal)}
          style={{ color: "red", fontSize: "20px", textAlign: "center" }}
        />
      </div>
    );
  };

  const showCarImage = (data) => {
    if (data) {
      setPreviewCarImage(data);
      setPeviewCarVisible(true);
    }
  };

  const searchTableData = (e) => {
   
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  return (
    <div>
      <div className="manage-markup-section">
        <div className="container-fluid">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Hotel FIT Booking Requests{" "}
                          <HelpInfoHelper
                            screenName={"/admin/hotelFITBooking"}
                          />
                        </h5>
                        <p>{requestList.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData(requestList)}
                      />
                      <img
                        src={excel}
                        alt="excel"
                        onClick={(e) => props.exportExcel(excelList, "Roles")}
                      />
                    </div>
                  </div>
                  <div>
                    <ANTD.Table
                      scroll={{ x: true }}
                      bordered
                      dataSource={requestList}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </div>
      </div>

      <ANTD.Modal
        destroyOnClose={true}
        visible={previewCarVisible}
        title={null}
        footer={null}
        onCancel={handleCancel}
        closable
      >
        <div>
          {previewCarImage ? (
            <ANTD.Image
              src={`${BASE}${previewCarImage.substring(1)}`}
              alt="imageCarPreview"
              style={{ width: "100%" }}
            />
          ) : (
            <p>No Image Found</p>
          )}
        </div>
      </ANTD.Modal>
    </div>
  );
};
export default UpdatedComponent(HotelFITReports);
