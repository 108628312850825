import React, { useEffect, useState, useCallback } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  withRouter,
} from "react-router-dom";
import { Button, message, notification } from "antd";

import { useAuthContext } from "./common/providers/AuthProvider";
import {
  ActivitiesProvider,
  HotelProvider,
  FlightProvider,
} from "./common/providers";

import Administration from "./admin/Administration";
import Footer from "./common/footer/Footer";
import Nav from "./common/navbar/Nav";
import UserNavigations from "./common/navbar/UserNavigations";

import "./App.scss";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);

  }, [pathname]);

  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

const AcceptBtn = () => {
  return (
    <Button
      className="cookies-btn"
      onClick={() => notification.close("cookies")}
    >
      Accept
    </Button>
  );
};

const openNotification = () => {
  if (localStorage.getItem("accept_cookies")) {
    return;
  }
  notification.open({
    message: "",
    key: "cookies",
    icon: null,
    duration: null,
    closeIcon: <AcceptBtn />,
    onClose: () => localStorage.setItem("accept_cookies", 1),
    placement: "bottomRight",
    description:
      "By click on accept button, you will accept that we may collect and use some of your personal data and set cookies to improve your experience and customize advertising. In order to get more information, you can read our Privacy policy and Cookie policy which explains the procedure to you in detail.",
    className: "cookies-notification",
  });
};



const App = () => {

  const {
    tryLocalSignin,
    isLoading,
    isLogin: { role },
    accessList,
    accessToken,
    resetIsLogin,
  } = useAuthContext();
  useEffect(() => {
    tryLocalSignin();

    setTimeout(() => {
      openNotification();
    }, 5000);

    message.config({
      duration: 10,
      maxCount: 1,
    });
  }, []);



  if (isLoading) {
    return null;
  }

  return (
    <Router>
      <ScrollToTop>
        {role ? (
          <Administration
            accessList={accessList}
            accessToken={accessToken}
            resetIsLogin={resetIsLogin}
          />
        ) : (
          <>
            <ActivitiesProvider>
              <FlightProvider>
                <HotelProvider>
                  <Nav />
                  <UserNavigations />
                  <Footer />
                </HotelProvider>
              </FlightProvider>
            </ActivitiesProvider>
          </>
        )}
      </ScrollToTop>
    </Router>
  );
};

export default App;
