import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Map.scss";

const Marker = (props) => {
  const isModalOpen = () => {
    props.updateModalState(true);
  };

  useEffect(() => {
    isModalOpen();
  }, []);

  return (
    <div>
      <div className="marker" onClick={() => isModalOpen()}></div>
    </div>
  );
};


const Map = (props) => {

  const hotelsObjsAsMarker = props.hotelContext.hotelsListObj.hotelsListObj[0];
  const [isPopUpModal, setIsPopUpModal] = useState(false);
  const [hotelInfoInModal, setHotelInfoInModal] = useState({});

  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 17.42159,
      lng: 78.33752,
    },
    zoom: 11,
  });

  const updateModalState = (toggleValue) => {
    setIsPopUpModal(toggleValue);
  };
  let history = useHistory();
  const goTo = (path) => {
    history.goBack();
  };

  return (
    <div className="map-container">
      <div className="map-header">
        <p className="hotel-count">
          {hotelsObjsAsMarker.length} <span> results</span>
        </p>
        <p
          className="map-close"
          onClick={() => {
            goTo();
          }}
        >
          close
        </p>
      </div>
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyCqYTNTjqLua5ABqGCzngrw4acAB3ucGdE",
            language: "en",
            region: "in",
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          distanceToMouse={() => { }}
        >
          <Marker
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
            updateModalState={updateModalState}
          />

        </GoogleMapReact>
      </div>

    </div>
  );
};

export default Map;
