import React, { createContext, useContext, useState } from "react";
import { useSessionStorage, useLocalStorage } from "../../helpers/useStorage";
import ApiClient from "../../helpers/ApiClient";

const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

const AuthProvider = ({ children }) => {
  const [isLogin, setLogin] = useState({
    flag: false,
    role: false,
    agent: false,
    adminAsUser: {
      status: false,
      id: -1,
    },
  });
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [accessToken, setAccessToken] = useSessionStorage("accessToken", null);
  const [adminAsUser, setAdminAsUser] = useSessionStorage("extraUser", {
    status: false,
    id: -1,
  });
  const [extraUserInfo, setExtraUserInfo] = useLocalStorage(
    "extraUserInfo",
    null,
    true
  );
  const [accessList, setAccessList] = useState([]);

  const resetIsLogin = () => {
    setLogin({
      flag: false,
      role: false,
      agent: false,
    });
    setUser(null);
    localStorage.clear();
    sessionStorage.clear();
  };

  const updateIsLogin = (attribute, value) => {
    setLogin((prev) => ({ ...prev, [attribute]: value }));
  };

  const updateAgentLoginFlag = (value) => updateIsLogin("agent", value);
  const updateLoginFlag = (value) => updateIsLogin("flag", value);
  const updateDashboardFlag = (value) => updateIsLogin("role", value);

  const tryLocalSignin = () => {
    if (accessToken) {
      setIsLoading(true);
      ApiClient.get("admin/getUserByauthToken")
        .then((resp) => {
          if (resp?.status === 200) {
            setUser(resp.data);
            if (resp.data.Role.RoleLevel == 1) {
              let screens = [];
              resp.data.Screens.forEach((element) => {
                screens.push(element.Name);
              });
              setAccessList(screens);
              updateDashboardFlag(true);
            } else if (resp.data.Role.RoleLevel == 3) {
              updateAgentLoginFlag(true);
            } else if (resp.data.Role.RoleLevel == 2) {
              updateLoginFlag(true);
            }
          } else {
            setAccessToken(null);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log("api error", e);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLogin,
        user,
        isLoading,
        setUser,
        accessToken,
        adminAsUser,
        setAccessToken,
        setAdminAsUser,
        accessList,
        extraUserInfo,
        setExtraUserInfo,
        setAccessList,
        updateAgentLoginFlag,
        updateLoginFlag,
        updateDashboardFlag,
        resetIsLogin,
        tryLocalSignin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
