import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import "./Refundpolicy.scss";
import { Link } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";
const Refundpolicy = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;

  const { Panel } = ANTD.Collapse;

  const [CMSList, setCMSList] = useState([]);
  useEffect(() => {
    const getCMSList = () => {
      const CMSId = 11;
      APIClient.get("admin/cms/" + `${CMSId}`)
        .then((response) => {
          if (response.status == 200) {
           
            setCMSList(response.data);
          
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getCMSList();
  }, []);
  
  
  return (
    <div className="collapseCustomWhite">
      <div className="promo providerapi refundpolicy-new">
        <ANTD.Layout className="ourteam-bg">
          <div className="">
            <Content className="admin-container ">
            
            </Content>
          </div>
        </ANTD.Layout>
      </div>

      <ANTD.Layout className="ourteam-bg">
        <Content className="admin-container cms-pages-width">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="">
                <div className="">
                  <h2 className="common-headings">Refund Policy</h2>

                
                </div>
              </div>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row>
            <ANTD.Col span={24}>
              <ANTD.Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                className="site-collapse-custom-collapse"
                expandIconPosition="right"
              >
                {CMSList.map((res) => (
                  <Panel
                    header={res.Title}
                    key={res.Id}
                    className="site-collapse-custom-panel"
                  >
                    <div dangerouslySetInnerHTML={{ __html: res.Description }} />
                    {/* <p>{res.Description}</p> */}
                  </Panel>
                ))}
              </ANTD.Collapse>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </ANTD.Layout>
    </div>
  );
};

export default Refundpolicy;
