import React, { useState } from "react";
// import hotelNoImage from "../../assets/images/hotels/no_img.png";
import fli8 from "../../assets/images/Icons/plane-ic.png"

const CustomflightImg = ({ src, alt }) => {
  const [showImage, setShowImage] = useState(true);


  const [img, setimg] = useState(alt === 'IX1' ||alt === 'I51' || alt === 'YY' ? 'I5' : alt);
  
  return (
      <img
          src={showImage ? src : `http://www.gstatic.com/flights/airline_logos/70px/${img}.png`}
          
          alt="image"
          className="airline-img"
          onError={() => setShowImage(false)}
      />
  );


};
export default CustomflightImg;