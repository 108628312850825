import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  message,
  Popconfirm,
  Row,
  Table,
  Select,
  Radio,
  Switch
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../helpers/ApiClient";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
const { Content } = Layout;

const Screens = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [screensList, setScreensList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  

  let Categorynames = {
    Masters: "Masters",
    Accounts: "Accounts",
    Ticketing: "Ticketing",
    Reports: "Reports",
    Logs: "Logs",
    Commissions: "Commissions",
    Marketing: "Marketing",
    CreditNotes: "CreditNotes",
    Settings: "Settings"

  };
  useEffect(() => {
    getScreensList();
   
  }, []);

  

  const getScreensList = () => {
    ApiClient.get("admin/screen")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setScreensList(data);
          setTableList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const add = (val) => {
    ApiClient.post("admin/screen", val)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.status == 200) {
          setModalVisible(false);
          message.success("Successfully screen added ", 3);
          getScreensList();
          form.resetFields();
        } else if (!response.isSuccess) {
          message.error("Screen Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleModal = () => {
    if (showUpdateBtn) {
      setShowUpdateBtn(false);
      form.resetFields();
    }
    setModalVisible(true);
  };

  const deleteScreen = (Record) => {
    let Id = Record.ScreenID;
    ApiClient.delete("admin/screen/" + Id)
      .then((res) => {
        if (res.message === "Success") {
          message.success("Screen Deleted  successfully", 3);
          getScreensList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const updateScreen = (val) => {
    ApiClient.put(`admin/screen/${currentId.ScreenID}`, {}, val).then(
      (resp) => {
        if (resp.message == "Success") {
          getScreensList();
          setModalVisible(false);
          message.success("Screens Updated successfully", 3);
          setCurrentId(-1);
        }
      }
    );
  };

  const submitForm = (val) => {
    let obj = {
      Name: val?.Name,
      DisplayName: val?.DisplayName,
      Category: val?.Category,
      Order: 6,
      Status: val?.Status,
      CreatedBy: 1,
      ModifiedBy: 1
    };
    if (showUpdateBtn) updateScreen(obj);
    else add(obj);
  };

  const tableActions = (currentRecord, Name) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord, Name)}
            style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteScreen(Name)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (id, data) => {
    setShowUpdateBtn(true);
    setCurrentId(id);
    form.setFieldsValue({
      ...data,
    });
    setModalVisible(true);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const searchData = () => {
    setShowSearchBox(true);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },
    {
      title: "Path Name",
      dataIndex: "Name",
     
    },
    {
      title: "Screens Name",
      dataIndex: "DisplayName",
      sorter: (a, b) => a.name?.localeCompare(b.name),
    },
    {
      title: "Category Name",
      dataIndex: "Category",
      sorter: (a, b) => a?.categoryName?.localeCompare(b?.categoryName),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
      render: (currentItem, data) => handelStatus(currentItem, data),
    },
    {
      title: "Actions",
      render: (id, name) => tableActions(id, name),
    },
  ];
  const handelStatus = (name, data) => {
    if (data?.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };
  const changeStatus = (val, status) => {
    let obj = {
      Name: val?.Name,
      DisplayName: val?.DisplayName,
      Category: val?.Category,
      Order: 6,
      Status: status,
      CreatedBy: 1,
      ModifiedBy: 1
    };
    ApiClient.put(`admin/screen/${val?.ScreenID}`, {}, obj).then(
      (resp) => {
        if (resp.message === "Success") {
          setModalVisible(true);
          message.success("Screen Status successfully", 3);
        }
      }
    );

  }




  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(screensList);
    }
  };


  return (
    <div className="outer-background-all">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false} style={{ borderRadius: "25px" }}>
              <Row className="card-add-heading">
                <Col md={14} xs={12}>
                  <div className="rows-count">
                    <h5>View Screens</h5>
                    <p>{tableList.length} rows found !</p>
                  </div>
                </Col>
                <Col md={10} xs={12}>
                  <Row>
                    <Col md={14} xs={24}>
                      <Form name="search">
                        <Input
                          autocomplete="newpassword"
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          //suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      </Form>
                    </Col>
                    <Col md={10} xs={24}>
                      <div className="action-images">
                        &nbsp;&nbsp;
                       
                        <img
                          src={exchange}
                          alt="exchange"
                          onClick={() => {
                            setTableList([]);
                            getScreensList();
                          }}
                        />
                        <PlusCircleFilled
                          className="add-deposit-icon"
                          onClick={handleModal}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {tableList.length > 0 ? (
                <Table
                  scroll={{ x: true }}
                  bordered
                  rowKey={uniqueKey}
                  dataSource={tableList}
                  columns={columns}
                  pagination={{
                    defaultPageSize: 25,
                    showSizeChanger: true,
                    pageSizeOptions: ["25", "50", "100", "125"],
                  }}
                />
              ) : (
                "No Record Found"
              )}
            </Card>
          </div>
        </Content>
      </div>
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {showUpdateBtn ? "Update Screens" : "Add Screens"}
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header bci-panel-admin-parts"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <Button key="add" type="primary" onClick={form.submit}>
            {showUpdateBtn ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form} onFinish={submitForm}>
          <Row gutter={12}>
            <Col md={24} xs={24}>
              <Form.Item
                label="Category"
                name="Category"
                rules={[{ required: true, message: "Please Select Category" }]}
              >
                <Select placeholder="Please select Category Type">
                  
                  <Option value={Categorynames.Accounts}>{Categorynames.Accounts}</Option>
                  <Option value={Categorynames.CreditNotes}>{Categorynames.CreditNotes}</Option>
                  <Option value={Categorynames.Settings}>{Categorynames.Settings}</Option>
                  <Option value={Categorynames.Marketing}>{Categorynames.Marketing}</Option>
                  <Option value={Categorynames.Masters}>{Categorynames.Masters}</Option>
                  <Option value={Categorynames.Commissions}>{Categorynames.Commissions}</Option>
                  <Option value={Categorynames.Ticketing}>{Categorynames.Ticketing}</Option>
                  <Option value={Categorynames.Reports}>{Categorynames.Reports}</Option>
                  <Option value={Categorynames.Logs}>{Categorynames.Logs}</Option>
                 
                </Select>
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item label="Path" name="Name" rules={[{ required: true, message: "Please enter path" }]}>
                <Input
                  placeholder="Please Enter Path"
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item label="Screen Name" name="DisplayName" rules={[{ required: true, message: "Please Enter Screen Name" }]}>
                <Input
                  placeholder="Please Enter Screen Name"
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item label="Status" name="Status" rules={[{ required: true, message: "Please Select Status" }]}>
                <Radio.Group
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default Screens;
