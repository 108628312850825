import { CloseOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Input,
  Layout,
  Row,
  Table,
  Form,
  DatePicker,
  Button,
  Select,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import ApiClient from "../../helpers/ApiClient";

import UpdatedComponent from "../ExportExcel";

const { Content } = Layout;
const { Option } = Select;
const oriDateFormat = "DD-MM-YYYY";

const OrdersTourExtranet = ({ exportExcel }) => {
  const [ordersList, setOrdersList] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [excelList, setExcelList] = useState([]);
  const [form] = Form.useForm();
  const [showTable, setShowTable] = useState(false);
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [toDisableDate, setToDisableDate] = useState(moment());

  const [arrivalDate, setArrivalDate] = useState(null);
  const [weekendDate, setWeekendDate] = useState(null);
  const [bookedType, setbookedType] = useState(null);
  const onFormSubmit = (values) => {
    if (values.options == 4) {
      values.fromDate = moment(values.fromDate).format("YYYY-MM-DD");
      values.toDate = moment(values.toDate).format("YYYY-MM-DD");
    } else {
      values.fromDate = "";
      values.toDate = "";
    }

    getOrdersRequest(values);
  };

  const getOrdersRequest = (values) => {
    ApiClient.get("HolidayExtranet/Holidayorders-filter", values)
      .then((res) => {
        setShowTable(true);
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              DepartureDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          if (bookedType != null) {
            data = data.filter((e) => e.pgType == bookedType);
          }
          setOrdersList(data);
          defineExcelData(data);
        } else {
          setOrdersList([]);
        }
      })
      .catch((e) => {
        setOrdersList([]);
      });
  };
  const handleTimeAndDate = (value) => {
    if (value === 4) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const BookedType = (value) => {
    setbookedType(value);
  };
  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        SrNo: item.SNo,
        ToursExPackagesID: item.ToursExPackagesID,
        ReferenceNo: item.ReferenceNo,
        CreatedDate: item.CreatedDate,
        PassengerName: item.PassengerName,
        MobileNumber: item.MobileNumber,
        EmailAddress: item.EmailAddress,
        PassengerName: item.PassengerName,
        Address: item.Address,
        AnyAdditionalRequest: item.AnyAdditionalRequest,
        PromoCode: item.PromoCode,
        AdvancePaid: item.AdvancePaid,
        FullAmountPaid: item.FullAmountPaid,
        VendorID: item.VendorID,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const columns = [
    {
      title: "Tours Packages",
      dataIndex: "HolidayName",
    },
    {
      title: "Reference No",
      dataIndex: "ReferenceNo",
      render: (ReferenceNo) => (
        <Link to={`holiday/ticket?ref=${ReferenceNo}`}>
          <p className="mb-0">{ReferenceNo}</p>
        </Link>
      ),
    },
    {
      title: "Booking Date",
      dataIndex: "CreatedDate",
      render: (CreatedDate) => (
        <p className="mb-0">{moment(CreatedDate).format("MM-DD-YYYY")}</p>
      ),
      sorter: (a, b) => {
        const dateA = new Date(a.CreatedDate);
        const dateB = new Date(b.CreatedDate);
        return dateA - dateB;
      },
      defaultSortOrder: "descend",
    },
    {
      title: "Passenger Name",
      dataIndex: "Name",
    },
    {
      title: "Mobile Number",
      dataIndex: "PhoneNo",
    },
    {
      title: "Email Address",
      dataIndex: "Email",
    },

    {
      title: "Total Price",
      dataIndex: "TotalPrice",
    },
    {
      title: "Booked Type",
      dataIndex: "pgType",
      render: (type) => (
        
          type === 2 ? <p>Offline</p> : <p>Online</p>
      ),
    },
    {
      title: "Action",
      dataIndex: "pgType",
      render: (type, record) =>
        type === 2 ? (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleUpdateClick(record.ReferenceNo)}
          >
            Update
          </span>
        ) : null,
    },
  ];


  const handleUpdateClick= ( referenceNo) => {
    
   
    
    ApiClient.put(`HolidayExtranet/offlinebookingholiday/update/${referenceNo}`)
      .then((res) => {
        if (res.status === 200) {
          
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error)
      });
    
  };
  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = ordersList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setOrdersList(searchResults);
    } else {
      getOrdersRequest();
      setOrdersList(ordersList);
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  const onChangeFromDate = (current, dateString) => {
    
    setArrivalDate(dateString);
  };
  const onChangeToDate = (current, dateString) => {
   
    setWeekendDate(dateString);
  };
  const disabledFromDate = (current) => {
    if (weekendDate != null) {
      return current.valueOf() > new Date(weekendDate);
    }
    
    return current.valueOf() > Date.now();
  };
  const disabledToDate = (current) => {
    
    return (
      current.valueOf() > Date.now() ||
      current.valueOf() < new Date(arrivalDate)
    );
  };

  return (
    <div className="promo providerapi">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <h5 className="mb-1">
                        TourExtranet Orders{" "}
                        <HelpInfoHelper
                          screenName={"/admin/toursextranet-orders"}
                        />
                      </h5>
                    </div>
                  </div>
                  <div>
                    <Form
                      form={form}
                      layout={"vertical"}
                      initialValues={{
                        options: 3,
                        ReferenceNo: "",
                      }}
                      onFinish={onFormSubmit}
                    >
                      <Row gutter={16}>
                        <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item
                            label="Time and Date"
                            name="options"
                            rules={[{ required: true }]}
                          >
                            <Select
                              placeholder="Please select"
                              onChange={handleTimeAndDate}
                            >
                              <Option value={1}>Last One Hour</Option>
                              <Option value={2}>Yesterday</Option>
                              <Option value={3}>Last Week</Option>
                              <Option value={4}>Custom Dates</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        {isDateDisplay ? (
                          <>
                            <Col md={6} sm={12} xs={24}>
                              <Form.Item
                                label="From Date"
                                name="fromDate"
                                rules={[{ required: true }]}
                              >
                                <DatePicker
                                  format="YYYY-MM-DD"
                                  style={{ width: "100%" }}
                                  disabledDate={disabledFromDate}
                                  onChange={(date, dateString) =>
                                    onChangeFromDate(date, dateString)
                                  }
                                />
                              </Form.Item>
                            </Col>

                            <Col md={6} sm={12} xs={24}>
                              <Form.Item
                                label="To Date"
                                name="toDate"
                                rules={[{ required: true }]}
                              >
                                <DatePicker
                                  format="YYYY-MM-DD"
                                  style={{ width: "100%" }}
                                  disabledDate={disabledToDate}
                                  onChange={(date, dateString) =>
                                    onChangeToDate(date, dateString)
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </>
                        ) : null}

                        <Col md={6} xs={24}>
                          <Form.Item
                            label="Reference number"
                            name="ReferenceNo"
                          >
                            <Input placeholder="Enter Reference Number" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item label="Booking Type" name="BookingType">
                            <Select
                              placeholder="Please select"
                              onChange={BookedType}
                            >
                              <Option value={3}>Online</Option>
                              <Option value={2}>Offline</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="reports-btns">
                        <Button type="primary" htmlType="submit">
                          Search
                        </Button>
                        <Button
                          type="danger"
                          onClick={() => {
                            form.resetFields();
                            setIsDateDisplay(false);
                            setShowTable(false);
                            setbookedType(null);
                          }}
                        >
                          Reset
                        </Button>
                      </Row>
                    </Form>
                  </div>
                </Card>
                {showTable && (
                  <Col span={24}>
                    <div className="card-bt-gap">
                      <Card bordered={false}>
                        {ordersList.length > 0 ? (
                          <div>
                            <Table
                              className="table-scroll-none"
                              bordered
                              dataSource={ordersList}
                              columns={columns}
                              pagination={{
                                defaultPageSize: 25,
                                showSizeChanger: true,
                                pageSizeOptions: ["25", "50", "100", "125"],
                              }}
                            />
                          </div>
                        ) : (
                          <b>No record Found</b>
                        )}
                      </Card>
                    </div>
                  </Col>
                )}
              </div>
            </Col>
          </Row>
        </Content>
      </div>
    </div>
  );
};

export default UpdatedComponent(OrdersTourExtranet);
