import React from "react";
import { Select } from "antd";

import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import currencyList from "./currencyList";
import './Navbar.scss';

const { Option } = Select;

const FlagsList = () => {
  const { activeCurrency, setActiveCurrency } = useCurrencyContext();

  return (
    <Select
      defaultValue={activeCurrency}
      onSelect={(val) => {
        setActiveCurrency(val);
      }}
     
       className="flag-select-items"
    >
      {currencyList.map((item) => (
        <Option value={item.name} key={item.name}>
          <img src={item.url} alt={item.name} className="currency-flag-img" />{" "}
          {item.name}
        </Option>
      ))}
    </Select>
  );
};

export default FlagsList;
