import React, { useEffect, useState } from "react";
import * as ANTD from "antd";
import excel from "../assets/vector-icons/excel.png";
import exchange from "../assets/vector-icons/exchange.png";
import search from "../assets/vector-icons/search.png";
import { CloseOutlined } from "@ant-design/icons";
import UpdatedComponent from "../ExportExcel";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Content } = ANTD.Layout;

function FlightEnquires(props) {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [flightEnquires, setFlightEnquires] = useState([]);
  const [offlineEnquires, setOfflineEnquires] = useState([]);

  const GetFlightEnquires = () => {
    ApiClient.get(`admin/offlineEnquiry?serviceType=${7}`)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              DepartureDate: moment(item.DepartureDate).format("DD-MM-YYYY"),
              ReturnDate: item.EndDate
                ? moment(item.EndDate).format("DD-MM-YYYY")
                : null,
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setOfflineEnquires(data);
        } else {
          setOfflineEnquires([]);
        }
      })
      .catch((e) => {
        setOfflineEnquires([]);
        console.log("api error", e);
      });
  };

  useEffect(() => {
    GetFlightEnquires();
  }, []);

  const tableColumns = [
    {
      title: "S. No.",
      dataIndex: "SNo",
    },
    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
    {
      title: "Contact Number",
      dataIndex: "ContactNumber",
    },
    {
      title: "Trip Type",
      dataIndex: "TripType",
    },
    {
      title: "Source City",
      dataIndex: "SourceCity",
      sorter: (a, b) => a.SourceCity.localeCompare(b.SourceCity),
    },
    {
      title: "Destination City",
      dataIndex: "DestinationCity",
      sorter: (a, b) => a.DestinationCity.localeCompare(b.DestinationCity),
    },
    {
      title: "Prefered Class",
      dataIndex: "PreferredClass",
    },
    {
      title: "Departure Date",
      dataIndex: "DepartureDate",
    },

    {
      title: "Return Date",
      dataIndex: "EndDate",
    },

    {
      title: "NumberOfPassangers",
      dataIndex: "NumberOfPassengers",
    },
  ];

  /* === === === === Search Button Starts === === === === */

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = offlineEnquires.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setFlightEnquires(searchResults);
    } else {
      GetFlightEnquires();
    }
  };

  const searchData = () => {
    setShowSearchBox(true);
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  /* === === === === Search Button ends === === === === */

  /* === === === === Excel Button starts === === === === */

  const handleExcel = () => {
    const excelData = offlineEnquires;

    props.exportExcel(excelData, "FlightEnquiresDetails");
  };

  /* === === === === Excel Button Ends === === === === */

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Flight Enquires{" "}
                          <HelpInfoHelper
                            screenName={"/admin/flightenquiries"}
                          />
                        </h5>
                        <p>{offlineEnquires.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => GetFlightEnquires()}
                      />
                    </div>
                  </div>
                  <div>
                    <ANTD.Table
                      className="table-scroll-none"
                      bordered
                      dataSource={offlineEnquires}
                      columns={tableColumns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>
    </div>
  );
}

export default UpdatedComponent(FlightEnquires);
