
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Popconfirm,
    Row,
    Table,
    Layout,
    Modal,
    Upload,
    Switch,
    Image,
    Select,
    Radio,
    Spin,
} from "antd";
import {
    PlusOutlined,
    EditOutlined,
    CloseOutlined,
    DeleteOutlined,
    EyeOutlined,
} from "@ant-design/icons";
import { useEffect, useState, useRef } from "react";
import HotelAutoSelect from "../../common/AutoCompleteSelect/HotelAutoSelect";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";

import ApiClient from "../../helpers/ApiClient";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";


const { TextArea } = Input;
const { Content } = Layout;
const { Option } = Select;
const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const ImageUpload = ({
    category,
    fileList,
    setFileList,
    formName,
    deleteBanner,
    limit,
    limitSize,
}) => {
    const [isUploading, setIsUploading] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");

    const uploadImageButton = !isUploading ? (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload </div>
        </div>
    ) : (
        <div className="ant-upload-text">Loading... </div>
    );
    // CategoryImages
    function beforeUpload(file) {
        const checkJpgOrPng =
            file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "image/jpg";
        if (!checkJpgOrPng) {
            message.error("You can only upload jpg, jpeg and png file!");
        }

        const checkFileSize = file.size < 1126400;
        if (!checkFileSize) {
            message.error(" Image must be smaller than 1Mb!");
        }

        return checkJpgOrPng && checkFileSize;
    }

    function getImages(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    function handleChange(info) {
        if (info.file.status === "uploading") {
            setFileList(info.fileList);
            setIsUploading(false);
        }
        if (info.file.status === "removed") {
            setIsUploading(false);
            setFileList(info.fileList);
        }
        if (info.file.status === "error") {
            setIsUploading(false);
            return;
        }
        if (info.file.status === "done") {
            setFileList(info.fileList);
            setIsUploading(false);
        }
    }

    const handlePreview = async (file) => {

        if (!file.url && !file.preview) {
            file.preview = await getImages(file.originFileObj);
        }
        setPreviewVisible(true);
        setPreviewImage(file.url || file.preview);

        setPreviewTitle(file.name);

    };

    const handleCancel = () => {
        setPreviewVisible(false);
    };

    return (
        <>
            <Form.Item
                name={formName}
                rules={[
                    { required: fileList.length > 0 ? false : true, message: "Required" },
                ]}
            >
                <Upload
                    name="image"
                    listType="picture-card"
                    className="avatar-uploader"
                    fileList={fileList}
                    action={uploadActionUrl}
                    onChange={handleChange}
                    onPreview={handlePreview}

                    data={{ category: category }}
                    onRemove={(val) => deleteBanner(val.uid)}
                    beforeUpload={beforeUpload}
                >
                    {limit
                        ? fileList.length >= limitSize
                            ? null
                            : uploadImageButton
                        : uploadImageButton}
                </Upload>
            </Form.Item>
            {/*<div onClick={handlePreview}>uy</div>*/}
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
            </Modal>
        </>
    );
};

const HotelImageUpload = ({ exportExcel }) => {
    const [MediaForm] = Form.useForm();
    const city = useRef(null);
    const supplierBox = useRef(null);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedCity, setSelectedCity] = useState()
    const [hotelCityId, setHotelCityId] = useState(0)
    const [supplier, setSupplier] = useState()
    const [hotelsCode, setHotelsCode] = useState([])
    const [excelList, setExcelList] = useState([]);
    const [image, setImage] = useState([]);
    const [imageRequestModal, setImageRequestModal] = useState(false);
    const [imageRequest, setImageRequest] = useState([]);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [Addupdate, setAddupdate] = useState(false);
    const [currID, setCurrID] = useState(-1);
    const [hotelInfo, setHotelInfo] = useState([]);
    const [cityId, setCityId] = useState({});


    useEffect(() => {
        getAdminAddedHotelImage()
    }, [])

    const ImageRequest = (holiday) => {
        return (
            <div className="text-center">
                <span
                    style={{ cursor: "pointer", color: "green" }}
                    onClick={(e) => {
                        setImageRequest(holiday);
                        setImageRequestModal(true);
                    }}
                >
                    <EyeOutlined />
                </span>
            </div>
        );
    };

    const getAdminAddedHotelImage = () => {
        ApiClient.get("hotels-v2/get/adminaddedhotelimages")
            .then((response) => {
                if (response.statusCode == 200) {
                    setHotelInfo(response.data);
                } else {
                    message.error(response.message, 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    const handleOnSubmit = (ref, selectCity) => {
        setSelectedCity(selectCity)
    };

    const hotelName_Code = (req) => {
        ApiClient.post("hotels-v2/hotelscode", req)
            .then((res) => {
                if (res.statusCode == 200) {
                    setHotelsCode(res.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const onSupplierSelect = (val) => {

        setSupplier(val)
        const cityCode = selectedCity.split('&&')[1].split("=")[1]
        setHotelCityId(cityCode)
        hotelName_Code({ supplierName: val, cityId: parseInt(cityCode) })
    }
    const searchData = () => {
        setShowSearchBox(true);
    };

    const DeleteImage = (id) => {
        console.log("delete");
    };


    const submitForm = (values) => {


        let ImageArray = [];

        if (image.length > 0) {
            for (let i = 0; i < image.length; i++) {
                if (image[i].response.status === 200) {
                    ImageArray.push(image[i].response.data.filepaths[0]);
                }
            }
        }
        let data = {
            
            cityId: Number(values?.hotelCityCode?.split('=')[2]),
            supplierName: values?.supplierName ?? supplier,
            hotelId: values?.hotelId,
            caption: "",
            images: ImageArray
        };

        isEdit ? updateMediaRequest(data, currID) : addMediaRequest(data);
    };


    const addMediaRequest = (data) => {
        setAddupdate(true);
        ApiClient.post("hotels-v2/upload/hotelimages", data)
            .then((response) => {
                if (response.statusCode == 200) {
                    message.success("Media added successfully !");
                    getAdminAddedHotelImage();
                    setModalVisible(false);
                    MediaForm.resetFields();
                } else {
                    message.error(response.message, 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const updateMediaRequest = (data, recId) => {
        setAddupdate(true);
        ApiClient.put("hotels-v2/update/hotelimages", {}, data)
            .then((response) => {
                if (response.statusCode == 200) {

                    message.success("Media updated successfully !");
                    getAdminAddedHotelImage()
                   
                    setModalVisible(false);
                } else {
                    message.error(response.message, 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const deleteMediaRequest = (id) => {
       
        ApiClient.delete(`hotels-v2/delete/adminaddedhotelimages/${id}`)
            .then((response) => {

                if (response.statusCode == 200) {

                    message.success("Media deleted successfully !");
                    getAdminAddedHotelImage()
                    setModalVisible(false);
                } else {
                    message.error(response.message, 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const closeSearch = () => {
        setShowSearchBox(false);
    };

    const closeSearchInput = (
        <div>
            <CloseOutlined onClick={closeSearch} />
        </div>
    );

    const showModal = () => {
        setAddupdate(false);
        setIsEdit(false);

        MediaForm.resetFields();
        setImage([]);
        setModalVisible({
            visible: true,
        });
    };

    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = hotelInfo.filter(function (item) {
                if (item != undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setHotelInfo(searchResults);
        } else {
            getAdminAddedHotelImage();
            setHotelInfo(hotelInfo);
        }
    };

    const onUpdate = (currentData) => {

        setAddupdate(false);
        setImage([]);
        MediaForm.resetFields();
        setIsEdit(true);
        setModalVisible(true);
        setCurrID(currentData.hotelId);
        setCityId(currentData.cityId)
        MediaForm.setFieldsValue({
            hotelCityCode: `cityName=${currentData.cityName}&&cityId=${currentData.cityId}`,
            supplierName: currentData.supplierName,
            hotelId: currentData.hotelId
        });
        let array = [];

        if (currentData?.adminHotelImages?.length > 0) {
            for (let i = 0; i < currentData.adminHotelImages?.length; i++) {
                array.push({
                    uid: currentData.ToursExmediaId,
                    status: "done",
                    url: `${BASE}${currentData.adminHotelImages[i].substring(1)}`,
                    response: {
                        status: 200,
                        data: {
                            filepaths: [currentData.adminHotelImages[i]],
                        },
                    },
                });
            }
            setImage(array);
            // setvideo(array);
        }

    };

    const tableActions = (currentItem) => {

        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div
                    className="edit-icon"
                    style={{ textAlign: "center" }}
                    onClick={() => onUpdate(currentItem)}
                >
                    <EditOutlined
                        style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() =>
                            deleteMediaRequest(currentItem._id)
                        }
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const columns = [
        {
            title: "HotelId",
            dataIndex: "hotelId",
        },
        {
            title: "PropertyName",
            dataIndex: "propertyName",
        },
        {
            title: "City",
            dataIndex: "cityName",
        },
        {
            title: "Country",
            dataIndex: "countryName",
        },
        {
            title: "Hotel Images",
            dataIndex: "adminHotelImages",
            render: (adminAddedImages) => {

                return adminAddedImages?.length > 0 ? ImageRequest(adminAddedImages) : null;
            },
        },

        {
            title: "SupplierName",
            dataIndex: "supplierName",
        },

        {
            title: "Actions",
            render: (currentItem) => tableActions(currentItem),
        },
    ];

    return (<div>
        <div className="manage-markup-section">
            <Content className="admin-container">
                <Row>
                    <Col span={24}>
                        <div className="card-bt-gap">

                            <Card bordered={false}>
                                <div className="card-add-heading">
                                    <div className="rows-count">
                                        <div>
                                            <h5>
                                                View Media{" "}
                                                <HelpInfoHelper
                                                    screenName={"/admin/toursextranet-category"}
                                                />
                                            </h5>

                                        </div>
                                    </div>
                                    <div className="action-images">
                                        {showSearchBox && (
                                            <Input
                                                placeholder="Search"
                                                onChange={(e) => searchTableData(e)}
                                                suffix={closeSearchInput}
                                                style={{ padding: "0px 12px" }}
                                            />
                                        )}
                                        &nbsp;&nbsp;
                                        <img
                                            src={search}
                                            alt="search"
                                            onClick={(e) => searchData()}
                                        />
                                        <img
                                            src={excel}
                                            alt="excel"
                                            onClick={(e) =>
                                                exportExcel(excelList, "TourExtranetCategory")
                                            }
                                        />

                                        <p className="add-icon" onClick={showModal}>
                                            <PlusOutlined />
                                        </p>
                                    </div>
                                </div>
                                {hotelInfo.length > 0 ? (
                                    <div>
                                        <Table
                                            className="table-scroll-none"
                                            bordered
                                            dataSource={hotelInfo}
                                            columns={columns}
                                            pagination={{
                                                defaultPageSize: 25,
                                                showSizeChanger: true,
                                                pageSizeOptions: ["25", "50", "100", "125"],
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <b>No record Found</b>
                                )}
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Content>
        </div>

        <Modal
            title={[
                <div>
                    <h6 style={{ marginBottom: "0px" }}>
                        <strong>{isEdit ? "Update Media" : "Add Media"}</strong>
                    </h6>
                </div>,
            ]}
            className="promo-modal-header admin-bc-level modal-header-bg12"
            visible={modalVisible}
            onOk={(e) => setModalVisible(false)}
            onCancel={(e) => setModalVisible(false)}
            footer={[
                <div>{Addupdate ? (<Spin style={{ marginRight: "5px" }} />) : null}
                    <Button htmlType="submit" type="primary" onClick={MediaForm.submit} disabled={Addupdate}>
                        {isEdit ? "Update" : "Add"}
                    </Button>
                    <Button type="danger" onClick={() => MediaForm.resetFields()}>
                        Clear
                    </Button>
                </div>,
            ]}
        >
            <Form layout={"vertical"} form={MediaForm} onFinish={submitForm}>
                <Row gutter={(12, 12)} className="mb-2">
                    <Col className="gutter-row" md={24} xs={24}>
                        <HotelAutoSelect
                            formItemProps={{
                                name: "hotelCityCode",
                                rules: [
                                    { required: true, message: "Please Specify The City" },
                                ],
                            }}
                            selectProps={{
                                size: "large",
                                placeholder: "Enter City Name",
                            }}
                            api={"hotels-v2/searchhotelcity/"}
                            refName={city}
                            focusRef={supplierBox}
                            handleOnSubmit={handleOnSubmit}
                            modifySearch={false}
                        />
                    </Col>
                    <Col className="gutter-row" md={24} xs={24}>
                        <Form.Item
                            label="Package"
                            name="supplierName"
                            rules={[{ required: true }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select Package"
                                ref={supplierBox}
                                onSelect={onSupplierSelect}
                            >

                                <Option key={0} value="TBO">
                                    TravelBoutique
                                </Option>
                                <Option key={0} value="GRNC">
                                    GRNConnect
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" md={24} xs={24}>
                        <Form.Item
                            label="Hotel"
                            name="hotelId"
                            rules={[{ required: true }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select Package"
                                ref={supplierBox}
                                onSelect={onSupplierSelect}
                                filterOption={(input, option) =>
                                    (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {hotelsCode.length > 0 && (hotelsCode.map((item, index) => (
                                    <Option key={index} value={item.hotelId}>
                                        {item.propertyName}
                                    </Option>
                                )))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <p>Hotel Images</p>
                        <div className="upload-logo">

                            <ImageUpload
                                category="HotelImages"
                                fileList={image}
                                setFileList={setImage}
                                formName="images"
                                limit={true}
                                limitSize={10}
                                deleteBanner={DeleteImage}
                            />
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
        <Modal
            visible={imageRequestModal}
            title="Photos"
            onCancel={(e) => {
                setImageRequestModal(false);
            }}
            footer={false}
            className="modal-header-bg12"
        >
            <Row gutter={12}>
                {imageRequest.map((image, index) => (
                    <Col md={6} sm={8} xs={12} key={index}>
                        <Image src={BASE + image.substring(1)} />
                    </Col>
                ))}
            </Row>
        </Modal>
    </div>)
}

export default HotelImageUpload