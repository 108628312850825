import React, { useState, useCallback, useEffect } from "react";
import { Form, Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import queryString from "query-string";
import { useLocalStorage } from "../../helpers/useStorage";
const { Option, OptGroup } = Select;
const HolidayAutoCompleteSelectByCategory = (props) => {
  useEffect(() => {
    let value = props.refName.current.props.value;

    if (!value || value == "" || value == " ") {
      return;
    }
    const hotelSearchParams = queryString.parse(value);
    let name = "";
    let countryId = null;
    let cityId = null;
    if (hotelSearchParams.countryId && hotelSearchParams.countryName) {
      name = hotelSearchParams.countryName;
      countryId = hotelSearchParams.countryId;
      if (hotelSearchParams.cityId && hotelSearchParams.cityName) {
        name = hotelSearchParams.cityName;
        cityId = hotelSearchParams.cityId;
      }
    }


    fetchData(name, cityId, countryId);
  }, props.refName.current);

  const onSelect = () => {
    if (props.focusRef) {
      props.handleOnSubmit(props.focusRef);
    }
  };

  const [details, setDetails] = useState({
    data: [],

    fetching: false,
  });
  const debounceOnChange = useCallback(debounce(fetchData, 800), []);

  const createOptions = (results, cityId, countryId) => {

    let arr = [];

    if (cityId != null) {
      results.cities = results.cities.filter((item) => item.cityId == cityId);
    } else if (countryId != null) {
      results.countries = results.countries.filter(
        (item) => item.countryId == countryId
      );
    }
    // results.forEach((result) => {
    //   let country = `countryName=${result.country}&&countryId=${result.countryCode}`;
    //   arr.push({
    //     value: country,
    //     name: result.country,
    //     country,
    //   });
    // });
    results.forEach((result) => {
      let country = `countryName=${result.country}&&countryId=${result.countryCode}`;
      let city = `cityName=${result.cityName}&&cityId=${result.cityId}`;
      arr.push({
        value: `${country}&&${city}`,
        name: `${result.cityName}, ${result.countryName}`,
        country: false,
      });
    });

    return arr;
  };

  function fetchData(value, cityId = null, countryId = null) {
    setDetails({ data: [], fetching: true });
    APIClient.get(`${props.api}${value}`)
      .then((res) => {
        if (res.status === 200 && res) {

          setDetails({
            data: createOptions(res.data, cityId, countryId),
            fetching: false,
          });
          return;
        }
        setDetails({ data: [], fetching: false });
      })
      .catch((error) => {
        console.error(error);
        setDetails({ data: [], fetching: false });
      });
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const [HolidayRecentSearches, setHolidayRecentSearches] = useLocalStorage(
    props.recentKey,
    []
  );

  const recentSearches = (e) => {
    if (e) {
      const optionObj = details.data.find((item) => item.value == e);

      if (optionObj) {
        if (HolidayRecentSearches.length > 0) {
          let array = [];
          array = [...HolidayRecentSearches];
          if (array.length > 4) {
            array.pop();
          }

          if (optionObj) {
            setHolidayRecentSearches([
              optionObj,
              ...array.filter((item) => item.value !== e),
            ]);
          }

          return;
        }

        setHolidayRecentSearches([optionObj]);
      }
    }
  };

  return (
    <Form.Item {...props.formItemProps}>
      <Select
        style={{ width: "100%" }}
        showSearch
        ref={props.refName}
        notFoundContent={
          details.fetching ? <Spin size="small" /> : "No Matches found."
        }
        filterOption={false}
        onSearch={debounceOnChange}
        {...props.selectProps}
        onSelect={(e) => {
          onSelect();

          recentSearches(e);
        }}
        defaultActiveFirstOption
      >
        {HolidayRecentSearches.length && (
          <OptGroup label="Recent Searches">
            {HolidayRecentSearches.map((d) => {
              if (d.country) {

                return (
                  <Option value={d.value} key={"holidayrecent" + d.value}>
                    <div className="d-flex align-items-center justify-content-between  ">
                      <div>
                        {" "}
                        <p className="m-0">
                          {d.value.split("&&")[0].split("=")[1]}
                        </p>
                      </div>
                      <div className="city-column">
                        <p className="m-0" style={{ color: "#9B9B9B" }}>
                          COUNTRY
                        </p>
                      </div>
                    </div>
                  </Option>
                );
              } else {
                return (
                  <Option value={d.value} key={"holidaycityrecent" + d.value}>
                    <div className="d-flex align-items-center justify-content-between  ">
                      <div>
                        {" "}
                        <p className="m-0" style={{ fontSize: '14px' }}>
                          {d.value.split("&&")[2].split("=")[1]},{" "}
                          {d.value.split("&&")[0].split("=")[1]}
                        </p>
                        <p
                          className="m-0 city-column"
                          style={{ fontSize: "11px", color: "#9B9B9B" }}
                        >
                          {d.value.split("&&")[0].split("=")[1]}
                        </p>
                      </div>
                      <div className="city-column">
                        <p className="m-0" style={{ color: "#9B9B9B" }}>
                          CITY
                        </p>
                      </div>
                    </div>
                  </Option>
                );
              }
            })}
          </OptGroup>
        )}

        {/* {props?.topCities.length && (
          <OptGroup label="Popular Destination">
            {props.topCities.map((item, index) => {
              return (
                <Option value={item.HolidayCityCode} key={"topCity" + index}>
                  <div className="d-flex align-items-center justify-content-between  ">
                    <div>
                      {" "}
                      <p className="m-0">
                        {item.HolidayCityCode?.split("&&")[2].split("=")[1]},{" "}
                        {item.HolidayCityCode?.split("&&")[0].split("=")[1]}
                      </p>
                      <p
                        className="m-0 city-column"
                        style={{ fontSize: "11px", color: "#9B9B9B" }}
                      >
                        {item.HolidayCityCode?.split("&&")[0].split("=")[1]}
                      </p>
                    </div>
                    <div className="city-column">
                      <p className="m-0" style={{ color: "#9B9B9B" }}>
                        CITY
                      </p>
                    </div>
                  </div>
                </Option>
              );
            })}
          </OptGroup>
        )} */}
        {details.data.length && (
          <OptGroup label="Search Results">
            {details.data.map((d) => {
              if (d.country) {

                return (
                  <Option value={d.value} key={"activityKey" + d.value}>
                    <div className="d-flex align-items-center justify-content-between  ">
                      <div>
                        {" "}
                        <p className="m-0" style={{ fontSize: '14px' }}>
                          {d.value.split("&&")[0].split("=")[1]}
                        </p>
                      </div>
                      <div className="city-column">
                        <p className="m-0" style={{ color: "#9B9B9B" }}>
                          COUNTRY
                        </p>
                      </div>
                    </div>
                  </Option>
                );
              } else {
                return (
                  <Option value={d.value} key={"activityKey" + d.value}>
                    <div className="d-flex align-items-center justify-content-between  ">
                      <div>
                        {" "}
                        <p className="m-0" style={{ fontSize: '14px' }}>
                          {d.value.split("&&")[2].split("=")[1]},{" "}
                          {d.value.split("&&")[0].split("=")[1]}
                        </p>
                        <p
                          className="m-0 city-column"
                          style={{ fontSize: "11px", color: "#9B9B9B" }}
                        >
                          {d.value.split("&&")[0].split("=")[1]}
                        </p>
                      </div>
                      <div className="city-column">
                        <p className="m-0" style={{ color: "#9B9B9B" }}>
                          CITY
                        </p>
                      </div>
                    </div>
                  </Option>
                );
              }
            })}
          </OptGroup>
        )}
      </Select>
    </Form.Item>
  );
};

export default HolidayAutoCompleteSelectByCategory;
