import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import "./Socialresponsibility.scss";
import { CaretRightOutlined } from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";
const Socialresponsibility = () => {
  const { Content } = ANTD.Layout;

  const { Panel } = ANTD.Collapse;
  const [CMSList, setCMSList] = useState([]);
  useEffect(() => {
    const getCMSList = () => {
      const CMSId = 9;
      APIClient.get("admin/cms/" + `${CMSId}`)
        .then((response) => {
          if (response.status === 200) {
            setCMSList(response.data.pages);
          }
        })
        .catch((error) => {
          console.log(error);
         });
    };
    getCMSList();
  }, []);

  var aray = [];
  CMSList.forEach((element) => {
    aray = element.Description;
  });

  return (
    <div className="collapseCustomWhite">


      <ANTD.Layout className="ourteam-bg">
        <Content className="admin-container cms-pages-width">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="">
                <div className="">
                  <h2 className="common-headings">Social Responsibility</h2>

                  <div>
                    {aray.map((res) => {
                      if (
                        res.title === "" ||
                        res.title === null ||
                        res.title === "undefine"
                      ) {
                        return (
                          <p className="common-paras">{res.description}</p>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row>
            <ANTD.Col span={24}>
              <ANTD.Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                className="site-collapse-custom-collapse"
                expandIconPosition="right"
              >
                {aray.map((res, i) => (
                  <Panel
                    header={res.title}
                    key={i}
                    className="site-collapse-custom-panel"
                  >
                    <p>{res.description}</p>
                  </Panel>
                ))}
              </ANTD.Collapse>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </ANTD.Layout>
    </div>
  );
};

export default Socialresponsibility;
