import React, { useState } from 'react';
import { SearchOutlined } from "@ant-design/icons";
import { Button, Drawer } from 'antd';
import './Navbar.scss';
import CombineServLink1 from '../CombineServicesLink/CombineServLink1';

const Drawersearch = (props) => {
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const index2 = props?.index;


    const showDrawer = () => {
        setOpen(true);
    };

    const showDrawer1 = () => {
        setOpen1(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onClose1 = () => {
        setOpen1(false);
    };

    return (
        <>
            <div type="primary" className='modal-head-searchbar-mob-pcbtn' onClick={showDrawer1}>
                <SearchOutlined style={{ color: 'black' }} /> <span>   Search</span>
            </div>
            <div type="primary" className='modal-head-searchbar-mob-pcbtn1' onClick={showDrawer1}>
                <SearchOutlined style={{ color: 'black' }} />   <span>   Search</span>
            </div>
            <Drawer height='233px' placement="top" onClose={onClose} visible={open}>
                <CombineServLink1 activetab={index2} />
            </Drawer>
            <Drawer height='1000px' width="100%" placement="left" onClose={onClose1} visible={open1}>
                <CombineServLink1 activetab={index2} />
            </Drawer>
        </>
    );
};

export default Drawersearch;