import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Layout,
  message,
  Select,
} from "antd";

import "./CheckStatus.scss";
import { useHistory } from "react-router-dom";
const { Content } = Layout;
const { Option } = Select;

const CheckStatus = () => {
  let history = useHistory();
  const [form] = Form.useForm();
  const [buttonDisabled, setButtonDisabled] = useState(true);
 

  const submitForm = (data) => {
   
  };

  return (
    <div>
      <div className="promo providerapi">
        <Layout className="ourteam-bg">
          <div className="">
            <Content className="admin-container cms-pages-width retrieve-linebar">
              <Row className="fst-book">
                <Col md={24} xs={24}></Col>
              </Row>

              <Form
                layout="vertical"
                form={form}
                onFinish={submitForm}
                initialValues={{ type: 1 }}
              >
                <Row className="mb-3">
                  <Col md={12} xs={24}>
                    <Card className="retrieve-booking">
                      <h4>Retrieve Your Status</h4>
                      <p className="booking-email-p">
                        Enter your Transaction number below and we’ll find your
                        status for you.
                      </p>

                      <Row className="booking-value-id">
                        <Col md={12} xs={24}>
                          <Form.Item
                            label="Transaction  Number"
                            name="transaction"
                            rules={[
                              {
                                required: true,
                                message: "Transaction No is Required",
                              },
                            ]}
                          >
                            <Input
                              className="contacts-value-de-retrive"
                              size={"large"}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                          <Form.Item
                            label="Service Type"
                            name="type"
                            rules={[
                              { required: true, message: "Field is Required" },
                            ]}
                          >
                            <Select className="contacts-value-de-retrive">
                            
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    
                      <Row justify="center">
                        <Col md={12} xs={24}>
                          <Button
                            className="google-btn-booking"
                            htmlType="submit"
                          >
                            Retrieve Booking
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  <Col md={12} xs={24}>
                    <img
                      className="email-from-book"
                      src={
                        require("../../assets/images/email-tickets.png").default
                      }
                      alt="search-img"
                    />
                  </Col>
                </Row>
              </Form>
            </Content>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default CheckStatus;
