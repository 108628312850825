import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import "./HolidayDestinations.scss";
import { Row, Col, Card } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import ApiClient from "../../../../helpers/ApiClient";
import HolidayImage from "../../../../assets/images/hotels/shopping-d-1.png";
const BASE = process.env.REACT_APP_BASE_URL;

const HolidayDestinations = (props) => {
  const { promoType } = props;
  const [promoCode, setPromoCode] = useState([]);
  let dateFormat = "YYYY-MM-DD";

  const [dataSource, setDataSource] = useState({
    sliderData: [],
  });
  const getallVisaRecords = () => {
    ApiClient.get("HolidayExtranet/getHolidayPackages").then((res) => {

      if (res?.status == 200) {
        let data = res?.data?.filter((item) => item.ShowHomepage == 1);

        setDataSource({ sliderData: res.data });
      }
    });
  };

  useEffect(() => {
    getallVisaRecords();
  }, []);
  const MobileSlidersettings = {
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {dataSource.sliderData.length > 0 ? (
        <div className="holiday-slider-wrapper  mt-5 mb-4 popularDestinationsVisa">
          <div className="mb-5">
            <h5 className="title text-center " style={{fontFamily:'Times New Roman, Times, serif', fontSize:'30px',fontWeight:1000 }}>Popular Holidays</h5>

          </div>

          <Slider {...MobileSlidersettings}>
            {dataSource.sliderData.length
              ? dataSource.sliderData
                .filter((item, index) => index <= 5)
                .map((itemData, ind) => {

                  return (
                    <Col
                      key={"popularDest" + ind}
                      md={24}
                      xs={24}
                      className="mb-3"
                    >
                      <Link
                        to={`/holidays/results?catId=${itemData.CategoryId}&cityId=${itemData.CityId}&cityName=${itemData.CityName}%2C${itemData.CountryName}&countryId=${itemData.CountryCode}&countryName=${itemData.CountryName}&travelDate=${moment(itemData.journeyDate, 'DD-MM-YYYY').format('YYYY-MM-DD')}`}
                      >
                        <Card
                          className="holiday-card orange-color"
                          bordered={false}
                        >
                          {itemData.TourImages.length > 0 ? (
                            <div className="holiday-image">

                              <img
                                src={
                                  BASE + (JSON.parse(itemData.TourImages)[0].substring(1))
                                }
                                className="w-100"
                                alt={itemData.PackageTitle}
                              />
                            </div>
                          ) : (
                            <div className="holiday-image">
                              <img src={HolidayImage} />{" "}
                            </div>
                          )}
                          <div className="holiday-content mt-2">
                            <h4 className="holiday-pkg-name">
                              {itemData.PackageTitle}
                            </h4>
                            <div className="holiday-overview">
                              {itemData.flightCount && (
                                <p>
                                  {" "}
                                  <i className="fa fa-plane" />{" "}
                                  {itemData.flightCount}
                                </p>
                              )}
                              {itemData.hotelsCount && (
                                <p>
                                  {" "}
                                  <i className="fa fa-bed" />{" "}
                                  {itemData.hotelsCount}
                                </p>
                              )}
                              {itemData.activitiesCount && (
                                <p>
                                  {" "}
                                  <i className="fa fa-anchor" />{" "}
                                  {itemData.activitiesCount}
                                </p>
                              )}
                              {itemData.transfersCount && (
                                <p>
                                  {" "}
                                  <i className="fa fa-bus" />{" "}
                                  {itemData.transfersCount}
                                </p>
                              )}
                            </div>
                          </div>
                        </Card>
                      </Link>
                    </Col>
                  );
                })
              : ""}
          </Slider>
        </div>
      ) : null}
    </>
  );
};

export default HolidayDestinations;
