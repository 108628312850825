import React, { useEffect, useState, useContext } from "react";
import {
  Row,
  Col,
  Skeleton,
  Modal,
  Collapse,
  Grid,
  Button,
  Form,
  Card,
  Statistic,
} from "antd";
import timeoutimg from "../../../assets/images/timeout.jpg"
import  { useHistory } from "react-router-dom";
import { GlobalStatesContext } from "../../../common/providers";
import ApiClient from "../../../helpers/ApiClient";
import HolidaySearchForm from "../HolidaysSearch/HolidaySearchForm";
import NoResultFound from "../../../components/ErrorPages/NoResultFound";
import queryString from "query-string";
import FilterSkeleton from "../../../components/FilterSkeleton/FilterSkeleton";
import SkeletonLayout from "../../../components/Skeleton/Skeleton";

import "./HolidayResults.scss";
import Holidaysort from "../Holidaysort/Holidaysort";
import HolidayFilter from "../HolidayFilter/HolidayFilter";
import HolidayList from "./HolidayList/HolidayList";

const { useBreakpoint } = Grid;
const { Panel } = Collapse;
const { Countdown } = Statistic;
const HolidayResults = ({ activitiesParam, isFromPackage = false }) => {
  useEffect(() => {
      getsessiontimeout();
    }, [])
  const {
    state: { holidaySearchResultObj },
    updateHolidaySearchResultObj,
    updateSelectedHolidayData,
    updateHolidayBookingData,
  } = useContext(GlobalStatesContext);
  let history = useHistory();
  

const [sessiontimeout, setsessiontimeout] = useState([]);
const [status, setStatus] = useState('');
const getsessiontimeout = () => {
  ApiClient.get("admin/get_active_session_timeout/6")
    .then((res) => {
      if (res.status === 200) {
        setsessiontimeout(new Date().getTime() + Number((res?.data?.sessionTime) * 60 * 1000));
        setStatus(res.data.status);
      }
      else {
        setsessiontimeout(new Date().getTime() + Number(0));
        setStatus(res.data.status);
      }
    })
    .catch((error) => {
      console.error(error);
    });
};


  let HolidayInitial = {
    tours: [],
    filtersObj: {
      price: {
        minPrice: 0,
        maxPrice: 0,
        maxPriceRange: 0,
        minPriceRange: 0,
      },
      packageTitle: [],
      category: [],
      subCategory: [],
      packagesModel: [],
      nights: [],
      cityName: [],
    },
  };
  const [isLoading, setIsLoading] = useState(true);
  const [holidayResultsList, setHolidayResultsList] = useState(HolidayInitial);
  const [showNetFare, setShowNetFare] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisiblenew, setModalVisiblenew] = useState(false);
  const { md } = useBreakpoint();
  const [key, setKey] = useState([]);

  const showModalflight = () => {
    setModalVisible({
      visible: true,
    });
  };
  const showModalsort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };


  const searchAllTours = () => {
    let param = queryString.parse(window.location.search);
    let holidaySearchObject = {
      countryCode: param.countryId,
      cityId: param.cityId,
      categoryId: param.catId,
      travellingOn: param.travelDate
    };


    fetchHolidayAvailability(holidaySearchObject);


  };

  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

    const timeout = () => {
      return (
  
        <div>
  
          <div className="timeout-popup-main">
            <div className="timeout-popup-main1">
              <img className="timeout-img" src={timeoutimg} alt="time" />
            </div>
            <div className="timeout-popup-main2">
              <h4 style={{ color: "red", }}><strong>SESSION TIMEOUT</strong></h4>
              <p className="popup-session-timeout-p-tag">Your Session is Expired</p>
              <p className="popup-session-timeout-p-tag">Click on "OK" to continue with New Search</p>
            </div>
          </div>
  
        </div>
      );
    }
    const handelCountdown = () => {
  
     
      let query = queryString.stringify(JSON.parse(localStorage.getItem('HolidaySearchBar')));
      Modal.warning({
        icon: <></>,
        //title: "",
        content: timeout(),
        onOk() {
          getsessiontimeout();
          searchAllTours();
          history.push("/holidays/results?" + query);
  
        },
      });
    };


  const fetchHolidayAvailability = (obj) => {
    setIsLoading(true);
    updateHolidaySearchResultObj(HolidayInitial);
    ApiClient.post("HolidayExtranet/holiday/search", obj)
      .then((results) => results)
      .then((resp) => {

        if (resp.status == 200) {

          if (resp.tours != {}) {
            let filtersObj = { ...HolidayInitial.filtersObj };

            let tours = resp.holidays.map((tour, i) => {
              let startingFare = Number(tour.PerAdultCharge);

              if (i === 0) {
                filtersObj.price.minPrice = startingFare;
              }
              let minFare = startingFare;
              if (startingFare > filtersObj.price.maxPrice) {
                filtersObj.price.maxPrice = filtersObj.price.maxPriceRange =
                  startingFare;
              }

              if (minFare < filtersObj.price.minPrice) {
                filtersObj.price.minPrice = minFare;
              }
              filtersObj.price.minPriceRange = filtersObj.price.minPrice;

              const { SubCategoryName } = tour.SubCategory;
              checkIfExist(filtersObj.subCategory, SubCategoryName) &&
                filtersObj.subCategory.push({
                  id: SubCategoryName,
                  label: SubCategoryName,
                  isChecked: false,
                });

              const { CityName } = tour;
              checkIfExist(filtersObj.cityName, CityName) &&
                filtersObj.cityName.push({
                  id: CityName,
                  label: CityName,
                  isChecked: false,
                });
              const { CategoryName } = tour.Category;
              checkIfExist(filtersObj.category, CategoryName) &&
                filtersObj.category.push({
                  id: CategoryName,
                  label: CategoryName,
                  isChecked: false,
                });
              const { Packagesmodel } = tour;
              checkIfExist(filtersObj.packagesModel, Packagesmodel) &&
                filtersObj.packagesModel.push({
                  id: Packagesmodel,
                  label: Packagesmodel,
                  isChecked: false,
                });
              const { Nights } = tour;
              checkIfExist(filtersObj.nights, Nights) &&
                filtersObj.nights.push({
                  id: Nights,
                  label: Nights,
                  isChecked: false,
                });

              return {
                ...tour,
                isVisible: true,
              };
            });

            filtersObj.nights.sort(function (a, b) {
              return a.id - b.id;
            });

            resp = {
              tours: {
                ...resp.holidays,
                tours: tours,
                traceId: resp.traceId,
                journeyDate: obj.travellingOn,
                filtersObj: filtersObj,
              },
            };

            updateHolidaySearchResultObj(resp);
            setHolidayResultsList(resp.tours);
          }
        } else {
          setHolidayResultsList(HolidayInitial);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setHolidayResultsList(HolidayInitial);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    searchAllTours();
  }, [window.location.search]);

  const [popUpVisibility, setPopUpVisibility] = useState(false);

  function onInactive(ms, cb) {
    var wait = setTimeout(cb, ms);

    document.onmousemove =
      document.mousedown =
      document.mouseup =
      document.onkeydown =
      document.onkeyup =
      document.focus =
      document.scroll =
      function () {
        clearTimeout(wait);

        if (
          !localStorage.getItem("popupShown") &&
          JSON.parse(localStorage.getItem("popupShown")) !== true
        ) {
          wait = setTimeout(cb, ms);
        }
      };
  }



  useEffect(() => {
    if (
      !localStorage.getItem("popupShown") &&
      JSON.parse(localStorage.getItem("popupShown")) !== true
    ) {
      onInactive(100000, function () {
        setPopUpVisibility(false);
      });
    }
  }, []);

  return (
    <>
      <div className="promo providerapi ">

        {isFromPackage ? null : (
          <section className="holiday_modify  buses-div-bg">
            <div className="hotel-modify-container">
              <Collapse
                activeKey={md ? ["1"] : key}
                showArrow={false}
                onChange={(val) => {
                  setKey(val);
                }}
              >
                <Panel
                  showArrow={false}
                  header={
                    <span className="hotels-hide-search">Modify Search</span>
                  }
                  key="1"
                >
                  <div className="main_div sub_div"
                    style={{ marginTop: "-4%" }}>
                    <HolidaySearchForm
                      modifySearch={true}
                      onModify={searchAllTours}
                    />
                  </div>
                </Panel>
              </Collapse>
            </div>
          </section>
        )}
      </div>

      <div className=" holiday-bar-panel">
        <div className="lin"></div>
        <div className="holiday-page-wrapper">
          <div className="travel-img">
            <div className="admin-container holiday-wrapper">
              {isLoading ? (
                <section>
                  <div className="hotel-skeleton-layout">
                    <div className="hotel-skeleton-layout-container">
                      <Row gutter={16}>
                        {isFromPackage ? (
                          <Col md={24} xs={24} className="result-body-skeleton">
                            <Card className="card-skeleton">
                              <Skeleton active={true} paragraph={{ rows: 0 }} />
                            </Card>

                            {[...Array(6)].map((_, key) => (
                              <SkeletonLayout key={key + "SkeletonLayout"} />
                            ))}
                          </Col>
                        ) : (
                          <>
                            <Col md={6} xs={0} className="filter-skeleton">
                              <FilterSkeleton />
                            </Col>
                            <Col
                              md={18}
                              xs={24}
                              className="result-body-skeleton"
                            >
                              <Card className="card-skeleton">
                                <Skeleton
                                  active={true}
                                  paragraph={{ rows: 0 }}
                                />
                              </Card>

                              {[...Array(6)].map((_, key) => (
                                <SkeletonLayout key={key + "SkeletonLayout"} />
                              ))}
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                  </div>
                </section>
              ) : Object.keys(holidaySearchResultObj).length > 0 &&
                holidaySearchResultObj?.tours &&
                holidaySearchResultObj?.tours?.tours?.length > 0 ? (
                  <>
                   {status !=0 &&
                  <div className="countdown3">
                  <div className="countdown-main-box">
                    <div className="countdown1">
                      <span class="fa fa-clock-o" style={{ fontSize: "18px" }}></span><span> Your session will expire in</span>
                    </div>
                    <div className="countdown2">
                      <Countdown
                        className="busCountdown"
                        onFinish={handelCountdown}
                        value={sessiontimeout}
                        format={"mm[ min]  ss[ sec]"}
                      />
                    </div>
                  </div>
                </div>}
                {status !=0 &&
                <div className="phone-view-for-result-page">
                  <Countdown
                    className="busCountdown"
                    //onFinish={handelCountdown}
                    value={sessiontimeout}
                    format="mm:ss"
                  />
                </div>  }
                <Row gutter={16}>
                  <Col md={6} className="d-none-small filter-holiday-sys">
                    <HolidayFilter
                      holidaySearchResultObj={holidaySearchResultObj}
                      setHolidayResultsList={setHolidayResultsList}
                    />
                  </Col>
                  <Col md={18} sm={24} xs={24} className="holiday-res-col-wor">
                    <Row className="d-none-small">
                      <Col md={24} xs={24}>

                        <Holidaysort
                          holidayResultObj={holidaySearchResultObj}
                          setHolidayResultsList={setHolidayResultsList}
                        />
                      </Col>
                    </Row>

                    {holidayResultsList.tours.length > 0 ? (
                      holidayResultsList.tours.map((tourObj, key) => {
                        return (
                          <>


                            <HolidayList
                              toursData={tourObj}
                              showNetFare={showNetFare}
                              updateSelectedHolidayData={
                                updateSelectedHolidayData
                              }
                              updateHolidayBookingData={
                                updateHolidayBookingData
                              }
                              travelDate={
                                holidaySearchResultObj?.tours?.journeyDate
                              }
                              traceId={holidaySearchResultObj?.tours?.traceId}
                            />
                          </>
                        );
                      })
                    ) : (
                      <NoResultFound />
                    )}
                  </Col>
                </Row>
                </>
              ) : (
                <NoResultFound />
              )}
            </div>
          </div>
        </div>
      </div>



      <Row className="fiters-value-hotel">
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" style={{ color: 'white' }} onClick={showModalflight}>
            {" "}
            <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;Filter
          </h5>
        </Col>
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" style={{ color: 'white' }} onClick={showModalsort}>
            <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
            &nbsp;Sort
          </h5>
        </Col>
      </Row>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Filters</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => setModalVisible(false)}>
              Close
            </Button>
          </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            <HolidayFilter
              holidaySearchResultObj={holidaySearchResultObj}
              setHolidayResultsList={setHolidayResultsList}
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Sort by</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => setModalVisiblenew(false)}>
              Close
            </Button>
          </div>,
        ]}
      >
        <Form>
          <Row>
            <Col md={24} xs={24}>
              {holidayResultsList.tours.length > 0 && (
                <Holidaysort
                  holidayResultObj={holidaySearchResultObj}
                  setHolidayResultsList={setHolidayResultsList}
                />
              )}
            </Col>
          </Row>
        </Form>
      </Modal>


    </>
  );
};

export default HolidayResults;
