import React, { createContext, useState, useContext } from "react";

import { useSessionStorage } from "../../../helpers/useStorage";
import ApiClient from "../../../helpers/ApiClient";

export const HotelContext = createContext();

export function useHotelContext() {
  return useContext(HotelContext);
}

const HotelProvider = ({ children }) => {
  const [hotelCheckOutData, setHotelCheckOutData] = useSessionStorage(
    "hotelcheckoutdata",
    {}
  );
  const [sessiontimeout, setsessiontimeout] = useState([]);
  const [status, setStatus] = useState('');

  const getsessiontimeout = () => {
    ApiClient.get("admin/get_active_session_timeout/2")
      .then((res) => {
        if (res.status == 200) {
          setsessiontimeout(new Date().getTime() + Number((res?.data?.sessionTime) * 60 * 1000));
          setStatus(res.data.status);
        }
        else {
          setsessiontimeout(new Date().getTime() + Number(0));
          setStatus(res.data.status);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <HotelContext.Provider
      value={{
        state: { hotelCheckOutData ,sessiontimeout,status},
        setHotelCheckOutData,
        getsessiontimeout: () => {
          getsessiontimeout();
        },
      }}
    >
      {children}
    </HotelContext.Provider>
  );
};
export default HotelProvider;
