import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  message,
  Modal,
  Row,
  Spin,
  Image,
} from "antd";
import moment from "moment";
import queryString from "query-string";
import { useAuthContext } from "../../providers/AuthProvider";
import { useSytContext } from "../../providers/SytProvider";
import APIClient from "../../../helpers/ApiClient";
import TicketHotel from "./TicketHotel";
import TicketSidebar from "../TicketSidebar";
import LogoImg from "../../../assets/images/trypmaker.png";


const { Panel } = Collapse;
const dateFormat = "DD MMM YYYY";
const HotelTicketScreen = ({ mode }) => {
  const {
    user,
    isLogin: { agent },
  } = useAuthContext();
  const { agentLogo, logo } = useSytContext();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const ticketSearchParams = queryString.parse(window.location.search);
  const BASE = process.env.REACT_APP_BASE_URL;

  const [ticketData, setTicketData] = useState({});
  const [loadingTicket, setLoadingTicket] = useState(true);
  const [cmsFareRules, setCmsFareRules] = useState({});

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const fetchTicketDetails = (ref) => {
    setLoadingTicket(true);
    APIClient.get("hotels-v2/hotelDetails/" + ref)
      .then((res) => {
        if (res.status == 200) {
          if (mode === "USER") {
            let userId = user?.UserID ?? 1;
            if (res.data.UserId === userId) {
              setTicketData(res.data);
            } else {
              setTicketData({});
            }
          } else {
            setTicketData(res.data);
          }
        } else {
          setTicketData({});
        }
        setLoadingTicket(false);
      })
      .catch((error) => {
        setTicketData({});
        setLoadingTicket(false);
      });
  };

  const cancelTicket = () => {
    setModalVisible(true);
  };
  const getStatus = () => {
    setModalStatus(true);
  };
  const submitStatusForm = (val) => {
    setLoading(true);

    if (ticketData.traceId == "" && ticketData.RefNumber == "") {
      return;
    }
    let data = {
      traceId: ticketData.traceId,
      RefNumber: ticketData.RefNumber,
    };
    APIClient.post("hotels-v2/hotelCancelStatus", data)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data.bookingStatus == 3) {
            message.success(
              `${res.data.message}. Your total refund amount is Rs. ${res.data.refundAmount} `);
          }
          else {
            message.success(
              `Your Status is Still In Progress.`
            
            );
          }
          getTicketDetails();
          setModalVisible(false);
          setModalStatus(false)
          getCmsFareRules(3);
        } else if (res.status === 400) {
          if (res.data.length > 0) {
            res.data.map((err) => {
              if (err.errorCode === "6033") {
                message.error("Ticket has cancelled already.");
              }
            });
            setModalVisible(false);
          }
        }
        else{
          setModalVisible(false);
          setModalStatus(false);
          message.error(res.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setModalVisible(false);
        console.error(error);
      });

  };
  const submitCancelForm = (val) => {
    setLoading(true);

    if (ticketData.traceId == "" && ticketData.RefNumber == "") {
      return;
    }
    let data = {
      traceId: ticketData.traceId,
      RefNumber: ticketData.RefNumber,
    };

    APIClient.post("hotels-v2/hotelCancel/", data)
      .then((res) => {

        setLoading(false);
        if (res.status === 200) {
          if (
            [
              "CancellationRequest",
              "CancellationPending",
              "CancellationInProgress",
            ].includes(res.data.bookingStatus)
          ) {
            hotelCancelStatus(data);
          } else {
            message.success(
              `${res.data.Message}. Your total refund amount is Rs. ${res.data.refundAmount} `,
              10
            );

            getTicketDetails();
            setModalVisible(false);
          }
        } else if (res.status === 400) {
          if (res.data.length > 0) {
            res.data.map((err) => {
              if (err.errorCode === "6033") {
               
              }
            });
            setModalVisible(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const hotelCancelStatus = (data) => {
    APIClient.post("hotels-v2/hotelCancelStatus/", data)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          message.success(
            `Cancellation Request Sent Succesfull`,
            10
          );
          getTicketDetails();
          setModalVisible(false);
          getCmsFareRules(3);
        } else if (res.status === 400) {
          if (res.data.length > 0) {
            res.data.map((err) => {
              if (err.errorCode === "6033") {
                
              }
            });
            setModalVisible(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const getTicketDetails = () => {
    fetchTicketDetails(ticketSearchParams.ref);
  };
  useEffect(() => {
    getTicketDetails();
  }, [ticketSearchParams.ref]);
  const getCmsFareRules = (serviceType) => {
    APIClient.get(`admin/getFareRulesbyserviceType/${serviceType}`)
      .then((res) => {
        if (res.status == 200) {
          setCmsFareRules(res.data);
        } else {
          setCmsFareRules({});
        }
      })
      .catch((e) => {
        setCmsFareRules({});
      });
  };
  return (
    <div className="flight-ticket-collapse">
      
      <Card>
        <div className="fligh-ticket-container">
          {loadingTicket ? (
            <div style={{ textAlign: "center" }} className="flight-ticket">
              <Spin indicator={antIcon} tip="Loading..." />
            </div>
          ) : Object.keys(ticketData).length > 0 &&
            [1, 2, 3, 6, 7, 8, 9].includes(ticketData.BookingStatus) ? (
            <div className="flight-ticket">

              <Row gutter={[32, 16]} className="ticket-row">
                <Col md={18} className="ticket-coloum">

                  <TicketHotel
                    ticketData={ticketData}
                    cmsFareRules={cmsFareRules}
                  />

                </Col>

                <Col xs={24} md={6} className="tic-info-flight">
                  <div className="web-tic-info">
                    <TicketSidebar
                      ticketData={ticketData}
                      ticketSearchParams={ticketSearchParams}
                      type="Hotel"
                      onCancelTicket={cancelTicket}
                      getTicketDetails={getTicketDetails}
                      cmsFareRules={cmsFareRules}
                      onGetStatus={getStatus}
                    />
                  </div>

                  <div className="mobile-tic-info">
                    <Collapse accordion>
                      <Panel header="Manage Tickets" key="1">
                        <TicketSidebar
                          ticketData={ticketData}
                          ticketSearchParams={ticketSearchParams}
                          type="Hotel"
                          onCancelTicket={cancelTicket}
                          getTicketDetails={getTicketDetails}
                          cmsFareRules={cmsFareRules}
                          onGetStatus={getStatus}
                        />
                      </Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <p>No Ticket Found</p>
          )}
        </div>
      </Card>

      <Modal
        title="Cancel Ticket"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={submitCancelForm}
              loading={loading}
            >
              Cancel Ticket
            </Button>
          </div>,
        ]}
        width={"600px"}
      >
        {Object.keys(ticketData).length > 0 &&
          [1, 2, 3, 7, 8, 9].includes(ticketData.BookingStatus) ? (
          <div className="wrapper">
            <p>
              <b>Reference No</b> : {ticketData.RefNumber}
            </p>
            <p>
              <b>ConfirmationNumber :</b> : {ticketData.ConfirmationNumber}
            </p>
            <p>
              <b>Hotel Name :</b> : {ticketData.HotelName}
            </p>

            <p>
              <b>Check In Date:</b> :{" "}
              {moment(ticketData.CheckInDate).format(dateFormat)}
            </p>
            <p>
              <b> Check Out Date:</b> :{" "}
              {moment(ticketData.CheckOutDate).format(dateFormat)}
            </p>
          </div>
        ) : null}
      </Modal>
      <Modal
        title="Cancel Ticket"
        className="promo-modal-header"
        visible={modalStatus}
        onOk={(e) => setModalStatus(false)}
        onCancel={(e) => setModalStatus(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={submitStatusForm}
              loading={loading}
            >
              Get Status
            </Button>
          </div>,
        ]}
        width={"600px"}
      >
        {Object.keys(ticketData).length > 0 &&
          [1, 2, 7, 8, 9].includes(ticketData.BookingStatus) ? (
          <div className="wrapper">
            <p>
              <b>Reference No</b> : {ticketData.RefNumber}
            </p>
            <p>
              <b>PNR :</b> : {ticketData.ConfirmationNumber}
            </p>
            <p>
              <b>Hotel Name :</b> : {ticketData.HotelName}
            </p>

            <p>
              <b>Check In Date:</b> :{" "}
              {moment(ticketData.CheckInDate).format(dateFormat)}
            </p>
            <p>
              <b> Check Out Date:</b> :{" "}
              {moment(ticketData.CheckOutDate).format(dateFormat)}
            </p>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};
export default HotelTicketScreen;
