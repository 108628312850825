import React from 'react'
import { SearchOutlined, DownOutlined, SwapOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Radio,
    Row,
    Select,
    Dropdown,
    Space,
    Checkbox
} from "antd";
import moment from "moment";
import "../flight-search/ModifyFlightSearch.scss";
import MultiCityList from "./MultiCityList1";
import {
    createQueryParamsFromRequest,
    createSearchFromForm,
    getSearchRequestFromQuery,
    MapRequestToForm,
} from "./SearchRequestHelper";

import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import arrowImg from "../../assets/images/flight-arrow.png";
import AirportAutoCompleteSelectByCategory from "../../common/AutoCompleteSelect/AirportAutoCompleteSelectByCategory";
import { useSytContext } from "../../common/providers/SytProvider";
import { AppConstants } from "../../helpers/constants";
import { OriDesValidate } from "../../helpers/CustomValidators";
import { useSessionStorage } from "../../helpers/useStorage";
import "../flight-search/FlightSearch.scss";
import queryString from "query-string";
const ModifyFlightSearch = (props) => {

    let history = useHistory();
    const origin = useRef();
    const destination = useRef();

    const dateBox = useRef();
    const returnDateBox = useRef();
    const paxBox = useRef();
    const searchBtn = useRef();
    const [showDate, setShowDate] = useState(false);
    const [showReturnDate, setShowReturnDate] = useState(false);
    const { Option } = Select;
    const [form] = Form.useForm();

    const [FlightRecentSearchesResults, setFlightRecentSearchesResults] =
        useSessionStorage("recentSearchesResults", []);

    const departureDate = moment();
    const [arrivalDate, setArrivalDate] = useState(moment());
    const [value, setValue] = useState(1);
    const [travel_type, setTravelType] = useState("oneWay");
    const [passengers, setPassengers] = useState({
        adults: 1,
        childs: 0,
        infants: 0,
    });

    const [toggle, setTogle] = useState(false);
    const [toggleTrip, setTogleTrip] = useState(false);
    const [toggle1, setTogle1] = useState(false);


    const [cabinClass, setcabinClass] = useState("Economy");
    const [noofroutes, setnoofroutes] = useState(2);
    const [faresearchType, setfaresearchType] = useState("REGULAR");
    const setUpFormInitial = () => {
        let results = false;
        if (props.modify) {
            let query_request = getSearchRequestFromQuery();
            results = MapRequestToForm(query_request);
        } else {
            let stored_request = JSON.parse(
                sessionStorage.getItem("flight_search_request")
            );
            results = MapRequestToForm(stored_request);
        }

        if (results) {
            setTravelType(results.airTravelType);

            form.setFieldsValue(results);
            setPassengers({
                adults: results.adults,
                childs: results.childs,
                infants: results.infants,
            });
        }
    };

    const recentSearches = (searchObj) => {
        if (searchObj) {
            searchObj = queryString.parse(searchObj);
            if (FlightRecentSearchesResults.length > 0) {
                let array = [];
                array = [...FlightRecentSearchesResults];
                if (array.length > 4) {
                    array.pop();
                }

                if (searchObj) {
                    setFlightRecentSearchesResults([
                        searchObj,
                        ...array.filter(
                            (item) =>
                                item.origin !== searchObj.origin &&
                                item.destination !== searchObj.destination
                        ),
                    ]);
                }

                return;
            }
        }

        setFlightRecentSearchesResults([searchObj]);
    };
    let intitial = {
        cabinClass: "Economy",
        departure: departureDate,

        originDestinationFields: [{}, {}],
    };

    useEffect(() => {
        const searchParams = queryString.parse(window.location.search);
        setValue(searchParams?.priceForTrip == "REGULAR" ? 1 : 2)
        setcabinClass(searchParams?.cabinClass)
    }, [])

    useEffect(() => {
        setUpFormInitial();
        let origin = form.getFieldValue("origin");
        let destination = form.getFieldValue("destination");
        if (!origin && !destination) {
            form.setFieldsValue({
                origin: "DEL",
                destination: "BOM",
            });
            setDataFlip((prev) => !prev);
        }
    }, [window.location.search]);

    const { topCities } = useSytContext();
    const [FlightTopCities, setFlightTopCities] = useState([]);
    useEffect(() => {
        if (topCities.length) {
            let data = topCities.filter((item) => item.ServiceType === 1);
            if (data.length) {
                setFlightTopCities(data);
            } else {
                setFlightTopCities([]);
            }
        }
    }, [topCities]);

    const updateTravelType = (val) => {
        if (val) {
            if (val === "oneWay") {
                form.setFieldsValue({
                    arrival: "",
                });
            } else {
                form.setFieldsValue({
                    arrival: moment().add(1, "days"),
                });
            }

            setTravelType(val);
        }
    };

    const onFormSubmit = (formData) => {
        let includeCarrier = "";
        let stopOver = "none";
        const searchRequest = createSearchFromForm(
            formData,
            travel_type,
            cabinClass,
            passengers,
            includeCarrier,
            stopOver,
            faresearchType
        );

        const params = createQueryParamsFromRequest(searchRequest);
        recentSearches(params);

        if (props.modify) {
            history.replace("/flight/results?" + params);
            props.onFormSubmit();
        } else {
            history.push("/flight/results?" + params);
        }
    };

    const handleOnSubmit = (ref) => {
        ref.current.focus();
        if (ref === dateBox) {
            setShowDate(true);
        }
    };
    const handleFocus = () => {
        searchBtn.current.focus();
    };
    const updatePaxCount = (opr) => {
        if (passengers.adults + passengers.childs + passengers.infants === 9) {
            if (opr === "adultInc" || opr === "infantInc" || opr === "childInc") {
                return;
            }
        }
        switch (opr) {
            case "adultInc":
                setPassengers({ ...passengers, adults: passengers.adults + 1 });
                break;
            case "adultDec":
                if (passengers.adults > 1) {
                    setPassengers({ ...passengers, adults: passengers.adults - 1 });
                }
                break;
            case "childInc":
                setPassengers({ ...passengers, childs: passengers.childs + 1 });
                break;
            case "childDec":
                if (passengers.childs >= 1) {
                    setPassengers({ ...passengers, childs: passengers.childs - 1 });
                }
                break;
            case "infantInc":
                setPassengers({ ...passengers, infants: passengers.infants + 1 });
                break;
            case "infantDec":
                if (passengers.infants >= 1) {
                    setPassengers({ ...passengers, infants: passengers.infants - 1 });
                }
                break;
        }
    };

    const disabledOriginDate = (currentDate) => {
        return currentDate < moment(departureDate).startOf("day");
    };

    const disabledDestDate = (currentDate) => {
        return currentDate < moment(arrivalDate).startOf("day");
    };
    const ClassInput = () => (
        <Form.Item className="flight-passenger">
            <Input
                ref={paxBox}
                size="large"
                readOnly
                value={cabinClass}
                style={{ cursor: "pointer" }}
                onClick={() => setTogle1(toggle1 ? false : true)}
            />
            <div
                style={{
                    display: toggle1 ? "block" : "none", width: "350px"
                }}
                className="pax-modal"
            >
                <div className="pax-modal-wrapper">
                    <div className="pax-modal-arrow"></div>


                    <div className="travel_class" >CHOOSE TRAVEL CLASS
                        <ul>
                            <li onClick={() => setcabinClass("Economy")} style={{
                                display: "inline-flex", boxShadow: "0 0 6px 0 rgb(0 0 0 / 20%)", background
                                    : cabinClass === "Economy" ? "#008cff" : ""
                            }}>Economy</li>
                            <li onClick={() => setcabinClass("Business")} style={{
                                display: "inline-flex", marginLeft: "5%", boxShadow: "0 0 6px 0 rgb(0 0 0 / 20%)"
                                , background: cabinClass === "Business" ? "#008cff" : ""
                            }}>Business</li>
                            <li onClick={() => setcabinClass("first")} style={{
                                display: "inline-flex", marginLeft: "5%", boxShadow: "0 0 6px 0 rgb(0 0 0 / 20%)"
                                , background: cabinClass === "first" ? "#008cff" : ""
                            }}>First Class</li>

                            <br />
                            <li onClick={() => setcabinClass("premiumEconomy")} style={{
                                display: "inline-flex", width: "48%", padding: "5px 5px", borderRadius: "10px", boxShadow: "0 0 6px 0 rgb(0 0 0 / 20%)"
                                , background: cabinClass === "premiumEconomy" ? "#008cff" : ""
                            }}>Premium Economy</li>

                            <li onClick={() => setcabinClass("premiumFirst")} style={{
                                display: "inline-flex", width: "48%", marginLeft: "4%", padding: "5px 5px", borderRadius: "5px", boxShadow: "0 0 6px 0 rgb(0 0 0 / 20%)"
                                , background: cabinClass === "premiumFirst" ? "#008cff" : ""
                            }}>Premium First Class</li>

                        </ul>
                    </div>
                    <div>
                        <Button
                            block
                            className="pax-ready-btn"
                            onClick={() => {
                                setTogle1((prev) => !prev);
                                if (toggle1) {
                                    handleFocus();
                                }
                            }}
                        >
                            Ready
                        </Button>
                    </div>
                </div>
            </div>
        </Form.Item>
    );

    const PaxInput = () => (
        <Form.Item className="flight-passenger">
            <Input
                ref={paxBox}
                size="large"
                readOnly
                value={passengers.adults + passengers.childs + passengers.infants}
                style={{ cursor: "pointer" }}
                onClick={() => setTogle(toggle ? false : true)}
            />
            <div
                style={{
                    display: toggle ? "block" : "none", width: "350px"
                }}
                className="pax-modal"
            >
                <div className="pax-modal-wrapper">
                    <div className="pax-modal-arrow"></div>
                    <li>
                        <div className="pax-label">
                            <p>Adults</p>
                            <span>over 12 years old</span>
                        </div>
                        <div className="pax-count">
                            <i
                                className="fa fa-minus"
                                aria-hidden="true"
                                onClick={(e) => updatePaxCount("adultDec")}
                            ></i>
                            <span>{passengers.adults}</span>
                            <i
                                className="fa fa-plus"
                                aria-hidden="true"
                                onClick={(e) => updatePaxCount("adultInc")}
                            ></i>
                        </div>
                    </li>

                    <li>
                        <div className="pax-label">
                            <p>Children</p>
                            <span>2 - 12 years old</span>
                        </div>
                        <div className="pax-count">
                            <i
                                className="fa fa-minus"
                                aria-hidden="true"
                                onClick={(e) => updatePaxCount("childDec")}
                            ></i>
                            <span>{passengers.childs}</span>
                            <i
                                className="fa fa-plus"
                                aria-hidden="true"
                                onClick={(e) => updatePaxCount("childInc")}
                            ></i>
                        </div>
                    </li>
                    <li>
                        <div className="pax-label">
                            <p>Infants</p>
                            <span>upto 2 years old</span>
                        </div>
                        <div className="pax-count">
                            <i
                                className="fa fa-minus"
                                aria-hidden="true"
                                onClick={(e) => updatePaxCount("infantDec")}
                            ></i>
                            <span>{passengers.infants}</span>
                            <i
                                className="fa fa-plus"
                                aria-hidden="true"
                                onClick={(e) => updatePaxCount("infantInc")}
                            ></i>
                        </div>
                    </li>
                    {passengers.infants > passengers.adults ? (
                        <li>
                            <span
                                style={{
                                    fontSize: "11px",
                                    color: "red",
                                }}
                            >
                                The number of infants cannot be grater than the number of
                                adults.
                            </span>
                        </li>
                    ) : (
                        ""
                    )}
                    {passengers.adults + passengers.child > 9 ? (
                        <li>
                            <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                style={{ fontSize: "30px" }}
                            ></i>
                            &nbsp;&nbsp;
                            <span
                                style={{
                                    fontSize: "11px",
                                    color: "blue",
                                }}
                            >
                                Booking for more than 9 persons will be procced as group
                                booking.
                            </span>
                        </li>
                    ) : (
                        ""
                    )}

                    <div>
                        <Button
                            block
                            className="pax-ready-btn"
                            onClick={() => {
                                setTogle((prev) => !prev);
                                if (toggle) {
                                    handleFocus();
                                }
                            }}
                        >
                            Ready
                        </Button>
                    </div>
                </div>
            </div>
        </Form.Item>
    );

    const [dataFlip, setDataFlip] = useState(false);


    const handleSwap = () => {
        const origin = form.getFieldValue("origin");
        const destination = form.getFieldValue("destination");

        if (origin && destination) {
            form.setFieldsValue({
                origin: destination,
                destination: origin,
            });
            setDataFlip((prev) => !prev);
        }
    };

    const onChangeOriginDate = (momentdate, _) => {
        let originDate = momentdate ? moment(momentdate).startOf("day") : "";
        setArrivalDate(momentdate);
        const toDate = form.getFieldValue("arrival");
        if (travel_type === "roundTrip" && toDate) {
            let a = moment(toDate).startOf("day");
            let diffDays = a.diff(originDate, "days");
            if (diffDays <= 0) {
                let newTodate = moment(momentdate).add(2, "days");
                form.setFieldsValue({
                    arrival: newTodate,
                });
            }
        }
    };
    const getnoofroutes = (count) => {
        setnoofroutes(count)
        updateinputfield(count - 2);
    }

    const updateinputfield = (text) => {
        const fiel = form.getFieldValue("originDestinationFields");
        if (fiel?.[text]?.destination && noofroutes - 1 > text) {
            fiel[text + 1] = { ...fiel?.[text + 1], ["origin"]: fiel?.[text]?.destination };
            form.setFieldsValue("originDestinationFields", fiel)
        }
    }
    const getsearchType = (e) => {
        setValue(e.target.value);
        setfaresearchType(e.target.value == 1 ? "REGULAR" : "STUDENT")
    };
    const getTripType = (e) => {

        updateTravelType(e.target.value);
        setTogleTrip(false);
    };
    const ChangeDates = (date, key) => {
        const fiel = form.getFieldValue("originDestinationFields");
        if (noofroutes - 1 > key) {
            fiel[key + 1] = { ...fiel?.[key + 1], ["departureDateTime"]: date };
            form.setFieldsValue("originDestinationFields", fiel)
        }

    }
    return (<>
        <div className="modify_search_bar">
            <div className="sub_modify_search_bar">

                <div>
                    <div id="oneWayForm">


                        <div className="fare_div">
                            <div className="faretype_container">
                                <ul className="Faretypes_list">
                                    <Radio.Group onChange={getTripType} value={travel_type}>
                                        <Radio value={"oneWay"} > One-Way  </Radio >
                                        <Radio value={"roundTrip"} >  Round-Trip</Radio >
                                        <Radio value={"multidestination"} >  Multi-City</Radio >
                                    </Radio.Group>

                                </ul>
                            </div>
                        </div>
                        <Form
                            form={form}
                            onFinish={onFormSubmit}
                            layout="vertical"
                            initialValues={intitial}
                        >


                            <Row >
                                {travel_type !== "multidestination" ? (
                                    <>


                                        <Col md={3}  >
                                            <div className="From_Auto">
                                                <label className="source_a">FROM</label>
                                                <AirportAutoCompleteSelectByCategory

                                                    formItemProps={{
                                                        name: "origin",

                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Please Specify The City",
                                                            },
                                                            {
                                                                validator: (_, value) => {
                                                                    let destination =
                                                                        form.getFieldValue("destination");
                                                                    return OriDesValidate(value, destination);
                                                                },
                                                            },
                                                        ],
                                                    }}
                                                    selectProps={{
                                                        size: "large",
                                                        placeholder: "Origin",
                                                    }}
                                                    refName={origin}
                                                    focusRef={destination}
                                                    handleOnSubmit={handleOnSubmit}
                                                    dataFlip={dataFlip}
                                                    topCities={FlightTopCities}
                                                    recentKey={"originRecent"}
                                                />
                                            </div>
                                        </Col>
                                        <span className="swip-btn-flight-work1-modify"  >
                                           
                                            <img
                                                className="arrow-bus-inr"
                                                src={arrowImg}
                                                alt="Arrow img"
                                                onClick={handleSwap}
                                            />       </span>
                                        <Col md={3} >
                                            <div className="From_Auto">
                                                <label className="source_a">TO</label>
                                                <AirportAutoCompleteSelectByCategory
                                                    formItemProps={{
                                                        name: "destination",
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Please Specify The City",
                                                            },
                                                            {
                                                                validator: (_, value) => {
                                                                    let origin = form.getFieldValue("origin");
                                                                    return OriDesValidate(origin, value);
                                                                },
                                                            },
                                                        ],
                                                    }}
                                                    selectProps={{
                                                        size: "large",
                                                        placeholder: "Destination",
                                                    }}
                                                    refName={destination}
                                                    focusRef={dateBox}
                                                    handleOnSubmit={handleOnSubmit}
                                                    dataFlip={dataFlip}
                                                    topCities={FlightTopCities}
                                                    recentKey={"destinationRecent"}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={4}  >
                                            <div className="depart_date">
                                                <label className="source_a">DEPART</label>
                                                <Form.Item
                                                    name="departure"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please Select a Date",
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        allowClear={false}
                                                        style={{ width: "100%" }}
                                                        className="train-search-btn "
                                                        size="large"
                                                        format={"dddd, MMM Do YY"}
                                                        disabledDate={disabledOriginDate}
                                                        onChange={(date, dateString) =>
                                                            onChangeOriginDate(date, dateString)
                                                        }
                                                        ref={dateBox}
                                                        open={showDate}
                                                        onOpenChange={() => {
                                                            setShowDate((prev) => !prev);
                                                        }}
                                                        placeholder="Departure Date"
                                                        dropdownClassName="custom-mob-calendar"
                                                        inputReadOnly={true}

                                                        panelRender={(originalPanel) => {
                                                            return (
                                                                <div className="original-panel">
                                                                    <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6 custom-mob-calendar-title">
                                                                        Departure Date
                                                                    </p>
                                                                    {originalPanel}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        <Col md={4}  >
                                            <div className="depart_date">
                                                <label className="source_a">RETURN</label>
                                                <Form.Item
                                                    className="returnDate"
                                                    name="arrival"
                                                    rules={[
                                                        {
                                                            required:
                                                                travel_type === "roundTrip" ? true : false,
                                                            message: "Please Select a Date",
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        dropdownClassName="custom-mob-calendar"
                                                        inputReadOnly={true}
                                                        style={{ width: "100%" }}
                                                        className="train-search-btn"
                                                        size="large"
                                                        format={"dddd, MMM Do YY"}
                                                        defaultPickerValue={arrivalDate}
                                                        disabledDate={disabledDestDate}
                                                        onChange={(date) => {
                                                            if (!date) {
                                                                form.setFieldsValue({
                                                                    arrival: "",
                                                                });
                                                                setTravelType("oneWay");
                                                            }
                                                        }}
                                                        ref={returnDateBox}
                                                        open={showReturnDate}
                                                        onOpenChange={() => {
                                                            setShowReturnDate((prev) => !prev);
                                                        }}
                                                        placeholder="Return Date"
                                                        disabled={travel_type !== "roundTrip" ? true : false}
                                                        onClick={() => {
                                                            if (travel_type != "roundTrip") {
                                                                setTravelType("roundTrip");
                                                                form.setFieldsValue({
                                                                    arrival: moment().add(1, "days"),
                                                                });
                                                            }
                                                        }}
                                                        panelRender={(originalPanel) => {
                                                            return (
                                                                <div className="original-panel">
                                                                    <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6">
                                                                        Return Date
                                                                    </p>
                                                                    {originalPanel}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </>
                                ) : (<Col md={15} className="multicity_modify">
                                    <MultiCityList updateinputfield={updateinputfield} getnoofroutes={getnoofroutes} ChangeDates={ChangeDates} />
                                </Col>)}

                                <Col md={2} style={{ marginLeft: travel_type !== "multidestination" ? "0px" : "-119px" }}>
                                    <div className="passenger_class">
                                        <label className="source_a">PASSENGERS</label>
                                        <PaxInput />
                                    </div>
                                </Col>
                                <Col md={3} style={{ marginLeft: travel_type !== "multidestination" ? "0px" : "5px" }}>
                                    <div className="passenger_class">
                                        <label className="source_a">CLASS</label>
                                        <ClassInput />
                                    </div>
                                </Col>
                                <Col md={3} >
                                    <div className="button_modify">
                                        <Button
                                            ref={searchBtn}
                                            className="primary-btn"
                                            htmlType="submit"
                                        >

                                            Search
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div >
                    <div className="fare_div">
                        <div className="faretype_container">

                            <ul className="Faretypes_list">
                                <Radio.Group onChange={getsearchType} value={value}>
                                    <Radio value={1} > REGULAR  </Radio >
                                    <Radio value={2} >  STUDENT</Radio >
                                </Radio.Group>
                            </ul>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    </>)
}
export default ModifyFlightSearch;