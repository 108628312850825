import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Popconfirm,
  Table,
  Modal,
  message,
} from "antd";

import excel from "../../assets/vector-icons/excel.png";
import search from "../../assets/vector-icons/search.png";

import ApiClient from "../../helpers/ApiClient";
import { getTypeSign } from "../AdminMarkup/commissionhelper";
import UpdatedComponent from "../ExportExcel";
import { CloseOutlined, PlusCircleFilled } from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
const { Option } = Select;

const SiteAdminCommission = (props) => {
  const [form] = Form.useForm();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [operatorList, setOperatorList] = useState([]);
  const [inputBox, setInputBox] = useState(1);
  const [providersList, setProvidersList] = useState([]);
  const [commissionList, setCommissionList] = useState([]);
  const [tableList, setTableList] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);
  const [cmsSearchId, setCmsSearchId] = useState(1);

  const [isEdit, setIsEdit] = useState(false);
  const [currID, setCurrID] = useState(-1);

  const toggleModal = () => {
    if (isEdit) {
      setIsEdit(false);
    }
    form.resetFields();

    setModalVisible((prev) => !prev);
  };

  const handleExcel = () => {
    let copyData = tableList;
    const excelData = copyData.map((item) => {
      return {
        ServiceType: getServiceType(item.ServiceType),
        OperatorName: item.OperatorName,
        ProviderName: item.ProviderName,
        CommissionType: item.CommissionType === 0 ? "Fixed" : "Percentage",
        Commission: item.Commission,
      };
    });
    props.exportExcel(excelData, "Commission");
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(commissionList);
    }
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };
  const getServiceType = (key) => {
    switch (key) {
      case 1:
        return "Flight";
      case 2:
        return "Hotel";
      default:
        return;
    }
  };

  const setUpdateForm = (curRec) => {
    setIsEdit(true);
    setModalVisible(true);
    setCurrID(curRec.Id);
    setInputBox(curRec.ServiceType);
    form.setFieldsValue({
      ServiceType: curRec.ServiceType,
      CategoryId: curRec.Category ? curRec.Category : "",
      OperatorId: curRec.OperatorId ? curRec.OperatorId : "",
      ProviderId: curRec.ProviderId ? curRec.ProviderId : "",
      CommissionType: curRec.CommissionType,
      Commission: curRec.Commission,
      StarRating:curRec.StarRating?curRec.StarRating:"",
    });
    
  };

  const deleteRecord = (id, serviceType) => {
    ApiClient.delete("setcommission/deletesetcommission/" + id)
      .then((res) => {
        if (res?.status == 200) {
          if (res?.message) message.success(res.message, 3);
          getCommissions(serviceType);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      })
      .catch(() => { });
  };
  const columns = [
    {
      title: "Sr No",
      dataIndex: "SNo",
      sorter: (a, b) => Number(a.SNo) - Number(b.SNo),
    },
    {
      title: "Service Type",
      dataIndex: "ServiceType",
      render: (ServiceType) => getServiceType(ServiceType),
      // sorter: (a, b) => a.ServiceType.localeCompare(b.ServiceType),
    },
    {
      title: "Operator",
      dataIndex: "OperatorName",
      sorter: (a, b) => a.OperatorName.localeCompare(b.OperatorName),
    },
    {
      title: "Star Rating",
      dataIndex: "StarRating",
      sorter: (a, b) => a.StarRating.localeCompare(b.StarRating),
      render: (e) => showstarRating(e),
    },
    {
      title: "Provider",
      dataIndex: "ProviderName",
      sorter: (a, b) => a.ProviderName.localeCompare(b.ProviderName),
    },

    {
      title: "Commission",
      dataIndex: "Commission",
      sorter: (a, b) => Number(a.Commission) - Number(b.Commission),
      render: (value, currentData, index) => (
        <>{value + " " + getTypeSign(currentData.CommissionType)}</>
      ),
    },
    { title: "Actions", render: (_, rec) => tableActions(rec) },
  ];
  const showstarRating = (rating) => {
     if (rating) {
      return rating;
    }
    else {
        return "N/A"
    }
  }
  const tableActions = (rec) => {
    return (
      <div>
        <span className="edit-icon" onClick={() => setUpdateForm(rec)}>
          <i className="fa fa-edit"></i>
        </span>
        &nbsp;&nbsp;&nbsp;
        <Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => deleteRecord(rec.Id, rec.ServiceType)}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </Popconfirm>
      </div>
    );
  };
  const onFormSubmit = (values) => {
    if (values.ServiceType === 3) {
      values.ProviderId = 1;
      values.CategoryId = 0;
    }
    if (isEdit) {
      updateCommApi(values);
    } else {
      createCommApi(values);
    }
  };

  const createCommApi = (req) => {
    ApiClient.post("setcommission/createsetcommission", req)
      .then((res) => {
        if (res.status == 200) {
          if (res?.message) message.success(res.message, 3);
          toggleModal();
          form.resetFields();
          getCommissions(req.ServiceType);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      })
      .catch(() => { });
  };

  const updateCommApi = (req) => {
    ApiClient.put(`setcommission/updatesetcommission/${currID}`, {}, req).then(
      (res) => {
        if (res?.status == 200) {
          if (res?.message) message.success(res.message, 3);
          setIsEdit(false);
          setCurrID(-1);
          toggleModal();
          getCommissions(req.ServiceType);
          setCmsSearchId(req.ServiceType);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      }
    );
  };

  useEffect(() => {
    getOperators();
    getProviders();
  }, [inputBox]);
  useEffect(()=> {getCommissions(1);},[])

  const getOperators = () => {
    ApiClient.get("admin/operators")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.filter((item) => item.ServiceType === inputBox);
          setOperatorList(data);
        }
      })
      .catch((error) => { });
  };
  const getProviders = () => {
    ApiClient.get("admin/providers")
      .then((res) => {
        if (res.statusCode == 200) {
          let filterProvider = res.data.filter(
            (item) => item.ServiceType === inputBox
          );
           setProvidersList(filterProvider);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCommissions = (serviceType) => {
    setLoading(true);
    setCommissionList([]);
    setTableList([]);
    ApiClient.get(`setcommission/getsetcommissionbyservicetype/${serviceType}`)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              OperatorName: item.OperatorName && item.OperatorName != "" ? item.OperatorName : "NA",
            };
          });
           setCommissionList(data);
          setTableList(data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleServiceType = (val) => {
    setInputBox(val);

    form.setFieldsValue({
      ProviderID: "",
    });
  };
  const selectCustomFilter = (input, option) => {
    let istext =
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    if (istext) {
      return true;
    }
  };
  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  return (
    <div className="manage-markup-section">
      <div className="container-fluid">
        <div className="card-bt-gap">
          <Card bordered={false}>
            <div className="card-add-heading">
              <div className="rows-count d-block">
                <h5>
                  Set Commissions{" "}
                  <HelpInfoHelper screenName={"/admin/set-commission"} />
                </h5>
                <p>{tableList.length} rows found !</p>
              </div>
              <div className="action-images">
                {showSearchBox && (
                  <Input
                    autocomplete="newpassword"
                    placeholder="Search"
                    onChange={(e) => searchTableData(e)}
                    suffix={closeSearchInput}
                    style={{
                      padding: "0px 12px",
                      maxWidth: "250px",
                    }}
                  />
                )}
                &nbsp;&nbsp;
                <img src={search} alt="search" onClick={() => searchData()} />
                <img src={excel} alt="excel" onClick={() => handleExcel()} />
                <p className="add-deposit-icon" onClick={toggleModal}>
                  <PlusCircleFilled />
                </p>
              </div>
            </div>

            <Form layout="vertical">
              <Row gutter={(12, 12)} align="middle">
                <Col className="gutter-row" md={6} xs={24}>
                  <Form.Item
                    label="Service Type"
                    name="serviceType"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                      placeholder="Please Select"
                      onSelect={(val) => getCommissions(val)}
                      defaultValue={cmsSearchId}
                      value={cmsSearchId}
                    >
                      <Option value={1}>Flights</Option>
                      <Option value={2}>Hotels</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            {loading ? (
              <Spin />
            ) : commissionList.length > 0 ? (
              <Table
                scroll={{ x: true }}
                bordered
                rowKey={uniqueKey}
                dataSource={tableList}
                columns={columns}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            ) : (
              <b>No Results Found!</b>
            )}
          </Card>
        </div>
      </div>
      <Modal
        title={isEdit ? "Update Commission" : "Add Commission"}
        className="promo-modal-header modal-header-bg12
        "
        visible={modalVisible}
        onOk={toggleModal}
        onCancel={toggleModal}
        footer={[
          <div>
            <Button type="primary" htmlType="submit" onClick={form.submit}>
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button type="primary" danger onClick={() => toggleModal()}>
              Cancel
            </Button>
          </div>,
        ]}
        width={"700px"}
      >
        <Form form={form} layout={"vertical"} onFinish={onFormSubmit}>
          <Row gutter={16}>
            <Col className="gutter-row" md={8} xs={24}>
              <Form.Item
                label="Service Type"
                name="ServiceType"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select
                  placeholder="Please Select"
                  onSelect={handleServiceType}
                >
                  <Option value={1}>Flights</Option>
                  <Option value={2}>Hotels</Option>
                  {/* <Option value={3}>Extranet</Option> */}
                </Select>
              </Form.Item>
            </Col>
            {inputBox === 1 ? (
              <Col md={8} xs={24}>
                <Form.Item
                  label="Category"
                  name="CategoryId"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Please Select">
                    <Option value={1}>Economy Premium</Option>
                    <Option value={2}>Business</Option>
                    <Option value={3}>First Class</Option>
                    <Option value={4}>Economy</Option>
                  </Select>
                </Form.Item>
              </Col>
            ) : null}
            <Col md={8} xs={24} >
              <Form.Item
                label="Operators"
                name="OperatorId"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select
                  placeholder="Please Select"
                  filterOption={(val, op) => selectCustomFilter(val, op)}
                >
                  {operatorList.map((item, index) => (
                    <Option key={index} value={item.OperatorServiceID}>
                      {item.OperatorName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {inputBox === 2 ? (
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item label="select star rating" placeholder="select star rating" name="StarRating" rules={[{ required: true, message: "Star Rating is required" }]}>
                  <Select >
                    <Select.Option value={0}>ALL</Select.Option>
                    <Select.Option value={1}>1 star</Select.Option>
                    <Select.Option value={2}>2 star</Select.Option>
                    <Select.Option value={3}>3 star</Select.Option>
                    <Select.Option value={4}>4 star</Select.Option>
                    <Select.Option value={5}>5 star</Select.Option>
                  </Select>
                </Form.Item>

              </Col>) : null}
            {providersList.length > 0 ? (
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Provider"
                  name="ProviderId"
             
                >
                  <Select placeholder="Select Provider" allowClear>
                    {providersList.map((data, index) => (
                      <Option key={index} value={data.ProviderID}>
                        {data.Provider}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : null}

            <Col className="gutter-row" md={8} xs={24}>
              <Form.Item
                label="Commission Type"
                name="CommissionType"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select placeholder="Please Select">
                  <Option value={0}>Fixed</Option>
                  <Option value={1}>Percentage</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={8} xs={24}>
              <Form.Item
                label="Commission Rs."
                name="Commission"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Commission Value " />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(SiteAdminCommission);
