import React, { useEffect, useState } from "react";
import { Button, Card, Col, Collapse, DatePicker, Form, Input, Layout, Row, Select, Spin, Table, Tooltip, Popover } from "antd";
import moment from "moment";

import "../../components/Bookingreports/Bookingreports.scss";
import ApiClient from "../../helpers/ApiClient";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";
import { CaretRightOutlined, CloseOutlined,} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Option } = Select;
const { Panel } = Collapse;
const { Content } = Layout;

const LedgerStatement = (props) => {
  const [reportRole, setReportRole] = useState(0);
  const [UserList, setUserList] = useState([]);
  const [form] = Form.useForm();
  let dateFormat = "YYYY-MM-DD";

  const [showTable, setShowTable] = useState(false);
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [statementList, setStatementList] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [weekendDate, setWeekendDate] = useState(null);
  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = () => {
    ApiClient.get("admin/siteUsers")
      .then((res) => {
        if (res.status == 200) {
          let Users = res.data.filter((item) => item.role.RoleID == 2);
          setUserList(Users);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };
  const handleExcel = () => {
    let copyData = dataSource;
    const excelData = copyData.map((item) => {
      return {
        Name: item.Name,
        Date: item.CreatedOn,
        Remarks: item.Remarks,
        Amount: item.Amount,
        Credit: item.Credit,
        Debit: item.Debit,
        NetBalance: item.NetBalance,
      };
    });
    props.exportExcel(excelData, "Ledger_Statements");
  };

  const columns = [
    {
      title: "Sr No",
      dataIndex: "SNo",
    },
    {
      title: "Name",
      dataIndex: "Name",
    },

    {
      title: "Date",
      dataIndex: "CreatedOn",
    },
    {
      title: "Remarks",
      dataIndex: "Remarks",
    },
    {
      title: "Amount  (Rs.)",
      dataIndex: "Amount",
      sorter: (a, b) => Number(a.Amount) - Number(b.Amount),
    },
    {
      title: "Credit (Rs.)	",
      dataIndex: "Credit",
      sorter: (a, b) => Number(a.Credit) - Number(b.Credit),
    },
    {
      title: "Debit (Rs.)",
      dataIndex: "Debit",
      sorter: (a, b) => Number(a.Debit) - Number(b.Debit),
    },
    {
      title: "NetBalance (Rs.)",
      dataIndex: "NetBalance",
    },
  ];

  const getStatmentsData = (values) => {
    setLoading(true);
    setDataSource([]);
    setStatementList([]);
    ApiClient.get("filter/usertransaction/records", values)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              CreatedOn: moment(item.CreatedOn).format(dateFormat),
            };
          });

          setStatementList(data);
          setDataSource(data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = statementList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item).toLowerCase().includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      setDataSource(statementList);
    }
  };

  const handleTimeAndDate = (value) => {
    if (value === 5) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const onFormSubmit = (values) => {

    let data = {
      options: Number(values.dateType),
      fromDate: Number(values.dateType) === 5 ? moment(values.fromDate).format("YYYY-MM-DD") + " 00:00:00" : null,
      toDate: Number(values.dateType) === 5 ? moment(values.toDate).format("YYYY-MM-DD") + " 23:59:59" : null,
      UserID: values.reportsOf === 0 ? 0 : values.UserID,
    };

    getStatmentsData(data);
  };

  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };

  const LedgerStatementsTitle = () => (
    <>
      User Ledger Statements <HelpInfoHelper screenName={"/admin/ledger-statement"} />
    </>
  );

  const onChangeFromDate = (current, dateString) => {
   
    setArrivalDate(dateString);
  };
  const onChangeToDate = (current, dateString) => {
    
    setWeekendDate(dateString);
  };
  const disabledFromDate = (current) => {
    if (weekendDate != null) {
      return current.valueOf() > (new Date(weekendDate));
    }
   

    return current.valueOf() > Date.now();
  }
  const disabledToDate = (current) => {
   
    return current.valueOf() > Date.now() || current.valueOf() < (new Date(arrivalDate));
  };
  return (
    <div className="manage-markup-section">
      <div className="container-fluid">
        <div className="card-bt-gap">
          <div>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <Tooltip placement="top" title={isActive ? "Show" : "Hide"}>
                  <CaretRightOutlined rotate={isActive ? 90 : -90} />
                </Tooltip>
              )}
              expandIconPosition="right"
              className="panel_wrapper"
              defaultActiveKey={["1"]}
            >
              <Panel header={<LedgerStatementsTitle />} key="1">
                <Form
                  form={form}
                  layout={"vertical"}
                  initialValues={{
                    dateType: 1,
                    reportsOf: 0,
                  }}
                  onFinish={onFormSubmit}
                >
                  <Row gutter={16}>
                    <Col className="gutter-row" md={8} xs={24}>
                      <Form.Item label="Time and Date" name="dateType" rules={[{ required: true, message: "Required" }]}>
                        <Select placeholder="Please Select" onChange={handleTimeAndDate}>
                          <Option value={1}>Last One Hour</Option>
                          <Option value={2}>Yesterday</Option>
                          <Option value={3}>Last Week</Option>
                          <Option value={4}>Month</Option>
                          <Option value={5}>Custom Dates</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={8} xs={24}>
                      <Form.Item label="Reports of" name="reportsOf" rules={[{ required: true, message: "Required" }]}>
                        <Select placeholder="Select" onSelect={(val) => setReportRole(val)}>
                          <Option value={0}>Self</Option>
                          <Option value={2}>Specific User</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {reportRole === 2 && (
                      <Col md={8} xs={24}>
                        <Form.Item label="Agent" name="UserID" rules={[{ required: true, message: "Required" }]}>
                          <Select placeholder="Select Agent">
                            {UserList.map((data, index) => (
                               <Option key={index} value={data.UserID}>
                                {data.userDetails?.FirstName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    {isDateDisplay ? (
                      <>
                        <Col className="gutter-row" md={8} xs={24}>
                          <Form.Item label="From Date" name="fromDate" rules={[{ required: true, message: "Required" }]}>
                            <DatePicker style={{ width: "100%" }} placeholder="Select From date" format={dateFormat} disabledDate={disabledFromDate}
                              onChange={(date, dateString) =>
                                onChangeFromDate(date, dateString)} />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={8} xs={24}>
                          <Form.Item label="To Date" name="toDate" rules={[{ required: true, message: "Required" }]}>
                            <DatePicker style={{ width: "100%" }} disabledDate={disabledToDate}
                              onChange={(date, dateString) =>
                                onChangeToDate(date, dateString)

                              } placeholder="Select To date" format={dateFormat} />
                          </Form.Item>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                  <Row className="reports-btns">
                    <Button type="primary" htmlType="submit" onClick={() => setShowTable(true)}>
                      Search
                    </Button>
                    <Button
                      type="danger"
                      onClick={() => {
                        form.resetFields();
                        handleTimeAndDate(1);
                      }}
                    >
                      Reset
                    </Button>
                  </Row>
                </Form>
              </Panel>
            </Collapse>
          </div>
        </div>

        {showTable && (
          <div className="card-bt-gap">
            <Card bordered={false}>
              {dataSource.length > 0 ? (
                <>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>View Reports {dataSource.length}</h5>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && <Input placeholder="Search" onChange={(e) => searchTableData(e)} suffix={closeSearchInput} style={{ padding: "0px 12px" }} />}
                      &nbsp;&nbsp;
                      <img src={search} alt="search" onClick={(e) => searchData()} />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                    </div>
                  </div>
                  <div>
                    <div>
                      <Table
                        scroll={{ x: true }}
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                        summary={(pageData) => {
                          let totalCredit = 0;
                          let totalDebit = 0;

                          pageData.forEach(({ Credit, Debit }) => {
                            totalCredit += Number(Credit);
                            totalDebit += Number(Debit);
                          });

                          return (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell> </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <b> Total : </b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell>
                                  <b>Rs. {Number(totalCredit).toFixed(2)}</b>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <b>Rs. {Number(totalDebit).toFixed(2)}</b>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell> </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : loading ? (
                <Spin size="large" tip="Loading..." style={{ width: "100%" }} />
              ) : (
                <b>No Results Found!</b>
              )}
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdatedComponent(LedgerStatement);
