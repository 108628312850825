import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  message,
  Radio,
  Popconfirm,
  Select,
  Switch,
  Table,
  Row,
  DatePicker,
  Upload,
} from "antd";
import {
  PlusCircleFilled,
  LoadingOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ApiClient from "../../helpers/ApiClient";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";
import moment from "moment";
import "../Promo/Promo.scss";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import TextArea from "antd/lib/input/TextArea";

const Coupons = (props) => {
  let dateFormat = "DD-MM-YYYY";
  const BASE = process.env.REACT_APP_BASE_URL;
  const uploadActionUrl = BASE + "media/upload/singleImage";

  const [id, setId] = useState(-1);

  const [modalVisible, setModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [form] = Form.useForm();
  const [imagePath, setImagePath] = useState()
  const [couponTypeForDisplay, setCouponTypeForDisplay] = useState(1);
  const [columns, setColumns] = useState([]);
  const [excelList, setExcelList] = useState([]);
  const [validtilldate, setvalidtilldate] = useState(moment());
  const [couponType, setCouponType] = useState(1)

  const couponTypeEnumObj = [
    {
      key: "REGISTERATION",
      value: 1
    },
    {
      key: "BOOKING",
      value: 2
    },
    {
      key: "EVENTS",
      value: 3
    }
  ]


 

  const [upLoadImage, setUpLoadImage] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  function handleChange(info) {
    if (info.file.status === "error") {
      message.error(" Error Uploading Image", 3);
      setUpLoadImage(false);
      return;
    }
    if (info.file.status === "uploading") {
      setUpLoadImage(true);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response.status == 200) {
       
        setImagePath(info.file.response.data.filepath);
        
        getBase64(info.file.originFileObj, (imageUrl) => {
          setImageUrl(imageUrl);
          setUpLoadImage(false);
        });
      }
      setUpLoadImage(false);
    }
  }


  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const uploadButton = (
    <div>
      {upLoadImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  //#endregion

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const updateCouponModel = (rec) => {

    setId(rec.couponId);
    
    form.setFieldsValue({
      ...rec,
      validFrom: moment(rec.validFrom, dateFormat),
      validTill: moment(rec.validTill, dateFormat),
    });
    if (rec.image != "" && rec.image != "string" && rec.image != null) {
      const imgUrl = BASE + rec.image.substring(1);
      setImageUrl(imgUrl);
    }

    setIsEditMode(true);
    showModal();
  };

  const addNewCoupons = () => {
    setIsEditMode(false);
    form.resetFields();

    showModal();
  };

  const changeStatus = (info, status) => {
    const id = info.couponId;
    delete info.SNo;
    delete info.couponTypeName;
    let obj = {
      ...info,
      status: status,
    };
    updateCoupons(obj, id);
  };

  const handelStatus = (data) => {
    return (
      <Switch
        checkedChildren="Active"
        unCheckedChildren="Inactive"
        checked={data.status == 1 ? true : false}
        onChange={() => {
          changeStatus(data, data.status == 1 ? 0 : 1);
        }}
      />
    );
  };

  const tableActions = (rec) => {
    return (
      <div>
        <span className="edit-icon" onClick={() => updateCouponModel(rec)}>
          <i className="fa fa-edit"></i>
        </span>
        &nbsp;&nbsp;&nbsp;
        <Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => deleteCoupons(rec?.couponId)}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </Popconfirm>
      </div>
    );
  };

  const registrationColumn = [
    {
      title: "SNo",
      dataIndex: "SNo",
    },

    {
      title: "Coupons Type",
      dataIndex: "couponTypeName",
    },
    {
      title: "Coupon Name",
      dataIndex: "couponName",
    },
    {
      title: "Coupon Code",
      dataIndex: "couponCode",
    },

    {
      title: "Coupon Value",
      dataIndex: "couponValue",
    },

    {
      title: "Terms & Condition",
      dataIndex: "couponTermsandcondition",
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
    {
      title: "Status",
      key: "category",
      render: (currentItem) => handelStatus(currentItem),
    },
  ];

  const bookingCouponsColumn = [
    {
      title: "SNo",
      dataIndex: "SNo",
    },

    {
      title: "Coupons Type",
      dataIndex: "couponTypeName",
    },
    {
      title: "Coupon Name",
      dataIndex: "couponName",
    },
    {
      title: "Coupon Code",
      dataIndex: "couponCode",
    },

    {
      title: "Coupon Value",
      dataIndex: "couponValue",
    },

    {
      title: "Booking Volume",
      dataIndex: "bookingVolume",
    },

    {
      title: "Booking Count",
      dataIndex: "bookingCount",
    },

    {
      title: "ValidFrom",
      dataIndex: "validFrom",
    },

    {
      title: "validTill",
      dataIndex: "validTill",
    },

    {
      title: "Terms & Condition",
      dataIndex: "couponTermsandcondition",
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
    {
      title: "Status",
      key: "category",
      render: (currentItem) => handelStatus(currentItem),
    },
  ];
  const eventsCouponsColumn = [
    {
      title: "SNo",
      dataIndex: "SNo",
    },

    {
      title: "Coupons Type",
      dataIndex: "couponTypeName",
    },
    {
      title: "Coupon Name",
      dataIndex: "couponName",
    },
    {
      title: "Coupon Code",
      dataIndex: "couponCode",
    },

    {
      title: "Coupon Value",
      dataIndex: "couponValue",
    },

    {
      title: "ValidFrom",
      dataIndex: "validFrom",
    },

    {
      title: "validTill",
      dataIndex: "validTill",
    },

    {
      title: "Terms & Condition",
      dataIndex: "couponTermsandcondition",
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
    {
      title: "Status",
      key: "category",
      render: (currentItem) => handelStatus(currentItem),
    },
  ];
  /* Creating service dynamic column headers */
  const setTableHeaders = (_couponType) => {
    switch (_couponType) {
      case 1:
        setColumns(registrationColumn);
        break;
      case 2:
        setColumns(bookingCouponsColumn);
        break;
      case 4:
        setColumns(eventsCouponsColumn);
        break;
      default:
        setColumns(registrationColumn);
    }
  };



  /* =============== API calls ================*/

  useEffect(() => {
    getCoupons(couponTypeForDisplay);
  }, [couponTypeForDisplay]);

  const getCouponTypeName = (_couponType) => {
    const name = couponTypeEnumObj.filter(item => item.value == _couponType)?.[0].key ?? ""
    return name
  }

  // api call to get all avaialble Coupon 
  const getCoupons = (_couponType) => {
    ApiClient.get("admin/GetAllCoupons").then((res) => {
      if (res.status == 200) {
        let data = res.data
          .filter((item) => item.couponType === _couponType)
          .map((item, index) => {
            return {
              ...item,
              SNo: index + 1,
              couponTypeName: getCouponTypeName(item.couponType)
            };
          });
        setDataSource(data);
        defineExcelData(data);
        setTableHeaders(_couponType)
      } else {
        setTableHeaders(_couponType)
      }

    });
  };
  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        couponType: item.couponType,
        couponName: item.couponName,
        couponCode: item.couponCode,
        couponLimit: item.couponLimit,
        bookingCount: item.bookingCount,
        bookingVolume: item.bookingVolume,
        validFrom: item.validFrom,
        validTill: item.validTill,
        status: 1, //Active
        couponValue: item.couponValue,
        couponTermsandcondition: item.couponTermsandcondition,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  // Delete specific promo Codes
  const deleteCoupons = (couponId) => {
    ApiClient.delete("admin/DeleteCoupons/" + couponId).then((res) => {
      if (res.status == 200) {
        message.success("Coupon Deleted  successfully", 3);
        getCoupons(couponTypeForDisplay);
      }
    });
  };

  const addCoupons = (data) => {
    ApiClient.post("admin/Coupons", data).then((res) => {
      if (res.status == 200) {
        message.success("Coupon added successfully", 3);
        setModalVisible(false);
        form.resetFields();
        setImageUrl("")
        getCoupons(couponTypeForDisplay);
      } else if (res.status == 400) {
        message.error(" Duplicate Coupon", 3);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };



  const updateCoupons = (data, id) => {
    ApiClient.put("admin/updateCoupons/" + id, {}, data).then((res) => {
      if (res.status == 200) {
        message.success("Coupan Updated  successfully", 3);
        setModalVisible(false);
        setImageUrl("")
        getCoupons(couponTypeForDisplay);
      } else if (res.status == 400) {
        message.error(" Duplicate Coupan Code", 3);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };


 
  const submitFormData = (formvalues) => {

    const imageData = imagePath ? imagePath : " ";
    const data = {
      couponType: couponType,
      roleType: 2,//USER
      couponName: formvalues.couponName,
      couponLimit: formvalues.couponLimit,
      bookingCount: formvalues.bookingCount,
      bookingVolume: formvalues.bookingVolume,
      validFrom: moment(formvalues.validFrom).format(dateFormat),
      validTill: moment(formvalues.validTill).format(dateFormat),
      bookingCondition: formvalues.bookingCondition ? formvalues.bookingCondition : 0,
      status: 1, //Active
      couponValue: formvalues.couponValue,
      image: imageData,
      couponTermsandcondition: formvalues.couponTermsandcondition,
      createdBy: 1,
      modifiedBy: 1
    };

    if (isEditMode) {
      setTableHeaders(couponType);
      updateCoupons(data, id);
    } else {
      setTableHeaders(couponType);
      addCoupons(data);
    }
  };

  const handelCouponType = (value) => {
    setCouponType(value)
  };


  /*======= Search and Export Options=====*/

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      getCoupons();
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  

  const handleTable = (e) => {
    if (e.target.value) {
      setCouponTypeForDisplay(e.target.value);
      setCouponType(e.target.value)
    }
  };


  const disabledOriginDate = (currentDate) => {
    //setvalidtilldate(moment(currentDate));
    return currentDate < moment().startOf("day");
  };
  const disabledDestDate = (currentDate) => {
    return currentDate < moment(validtilldate).startOf("day");
  }
  const onChangeOriginDate = (momentdate, _) => {

    let originDate = momentdate ? moment(momentdate).startOf("day") : "";
    setvalidtilldate(moment(momentdate));
  };




  return (
    <div className="promo">
      <div className="manage-markup-section">
        <div className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <div>
                    <h5>
                      View Coupons{" "}
                      <HelpInfoHelper screenName={"/admin/coupons"} />
                    </h5>
                    <p>{dataSource.length} rows found !</p>
                    <Radio.Group
                      className="mb-2"
                      defaultValue={1}
                      onChange={(e) => handleTable(e)}
                    >
                      {couponTypeEnumObj.map((item, index) => (<Radio id={index} value={item.value}>{item.key}</Radio>))}

                    </Radio.Group>
                  </div>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img
                    src={search}
                    alt="search"
                    onClick={(e) => searchData(dataSource)}
                  />
                  <img
                    src={excel}
                    alt="excel"
                    onClick={(e) => props.exportExcel(excelList, "Coupons")}
                  />
                  
                  <p
                    className="add-deposit-icon"
                    onClick={() => {
                      addNewCoupons();
                    }}
                  >
                    <PlusCircleFilled />
                  </p>
                </div>
              </div>

              <Table
                className="table-scroll-none"
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            </Card>
          </div>
        </div>
      </div>

      <Modal
        title={isEditMode ? "Update Coupons" : "Add Coupons"}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <>
            <Button type="primary" onClick={() => form.submit()}>
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button
              type="primary"
              danger
              className="cancel-btn"
              onClick={(e) => {
                form.resetFields();
                setModalVisible(false);
              }}
            >
              Cancel
            </Button>
          </>,
        ]}
        width={"1100px"}
      >
        <Form
          initialValues={{ couponType: 1 }}
          layout="vertical"
          form={form}
          onFinish={submitFormData}
        >
          <Row gutter={16}>
            <Col className="gutter-row" md={4} xs={24}>
              <Form.Item
                label="Apply Coupons"
                name="couponType"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select placeholder="Please select" onChange={handelCouponType}>
                  {couponTypeEnumObj.map((item, index) => (<Select.Option index={index} value={item.value}>{item.key}</Select.Option>))}
                </Select>
              </Form.Item>
            </Col>
           

            <Col md={8} xs={24}>
              <Form.Item
                label="CouponName"
                name="couponName"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="couponName" />
              </Form.Item>
            </Col>

            <Col md={4} xs={24}>
              <Form.Item
                label="Coupon Value"
                name="couponValue"
                rules={[
                  { required: true, message: " CouponValue is required" },
                ]}
              >
                <Input placeholder=" couponValue" type="number" min={0} />
              </Form.Item>
            </Col>
            {couponType != 1 && (
              <>
                <Col md={6} xs={24}>
                  <Form.Item
                    label="Valid From"
                    name="validFrom"
                    rules={[{ required: true, message: "Valid From is required" }]}
                  >
                    <DatePicker disabledDate={disabledOriginDate} onChange={(date, dateString) =>
                      onChangeOriginDate(date, dateString)
                    } format={dateFormat} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item
                    label="Valid Till"
                    name="validTill"
                    rules={[{ required: true, message: "Valid Till is required" }]}
                  >
                    <DatePicker disabledDate={disabledDestDate} format={dateFormat} />
                  </Form.Item>
                </Col>
              </>
            )}

            {couponType == 2 && (<>
              <Col md={4} xs={24}>
                <Form.Item
                  label="Booking Count"
                  name="bookingCount"
                >
                  <Input placeholder="bookingCount" type="number" min={0} />
                </Form.Item>
              </Col>

              <Col md={4} xs={24}>
                <Form.Item
                  label="BookingVolume"
                  name="bookingVolume"
                >
                  <Input placeholder=" bookingVolume" type="number" min={0} />
                </Form.Item>
              </Col>
              <Col md={4} xs={24}>
                <Form.Item
                  label="BookingCondition"
                  name="bookingCondition"
                >
                  <Radio.Group defaultValue={0}>
                    <Radio value={1}>AND</Radio>
                    <Radio value={0}>OR</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </>)}


            <Col md={8} xs={24}>
              <Form.Item
                label="Terms & Condition"
                name="couponTermsandcondition"
              >
                <TextArea placeholder="couponTermsandcondition" type="number" min={0} />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="Upload Image" name="UploadImage">
                <Upload
                  name="image"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={uploadActionUrl}
                  data={{ category: "coupon" }}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default UpdatedComponent(Coupons);
