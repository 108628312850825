import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "antd";
import { Link} from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSytContext } from "../../common/providers/SytProvider";
import "./Blog.scss";
import ApiClient from "../../helpers/ApiClient";
import Banner from "../banner/Banner";

const BASE = process.env.REACT_APP_BASE_URL;

const Blog = () => {
  const { blog, blogBanners } = useSytContext();
  const [blogCategory, setBlogCategory] = useState([]);

  const [blogDataSource, setBlogDataSource] = useState([]);

  const getBlogCategory = () => {
    setBlogCategory([]);
    ApiClient.get("admin/blogCategories")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setBlogCategory(data);
        }
      })
      .catch((e) => {
        setBlogCategory([]);
      });
  };
  useEffect(() => {
    getBlogCategory();
    setBlogDataSource(blog);
  }, [blog]);

  const filterBlogsByCategory = (categoryId) => {
    if (categoryId > 0) {
      let data = blog.filter((item) => item.CategoryId == categoryId);

      setBlogDataSource(data);
    } else {
      setBlogDataSource(blog);
    }
  };

  return (
    <div className="deals-section">
      <Helmet>
        <meta
          name="description"
          content="trypmaker blog providing tips and advice on how you can travel better, cheaper, and smarter so you can explore the world on a budget."
        />
      </Helmet>
      <div className="bus-block">
        <div className="bus-banner">
          <Banner banner={blogBanners} />
        </div>
        <div className="bus-search-header">
          <h2>Our Blogs</h2>
        </div>
      </div>
      <div className="blog_wrapper">

        <div className="categoryNavigation mt-4">
          <ul>
            <li
              onClick={() => {
                filterBlogsByCategory(0);
              }}
            >
              All
            </li>
            {blogCategory.length
              ? blogCategory.map((item, index) => {
                if (index <= 6) {
                  return (
                    <li
                      key={"catnav" + index}
                      onClick={() => {
                        filterBlogsByCategory(item.CategoryId);
                      }}
                    >
                      {item.CategoryName}
                    </li>
                  );
                }
              })
              : ""}
          </ul>
        </div>
        {blogDataSource.length > 0 ? (
          <div className="blog_grid">
             <Row gutter={[24, 24]}>
              {blogDataSource.map(
                (item, index) => (
                   <Col md={8} xs={24}>
                    <Link to={`/bloginfo/${item.BlogID}/${item.urlTitle}`}>
                      <Card
                        className="card "
                        style={{
                          minHeight: "200px",
                          backgroundRepeat: "no - repeat",
                          backgroundSize: "cover",
                          backgroundImage: `url(${BASE + item.BlogTitleImage1.substring(1)
                            })`,
                          paddingTop: "25% !important",
                        }}
                      >
                        <p
                          className="title"
                          style={{
                            color: "white",
                            backgroundColor: "#00000094",
                            padding: "10px",
                          }}
                        >
                          {item.BlogTitle}
                        </p>
                      </Card>
                    </Link>
                  </Col>
                )

              )}
            </Row>
          </div>
        ) : null}{" "}
      </div>


    </div>
  );
};

export default Blog;
