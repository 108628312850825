import { Col, Row, Skeleton, Divider, message, Button, Modal, Radio, Carousel, Tooltip,Statistic } from "antd";
import { useEffect, useState } from "react";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import * as ANTD from "antd";
import HotelPassengers from "../../../components/Hotels/HotelPassengers/HotelPassengers";
import kid from "../../../assets/images/kid.png"
import timeoutimg from "../../../assets/images/timeout.jpg"
import moment from "moment";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import ApiClient from "../../../helpers/ApiClient";
import queryString from "query-string";
import CustomNoResultFound from "../../../components/ErrorPages/CustomNoResultFound";
import ReactHtmlParser from "react-html-parser";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import './Holidaytourdetail.scss';
import { Collapse } from "antd";

import NoImage from "../../../assets/images/image-not-found-scaled-1150x647.png";
import { useHolidayContext } from "../../../common/providers/Holidays/HolidayProvider";
const { Countdown } = Statistic;
const HolidaysTourDetailNew = () => {
    useEffect(() => {
        getsessiontimeout();
      }, [])
    let history = useHistory();
    const param = queryString.parse(document.location.search);
    const [tourDetail, setTourDetail] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [form] = ANTD.Form.useForm();
    const { activeCurrency, currencyValue } = useCurrencyContext();
    const { Panel } = Collapse;
    //const { state: { sessiontimeout,  } } = useHolidayContext();
    const [showdetails, setshowdetails] = useState({
        itinerary: true,
        overview: false,
        inclusions: false,
        exclusions: false,
        cancelpolicy: false
    });
    const roomLimit = 6;
    const defHotelPaxInfo = [
        {
            noOfAdults: 1,
            noOfChilds: 0,
            childAge: [],
        },
    ];
    const [roomlength, setroomlength] = useState(1);

    const addRoom = () => {
        paxInfo.push({
            noOfAdults: 1,
            noOfChilds: 0,
            childAge: [],
        });
        setroomlength(paxInfo?.length);
        setHotelPaxInfo(paxInfo);
    };

    const [sessiontimeout, setsessiontimeout] = useState([]);
    const [status, setStatus] = useState('');

const getsessiontimeout = () => {
  ApiClient.get("admin/get_active_session_timeout/6")
    .then((res) => {
      if (res.status === 200) {
        setsessiontimeout(new Date().getTime() + Number((res?.data?.sessionTime) * 60 * 1000));
        setStatus(res.data.status);
      }
      else {
        setsessiontimeout(new Date().getTime() + Number(0));
        setStatus(res.data.status);
      }
    })
    .catch((error) => {
      console.error(error);
    });
};


    let updatePaxInfoFromChild = (paxInfo) => {
        setHotelPaxInfo(paxInfo);
    };

    let ondelete = (i) => {
        paxInfo.splice(i, 1)
        setHotelPaxInfo(paxInfo);
        setroomlength(i)
    }
    let getPaxCount = () => {
        return paxInfo.reduce(
            (total, pax) => total + pax.noOfAdults + pax.noOfChilds,
            0
        );
    };
    const onupdate = (i) => {
        setroomlength(i);
    }

    const [hotelPaxInfo, setHotelPaxInfo] = useState(defHotelPaxInfo);
    const paxInfo = [...hotelPaxInfo];
    const [faredetails, setfaredetails] = useState({});
    const [noofpersons, setnoofpersons] = useState({ adult: 1, child: 0, infant: 0 })
    const [openModal, setopenModal] = useState(false);
    const [queryModal, setqueryModal] = useState(false);
    const [packagefare, setackagefare] = useState(null)
    const [roomfare, setroomfare] = useState(0);
    const [hotelRoomPaxCount, sethotelRoomPaxCount] = useState([
        {
            adultsCount: 1,
            childCount: 0,
            infantCount: 0
        }
    ]);
    useEffect(() => {
        if (param.tourId) {
            selectTours(param);
        }
    }, []);
    const selectTours = (paramObj) => {
        if (paramObj.isFrm === "list") {
            const ReqObj = {
                traceId: paramObj.traceId,
                holidayId: paramObj.tourId,
            };
            if (ReqObj.traceId != null && ReqObj.holidayId != null) {
                ApiClient.post("HolidayExtranet/holiday/details", ReqObj)
                    .then((result) => result)
                    .then((resp) => {
                        if (resp.status == 200) {

                            setTourDetail({
                                ...resp.data,
                                traceId: resp.traceId,
                            });
                        } else {
                            setTourDetail({});
                        }

                        setLoading(false);
                    })
                    .catch((err) => {
                        setTourDetail({});
                    });
            }
        }
    };
    const showcertaildetails = (key) => {
        if (key === "itinerary")
            setshowdetails((prev) => ({ itinerary: true, cancelpolicy: false, exclusions: false, overview: false, inclusions: false }))
        if (key === "overview")
            setshowdetails((prev) => ({ itinerary: false, cancelpolicy: false, exclusions: false, overview: true, inclusions: false }))
        if (key === "cancel")
            setshowdetails((prev) => ({ itinerary: false, cancelpolicy: true, exclusions: false, overview: false, inclusions: false }))
        if (key === "inclusions")
            setshowdetails((prev) => ({ itinerary: false, cancelpolicy: false, exclusions: false, overview: false, inclusions: true }))
        if (key === "exclusions")
            setshowdetails((prev) => ({ itinerary: false, cancelpolicy: false, exclusions: true, overview: false, inclusions: false }))

    }
    const Changecount = (type) => {
        if (type == "adult")
            setnoofpersons((prev) => ({ ...prev, adult: noofpersons.adult + 1 }))

        if (type == "child")
            setnoofpersons((prev) => ({ ...prev, child: noofpersons.child + 1 }))


        if (type == "infant")
            setnoofpersons((prev) => ({ ...prev, infant: noofpersons.infant + 1 }))

        //setackagefare();
    }
    const Changeminuscount = (type) => {
        if (type == "adult")
            setnoofpersons((prev) => ({ ...prev, adult: noofpersons.adult - 1 }))

        if (type == "child")
            setnoofpersons((prev) => ({ ...prev, child: noofpersons.child - 1 }))


        if (type == "infant")
            setnoofpersons((prev) => ({ ...prev, infant: noofpersons.infant - 1 }))

        //setackagefare();
    }

    const timeout = () => {
        return (
    
          <div>
    
            <div className="timeout-popup-main">
              <div className="timeout-popup-main1">
                <img className="timeout-img" src={timeoutimg} alt="time" />
              </div>
              <div className="timeout-popup-main2">
                <h4 style={{ color: "red", }}><strong>SESSION TIMEOUT</strong></h4>
                <p className="popup-session-timeout-p-tag">Your Session is Expired</p>
                <p className="popup-session-timeout-p-tag">Click on "OK" to continue with New Search</p>
              </div>
            </div>
    
          </div>
        );
      }
      const handelCountdown = () => {
    
        // const query = queryString.stringify(searchhotelobj);
        let query = queryString.stringify(JSON.parse(localStorage.getItem('HolidaySearchBar')));
        Modal.warning({
          icon: <></>,
          //title: "",
          content: timeout(),
          onOk() {
            history.push("/holidays/results?" + query);
    
          },
        });
      };
    const FareChangePerAdult = () => {
        let ReqBody = {
            holidayId: tourDetail.holidayId,
            traceId: tourDetail.traceId,
            paxInfo: hotelRoomPaxCount

        }
        ApiClient.post("HolidayExtranet/holiday/calculatedprice", ReqBody)
            .then((result) => result)
            .then((resp) => {
                if (resp.status == 200) {

                    setfaredetails(resp.data)
                    
                }
            })
            .catch((err) => {
                //setTourDetail({});
            });
    }
    useEffect(() => {
        FareChangePerAdult();
    }, [Object.keys(tourDetail).length > 0])

    const GetRoomWiseFare = (id) => {
        setroomfare(id);
        setackagefare(faredetails?.PriceBreakUp[id].totalPackagePrice);
    }
    useEffect(() => { setackagefare(faredetails?.totalPackagePrice) }, [faredetails])
    const GotoCheckoutPage = () => {
        if (tourDetail && faredetails) {
            const queryObj = {
                traceId: tourDetail?.traceId,
                TourPriceId: faredetails?.PriceBreakUp[roomfare]?.holidayPriceId,
                tourId: tourDetail?.holidayId,
            };
            const query = queryString.stringify(queryObj);

            history.push("/holiday/tourcheckout?" + query);
        }
    }
    const [queryData, setQueryData] = useState({
        fullName: '',
        email: '',
        mobile: '',
        journeyDate: '',
        city: '',
        duration: ''
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setQueryData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    const querypost = (values) => {

        const data = {
            HolidayPackageId: tourDetail.holidayId,
            PackageName: tourDetail.PackageTitle,
            FullName: values.fullname,
            Email: values.email,
            Mobile: values.mobile,
            JourneyDate: tourDetail?.serachRequest?.travellingOn,
            City: values.origincity,
            Duration: values.duration,
            CreatedBy: 0,
            ModifiedBy: 0
        }
        ApiClient.post("HolidayExtranet/save/offlinequery", data)
            .then((response) => {
                if (response.statusCode == 200) {
                    message.success("Querry Sent SuccessFully", 3);
                    setqueryModal(false);
                }
                else {
                    message.success("Not Responding", 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const RoomCount = (type) => {
        if (type === "+" && hotelRoomPaxCount?.length <= 3) {
            sethotelRoomPaxCount((prev) => [...prev, { adultsCount: 1, childCount: 0, infantCount: 0 }])
        }
        if (type === "-" && hotelRoomPaxCount?.length > 1) {
            let temp = hotelRoomPaxCount;
            sethotelRoomPaxCount(hotelRoomPaxCount.slice(0, (hotelRoomPaxCount.length - 1)))
        }

    }
    useEffect(() => {
        FareChangePerAdult();
    }, [hotelRoomPaxCount])
    const HotelRooms = (type) => {
        RoomCount(type);
    }
    const GetIncreaseAdults = (type, index, paxType) => {
        const temp = hotelRoomPaxCount;
        if (type === "+") {
            temp[index] = {
                adultsCount: temp[index]?.adultsCount + (paxType == "adult" && temp[index].adultsCount <= 3 ? 1 : 0),
                childCount: temp[index].childCount + (paxType == "child" && temp[index].childCount <= 3 ? 1 : 0),
                infantCount: temp[index].infantCount + (paxType == "infant" && temp[index].infantCount <= 2 ? 1 : 0)
            }
        }
        if (type === "-") {
            temp[index] = {
                adultsCount: temp[index]?.adultsCount - (paxType == "adult" && (temp[index].adultsCount != 1) ? 1 : 0),
                childCount: temp[index].childCount - (paxType == "child" && temp[index].childCount != 0 ? 1 : 0),
                infantCount: temp[index].infantCount - (paxType == "infant" && temp[index].infantCount != 0 ? 1 : 0)
            }
        }
        sethotelRoomPaxCount(temp)
        FareChangePerAdult();
    }
    return (
    <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
        {isLoading ? (
            <div className="hotel-skeleton-layout">
                <div className="hotel-skeleton-layout-container">
                    <Row>
                        <Col md={6} xs={24}>
                            <Skeleton paragraph={{ rows: 0 }} />
                        </Col>
                        <Col md={18} xs={24}>
                            <Skeleton paragraph={{ rows: 2 }} />
                            <Skeleton paragraph={{ rows: 2 }} />
                            <Divider />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Row>
                            <Col md={6} xs={24}>
                                <Skeleton paragraph={{ rows: 0 }} />
                            </Col>
                            <Col md={18} xs={24}>
                                <Skeleton paragraph={{ rows: 3 }} />
                            </Col>
                        </Row>
                    </Row>
                </div>
            </div>) : Object.keys(tourDetail).length > 0 ? (
                <>
                {status !=0  &&
                <div   className="countdown3det">
                  <div className="countdown-main-box">
                    <div className="countdown1">
                      <span class="fa fa-clock-o" style={{ fontSize: "18px" }}></span><span> Your session will expire in</span>
                    </div>
                    <div className="countdown2">
                      <Countdown
                        className="busCountdown"
                        onFinish={handelCountdown}
                        value={sessiontimeout}
                        format={"mm[ min]  ss[ sec]"}
                      />
                    </div>
                  </div>
                </div>}
                <Row md={24}>
                    <Col md={18}>
                        <Carousel autoplay className="holiday-carousel">
                            {tourDetail?.HolidayMedia?.[0]?.Images?.length > 0 ?
                                (tourDetail?.HolidayMedia?.[0]?.Images?.map((image, id) => {
                                    return (<img
                                        className="slider-img-holidays"
                                        src={image}
                                        alt="slider-img"
                                    />)
                                })
                                ) :
                                <img
                                    className="slider-img-holidays"
                                    src={NoImage}
                                    alt="slider-img"
                                />}
                        </Carousel>

                        <div className="tour-step">
                            <Row className="tour-info">
                                <Col className="tour-plan" md={3}>
                                    <Button style={{ width: "100%" }} onClick={() => showcertaildetails("itinerary")}>Itinerary</Button>
                                    <Button style={{ width: "100%" }} onClick={() => showcertaildetails("overview")}>OverView</Button>
                                    <Button style={{ width: "100%" }} onClick={() => showcertaildetails("inclusions")}>Inclusions</Button>
                                    <Button style={{ width: "100%" }} onClick={() => showcertaildetails("exclusions")}>Exclusions</Button>
                                    <Button style={{ width: "100%" }} onClick={() => showcertaildetails("cancel")}>Cancel Policy</Button>
                                </Col>
                                <Col md={21}>
                                    <div className="tour-content" >
                                        {showdetails?.itinerary ?
                                            <div>
                                                {ReactHtmlParser(tourDetail.Itinerary)}
                                            </div> : null}
                                        {showdetails?.overview ? <div>
                                            {ReactHtmlParser(tourDetail.Overview)}
                                        </div> : null}
                                        {showdetails?.inclusions ? <div>
                                            {ReactHtmlParser(tourDetail.Whatsincluded)}
                                        </div> : null}
                                        {showdetails?.exclusions ? <div>
                                            {ReactHtmlParser(tourDetail.Whatsnotincluded)}
                                        </div> : null}
                                        {showdetails?.cancelpolicy ? <div>
                                            {ReactHtmlParser(tourDetail?.CancelPolicy)}
                                        </div> : null}
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="tour-info-side">
                            <div className="tour">
                                <h4>{tourDetail.PackageTitle}</h4>
                                <p>{tourDetail?.Nights} Night's / {tourDetail?.Days} days</p>
                            </div>
                            <h4 className="price-tag-info">{tourDetail?.price?.adultPrice}{activeCurrency} {currencyValue(packagefare)}/-<p className="fare-line">(price per person on twin sharing)</p></h4>
                            <p className="desc-line">{tourDetail?.Packagesdescription}</p>
                            <Button className="btn-book" onClick={() => setopenModal(true)}>Book Online</Button>
                            <Button className="btn-querry" onClick={() => { form.resetFields(); setqueryModal(true) }}>Send Query</Button>
                        </div>
                        <div className="tour-info-details">
                            <div className="tour-details">
                                <h4>Search Summary</h4>
                            </div>
                            <p>Packages Available in:{tourDetail?.CityName}</p>
                            <p>Duration : {tourDetail?.Days} days</p>
                            <p>Date : {tourDetail?.TravellingDate}</p>
                            <p>Destinations : {tourDetail?.CityName},{tourDetail?.CountryCode}</p>
                            <p>Themes : {tourDetail?.PackageTitle}</p>
                            <p>Valid Till :{tourDetail?.ValidtillDatetime}</p>
                            <p>Star Rating :{tourDetail?.StarRating}-Star's</p>
                        </div>
                        <div className="tour-info-details">
                            <div className="why">
                                <h4>Why Book with us?</h4>
                            </div>
                            <p><i class="fa fa-check-square-o" aria-hidden="true"></i> Best Price Guaranteed</p>
                            <p><i class="fa fa-check-square-o" aria-hidden="true"></i> World Class Service</p>
                            <p><i class="fa fa-check-square-o" aria-hidden="true"></i> Excellent Discount for regular Client's</p>
                            <p><i class="fa fa-check-square-o" aria-hidden="true"></i> Best Customer Support</p>

                        </div>
                    </Col>
                </Row>
                </>
            ) : (
            <CustomNoResultFound title={"No Tour Available"} />
        )}
        <div className="maindiv-modal-holiday" >
            <Modal visible={openModal}
                className="holidaybook-modalpopup"
                title={<div className="title-modal-head" >
                    <h6 style={{ marginBottom: "0px", color: 'black' }}>
                        <strong>Check Availability & Book Now</strong>
                    </h6>
                </div>}
                onOk={() => GotoCheckoutPage()}
                onCancel={() => setopenModal(false)}>
                <div className="details-header">
                    <h6 className="header-pck">Discover {tourDetail.PackageTitle}</h6>
                    <span className="cust-suppport">SPEAK TO OUR HOLIDAY EXPERT's. Call  Us :<p className="enqury">  9704206860 (10 am to 06:00 pm)</p></span>
                </div>
                <div className="room-data-info" >
                    <Row className="headrs">
                        <Col md={4} > Room's <i class="fa fa-home" aria-hidden="true"></i></Col >
                        <Col md={5} ><Tooltip title="12+ yrs">Adult's <i class="fa fa-user-plus" aria-hidden="true"></i></Tooltip></Col >
                        <Col md={6}><Tooltip title="2-12 yrs">Child <i class="fa fa-child" aria-hidden="true"></i> </Tooltip></Col >
                        <Col md={5}><Tooltip title="less than 2 yrs">Infant's <img src={kid} height={"13px"} width={"13px"} alt="infant" /></Tooltip></Col >
                    </Row>
                    {hotelRoomPaxCount?.map((room, id) => {
                        return (
                            <Row className="room-info"  >
                                {id === 0 ? <Col md={4}><span className="less" onClick={() => HotelRooms("-")}>-</span><p className="info-count">{hotelRoomPaxCount?.length}</p><span className="add" onClick={() => HotelRooms("+")}>+</span></Col > : <Col md={4}></Col>}
                                <Col md={5}><span className="less" onClick={() => GetIncreaseAdults("-", id, "adult")}>-</span><p className="info-count">{room.adultsCount}</p><span className="add" onClick={() => GetIncreaseAdults("+", id, "adult")}>+</span></Col >
                                <Col md={6}><span className="less" onClick={() => GetIncreaseAdults("-", id, "child")}>-</span><p className="info-count">{room.childCount}</p><span className="add" onClick={() => GetIncreaseAdults("+", id, "child")}>+</span></Col >
                                <Col md={5} ><span className="less" onClick={() => GetIncreaseAdults("-", id, "infant")}>-</span><p className="info-count">{room.infantCount}</p><span className="add" onClick={() => GetIncreaseAdults("+", id, "infant")}>+</span></Col >
                            </Row>)
                    })}

                    <div className="package-info">
                        <h6>Package</h6>
                        <Radio.Group value={roomfare}>
                            {
                                faredetails?.PriceBreakUp?.map((data, id) => {
                                    return (<Radio onChange={() => GetRoomWiseFare(id)} value={id}>{data?.RoomCategoryName}</Radio>)
                                })
                            }</Radio.Group>
                    </div>
                </div>
                <div className="package-price"><p>Grand Total :</p>{currencyValue(packagefare)}</div>
            </Modal>
            <Modal visible={queryModal}
                className="holidaybook-modalpopup"
                title={<div className="title-modal-head" >
                    <h6 style={{ marginBottom: "0px", fontSize: "1.5rem", color: "black" }}>
                        <strong>Send Query</strong>
                    </h6>
                </div>}
                onOk={() => querypost()}
                onCancel={() => setqueryModal(false)}
                footer={[
                    <ANTD.Button key="add" type="primary" onClick={form.submit}>
                        Send
                    </ANTD.Button>,
                ]}>
                <div className="details-header">
                    <h6 className="header-pck">Discover {tourDetail.PackageTitle}</h6>
                    <span className="cust-suppport">SPEAK TO OUR HOLIDAY EXPERT's. Call  Us :<p className="enqury"> 9999999999 (10 am to 06:00 pm)</p></span>
                </div>
                <ANTD.Form layout="vertical" form={form} onFinish={querypost}>
                    <div className="query-input">
                        <ANTD.Row>
                            <ANTD.Col className="gutter-row" md={8} xs={24}>

                                <ANTD.Form.Item
                                    label="Full Name"
                                    name="fullname"
                                    rules={[{ required: true, message: "Please Enter FullName" },
                                    {
                                        pattern: "^[a-zA-Z\\s]*$",

                                        message: "Must be Alphabet",
                                    },]}
                                >
                                    <ANTD.Input autoComplete="newpassword" />
                                </ANTD.Form.Item>
                            </ANTD.Col>
                            <ANTD.Col className="gutter-row" md={8} xs={24}>
                                <ANTD.Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        { required: true, message: "Please Enter your Email!" },
                                        { type: "email", message: "Invalid Email" },
                                    ]}
                                >
                                    <ANTD.Input autoComplete="newpassword" />
                                </ANTD.Form.Item>

                            </ANTD.Col>
                            <ANTD.Col className="gutter-row" md={8} xs={24}>

                                <ANTD.Form.Item
                                    label="Mobile"
                                    name="mobile"
                                    rules={[{ required: true, message: "Please Enter Mobile Number" },
                                    {
                                        minLength: 10,
                                        maxLength: 10,
                                        pattern: "^[0-9]{10}$",
                                        message: "Must be 10 Digits",
                                    },]}
                                >
                                    <ANTD.Input
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} autoComplete="newpassword" />
                                </ANTD.Form.Item>
                            </ANTD.Col>
                        </ANTD.Row>
                        <ANTD.Row>
                            <ANTD.Col className="gutter-row" md={8} xs={24}>

                                <ANTD.Form.Item
                                    label="Date Of Journey"
                                    name="journeyDate"

                                >
                                    <ANTD.Input disabled placeholder={moment(tourDetail?.TravellingDate).format("DD-MM-YYYY")} autoComplete="Off" />
                                </ANTD.Form.Item>
                            </ANTD.Col>
                            <ANTD.Col className="gutter-row" md={8} xs={24}>

                                <ANTD.Form.Item
                                    label="Origin City"
                                    name="origincity"
                                    rules={[{ required: true, message: "Please Enter Origin City" }]}
                                >
                                    <ANTD.Input autoComplete="newpassword" />
                                </ANTD.Form.Item>
                            </ANTD.Col>
                            <ANTD.Col className="gutter-row" md={8} xs={24}>

                                <ANTD.Form.Item
                                    label="Duration"
                                    name="duration"
                                    rules={[{ required: true, message: "Enter Days" }]}
                                >
                                    <ANTD.Input
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        autoComplete="newpassword" />
                                </ANTD.Form.Item>
                            </ANTD.Col>
                        </ANTD.Row>
                    </div>
                </ANTD.Form>
            </Modal>
            <div className="maindiv-modal-holiday" >

            </div>
        </div>

    </div>)
}
export default HolidaysTourDetailNew;