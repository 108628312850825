import { Card, Col, Layout, Row } from "antd";
import moment from "moment";
import React from "react";
import ReactHtmlParser from "react-html-parser";

import {
  formatFlightTime,
  getFlightPrice,
  calculateDuration,
} from "./flightHelper.js";
import { getFlightTicketStatus } from "../AllTicketStatus";
import "../NewTicket.scss";
import { SafetyCertificateOutlined } from "@ant-design/icons";

import LogoImg from "../../../assets/images/trypmaker.png";

const TicketFlight = ({ ticketData, source, destination, cmsFareRules,Logo }) => {
  let uidateFormat = "DD-MMM-YYYY";
  let {
    baseAmount,
    taxAmount,
    convienenceFee,
    discount,
    RefundAmount,
    grandTotal,
    insuranceTotal,
    totalmeal,
    totalbagg,
  } = getFlightPrice(ticketData);

  const getStatus = (status) => {
    switch (status) {
      case "BLOCKED": {
        return <p style={{ color: "#FFA500" }}>{status} </p>;
      }
      case "CONFIRMED": {
        return (
          <p style={{ color: "#008000" }}>
            <b> {status}</b>
          </p>
        );
      }
      case "CANCELLED": {
        return (
          <p style={{ color: "#FF0000" }}>
            <b> {status}</b>
          </p>
        );
      }

      case "PARTIALLY_CANCELLED": {
        return (
          <p style={{ color: "#ff6a00" }}>
            <b> PARTIALLY CANCELLED</b>
          </p>
        );
      }
      case "CANCELLATIONREQUESTED": {
        return (
          <p style={{ color: "#ff6a00" }}>
            <b>CANCELLATION REQUESTED</b>
          </p>
        );
      }

      default:
        return;
    }
  };
  const BASE = process.env.REACT_APP_BASE_URL;
  return (
    <>
      <span className="tic-gredient-f"></span>

      <div className="cms-pages-width-1 ourteam-bg">
        <Row>
         
          {ticketData.tripType != "multidestination" ? (
            <Col md={24} xs={24}>
           
             
              <img style={{ width: 175, height: 72,marginBottom:10 }} src={LogoImg} />
            
              
              <Card className="card-tic-f3">
                <Row gutter={[16, 8]}>
                  <Col md={10} sm={24} xs={24}>
                    <Row gutter={[16, 8]}>
                      <Col md={10} sm={10} xs={24}>
                        <div className="city-f3">
                          <p>{ticketData?.source}</p>
                          <span>
                            {`${ticketData.oneWaySegment[0].flightCode} -
                            ${ticketData.oneWaySegment[0].flightNumber}`}
                          </span>
                        </div>
                      </Col>
                      <Col md={4} sm={4} xs={24}>
                        <div className="city-f3">
                          <p>
                            <i
                              className="fa fa-long-arrow-right"
                              aria-hidden="true"
                            ></i>
                          </p>
                        </div>
                      </Col>
                      <Col md={10} sm={10} xs={24}>
                        <div className="city-f3">
                          <p>{ticketData.destination}</p>
                         
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={14} sm={24} xs={24}>
                    <Row>
                      <Col md={6} sm={8} xs={24}>
                        <div className="city-f4">
                          <p>
                            {moment(ticketData.journeyDate).format(
                              uidateFormat
                            )}
                          </p>
                          <span>Journey Date</span>
                        </div>
                      </Col>
                      <Col md={17} sm={16} xs={24}>
                        <div className="city-f4" style={{ marginLeft: 70 }}>
                          <p className="word-break break-pnr">
                            {ticketData?.tripType === "roundTrip"
                              ? ticketData?.pnr.includes("~")
                                ? ticketData?.pnr.split("~")[0]
                                : ticketData.pnr
                              : ticketData.pnr}
                          </p>
                          <span>PNR</span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="row-top-f4">
                  <Col md={6} xs={24} className="city-f4">
                    <p>
                      {ticketData.referenceNumber
                        ? ticketData.referenceNumber
                        : ticketData.id}
                    </p>
                    <span>Ticket Ref Number</span>
                  </Col>
                  <Col md={4} xs={24} className="city-f4">
                    {getFlightTicketStatus(ticketData.bookingStatus)}
                   
                    <span>Status</span>
                  </Col>
                  <Col md={6} xs={24} className="city-f4" style={{ marginLeft: 8 }}>
                    <p>
                      {ticketData.bookingDate
                        ? moment(ticketData.bookingDate).format(uidateFormat)
                        : ""}
                    </p>
                    <span>Booking Date</span>
                  </Col>
                  <Col md={5} xs={24} className="city-f4">
                    <p>Paid</p>
                    <span>Payment Status</span>
                  </Col>
                </Row>
              </Card>
             
              <Card className="card-tic-f1">
                <Row className="info-flight-t22">
                  <Col md={24} xs={24} className="city-tt-f4">
                    <p style={{ color: "#3f52b9" }}>Travel Information</p>
                    <span>
                      Here are the details of your flight from{" "}
                      {ticketData.oneWaySegment[0].origin} to{" "}
                      {
                        ticketData.oneWaySegment[
                          ticketData.oneWaySegment.length - 1
                        ].destination
                      }{" "}
                      on {moment(ticketData.journeyDate).format(uidateFormat)}
                    </span>
                  </Col>
                </Row>
                {ticketData.oneWaySegment.length > 0
                  ? ticketData.oneWaySegment.map((flightSegment, i) => (
                    <Row key={i}>
                      <Col md={8} xs={24} className="city-tt-f5">
                        <div className="logo-f3-text">
                          <div className="sg-logo-flight">
                            <p>
                              {ticketData.operator} {" "}
                              ({`${flightSegment.flightCode} -
                            ${flightSegment.flightNumber}`})
                            </p>
                            
                          </div>
                        </div>
                      </Col>
                      <Col md={16} xs={24} className="city-tt-f4">
                        <div className="city-from-d3">
                          <div className="city-f55">
                            <p>{flightSegment.origin}</p>
                            <span>
                              {formatFlightTime(
                                flightSegment.departureDateTime
                              )}
                            </span>
                            {flightSegment.departureTerminal ? (
                              <span>
                                Terminal- {flightSegment.departureTerminal}
                              </span>
                            ) : <span>
                              Terminal- N/m
                            </span>
                            }
                          </div>
                          <div className="city-f55">
                            <p className="dotted-line-flight"></p>
                            <span>
                              {calculateDuration(
                                flightSegment.departureDateTime,
                                flightSegment.arrivalDateTime
                              )}
                            </span>
                          </div>
                          <div className="city-f55">
                            <p>{flightSegment.destination}</p>
                            <span className="d-block mt-1">
                                {formatFlightTime(
                                  flightSegment.arrivalDateTime
                                )}
                              </span>
                              {flightSegment.arrivalTerminal ? (
                                <span>
                                  Terminal- {flightSegment.arrivalTerminal}
                                </span>
                              ) : <span>
                                Terminal- N/m
                              </span>
                              }
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))
                  : null}

                <Row className="baggagge-bottom-sec">
                  <Col md={6} xs={24} className="city-tt-f6">
                    <div className="baggagge-f4">
                      <p className="baggagge-icon-4">
                        <i className="fa fa-suitcase" aria-hidden="true"></i>
                      </p>
                      <p className="info-line-bag">Baggage Included</p>
                    </div>
                  </Col>

                  <Col md={4} xs={24} className="city-tt-f6">
                    <div className="baggagge-f4">
                     

                      <div className="allowance-baggage">
                        <p style={{ color: "#757575" }}>Check-in</p>
                        <span>
                          {ticketData.oneWayBaggageInfo.length > 0
                            ? ticketData.oneWayBaggageInfo[0].BaggageInfo
                            : null}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col md={8} xs={24} className="city-tt-f6">
                    <div className="baggagge-f4">
                     

                      <div className="allowance-baggage">
                        <p style={{ color: "#757575" }}>Hand Baggage</p>
                        <span>
                          {ticketData.oneWayBaggageInfo.length > 0
                            ? ticketData.oneWayBaggageInfo[0].cabinBaggageInfo
                            : null}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>

              {ticketData.returnSegment.length > 0 &&
                ticketData.tripType === "roundTrip" ? (
                <>
                 <p className="tic-city-name-d2" style={{ color: "#000" }}>
                      Return Details
                    </p>
                  <Card className="card-tic-f3">
                    <Row gutter={[16, 8]}>
                      <Col md={10} sm={24} xs={24}>
                        <Row gutter={[16, 8]}>
                          <Col md={10} sm={10} xs={24}>
                            <div className="city-f3">
                              <p>{ticketData.destination}</p>
                              
                            </div>
                          </Col>
                          <Col md={4} sm={4} xs={24}>
                            <div className="city-f3 px-2">
                              <p>
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </p>
                            </div>
                          </Col>
                          <Col md={10} sm={10} xs={24}>
                            <div className="city-f3">
                              <p>{ticketData.source}</p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={14} sm={24} xs={24}>
                        <Row>
                          <Col md={7} sm={8} xs={24}>
                            <div className="city-f4">
                              <p>
                                {moment(
                                  ticketData.returnSegment[0].departureDateTime
                                ).format(uidateFormat)}
                              </p>
                              <span>Journey Date</span>
                            </div>
                          </Col>
                          <Col md={17} sm={16} xs={24}>
                            <div className="city-f4">
                              <p className="word-break break-pnr">
                                {ticketData.pnr.includes("~")
                                  ? ticketData.pnr.split("~")[1]
                                  : ticketData.pnr}
                              </p>
                              <span>PNR</span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="border-hr"></div>
                    <Row className="row-top-f4">
                      <Col md={6} xs={24} className="city-f4">
                      <p>
                            {ticketData.referenceNumber
                              ? ticketData.referenceNumber
                              : ticketData.id}
                          </p>
                        <span>Ticket Ref Number</span>
                      </Col>
                      <Col md={4} xs={24} className="city-f4">
                        {getFlightTicketStatus(ticketData.bookingStatus)}

                        <span>Status</span>
                      </Col>
                      <Col md={6} xs={24} >
                        <div lassName="city-f4" style={{ marginLeft: 8 }}>
                          <p>
                            {ticketData.bookingDate
                              ? moment(ticketData.bookingDate).format(
                                uidateFormat
                              )
                              : ""}
                          </p>
                          <span>Booking Date</span>
                        </div>
                      </Col>
                      <Col md={5} xs={24} className="city-f4">
                        <p>Paid</p>
                        <span>Payment Status</span>
                      </Col>
                    </Row>
                  </Card>
                  <div style={{ padding: 10 }}></div>
                  {ticketData.returnSegment.length > 0 ? (
                  <Card className="card-tic-f1">
                    <Row className="info-flight-t22">
                      <Col md={24} xs={24} className="city-tt-f4">
                        <p style={{ color: "#3f52b9" }}>Travel Information</p>
                        <span>
                          Here are the details of your flight from{" "}
                          {ticketData.returnSegment[0].origin} to{" "}
                          {
                            ticketData.returnSegment[
                              ticketData.returnSegment.length - 1
                            ].destination
                          }{" "}
                          on{" "}
                          {moment(
                            ticketData.returnSegment[0].departureDateTime
                          ).format(uidateFormat)}
                        </span>
                      </Col>
                    </Row>
                    {ticketData.returnSegment.length > 0
                      ? ticketData.returnSegment.map((flightSegment, i) => (
                        <Row key={i}>
                          <Col md={8} xs={24} className="city-tt-f5">
                            <div className="logo-f3-text">
                              <div className="sg-logo-flight">
                              <p> {`${flightSegment.flightCode} - ${flightSegment.flightNumber}`}</p>
                                
                              </div>
                            </div>
                          </Col>
                          <Col md={16} xs={24} className="city-tt-f4">
                            <div className="city-from-d3">
                              <div className="city-f55">
                                <p>{flightSegment.origin}</p>
                                <span className="d-block mt-1">
                                      {formatFlightTime(
                                        flightSegment.departureDateTime
                                      )}
                                    </span>
                              </div>
                              <div className="city-f55">
                                <p className="dotted-line-flight"></p>
                                <span>
                                  {calculateDuration(
                                    flightSegment.departureDateTime,
                                    flightSegment.arrivalDateTime
                                  )}
                                </span>
                              </div>
                              <div className="city-f55">
                                <p>{flightSegment.destination}</p>
                                <span className="d-block mt-1">
                                      {formatFlightTime(
                                        flightSegment.arrivalDateTime
                                      )}
                                    </span>
                                    {flightSegment.arrivalTerminal ? (
                                      <span>
                                        Terminal- {flightSegment.arrivalTerminal}
                                      </span>
                                    ) : null}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ))
                      : null}

                    <Row className="baggagge-bottom-sec">
                      <Col md={6} xs={24} className="city-tt-f6">
                        <div className="baggagge-f4">
                          <p className="baggagge-icon-4">
                            <i
                              className="fa fa-suitcase"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <p className="info-line-bag">Baggage Included</p>
                        </div>
                      </Col>

                      <Col md={4} xs={24} className="city-tt-f6">
                        <div className="baggagge-f4">
                          
                          <div className="allowance-baggage">
                            <p style={{ color: "#757575" }}>Check-in</p>
                            <span>
                              {ticketData.returnBaggageInfo.length > 0
                                ? ticketData.returnBaggageInfo[0].BaggageInfo
                                : null}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col md={8} xs={24} className="city-tt-f6">
                        <div className="baggagge-f4">
                          

                          <div className="allowance-baggage">
                            <p style={{ color: "#757575" }}>Hand Baggage</p>
                            <span>
                              {ticketData.returnBaggageInfo.length > 0
                                ? ticketData.returnBaggageInfo[0]
                                  .cabinBaggageInfo
                                : null}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card>) : null}
                </>
              ) : null}
              <div style={{ padding: 10 }}></div>
              <Card className="card-tic-f2">
                <Row>
                  <Col md={20} xs={24} className="city-tt-f4">
                    <p style={{ color: "#3f52b9" }}>Contact Details</p>
                    <p>Contact Details</p>
                      <span>
                        All updates sent by airlines are below given details.
                      </span>
                  </Col>
                 
                </Row>

                <Row>
                  <Col md={6} xs={24} className="contact-person-d4">
                    <span>Name</span>
                    <p>{ticketData.guestName}</p>
                  </Col>
                  <Col md={6} xs={24} className="contact-person-d4">
                    <span>Mobile Number</span>
                    <p>{ticketData.guestMobileNo}</p>
                  </Col>
                  <Col md={6} xs={24} className="contact-person-d4">
                    <span>Email</span>
                    <p>{ticketData.guestEmaiId}</p>
                  </Col>
                  <Col md={6} xs={24} className="contact-person-d4">
                    <span>Nationality</span>
                    <p>
                    
                      {ticketData.passengers[0].passengerNationality}
                    </p>
                  </Col>
                  
                </Row>
              </Card>
              <div style={{ padding: 10 }}></div>
             
              <Card className="card-tic-f2">
                <Row>
                  <Col md={20} xs={24} className="city-tt-f4">
                    <p style={{ color: "#3f52b9" }}>Passengers Details</p>
                  </Col>
                </Row>
                {ticketData?.passengers?.length > 0
                  ? ticketData?.passengers?.map((item) => (
                    <Row className="pax-add-ssr">
                      <Col md={12} xs={24} className="contact-person-d4">
                        <span>Pax Name : <span className="pax-ssr">{item?.firstName} {item?.lastName}</span></span>
                        {/* <p>
                          {item.firstName} {item.lastName}
                        </p> */}
                        {item?.mealPref?.length > 0 ?
                          <div>
                            <div className="s-meals">Meal - Opted :</div>
                            <div className="r-meals">

                              {item?.mealPref?.map((meals, i) => {
                                return (
                                  <>
                                    {
                                      meals?.map((meal, j) => {
                                        return (
                                          <>
                                            {meal?.map((m, k) => {
                                              return (
                                                <span className="meals-sel">
                                                  <Col className="meals-col">
                                                    <div className="meals-city">{m?.cityPair} {" - "}
                                                      
                                                      <span className="pax-ssr-txt">{m?.mealDesc}{i <= j ? (", ") : ""}</span></div>
                                                   
                                                  </Col>
                                                </span>);

                                            })}
                                          </>)
                                      })}
                                  </>
                                );
                              })}

                           
                            </div>
                          </div> : null}
                      </Col>
                      <Col md={6} xs={24} className="contact-person-d4">
                        <span>Passenger Type : <span className="pax-ssr">{item?.paxType === "ADT"
                          ? "Adult"
                          : item?.paxType === "CHD"
                            ? "Child"
                            : "Infant"}
                        </span></span>
                        {item?.baggagePref?.length > 0 ?
                          <div>
                            <div className="s-meals">Baggage - Opted :</div>
                            <div className="r-meals">
                              {item?.baggagePref?.map((bags, i) => {
                                return (
                                  <>
                                    {bags?.map((bag, j) => {
                                      return (
                                        <>
                                          {bag?.map((b, k) => {
                                            return (
                                              <>

                                              
                                                <span className="meals-sel">
                                                  <Col className="meals-col">
                                                    <div className="meals-city">{b?.cityPair} {" - "}
                                                     
                                                      <span className="pax-ssr-txt">{b?.baggWeight} {" Kg"} {i < j ? (", ") : ""}</span></div>
                                                   

                                                  </Col>
                                                </span>
                                               
                                              </>
                                            );

                                          })}
                                        </>)
                                    })}
                                  </>
                                );
                              })}
                              
                            </div>
                          </div> : null}
                      </Col>
                      {item.dob ? (
                        <Col md={6} xs={24} className="contact-person-d4">
                          <span>DOB</span>
                          <p>{moment(item.dob).format(uidateFormat)}</p>
                        </Col>
                      ) : null}
                      
                    </Row>
                  ))
                  : null}
              </Card>

              {ticketData?.insuranceRequired === 1 &&
                ticketData.insuranceData &&
                ticketData?.insuranceData?.serviceType === 1 ? (
                <Card className="insurance-section card-tic-f2">
                  <h5 style={{ fontSize: "16px" }}>
                    THE SMART INSURANCE COVER
                  </h5>
                  <p>EASY CLAIM PROCESS | NO QUESTIONS ASKED</p>

                  <Row gutter={[16, 16]} align="stretch" className="mt-4">
                    <Col md={8} sm={24} xs={24}>
                      <div className="insurance-box">
                        {ticketData?.insuranceData.description_box_1}
                      </div>
                    </Col>
                    <Col md={8} sm={24} xs={24}>
                      <div className="insurance-box">
                        {ticketData?.insuranceData.description_box_2}
                      </div>
                    </Col>
                    <Col md={8} sm={24} xs={24}>
                      <div className="insurance-box">
                        {ticketData?.insuranceData.description_box_3}
                      </div>
                    </Col>
                  </Row>

                  <div className="insurance-coverage">
                    <SafetyCertificateOutlined />
                    <span>
                      Insurance Coverage Amount :{" "}
                      {ticketData?.insuranceData.insuranceCoverage}
                    </span>
                  </div>
                </Card>
              ) : null}
              <div style={{ padding: 10 }}></div>
              <Card className="card-tic-f2">
                <Row className="details-airlines-contact">
                  <Col md={20} xs={24} className="city-tt-f4">
                    <p style={{ color: "#3f52b9" }}>Fare Details</p>
                  </Col>
                </Row>

                <Row>
                  <Col md={18} xs={12} className="contact-person-d4">
                    <p>Base Fare</p>
                  </Col>
                  <Col md={6} xs={12} className="contact-person-d4">
                  <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {baseAmount}</span>
                   
                  </Col>
                </Row>
                <Row>
                  <Col md={18} xs={12} className="contact-person-d4">
                    <p>Surge and Taxes:YQ:</p>
                  </Col>
                  <Col md={6} xs={12} className="contact-person-d4">
                  <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {taxAmount}</span>
                   
                  </Col>
                </Row>
                {totalmeal > 0 ?
                  <Row>
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Total Meals :</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                    <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {totalmeal}</span>
                      
                    </Col>
                  </Row> : null}
                {totalbagg > 0 ?
                  <Row>
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Additional Baggage :</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                    <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {totalbagg}</span>
                     
                    </Col>
                  </Row> : null}
                {convienenceFee > 0 ? (
                  <Row>
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Conveneience Fee</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                    <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {convienenceFee}</span>
                      
                    </Col>
                  </Row>
                ) : null}
                {ticketData?.insuranceRequired === 1 &&
                  ticketData.insuranceData ? (
                  <Row>
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Insurance Amount</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                    <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {insuranceTotal}</span>
                     
                    </Col>
                  </Row>
                ) : null}
                {discount > 0 ? (
                  <Row>
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Discount</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                    <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {discount}</span>
                    
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col md={18} xs={12} className="contact-person-d4">
                    <p>Grand Total</p>
                  </Col>
                  <Col md={6} xs={12} className="contact-person-d4">
                  <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {grandTotal}</span>
                    
                  </Col>
                </Row>
                {ticketData.BookingStatus === "Blocked" || 'Booked' ? null :
                  <Row>
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>RefundAmount</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                    <span style={{ color: "green", fontWeight: "600" }}> ₹ {RefundAmount}</span>
                      
                    </Col>
                  </Row>
                }
              </Card>
              <div style={{ padding: 10 }}></div>
              <Card className="card-tic-f2">
                <Row>
                  <Col md={20} xs={24} className="city-tt-f4">

                    <p style={{ color: "#e20e27" }}>{"Terms & Conditions"}</p>
                  </Col>
                </Row>

                <Row>
                  <Col md={24} xs={24} className="contact-person-d4">
                    {cmsFareRules?.cancelPolicyDescription ? (
                      <div className="termCon_wrapper">
                        <div className="description">
                          <p className="title">Cancel Policy</p>
                          {ReactHtmlParser(
                            cmsFareRules?.cancelPolicyDescription
                          )}
                          {cmsFareRules?.covidRulesDescription ? (
                            <>
                              <p className="title">Covid Policy</p>

                              {ReactHtmlParser(
                                cmsFareRules?.covidRulesDescription
                              )}
                            </>
                          ) : null}
                          {cmsFareRules?.reschedulePolicyDescription ? (
                            <>
                              <p className="title">Reschedule Policy</p>

                              {ReactHtmlParser(
                                cmsFareRules?.reschedulePolicyDescription
                              )}
                            </>
                          ) : null}

                          {cmsFareRules?.otherRulesDescription ? (
                            <>
                              <p className="title">Other Policy</p>

                              {ReactHtmlParser(
                                cmsFareRules?.otherRulesDescription
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <p></p>
                      
                    )}
                  
                  </Col>
                </Row>
              </Card>
            </Col>
          ) : (
            <Col md={24} xs={24}>
              <p className="tic-city-name">
                
           
              <img style={{ width: 100, heigh: 50 }} src={LogoImg} />


              </p>

              <Card className="card-tic-f3">
                <Row gutter={[16, 8]}>
                  <Col md={10} sm={24} xs={24}>
                    <Row gutter={[16, 8]}>
                      <Col md={10} sm={10} xs={24}>
                        <div className="city-f3">
                          <p>{ticketData.source}</p>
                        </div>
                      </Col>
                      <Col md={4} sm={4} xs={24}>
                        <div className="city-f3">
                          <p>
                            <i
                              className="fa fa-long-arrow-right"
                              aria-hidden="true"
                            ></i>
                          </p>
                        </div>
                      </Col>
                      <Col md={10} sm={10} xs={24}>
                        <div className="city-f3">
                          <p>{ticketData.destination}</p>
                       
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={14} sm={24} xs={24}>
                    <Row>
                      <Col md={7} sm={8} xs={24}>
                        <div className="city-f4">
                          <p>
                            {moment(ticketData.journeyDate).format(
                              uidateFormat
                            )}
                          </p>
                          <span>Journey Date</span>
                        </div>
                      </Col>

                    </Row>
                  </Col>
                </Row>
                <Row className="row-top-f4">
                  <Col md={6} xs={24} className="city-f4">
                    <p>{ticketData.referenceNumber}</p>
                    <span>Ticket Ref Number</span>
                  </Col>
                  <Col md={4} xs={24} className="city-f4">
                    {getFlightTicketStatus(ticketData.bookingStatus)}

                    <span>Status</span>
                  </Col>
                  <Col md={6} xs={24} className="city-f4">
                    <p>
                      {ticketData.bookingDate
                        ? moment(ticketData.bookingDate).format(uidateFormat)
                        : ""}
                    </p>
                    <span>Booking Date</span>
                  </Col>
                  <Col md={5} xs={24} className="city-f4">
                    {ticketData?.pnr.split("~")?.length > 1 ? (
                      <p>Paid</p>
                    ) : (
                      <p>{ticketData?.pnr}</p>
                    )}
                    {ticketData?.pnr.split("~")?.length > 1 ? (
                      <span>Payment Status</span>
                    ) : (
                      <span>PNR</span>
                    )}
                  </Col>
                </Row>
              </Card>
              {ticketData.multiDestinationSegment.length > 0
                ? ticketData.multiDestinationSegment.map(
                  (flightSegments, id) => {
                    return (
                      <Card className="card-tic-f1">
                        {flightSegments.map((flightSegment, idx) => {
                          return (
                            <>
                              {" "}
                              <Row>
                                <Col md={7} xs={24} className="city-tt-f5">
                                  <div className="logo-f3-text">
                                    <div className="sg-logo-flight">
                                      <p>
                                        {" "}
                                        {`${ticketData.operator}  (${flightSegment.airlineName} -
                            ${flightSegment.flightNumber})`}
                                      </p>
                                      
                                    </div>
                                  </div>
                                </Col>
                                {ticketData?.pnr.split("~")?.length > 1 && (
                                  <Col md={4} xs={24}>
                                    PNR: {ticketData?.pnr.split("~")[id]}
                                  </Col>
                                )}
                                <Col md={13} xs={24} className="city-tt-f4">
                                  <div className="city-from-d3">
                                    <div className="city-f55">
                                      <p>{flightSegment.origin}</p>
                                      <span>
                                        {formatFlightTime(
                                          flightSegment.departureDateTime
                                        )}
                                      </span>
                                    </div>
                                    <div className="city-f55">
                                      <p className="dotted-line-flight"></p>
                                      <span>
                                        {calculateDuration(
                                          flightSegment.departureDateTime,
                                          flightSegment.arrivalDateTime
                                        )}
                                      </span>
                                    </div>
                                    <div className="city-f55">
                                      <p>{flightSegment.destination}</p>
                                      <span>
                                        {formatFlightTime(
                                          flightSegment.arrivalDateTime
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </>
                          );
                        })}{" "}
                        <Row className="baggagge-bottom-sec">
                          <Col md={6} xs={24} className="city-tt-f6">
                            <div className="baggagge-f4">
                              <p className="baggagge-icon-4">
                                <i
                                  className="fa fa-suitcase"
                                  aria-hidden="true"
                                ></i>
                              </p>
                              <p className="info-line-bag">
                                Baggage Included
                              </p>
                            </div>
                          </Col>

                          <Col md={4} xs={24} className="city-tt-f6">
                            <div className="baggagge-f4">
                             

                              <div className="allowance-baggage">
                                <p style={{ color: "#757575" }}>Check-in</p>
                                <span>
                                  {ticketData.multiDestinationBaggage
                                    ?.length > 0
                                    ? ticketData.multiDestinationBaggage?.[
                                      id
                                    ]?.[0].BaggageInfo
                                    : null}
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col md={8} xs={24} className="city-tt-f6">
                            <div className="baggagge-f4">
                             

                              <div className="allowance-baggage">
                                <p style={{ color: "#757575" }}>Hand Baggage</p>
                                <span>
                                  {ticketData.multiDestinationBaggage
                                    ?.length > 0
                                    ? ticketData.multiDestinationBaggage?.[
                                      id
                                    ]?.[0].cabinBaggageInfo
                                    : null}
                                </span>
                              </div>
                            </div>
                          </Col>
                        </Row>{" "}
                      </Card>
                    );
                  }
                )
                : null}

              {ticketData.returnSegment.length > 0 &&
                ticketData.tripType === "roundTrip" ? (
                <>
                  <p className="tic-city-name-d2">
                    Return Details
                  </p>
                  <Card className="card-tic-f3">
                    <Row gutter={[16, 8]}>
                      <Col md={10} sm={24} xs={24}>
                        <Row gutter={[16, 8]}>
                          <Col md={10} sm={10} xs={24}>
                            <div className="city-f3">
                              <p>{ticketData.destination}</p>
                              <span>
                                {`${ticketData.returnSegment[0].flightCode} -
                            ${ticketData.returnSegment[0].flightNumber}`}
                              </span>
                            </div>
                          </Col>
                          <Col md={4} sm={4} xs={24}>
                            <div className="city-f3">
                              <p>
                                <i
                                  className="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </p>
                            </div>
                          </Col>
                          <Col md={10} sm={10} xs={24}>
                            <div className="city-f3">
                              <p>{ticketData.source}</p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={14} sm={24} xs={24}>
                        <Row>
                          <Col md={7} sm={8} xs={24}>
                            <div className="city-f4">
                              <p>
                                {moment(
                                  ticketData.returnSegment[0].departureDateTime
                                ).format(uidateFormat)}
                              </p>
                              <span>Journey Date</span>
                            </div>
                          </Col>
                          <Col md={17} sm={16} xs={24}>
                            <div className="city-f4">
                              <p className="word-break break-pnr">
                                {ticketData.pnr.includes("~")
                                  ? ticketData.pnr.split("~")[1]
                                  : ticketData.pnr}
                              </p>
                              <span>PNR/Booking No</span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="border-hr"></div>
                    <Row className="row-top-f4">
                      <Col md={6} xs={24} className="city-f4">
                        <p>{ticketData.referenceNumber}</p>
                        <span>Ticket Ref Number</span>
                      </Col>
                      <Col md={4} xs={24} className="city-f4">
                        {getFlightTicketStatus(ticketData.bookingStatus)}

                        <span>Status</span>
                      </Col>
                      <Col md={6} xs={24} className="city-f4">
                        <p>
                          {ticketData.bookingDate
                            ? moment(ticketData.bookingDate).format(
                              uidateFormat
                            )
                            : ""}
                        </p>
                        <span>Booking Date</span>
                      </Col>
                      <Col md={5} xs={24} className="city-f4">
                        <p>Paid</p>
                        <span>Payment Status</span>
                      </Col>
                    </Row>
                  </Card>
                  <Card className="card-tic-f1">
                    <Row className="info-flight-t22">
                      <Col md={24} xs={24} className="city-tt-f4">
                        <p style={{ color: "#3f52b9" }}>Travel Information</p>
                        <span>
                          Here are the details of your flight from{" "}
                          {ticketData.returnSegment[0].origin} to{" "}
                          {
                            ticketData.returnSegment[
                              ticketData.returnSegment.length - 1
                            ].destination
                          }{" "}
                          on{" "}
                          {moment(
                            ticketData.returnSegment[0].departureDateTime
                          ).format(uidateFormat)}
                        </span>
                      </Col>
                    </Row>
                    {ticketData.returnSegment.length > 0
                      ? ticketData.returnSegment.map((flightSegment, i) => (
                        <Row key={i}>
                          <Col md={8} xs={24} className="city-tt-f5">
                            <div className="logo-f3-text">
                              <div className="sg-logo-flight">
                                <p>
                                  {" "}
                                  {`${ticketData.operator}  (${flightSegment.airlineName} -
                            ${flightSegment.flightNumber})`}
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col md={16} xs={24} className="city-tt-f4">
                            <div className="city-from-d3">
                              <div className="city-f55">
                                <p>{flightSegment.origin}</p>
                                <span>
                                  {formatFlightTime(
                                    flightSegment.departureDateTime
                                  )}
                                </span>
                              </div>
                              <div className="city-f55">
                                <p className="dotted-line-flight"></p>
                                <span>
                                  {calculateDuration(
                                    flightSegment.departureDateTime,
                                    flightSegment.arrivalDateTime
                                  )}
                                </span>
                              </div>
                              <div className="city-f55">
                                <p>{flightSegment.destination}</p>
                                <span>
                                  {formatFlightTime(
                                    flightSegment.arrivalDateTime
                                  )}
                                </span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ))
                      : null}

                    <Row className="baggagge-bottom-sec">
                      <Col md={6} xs={24} className="city-tt-f6">
                        <div className="baggagge-f4">
                          <p className="baggagge-icon-4">
                            <i
                              className="fa fa-suitcase"
                              aria-hidden="true"
                            ></i>
                          </p>
                          <p className="info-line-bag">Baggage Included</p>
                        </div>
                      </Col>

                      <Col md={4} xs={24} className="city-tt-f6">
                        <div className="baggagge-f4">
                         

                          <div className="allowance-baggage">
                            <p style={{ color: "#757575" }}>Check-in</p>
                            <span>
                              {ticketData.returnBaggageInfo.length > 0
                                ? ticketData.returnBaggageInfo[0].BaggageInfo
                                : null}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col md={8} xs={24} className="city-tt-f6">
                        <div className="baggagge-f4">
                         

                          <div className="allowance-baggage">
                            <p style={{ color: "#757575" }}>Hand Baggage</p>
                            <span>
                              {ticketData.returnBaggageInfo.length > 0
                                ? ticketData.returnBaggageInfo[0]
                                  .cabinBaggageInfo
                                : null}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </>
              ) : null}
              <div style={{ padding: 10 }}></div>
              <Card className="card-tic-f2">
                <Row>
                  <Col md={20} xs={24} className="city-tt-f4">
                    <p style={{ color: "#3f52b9" }}>Contact Details</p>
                    <span>
                      Any Communication by airlines will be sent these details
                    </span>
                  </Col>
                 
                </Row>

                <Row>

                  <Col md={6} xs={24} className="contact-person-d4">
                    <span>Name</span>
                    <p>{ticketData.guestName}</p>
                  </Col>
                  <Col md={6} xs={24} className="contact-person-d4">
                    <span>Mobile Number</span>
                    <p>{ticketData.guestMobileNo}</p>
                  </Col>
                  <Col md={6} xs={24} className="contact-person-d4">
                    <span>Email</span>
                    <p>{ticketData.guestEmaiId}</p>
                  </Col>
                  <Col md={6} xs={24} className="contact-person-d4">
                    <span>Nationality</span>
                    <p>
                      
                      {ticketData.passengers[0].passengerNationality}
                    </p>
                  </Col>

                
                </Row>
              </Card>
              <div style={{ padding: 10 }}></div>
              <Card className="card-tic-f2">
                <Row>
                  <Col md={20} xs={24} className="city-tt-f4">
                    <p style={{ color: "#3f52b9" }}>Passengers Details</p>
                  </Col>
                </Row>
                {ticketData?.passengers?.length > 0
                  ? ticketData?.passengers?.map((item) => (
                    <Row className="pax-add-ssr">
                      <Col md={12} xs={24} className="contact-person-d4">
                        <span>Pax Name : <span className="pax-ssr">{item?.firstName} {item?.lastName}</span></span>
                       
                        {item?.mealPref?.length > 0 ?
                          <div>
                            <div className="s-meals">Meal - Opted :</div>
                            <div className="r-meals">

                              {item?.mealPref?.map((meals, i) => {
                                return (
                                  <>
                                    {
                                      meals?.map((meal, j) => {
                                        return (
                                          <>
                                            {meal?.map((m, k) => {
                                              return (
                                                <span className="meals-sel">
                                                  <Col className="meals-col">
                                                    <div className="meals-city">{m?.cityPair} {" - "}
                                                      
                                                      <span className="pax-ssr-txt">{m?.mealDesc}{i <= j ? (", ") : ""}</span></div>
                                                   

                                                  </Col>
                                                </span>);

                                            })}
                                          </>)
                                      })}
                                  </>
                                );
                              })}

                             
                            </div>
                          </div> : null}
                      </Col>
                      <Col md={6} xs={24} className="contact-person-d4">
                        <span>Passenger Type : <span className="pax-ssr">{item?.paxType === "ADT"
                          ? "Adult"
                          : item?.paxType === "CHD"
                            ? "Child"
                            : "Infant"}
                        </span></span>
                        {item?.baggagePref?.length > 0 ?
                          <div>
                            <div className="s-meals">Baggage - Opted :</div>
                            <div className="r-meals">
                              {item?.baggagePref?.map((bags, i) => {
                                return (
                                  <>
                                    {bags?.map((bag, j) => {
                                      return (
                                        <>
                                          {bag?.map((b, k) => {
                                            return (
                                              <>

                                              
                                                <span className="meals-sel">
                                                  <Col className="meals-col">
                                                    <div className="meals-city">{b?.cityPair} {" - "}
                                                    
                                                      <span className="pax-ssr-txt">{b?.baggWeight} {" Kg"} {i < j ? (", ") : ""}</span></div>
                                                    

                                                  </Col>
                                                </span>
                                                
                                              </>
                                            );

                                          })}
                                        </>)
                                    })}
                                  </>
                                );
                              })}
                             
                            </div>
                          </div> : null}
                      </Col>
                      {item?.dob ? (
                        <Col md={6} xs={24} className="contact-person-d4">
                          <span>DOB</span>
                          <p>{moment(item?.dob).format(uidateFormat)}</p>
                        </Col>
                      ) : null}
                    
                    </Row>
                  ))
                  : null}
              </Card>
              {ticketData?.insuranceRequired === 1 &&
                ticketData.insuranceData &&
                ticketData?.insuranceData?.serviceType === 1 ? (
                <Card className="insurance-section card-tic-f2">
                  <h5 style={{ fontSize: "16px" }}>
                    THE SMART INSURANCE COVER
                  </h5>
                  <p>EASY CLAIM PROCESS | NO QUESTIONS ASKED</p>

                  <Row gutter={[16, 16]} align="stretch" className="mt-4">
                    <Col md={8} sm={24} xs={24}>
                      <div className="insurance-box">
                        {ticketData?.insuranceData.description_box_1}
                      </div>
                    </Col>
                    <Col md={8} sm={24} xs={24}>
                      <div className="insurance-box">
                        {ticketData?.insuranceData.description_box_2}
                      </div>
                    </Col>
                    <Col md={8} sm={24} xs={24}>
                      <div className="insurance-box">
                        {ticketData?.insuranceData.description_box_3}
                      </div>
                    </Col>
                  </Row>

                  <div className="insurance-coverage">
                    <SafetyCertificateOutlined />
                    <span>
                      Insurance Coverage Amount :{" "}
                      {ticketData?.insuranceData.insuranceCoverage}
                    </span>
                  </div>
                </Card>
              ) : null}
              <div style={{ padding: 10 }}></div>
              <Card className="card-tic-f2">
                <Row className="details-airlines-contact">
                  <Col md={20} xs={24} className="city-tt-f4">
                    <p style={{ color: "#3f52b9" }}>Fare Details</p>
                  </Col>
                </Row>

                <Row>
                  <Col md={18} xs={12} className="contact-person-d4">
                    <p>Base Fare</p>
                  </Col>
                  <Col md={6} xs={12} className="contact-person-d4">
                    <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {baseAmount}</span>
                   
                  </Col>
                </Row>
                <Row>
                  <Col md={18} xs={12} className="contact-person-d4">
                    <p>Surge and Taxes:YQ:</p>
                  </Col>
                  <Col md={6} xs={12} className="contact-person-d4">
                    <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {taxAmount}</span>
                   
                  </Col>
                </Row>
                {totalmeal > 0 ?
                  <Row>
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Total Meals :</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {totalmeal}</span>
                      
                    </Col>
                  </Row> : null}
                {totalbagg > 0 ?
                  <Row>
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Additional Baggage :</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {totalbagg}</span>
                     
                    </Col>
                  </Row> : null}
                {convienenceFee > 0 ? (
                  <Row>
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Conveneience Fee</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {convienenceFee}</span>
                     
                    </Col>
                  </Row>
                ) : null}
                {ticketData?.insuranceRequired === 1 &&
                  ticketData.insuranceData ? (
                  <Row>
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Insurance Amount</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {insuranceTotal}</span>

                      
                    </Col>
                  </Row>
                ) : null}
                {discount > 0 ? (
                  <Row>
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Discount</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {discount}</span>
                    
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col md={18} xs={12} className="contact-person-d4">
                    <p>Grand Total</p>
                  </Col>
                  <Col md={6} xs={12} className="contact-person-d4">
                    <span style={{ color: "#35459c", fontWeight: "600" }}> ₹ {grandTotal}</span>
                   
                  </Col>
                </Row>
                {ticketData.BookingStatus === "Blocked" || 'Booked' ? null :
                  <Row>
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>RefundAmount</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <span>₹ {RefundAmount}</span>
                     
                    </Col>
                  </Row>
                }
              </Card>
              <div style={{ padding: 10 }}></div>
              <Card className="card-tic-f2">
                <Row>
                  <Col md={20} xs={24} className="city-tt-f4">
                    <p style={{ color: "#e20e27" }}>{"Terms & Conditions"}</p>
                  </Col>
                </Row>


              </Card>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default TicketFlight;
